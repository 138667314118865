<template>
  <div>
    <div class="text-right">
      <button
        v-if="!redefineCategories && overview.numberOfCategories > 0"
        :disabled="loading || overview.status !== 'opened'"
        class="btn btn-success text-white"
        @click="createNewEvaluation"
      >
        <i
          :class="loading ? 'fa-spinner fa-spin' : 'fa-plus'"
          class="fa"
        /> Rozpocznij nową ewaluację
      </button>
      <router-link
        v-if="overview.numberOfCategories === 0 && !hideLinkToCreator"
        :to="{name: 'icf_creator', params: {ipzId: overview.ipzId}}"
        class="btn btn-success text-white"
      >
        <i
          class="fa fa-plus"
        /> Przejdź do kreatora ICF
      </router-link>
    </div>
    <div>
      <div class="card-box p-0 m-t-10 table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Osoba przeprowadzająca badanie</th>
              <th>Kategorie kluczowe ICF</th>
              <th>Osoba ewaluująca</th>
              <th>Status ewaluacji</th>
              <th>Data ewaluacji</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="evaluation in evaluations"
              :key="evaluation.id"
            >
              <td>{{ evaluation.workerName }}</td>
              <td>{{ coreSetNames(evaluation) }}</td>
              <i18n
                :msgid="evaluation.evaluatorType ? evaluation.evaluatorType : 'brak'"
                component="td"
              />
              <td>
                <b-badge
                  class="status-badge"
                  :variant="getBadgeVariant(evaluation.evaluationStatus)"
                >
                  <i18n
                    component="fragment"
                    :msgid="evaluation.evaluationStatus"
                  />
                </b-badge>
              </td>
              <td>{{ evaluationDate(evaluation.createdAt) }}</td>
              <td>
                <is-granted
                  :privileges="[
                    'GET_FINISHED_EVALUATION_BY_ID',
                    'PRINT_FINISHED_EVALUATION_BY_ID',
                    'EDIT_FINISHED_EVALUATION_BY_ID',
                    'EVALUATION_VIEW',
                  ]"
                  :subject="evaluation.id"
                  component="fragment"
                >
                  <b-dropdown
                    text="Opcje"
                    variant="outline-secondary"
                  >
                    <is-granted
                      :privileges="['GET_FINISHED_EVALUATION_BY_ID']"
                      :subject="evaluation.id"
                      component="fragment"
                    >
                      <b-dropdown-item
                        :to="{
                          name: 'ipz_evaluation_result',
                          params: {ipzId: overview.ipzId, evaluationId: evaluation.id}
                        }"
                      >
                        <i class="fa fa-eye" /> Podgląd
                      </b-dropdown-item>
                    </is-granted>
                    <is-granted
                      :privileges="['PRINT_FINISHED_EVALUATION_BY_ID']"
                      :subject="evaluation.id"
                      component="fragment"
                    >
                      <b-dropdown-item
                        :href="`/api/ipzs/${overview.ipzId}/evaluations/${evaluation.id}/result/pdf`"
                        target="_blank"
                      >
                        <i class="fa fa-print" /> Drukuj
                      </b-dropdown-item>
                    </is-granted>
                    <is-granted
                      :privileges="['EDIT_FINISHED_EVALUATION_BY_ID']"
                      :subject="evaluation.id"
                      component="fragment"
                    >
                      <b-dropdown-item-btn @click="openEditEvaluationModal(evaluation)">
                        <i class="fas fa-pencil-alt" /> Edytuj datę ewaluacji
                      </b-dropdown-item-btn>
                    </is-granted>
                    <is-granted
                      v-if="overview.status === 'opened'"
                      :privileges="['EVALUATION_VIEW']"
                      :subject="evaluation.id"
                      component="fragment"
                    >
                      <b-dropdown-item
                        :to="{
                          name: 'ipz_evaluation_view',
                          params: {ipzId: overview.ipzId, evaluationId: evaluation.id }
                        }"
                      >
                        <i class="fa fa-edit" /> Edytuj ewaluację
                      </b-dropdown-item>
                    </is-granted>
                  </b-dropdown>
                </is-granted>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination-description
          :items-count="evaluations.length"
          :page="evaluationPagination.currentPage"
          :per-page="evaluationPagination.perPage"
          :total-count="evaluationPagination.totalRows"
        >
          <b-pagination
            v-model="evaluationsPage"
            :total-rows="evaluationPagination.totalRows"
            :per-page="evaluationPagination.perPage"
            align="right"
            class="mb-0"
            @input="loadEvaluations"
          />
        </pagination-description>
      </div>
    </div>
    <EditEvaluationModal
      :evaluation-id="evaluationId"
      :evaluation-created-at="evaluationCreatedAt"
      @editEvaluationModalEvent="loadEvaluations"
    />
  </div>
</template>

<script>
import IsGranted from "../../IsGranted";
import create from "../../../rest/create";
import {generateUuid} from "../../../utils/uuid/generateUuid";
import I18n from "../../i18n";
import stringifyDate from "../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import parseDate from "../../../utils/date/parseDate";
import read from "../../../rest/read";
import PaginationDescription from "../../Pagination/PaginationDescription";
import EditEvaluationModal from "./EditEvaluationModal";
import t from "@/i18n";

export default {
  name: "PatientEvaluationsList",
  components: {
    PaginationDescription,
    I18n,
    IsGranted,
    EditEvaluationModal,
  },
  props: {
    overview: {type: Object, required: true},
    hideLinkToCreator: {type: Boolean, default: false},
    redefineCategories: {type: Boolean, default: false}
  },
  data() {
    return {
      loading: false,
      evaluationsPage: 1,
      evaluationPagination: {perPage: 5, totalRows: 0},
      evaluations: [],
      evaluationId: "",
      evaluationCreatedAt: ""
    };
  },
  watch: {
    "overview.ipzId"() {
      this.loadEvaluations();
    },
  },
  async mounted() {
    await this.loadEvaluations();
  },
  methods:{
    coreSetNames(evaluation) {
      return evaluation.coreSets.map(name => t(name)).join(", ");
    },
    async createNewEvaluation() {
      this.loading = true;
      const evaluationId = generateUuid();
      try {
        await create(`/api/ipzs/${this.overview.ipzId}/evaluations`, {
          evaluationId: evaluationId
        });
        await this.$router.push({name: "ipz_evaluation_view", params: {ipzId: this.overview.ipzId, evaluationId}})
      } catch (exception) {
        //todo handle error
        this.loading = false;
        window.location.reload();
      }
    },
    evaluationDate(dateString) {
      return stringifyDate(parseDate(dateString), DATE_FORMAT.DATE_TIME_SEC);
    },
    async loadEvaluations() {
      this.loading = true;
      const {evaluations, pagination} = await read(`/api/ipzs/${this.overview.ipzId}/details/evaluations`, {
        page: this.evaluationsPage,
        perPage: this.evaluationPagination.perPage,
      });

      this.evaluations = evaluations;
      this.evaluationPagination = pagination;
      this.loading = false;
    },
    openEditEvaluationModal(evaluation) {
      this.evaluationId = evaluation.id;
      this.evaluationCreatedAt = evaluation.createdAt;
      this.$bvModal.show("edit-evaluation-modal");
    },
    getBadgeVariant(status) {
      switch (status) {
        case "opened":
          return "success";
        case "finished":
          return "primary";
        case "canceled":
          return "danger";
        default:
          throw Error(`Not supported status: ${status}`);
      }
    }
  }
};
</script>

<style scoped lang="scss">
  .status-badge{
    width: 5rem;
  }
</style>
