import {Location} from "vue-router"

interface ReportItem {
  id: string,
  name: string,
  path: Location,
  description: string,
  legendItems?: Array<LegendItem>,
  permissionAttributes: Array<string>
}

enum LegendNames {
  DATE_RANGE = "Data początkowa / Data końcowa",
  DATE_SHORT_RANGE = "Data od, Data do",
  STATUS = "Status",
  PATIENT = "Pacjent",
  PATIENT_PRESENCE = "Obecność pacjenta (opcjonalnie)",
  CANCELED_VISIT = "Uwzględnić wizyty odwołane",
  CASE_MANAGER = "Dodaj pacjentów wybranego Case managera",
  SPECIALIST = "Specjalista",
  MONTH = "Miesiąc",
  INACTIVE_ACCOUNT = "Dołącz nieaktywne konta",
  IPZ_STATUS = "Status IPZ",
  IPZ_DATE_RANGES = "Data pierwszego IPZ Klienta: Od / Data pierwszego IPZ Klienta: Do",
  IPZ_SIGNATURE = "Wygenerowany IPZ do podpisu",
  BRANCH_IDS = "Lista komórek",
  ESTABLISHMENT_IDS = "Lista Podmiotów"
}

interface LegendItem {
  name: LegendNames,
  description: string,
}

const reportItems: Array<ReportItem> = [
  {
    id: "monthly-stats",
    name: "Usługi w miesiącu",
    path: {name: "viewReportAppointmentMonthlyStats"},
    description: "Raport zawiera ilość usług lokalnych oraz zdalnych w podziale na dni oraz ogólną sumę wszystkich"+
      " wizyt w wybranym przedziale czasowym w aplikacji Auxilio. Użytkownik ma możliwość samodzielnie określić" +
      " interesujący go przedział czasowy oraz wybrać status usług (utworzona, zarejestrowana, rozpoczęta," +
      " zakończona, odwołana).",
    legendItems: [
      {
        name: LegendNames.DATE_SHORT_RANGE,
        description: "zakres dat jaki ma obejmować raport"
      },
      {
        name: LegendNames.STATUS,
        description: "status wyszukiwanych wizyt lokalnych lub zdalnych"
      }
    ],
    permissionAttributes: ["REPORT_APPOINTMENT_MONTHLY_STATS"]
  },
  {
    id: "specialist_list",
    name: "Specjaliści",
    path: {name: "report_specialist_list"},
    description: "Raport zawiera listę wszystkich zarejestrowanych w systemie Auxilio pracowników,z podziałem na:" +
      " imię, nazwisko, PESEL oraz datę rejestracji pracownika. Raport uwzględnia wszystkich pracowników," +
      " niezależnie od tego czy konto danego pracownika jest aktywne czy nie.",
    permissionAttributes: ["REPORT_SPECIALIST_LIST"]
  },
  {
    id: "ikup_children",
    name: "IKUP dziecięcy",
    path: {name: "ikup_children_view"},
    description: "Raport zawiera listę usług zrealizowanych na rzecz konkretnego pacjenta w aplikacji Auxilio." +
      " Użytkownik ma możliwość wyboru konkretnego pacjenta, uwzględniając jego obecność oraz wizyty odwołane" +
      " w konkretnym przedziale czasowym.",
    legendItems: [
      {
        name: LegendNames.PATIENT,
        description: "wyszukiwarka pacjentów"
      },
      {
        name: LegendNames.PATIENT_PRESENCE,
        description: "możliwość określenia czy raport ma zawierać wizyty tylko z obecnością pacjenta (TAK) lub" +
          " bez obecności pacjenta (NIE)"
      },
      {
        name: LegendNames.CANCELED_VISIT,
        description: " możliwość określenia czy raport ma zawierać również wizyty odwołane (TAK), domyślnie" +
          " raport ich nie uwzględnia (NIE)"
      },
      {
        name: LegendNames.DATE_RANGE,
        description: "zakres dat jaki ma obejmować raport"
      }
    ],
    permissionAttributes: ["IKUP_CHILDREN"]
  },
  {
    id: "ikup_adults",
    name: "IKUP dla dorosłych",
    path: {name: "ikup_adults_view"},
    description: "Raport zawiera listę usług zrealizowanych na rzecz konkretnego pacjenta w aplikacji Auxilio." +
      " Użytkownik ma możliwość wyboru konkretnego pacjenta, uwzględniając jego obecność oraz wizyty odwołane" +
      " w konkretnym przedziale czasowym.",
    legendItems: [
      {
        name: LegendNames.PATIENT,
        description: "wyszukiwarka pacjentów"
      },
      {
        name: LegendNames.PATIENT_PRESENCE,
        description: "możliwość określenia czy raport ma zawierać wizyty tylko z obecnością pacjenta (TAK) lub" +
          " bez obecności pacjenta (NIE)"
      },
      {
        name: LegendNames.CANCELED_VISIT,
        description: " możliwość określenia czy raport ma zawierać również wizyty odwołane (TAK), domyślnie" +
          " raport ich nie uwzględnia (NIE)"
      },
      {
        name: LegendNames.DATE_RANGE,
        description: "zakres dat jaki ma obejmować raport"
      }
    ],
    permissionAttributes: ["IKUP_ADULTS"]
  },
  {
    id: "ikup_children_summary",
    name: "IKUP dziecięcy (Zbiorczy)",
    path: {name: "ikup_children_summary_view"},
    description: "Raport zbiorczy umożliwia wygenerowanie raportu IKUP dla większej ilości pacjentów jednocześnie."+
      " Użytkownik ma możliwość wybrania puli pacjentów, dla których mają się wygenerować raporty, uwzględniając" +
      " ich obecność oraz wizyty odwołane w konkretnym przedziale czasowym. Dodatkowo istnieje możliwość" +
      " odfiltrowania pacjentów przypisanych do wybranego Case managera.",
    legendItems: [
      {
        name: LegendNames.CASE_MANAGER,
        description: "lista case manager’ów do wyboru"
      },
      {
        name: LegendNames.PATIENT_PRESENCE,
        description: "możliwość określenia czy raport ma zawierać wizyty tylko z obecnością pacjenta (TAK) lub" +
          " bez obecności pacjenta (NIE)"
      },
      {
        name: LegendNames.PATIENT,
        description: "wyszukiwarka pacjentów"
      },
      {
        name: LegendNames.CANCELED_VISIT,
        description: " możliwość określenia czy raport ma zawierać również wizyty odwołane (TAK), domyślnie" +
          " raport ich nie uwzględnia (NIE)"
      },
      {
        name: LegendNames.DATE_RANGE,
        description: "zakres dat jaki ma obejmować raport"
      }
    ],
    permissionAttributes: ["CREATE_REQUEST_IKUP_CHILDREN_RECORDS"]
  },
  {
    id: "ikup_adults_summary",
    name: "IKUP dla dorosłych (Zbiorczy)",
    path: {name: "ikup_adults_summary_view"},
    description: "Raport zbiorczy umożliwia wygenerowanie raportu IKUP dla większej ilości pacjentów jednocześnie."+
      " Użytkownik ma możliwość wybrania puli pacjentów, dla których mają się wygenerować raporty, uwzględniając" +
      " ich obecność oraz wizyty odwołane w konkretnym przedziale czasowym.",
    legendItems: [
      {
        name: LegendNames.PATIENT,
        description: "wyszukiwarka pacjentów"
      },
      {
        name: LegendNames.PATIENT_PRESENCE,
        description: "możliwość określenia czy raport ma zawierać wizyty tylko z obecnością pacjenta (TAK) lub" +
          " bez obecności pacjenta (NIE)"
      },
      {
        name: LegendNames.CANCELED_VISIT,
        description: " możliwość określenia czy raport ma zawierać również wizyty odwołane (TAK), domyślnie " +
          " raport ich nie uwzględnia (NIE)"
      },
      {
        name: LegendNames.DATE_RANGE,
        description: "zakres dat jaki ma obejmować raport"
      }
    ],
    permissionAttributes: ["CREATE_REQUEST_IKUP_ADULTS_RECORDS"]
  },
  {
    id: "reports-schedule-specialist",
    name: "Harmonogram specjalisty",
    path: {name: "scheduleSpecialistView"},
    description: "Raport zawiera miesięczny harmonogram specjalisty w systemie Auxilio. Użytkownik ma możliwość" +
      " wyboru konkretnego specjalisty wraz z miesiącem, którego ma dotyczyć konkretny raport.",
    legendItems: [
      {
        name: LegendNames.SPECIALIST,
        description: "lista specjalistów do wyboru"
      },
      {
        name: LegendNames.MONTH,
        description: "data, jaki ma obejmować raport"
      },
    ],
    permissionAttributes: ["GET_SCHEDULE_SPECIALIST", "GET_OWN_SCHEDULER_REPORT"]
  },
  {
    id: "patient_medical_care_report",
    name: "Lista pacjentów pod opieką specjalistyczną",
    path: {name: "reportPatientMedicalCare"},
    description: "Raport zawiera listę pacjentów, którzy w danym okresie byli objęci opieką specjalistyczną, liczbę" +
      " odbytych wizyt z podziałem na poszczególnych specjalistów. Do raportu brane są pod uwagę jedynie wizyty" +
      " zamknięte.",
    legendItems: [
      {
        name: LegendNames.DATE_SHORT_RANGE,
        description: "zakres dat jaki ma obejmować raport"
      },
    ],
    permissionAttributes: ["PATIENT_MEDICAL_CARE_REPORT"]
  },
  {
    id: "appointments_in_branches_report",
    name: "Usługi w poradniach",
    path: {name: "reportAppointmentsInBranches"},
    description: "Raport zawiera listę usług, które zostały wykonane w określonym czasie. " +
      "Wizyty z wieloma specjalistami są reprezentowane jako jeden wiersz w raporcie",
    legendItems: [
      {
        name: LegendNames.DATE_SHORT_RANGE,
        description: "zakres dat jaki ma obejmować raport"
      },
      {
        name: LegendNames.BRANCH_IDS,
        description: "Lista komórek"
      }
    ],
    permissionAttributes: ["GET_APPOINTMENTS_IN_BRANCHES_REPORT"]
  },
  {
    id: "interventions_report",
    name: "Interwencje",
    path: {name: "reportInterventions"},
    description: "Raport zawiera wykaz interwencji w danym przedziale czasowym.",
    legendItems: [
      {
        name: LegendNames.DATE_SHORT_RANGE,
        description: "zakres dat jaki ma obejmować raport"
      }
    ],
    permissionAttributes: ["INTERVENTIONS_REPORT"]
  },
  {
    id: "anonymous_services_report",
    name: "Usługi Anonimowe",
    path: {name: "reportAnonymousServices"},
    description: "Raport zawiera wykaz usług anonimowych w danym przedziale czasowym.",
    legendItems: [
      {
        name: LegendNames.DATE_SHORT_RANGE,
        description: "zakres dat jaki ma obejmować raport"
      }
    ],
    permissionAttributes: ["ANONYMOUS_SERVICES_REPORT"]
  },
  {
    id: "cancel_reasons",
    name: "Odwołane wizyty",
    path: {name: "cancelReasons"},
    description: "Raport zawiera odwołane wizyty",
    legendItems: [
      {
        name: LegendNames.DATE_RANGE,
        description: "zakres dat jaki ma obejmować raport"
      },
      {
        name: LegendNames.INACTIVE_ACCOUNT,
        description: "uwzględnienie specjalistów, którzy mają zablokowane konta (TAK/NIE)"
      }
    ],
    permissionAttributes: ["GET_CANCEL_REASON_REPORT"]
  },
  {
    id: "child_ipz_monitoring_report",
    name: "Monitoring IPZ Dziecięcego",
    path: {name: "childIpzMonitoringReport"},
    description: "Raport zawiera listę IPZ'tów dziecięcych dla wybranego pacjenta",
    legendItems: [
      {
        name: LegendNames.PATIENT,
        description: "wyszukiwarka pacjenta"
      },
      {
        name: LegendNames.IPZ_STATUS,
        description: "możliwość określenia z jakim statusem IPZ ma być wzięty pod uwagę w wygenerowanym raporcie"
      },
      {
        name: LegendNames.IPZ_DATE_RANGES,
        description: "zakres dat jaki ma obejmować raport"
      },
      {
        name: LegendNames.IPZ_SIGNATURE,
        description: "możliwość określenia czy raport ma zawierać wyłącznie IPZ, który został" +
          " wygenerowany do podpisu (TAK), lub nie (NIE)"
      }
    ],
    permissionAttributes: ["GET_CHILD_IPZ_MONITORING_REPORT"]
  },
  {
    id: "patient_list",
    name: "Lista pacjentów",
    path: {name: "report_patient_list_report"},
    description: "Raport zawiera listę pacjentów zarejestrowanych w systemie Auxilio.",
    permissionAttributes: ["GET_PATIENT_LIST_REPORT"]
  },
  {
    id: "type_of_services_per_patient",
    name: "Rodzaje usług w podziale na pacjenta",
    path: {name: "type_of_services_per_patient_report"},
    description: "Raport zawiera liczbę zakończonych lub anulowanych wizyt z klasyfikacją na: usługi" +
      " ambulatoryjne, doraźne lub środowiskowe, oraz z dodatkowym podziałem na wizyty przypisane do lekarza" +
      " lub psychologa. Liczba poszczególnych wizyt podzielona jest na pacjentów, którzy mieli przypisaną usługę.",
    permissionAttributes: ["FIND_REQUEST_TYPE_OF_SERVICES_PER_PATIENT_REPORT"]
  },
  {
    id: "patients_support",
    name: "Wsparcie pacjentów",
    path: {name: "patients_support_report"},
    description: "Raport zawiera listę wsparcia pacjentów ",
    legendItems: [
      {
        name: LegendNames.DATE_RANGE,
        description: "zakres dat jaki ma obejmować raport"
      }
    ],
    permissionAttributes: ["CREATE_REQUEST_PATIENTS_SUPPORT_REPORT"]
  },
  {
    id: "mhc_surveys",
    name: "Monitoring MHC",
    path: {name: "mhc_surveys_report"},
    description: "Raport pokazuje ilość przeprowadzonych badań MHC w podziale na pacjenta ",
    permissionAttributes: ["MHC_SURVEY_REPORT"]
  },
  {
    id: "open_services_by_specialists",
    name: "Liczba otwartych usług u specjalistów",
    path: {name: "open_services_by_specialists_report"},
    description: "Raport podsumowujący ilość otwartych(utworzona, zarejestrowana, rozpoczęta) " +
      "usług specjalistów z danego przedziału czasu.",
    legendItems: [
      {
        name: LegendNames.DATE_RANGE,
        description: "zakres dat jaki ma obejmować raport"
      }
    ],
    permissionAttributes: ["GET_OPEN_SERVICES_BY_SPECIALISTS_REPORT"]
  },
  {
    id: "missing_data",
    name: "Braki w danych",
    path: {name: "missing_data_report"},
    description: "Raport - Braki w danych",
    permissionAttributes: ["GET_MISSING_DATA_REPORT"]
  },
  {
    id: "continuous_stay",
    name: "Pobyty Ciągłe",
    path: {name: "continuous_stay_report"},
    description: "Raport - Pobyty ciągłe",
    permissionAttributes: ["GET_CONTINUOUS_STAY_REPORT"],
    legendItems: [
      {
        name: LegendNames.DATE_SHORT_RANGE,
        description: "zakres dat otwarcia pobytu jaki ma obejmować raport"
      },
    ]
  },
  {
    id: "czp",
    name: "Raport CZP",
    path: {name: "czp_report"},
    description: "Raport - CZP",
    permissionAttributes: ["CZP_REPORT_LIST"],
    legendItems: [
      {
        name: LegendNames.DATE_SHORT_RANGE,
        description: "Raport CZP"
      },
    ]
  },
  {
    id: "mz_report_view",
    name: "Raporty MZ",
    path: {name: "mz_report_view"},
    description: "Raport - Raporty mz",
    permissionAttributes: ["CREATE_REQUEST_REPORT_MZ"],
    legendItems: [
      {
        name: LegendNames.DATE_SHORT_RANGE,
        description: "zakres dat otwarcia pobytu jaki ma obejmować raport"
      },
    ]
  },
  {
    id: "ikup_summary_xlsx",
    name: "Raport sumaryczny w oparciu o IKUPy",
    path: {name: "ikup-summary-report"},
    description: "Raport sumaryczny",
    permissionAttributes: ["IKUP_SUMMARY_REPORT_XLSX"],
    legendItems: [
      {
        name: LegendNames.DATE_RANGE,
        description: "zakres dat jaki ma obejmować raport"
      },
    ]
  },
  {
    id: "work-on-ipz-report",
    name: "Praca z PTiZ",
    path: {name: "work-on-ipz-report"},
    description: "Praca specjalistów na PTiZ pacjentów",
    permissionAttributes: ["GET_WORK_ON_IPZ_REPORT"],
    legendItems: [
      {
        name: LegendNames.DATE_RANGE,
        description: "zakres dat jaki ma obejmować raport"
      },
      {
        name: LegendNames.SPECIALIST,
        description: "lista specjalistów do wyboru"
      }
    ]
  },
  {
    id: "benefit-performance-report",
    name: "Raport wykonania świadczeń",
    path: {name: "benefit-performance-report"},
    description: "Raport wykonania świadczeń",
    permissionAttributes: ["GET_BENEFIT_PERFORMANCE_REPORT"],
    legendItems: [
      {
        name: LegendNames.ESTABLISHMENT_IDS,
        description: "Wybrany podmiot dla którego ma być wygenerowany raport"
      }
    ]
  },
  {
    id: "request_medical_records",
    name: "Wykaz wydanej dokumentacji medycznej",
    path: {name: "request_medical_records_report"},
    description: "Raport - Wykaz wydanej dokumentacji medycznej",
    permissionAttributes: ["GET_REQUEST_MEDICAL_RECORDS_REPORT"],
    legendItems: [
      {
        name: LegendNames.DATE_RANGE,
        description: "zakres dat jaki ma obejmować raport"
      },
      {
        name: LegendNames.BRANCH_IDS,
        description: "Lista komórek"
      }
    ]
  },

];

export default reportItems;
