


























































import Vue from "vue";
import {Component} from "vue-property-decorator";
import {mapActions, mapState} from "vuex";
import ScheduleAppointmentModal from "./ScheduleAppointmentModal.vue";
import AppointmentScheduleSummary from "../Appointment/AppointmentScheduleSummary.vue";
import {ModalType} from "../../store/modules/scheduleAppointment/types";
import ScheduleContinuousStayTreatment from "./ScheduleContinuousStayTreatment.vue";

@Component({
  name: "ScheduleAppointment",
  components: {
    ScheduleContinuousStayTreatment,
    AppointmentScheduleSummary,
    ScheduleAppointmentModal,
  },
  computed: {
    ...mapState("scheduleAppointment", [
      "modalOpened",
      "modalType",
      "appointmentData",
      "summaryModalOpened",
      "appointmentDetails",
      "scheduleStep",
    ]),
  },
  methods: {
    ...mapActions("scheduleAppointment", [
      "closeModal",
      "resetAppointmentData",
      "openSummaryModal",
      "updateAppointmentDetails",
    ]),
  },
})
export default class ScheduleAppointment extends Vue {
  private ModalType = ModalType;

  private onHide(): void {
    this.closeModal();
    this.resetAppointmentData();
  }

  private onSave(appointmentDetails): void {
    this.closeModal();
    this.updateAppointmentDetails(appointmentDetails);
    this.resetAppointmentData();
    this.openSummaryModal();
  }

  private appointmentScheduled(): void {
    this.$emit("appointmentScheduled");
  }
}
