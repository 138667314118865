











































































































































































































































import Component from "vue-class-component";
import {Mixins, Prop, Watch} from "vue-property-decorator";
import ErrorsMixin from "../../../mixins/ErrorsMixin";
import create from "../../../rest/create";
import update from "../../../rest/update";
import {InsuranceHistory, GuardianType, GuardianAddress} from "../../../types/Insurance";
import stringifyDate from "../../../utils/date/stringifyDate";
import processResponseException from "../../../utils/errors/processResponseException";
import DatePicker from "../../Form/DatePicker/DatePicker.vue";
import ErrorMessage from "../../Form/ErrorMessage.vue"
import IdentificationDocumentSelect from "../../Form/Select/IdentificationDocumentSelect.vue";
import ObjectSelect from "../../Form/Select/ObjectSelect.vue";
import identityConfirmMethod from "./identityConfirmMethod";
import patientStatementInsuranceSource from "./patientStatementInsuranceSource";
import AddressFormExtended from "../../Address/AddressFormExtended.vue";
import subErrors from "../../../utils/errors/subErrors";

interface PatientStatement {
  validFrom: Date;
  validTo: Date;
  identificationDocument: {
    type: string;
    number: string;
  };
  statementAuthor: string;
  source: string;
  identityConfirmationMethod: string;
  onlineConsultation: boolean;
  guardian: {
    name:string;
    surname:string;
    type:GuardianType;
    address: GuardianAddress;
  };
}

@Component({
  name:"PatientStatementModal",
  components: {
    ObjectSelect,
    DatePicker,
    ErrorMessage,
    IdentificationDocumentSelect,
    AddressFormExtended
  }
})
export default class PatientStatementModal extends Mixins(ErrorsMixin) {
  @Prop({required: true, type: String}) patientId!: string;
  @Prop({required: true}) item!: InsuranceHistory | null;

  insurance: PatientStatement = this.defaultData;
  loading: boolean = false;

  get defaultData(): PatientStatement {
    return {
      validFrom: new Date(),
      validTo: new Date(),
      identificationDocument: {
        type: "identity_card",
        number: ""
      },
      statementAuthor: "patient",
      source: patientStatementInsuranceSource[0].value,
      identityConfirmationMethod: identityConfirmMethod[0].value,
      onlineConsultation: false,
      guardian: {
        type: GuardianType.GUARDIAN,
        address : {}
      }
    };
  }

  ifGuardian(){
    return this.insurance.statementAuthor === GuardianType.GUARDIAN
  }

  @Watch("item")
  subscribeItem(item: InsuranceHistory | null) {
    if (null == item || item.insuranceType !== "patient_statement") {
      this.insurance = this.defaultData;
      return;
    }

    this.insurance = {
      validFrom: new Date(item.validFrom),
      validTo: new Date(item.validTo as string),
      identificationDocument: {
        type: item.identificationDocument!.type as string,
        number: item.identificationDocument!.number as string,
      },
      statementAuthor: item.statementAuthor as string,
      source: item.insuranceSource as string,
      identityConfirmationMethod: item.identityConfirmationMethod as string,
      onlineConsultation: item.onlineConsultation as boolean,
      guardian: {
        name: item.guardian?.name as string,
        surname: item.guardian?.surname as string,
        type: GuardianType.GUARDIAN,
        address : {
          city:item.guardian?.address?.city,
          district:item.guardian?.address?.district,
          province:item.guardian?.address?.province,
          commune:item.guardian?.address?.commune,
          buildingNumber:item.guardian?.address?.buildingNumber,
          apartmentNumber:item.guardian?.address?.apartmentNumber,
          street:item.guardian?.address?.street,
          zipCode:item.guardian?.address?.zipeCode,
          terytId:item.guardian?.address?.terytId,
          country:item.guardian?.address?.country
        }
      }
    }
  }

  hide() {
    this.$bvModal.hide("patientStatementModal");
    this.errors = [];
    this.loading = false;
    this.insurance = this.defaultData;
    this.$emit("canceled")
  }

  async submit() {
    this.loading = true;
    this.insurance.validFrom?.setHours(0,0,0);
    this.insurance.validTo?.setHours(23,59,59);
    const data = {
      ...this.insurance,
      validFrom: stringifyDate(this.insurance.validFrom),
      validTo: stringifyDate(this.insurance.validTo),
      guardian: this.insurance.statementAuthor===GuardianType.GUARDIAN ? this.insurance.guardian : undefined
    };
    try {
      if (null == this.item) {
        await create(`/api/patients/${this.patientId}/insurance/patient-statement`, data);
      } else {
        await update(`/api/patients/${this.patientId}/insurance/patient-statement/${this.item.id}`, data);
      }
      this.hide();
      this.$emit("saved");
    } catch (error) {
      this.errors = processResponseException(error);
    }
    this.loading = false;
  }

  get statementAuthorOptions() {
    return [
      {value: "patient", name: "Pacjent"},
      {value: "guardian", name: "Opiekun"},
    ];
  }

  getSubErrors(field:string): Array<Error> {
    return subErrors(this.errors, field);
  }

  get sourceOptions() {
    return patientStatementInsuranceSource;
  }

  get identityConfirmOptions() {
    return identityConfirmMethod;
  }

  get onlineConsultationOptions() {
    return [
      {value: true, name: "ustne"},
      {value: false, name: "inny (np. w wersji papierowej)"},
    ];
  }
}
