<template>
  <b-modal
    id="interventionNotification"
    :title="nfzConfiguration ? 'Zgłoszenie PZK' : 'Zgłoszenie interwencyjne'"
    no-close-on-backdrop
    title-tag="h3"
    size="lg"
    @show="clear"
  >
    <template #default>
      <InterventionNotificationForm
        v-model="interventionNotificationData"
        :errors="errors"
      />
      <error-message :errors="errors" />
    </template>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary"
        @click="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Utwórz zgłoszenie
      </button>
    </template>
  </b-modal>
</template>

<script>
import InterventionNotificationForm from "./InterventionNotificationForm.vue";
import create from "../../rest/create";
import {generateUuid} from "../../utils/uuid/generateUuid";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../Form/ErrorMessage";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import {mapActions, mapState} from "vuex";
const interventionNotificationInitialData = {
  isDuration: false,
  startDate: null,
  endDate: null,
  additionalWorkers: [],
  scopeOfActivitiesAndHealthcareInformation: false,
  benefitsOfSocialAssistance: false
};
export default {
  components: {
    ErrorMessage,
    InterventionNotificationForm,
  },
  data() {
    return {
      interventionNotificationData: interventionNotificationInitialData,
      errors: [],
      loading: false,
    };
  },
  computed: {
    ...mapState({
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration
    }),
  },
  methods: {
    ...mapActions({
      getInterventionNotificationList: "interventionNotifications/getItems"
    }),
    clear() {
      this.interventionNotificationData = interventionNotificationInitialData;
      this.errors = [];
    },
    async submit(e) {
      e.preventDefault();
      this.loading = true;
      try {
        const interventionNotificationId = generateUuid();
        await create("/api/intervention-notifications", {
          interventionNotificationId: interventionNotificationId,
          ...this.interventionNotificationData,
          description: this.interventionNotificationData.description,
          startDate: this.interventionNotificationData.startDate
            ? stringifyDate(this.interventionNotificationData.startDate, DATE_FORMAT.DATE_TIME)
            : null,
          endDate: this.interventionNotificationData.endDate
            ? stringifyDate(this.interventionNotificationData.endDate, DATE_FORMAT.DATE_TIME)
            : null,
          nfzConfiguration: this.nfzConfiguration
        });
        this.closeModal();
        if (this.$route.name === "intervention_notification_list" || this.$route.name === "pzk_applications_list") {
          this.getInterventionNotificationList();
        }
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    closeModal() {
      this.$bvModal.hide("interventionNotification");
    },
  }
}
</script>
