












































































import {Component, Mixins, Prop} from "vue-property-decorator";
import IsGranted from "../IsGranted.vue";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../Form/ErrorMessage.vue";
import read from "../../rest/read";
import ErrorsMixin from "../../mixins/ErrorsMixin.ts";
import {Location} from "vue-router";

@Component({
  name: "CreateZlaModal",
  components: {ErrorMessage, IsGranted},
})
export default class CreateZlaModa extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly appointmentId!: string;
  @Prop({type: String, required: true}) readonly appointmentDocumentId!: string;
  @Prop({type: String, required: true}) readonly modalId!: string;
  @Prop({type: String, required: true}) readonly patientId!: string;

  private loading: boolean = false;
  private password: string = "";
  private sessionId: string|null = null;

  private get title(): string {
    return "Przejdź do formularza - zwolnienie elektroniczne (e-ZLA)";
  }

  private get zlaUrl(): Location {
    return {
      name: "zla",
      params: {
        sessionId: this.sessionId || "",
        patientId: this.patientId,
      },
      query: {
        appointmentId: this.appointmentId,
        appointmentDocumentId: this.appointmentDocumentId,
      }
    };
  }

  private onHideModal(): void {
    this.password = "";
    this.sessionId = null;
    this.errors = [];
  }

  private async submit(): Promise<void> {
    this.loading = true;
    this.errors = [];
    try {
      const {sessionId} = await read<{sessionId: string}>("/api/zla/get-session-id", {
        password: this.password,
      })
      this.sessionId = sessionId;
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private hideModal(): void {
    this.$bvModal.hide(this.modalId);
  }

  private saveCreate(): void {
    this.$emit("saveCreate", this.zlaUrl);
    this.hideModal();
  }
}
