var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('error-message',{attrs:{"errors":_vm.errors}}),_c('b-table',{attrs:{"empty-text":"Brak skierowań do wyświetlenia","fields":_vm.fields,"items":_vm.items,"hover":"","responsive":true},scopedSlots:_vm._u([{key:"cell(createdAt)",fn:function(ref){
var createdAt = ref.item.createdAt;
return [_c('date-time',{attrs:{"value":_vm.parseDate(createdAt, _vm.DATE_FORMAT.DATE_TIME_SEC),"format":_vm.DATE_FORMAT.DATE_TIME}})]}},{key:"cell(doctor)",fn:function(ref){
var ref_item_doctor = ref.item.doctor;
var name = ref_item_doctor.name;
var surname = ref_item_doctor.surname;
return [_vm._v(" "+_vm._s(name)+" "+_vm._s(surname)+" ")]}},{key:"cell(type)",fn:function(ref){
var ref_item = ref.item;
var isElectronic = ref_item.isElectronic;
var type = ref_item.type;
return [_c('i18n',{attrs:{"msgid":_vm.typeTranslation(isElectronic, type)}})]}},{key:"cell(unitDepartment)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.unitDepartment || "-")+" ")]}},{key:"cell(icd10Code)",fn:function(ref){
var icd10Code = ref.item.icd10Code;
return _vm._l((icd10Code),function(icd10,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(icd10 || "-")+" ")])})}},{key:"cell(icd9)",fn:function(ref){
var icd9 = ref.item.icd9;
return _vm._l((icd9),function(code,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(code || "-")+" ")])})}},{key:"cell(status)",fn:function(ref){
var status = ref.item.status;
return [_c('b-badge',{staticClass:"status-badge",attrs:{"variant":_vm.getBadgeVariant(status)}},[_c('i18n',{attrs:{"component":"fragment","msgid":("@referral-status." + status)}})],1)]}},{key:"cell(options)",fn:function(ref){
var ref_item = ref.item;
var isElectronic = ref_item.isElectronic;
var referralId = ref_item.referralId;
var status = ref_item.status;
return [(!isElectronic || status === _vm.ReferralStatus.COMPLETED)?_c('b-btn',{staticClass:"m-1",attrs:{"href":("/api/files/" + referralId),"size":"sm","variant":"secondary","target":"_blank"}},[_c('span',{staticClass:"fa fa-print"}),_vm._v(" Drukuj ")]):_vm._e(),(isElectronic && status === _vm.ReferralStatus.COMPLETED)?_c('is-granted',{attrs:{"privileges":['CANCEL_EREFERRAL']}},[_c('b-btn',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("cancel-referral-" + referralId)),expression:"`cancel-referral-${referralId}`"}],staticClass:"m-1",attrs:{"size":"sm","variant":"outline-secondary"}},[_c('span',{staticClass:"fa fa-trash"}),_vm._v(" Anuluj ")]),_c('cancel-referral-modal',{attrs:{"referral-id":referralId},on:{"canceled":_vm.refreshTable}})],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }