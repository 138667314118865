<template>
  <div class="card-box">
    <div class="form-row">
      <div class="form-group col font-weight-bold">
        Rozpoznanie
      </div>
      <icd10-code-select
        v-if="!readOnly"
        :state="(module.errors && module.errors.codes) ? false : null"
        class="w-100"
        @input="addRecognition"
      />
      <module-error-message :messages="module.errors && module.errors.codes" />
    </div>
    <div
      v-if="module.codes.length !== 0"
      class="table-responsive"
    >
      <table class="table">
        <thead>
          <tr>
            <th class="w-xs">
              Kod
            </th>
            <th>
              Data diagnozy
            </th>
            <th>
              Opis
            </th>
            <th v-if="!readOnly" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in module.codes"
            :key="index"
          >
            <td>{{ item.code }}</td>
            <td>{{ item.diagnosisDate }}</td>
            <td>{{ item.description }}</td>
            <td
              v-if="!readOnly"
              class="text-right"
            >
              <b-btn
                size="sm"
                variant="outline-secondary"
                @click="onClickRemove(item)"
              >
                <i class="fa fa-trash" /> Usuń
              </b-btn>
            </td>
          </tr>
        </tbody>
      </table>
      <b-form-checkbox
        v-if="!nfzConfiguration"
        id="synchronizeChronicDiseasesCheckbox"
        :checked="module.synchronizeChronicDiseases"
        :disabled="readOnly"
        @change="updateModule($event, 'synchronizeChronicDiseases')"
      >
        Aktualne rozpoznanie
      </b-form-checkbox>
    </div>
  </div>
</template>
<script>
import Icd10CodeSelect from "../../Form/Select/Icd10CodeSelect";
import stringifyDate from "../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import ModuleErrorMessage from "../../Form/ModuleErrorMessage";
import {mapState} from "vuex";

export default {
  name: "Icd10Module",
  components: {
    ModuleErrorMessage,
    Icd10CodeSelect,
  },
  model: {
    prop: "module",
  },
  props: {
    idx: {required: true, type: Number},
    module: {required: true, type: Object},
    readOnly: {required: true, type: Boolean}
  },
  computed: {
    ...mapState({
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration
    }),
  },
  methods: {
    onClickRemove(itemToRemove){
      const module = {
        ...this.module,
        codes: this.module.codes.filter(item => item.code !== itemToRemove.code)
      };
      this.$emit("input", module);
    },
    updateModule(event, field) {
      const module = {
        ...this.module,
        [field]: event,
      }
      this.$emit("input", module);
    },
    addRecognition(selectedCode) {
      if (null === selectedCode) {
        return;
      }
      const filteredItems = this.module.codes.filter((item) => {
        return item.code === selectedCode.value;
      });
      if (filteredItems.length !== 0) {
        return
      }
      const module = {
        ...this.module,
        codes: this.module.codes.concat([
          {
            ...selectedCode,
            code: selectedCode.value,
            diagnosisDate: stringifyDate(new Date(), DATE_FORMAT.DATE)
          }
        ]),
        synchronizeChronicDiseases: this.nfzConfiguration ? true : this.module.synchronizeChronicDiseases
      };
      this.$emit("input", module);
    },
  },
}
</script>
