<template>
  <b-modal
    :id="`cancel-prescription-${index}`"
    no-close-on-backdrop
    title="Anulowanie recepty"
    size="lg"
    title-tag="h3"
    @hide="clearCancelPrescriptionErrors"
  >
    <div class="card-box">
      <div>
        <form-row
          :errors="cancelPrescriptionErrors"
          field="cancelReason"
          label="Powód anulacji"
        >
          <b-form-textarea
            v-model="cancelReason"
            :rows="3"
            :state="stateCancelPrescription('cancelReason')"
            placeholder="Powód anulacji"
          />
        </form-row>
        <form-row
          :errors="cancelPrescriptionErrors"
          field="password"
          label="Hasło do certyfikatu"
        >
          <b-input
            v-model="password"
            :state="stateCancelPrescription('password')"
            type="password"
            autocomplete="new-password"
          />
        </form-row>
      </div>
      <error-message
        :errors="cancelPrescriptionErrors"
      />
    </div>
    <template #modal-footer>
      <button
        class="btn btn-secondary"
        @click="hideCancelPrescriptionModal"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary"
        @click="cancelPrescription"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import create from "../../rest/create";
import processResponseException from "../../utils/errors/processResponseException";
import FormRow from "../Form/FormRow.vue";
import ErrorMessage from "../Form/ErrorMessage.vue";

export default {
  name: "CancelPrescriptionModal",
  components: {
    FormRow,
    ErrorMessage
  },
  props: {
    prescriptionId: {type: String, default: null},
    index: {type: Number, default: null}
  },

  data() {
    return {
      cancelPrescriptionErrors: [],
      loading: false,
      cancelReason: null,
      password: null
    }
  },

  methods: {
    clearCancelPrescriptionErrors() {
      this.cancelPrescriptionErrors = [];
    },
    async cancelPrescription() {
      this.loading = true;
      try {
        await create(`/api/cancel-electronic-prescription/${this.prescriptionId}`, {
          password: this.password,
          cancelReason: this.cancelReason
        });
        this.clearCancelPrescriptionErrors();
        this.hideCancelPrescriptionModal();
        this.cancelReason = null;
        this.password = null;
        this.$emit("canceled");
      } catch (exception) {
        this.cancelPrescriptionErrors = processResponseException(exception);
      }
      this.loading = false;
    },
    stateCancelPrescription(field) {
      return this.cancelPrescriptionErrors.find((error) => error.field === field) ? false : null;
    },
    hideCancelPrescriptionModal() {
      this.$bvModal.hide(`cancel-prescription-${this.index}`);
      this.clearCancelPrescriptionErrors();
    },
  }
}
</script>

<style scoped>

</style>
