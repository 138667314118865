<template>
  <form @submit.prevent="submit">
    <div class="row">
      <b-form-group
        class="col-lg-6 col-xl-2"
        label="Data od"
      >
        <date-picker v-model="formData.startDate" />
      </b-form-group>
      <b-form-group
        class="col-lg-6 col-xl-2"
        label="Data do"
      >
        <date-picker
          v-model="formData.endDate"
          clearable
        />
      </b-form-group>
      <b-form-group
        class="col-lg-6 col-xl-4"
        label="Kategoria pobytu"
      >
        <continuous-stay-category-select
          v-model="formData.categories"
          multiple
        />
      </b-form-group>
      <b-form-group
        class="col-lg-6 col-xl-4"
        label="Status"
      >
        <continuous-stay-status-select
          v-model="formData.statuses"
          multiple
        />
      </b-form-group>
      <b-form-group
        label="Pacjent"
        class="col-lg-6 col-xl-4"
      >
        <patient-select
          v-model="formData.patientId"
        />
      </b-form-group>
    </div>
    <div class="row">
      <div class="offset-lg-12 offset-xl-6 col-lg-12 col-xl-6 text-right no-label">
        <a
          v-if="!loading"
          :href="exportUrl"
          class="btn btn-warning mr-2"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fa fa-atom" />
          Eksportuj
        </a>
        <button
          class="btn btn-danger ml-2"
          type="button"
          @click="clearFilters"
        >
          <i class="fa fa-ban" />
          Wyczyść
        </button>
        <button
          class="btn btn-primary ml-2"
          type="submit"
        >
          <i class="fa fa-search" />
          Szukaj
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import DatePicker from "../Form/DatePicker/DatePicker.vue";
import PatientSelect from "../Patient/PatientSelect";
import stringifyDate from "../../utils/date/stringifyDate";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import {changePageQuery} from "../../utils/pageUrl/handlePageQuery";
import ContinuousStayCategorySelect from "@/components/Patient/PatientCard/ContinuousStay/ContinuousStayCategorySelect";
import ContinuousStayStatusSelect from "@/components/ContinuousStay/ContinuousStayStatusSelect";

export default {
  components: {
    ContinuousStayStatusSelect,
    PatientSelect,
    DatePicker,
    ContinuousStayCategorySelect,
  },
  props: {
    startDate: {type: Date, default: null},
    endDate: {type: Date, default: null},
    categories: {type: Array, default: null},
    statuses: {type: Array, default: null},
    patientId: {type: String, default: null},
    exportUrl: {type: String, required: true},
    loading: {type: Boolean, required: true},
  },
  data() {
    return {
      formData: {
        startDate: this.startDate,
        endDate: this.endDate,
        categories: this.categories ? this.categories : [],
        statuses: this.statuses ? this.statuses : [],
        patientId: this.patientId,
      },
    };
  },
  computed: {
    filters() {
      return {
        startDate: this.formData.startDate ? stringifyDate(this.formData.startDate) : undefined,
        endDate: this.formData.endDate ? stringifyDate(this.formData.endDate) : undefined,
        categories: this.formData.categories.join(",") || undefined,
        statuses: this.formData.statuses.join(",") || undefined,
        patientId: this.formData.patientId ? this.formData.patientId.patientId : undefined,
      };
    },
  },
  watch: {
    startDate(val) {
      this.formData.startDate = val;
    },
    endDate(val) {
      this.formData.endDate = val;
    },
    categories(val) {
      this.formData.categories = val ?? [];
    },
    statuses(val) {
      this.formData.statuses = val ?? [];
    },
    patientId(val) {
      this.formData.patientId = val;
    },
    "$route": {
      immediate: true,
      async handler() {
        if (isEmpty(this.$route.query) && this.savedFilters) {
          this.$router.replace({
            path: this.$route.path,
            query: this.savedFilters,
          });
          return;
        }
        this.$emit("reloadList");
      }
    },
  },
  methods: {
    submit() {
      if (isEqual(this.$route.query, this.filters)) {
        this.$emit("reloadList");
        return;
      }
      changePageQuery(this.filters);
    },
    async clearFilters() {
      const startDate = new Date();
      startDate.setHours(0,0,0,0);
      this.formData = {
        startDate,
        endDate: null,
        categories: [],
        statuses: [],
        patient: null,
      };
    },
  },
}
</script>
