<template>
  <div>
    <b-form-group>
      <template #label>
        Certyfikat p12
        <i
          :class="value ? 'fa-check text-success' : 'fa-times text-danger'"
          class="fa"
        />
        <button
          v-if="value"
          class="btn btn-primary btn-sm float-right"
          @click="removeFile"
        >
          <i class="fa fa-trash" /> Usuń certyfikat
        </button>
      </template>
      <file-upload
        :uploaded-files="uploadedFiles"
        @removed="removeFile"
        @uploaded="addFile"
      />
      <error-message
        :errors="errors"
        field="pkcs12FileId"
      />
      <b-form-group label="Hasło">
        <b-form-input
          :value="pkcs12password"
          :state="state('password')"
          type="password"
          autocomplete="new-password"
          @input="updatePassword($event ? $event.trim() : null)"
        />
        <error-message
          :errors="errors"
          field="pkcs12FilePassword"
        />
      </b-form-group>
    </b-form-group>
    <b-modal
      id="certificateOverwriteModal"
      ref="certificateOverwriteModal"
      no-close-on-backdrop
      title="Potwierdzenie napdisania certyfikatu"
      title-tag="h3"
    >
      <div>
        Posiadasz już wgrany certyfikat, czy chcesz go nadpisać?
      </div>
      <template #modal-footer>
        <button
          class="btn btn-secondary"
          @click="hideModal"
        >
          Anuluj
        </button>
        <button
          class="btn btn-primary"
          @click="confirmCertificateOverwrite"
        >
          Nadpisz certyfikat
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import FileUpload from "../File/FileUpload";
import ErrorMessage from "../Form/ErrorMessage";

export default {
  name: "CertificateP12",
  components: {FileUpload, ErrorMessage},
  props: {
    value: {type: String, default: null},
    errors: {type: Array, default: () => []},
  },
  data() {
    return {
      file: null,
      uploadedFiles: [],
      pkcs12password: null,
    };
  },
  watch: {
    value() {
      this.file = {fileId: this.value};
    }
  },
  methods: {
    addFile(file) {
      if (this.value) {
        this.$bvModal.show("certificateOverwriteModal");
        this.file = file;
        return;
      }
      this.$emit("input", {id: file.fileId, password: this.pkcs12password});
      this.uploadedFiles = [];
    },
    removeFile() {
      this.pkcs12password = null;
      this.$emit("input", {id: null, password: null});
    },
    hideModal() {
      this.$bvModal.hide("certificateOverwriteModal");
      this.file = null;
      this.uploadedFiles = [];
    },
    confirmCertificateOverwrite(){
      this.$emit("input", {id: this.file.fileId, password: this.pkcs12password});
      this.hideModal();
    },
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    updatePassword(password) {
      this.pkcs12password = password;
      this.$emit("input", {password, id: this.file?.fileId});
    }
  }
}
</script>

<style scoped>

</style>
