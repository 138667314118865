<template>
  <div>
    <media-query-collapse
      message-close="Zwiń filtry"
      message-open="Rozwiń filtry"
    >
      <template #collapseContent>
        <continuous-stay-filters
          :start-date="parsedStartDate"
          :end-date="parsedEndDate"
          :categories="categories"
          :statuses="statuses"
          :patient-id="patientId"
          :export-url="exportUrl"
          :loading="loading"
          class="card-box m-b-5"
          @reloadList="getContinuousStayList"
        />
      </template>
    </media-query-collapse>
    <card
      ref="content"
      :loading="loading"
      class="continuous-stay-card"
      title=""
    >
      <template v-if="!items.length">
        <div class="alert text-center">
          Brak pobytów ciągłych w wybranym terminie
        </div>
      </template>
      <pagination-description
        :items-count="items.length"
        :page="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-count="pagination.totalRows"
      />
      <b-table
        :items="items"
        :fields="fields"
        empty-text="Brak dodanych pobytów"
        :responsive="true"
        show-empty
      >
        <template #cell(patient)="data">
          <router-link
            :to="{name: 'patientCard', params: {patientId: data.item.patient.patientId}}"
          >
            {{ data.item.patient.displayName.toUpperCase() }}
          </router-link>
        </template>
        <template #cell(continuousStayCategory)="data">
          <i18n
            component="fragment"
            :msgid="`@continuousStay.${data.item.category}`"
          />
        </template>
        <template #cell(startDate)="data">
          <date-time
            :value="parseDate(data.item.startDate)"
            :format="DATE_FORMAT.DATE"
          />
        </template>
        <template #cell(endDate)="data">
          <date-time
            v-if="data.item.endDate"
            :value="parseDate(data.item.endDate)"
            :format="DATE_FORMAT.DATE"
          />
        </template>
        <template #cell(continuousStayStatus)="data">
          <b-badge
            class="status-badge"
            :variant="getBadgeVariant(data.item.status)"
          >
            <i18n :msgid="`@continuousStay.${data.item.status}`" />
          </b-badge>
        </template>
        <template #cell(options)="data">
          <is-granted
            :privileges="['CONTINUOUS_STAY_EDIT_VIEW']"
            :subject="data.item.continuousStayId"
            component="fragment"
          >
            <router-link
              class="btn btn-sm btn-secondary m-1"
              :to="editViewPath(data.item.continuousStayId)"
            >
              <span class="fa fa-eye mr-1" /> Szczegóły
            </router-link>
          </is-granted>
        </template>
      </b-table>
      <pagination-description
        :items-count="items.length"
        :page="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-count="pagination.totalRows"
      >
        <b-pagination
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          :value="pagination.currentPage"
          align="right"
          class="mb-0"
          @input="changePage"
        />
      </pagination-description>
    </card>
  </div>
</template>
<script>
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import parseDate from "../../utils/date/parseDate";
import stringifyDate from "../../utils/date/stringifyDate";
import read from "../../rest/read";
import {createUrl} from "../../utils/pageUrl/createUrl";
import Card from "../../components/Card";
import MediaQueryCollapse from "../../components/MediaQueryCollapse";
import PaginationDescription from "../../components/Pagination/PaginationDescription";
import {updatePageQuery} from "../../utils/pageUrl/handlePageQuery";
import ContinuousStayFilters from "@/components/ContinuousStay/ContinuousStayFilters";
import I18n from "@/components/i18n";
import DateTime from "@/components/DateTime";
import IsGranted from "@/components/IsGranted";

export default {
  name: "ContinuousStayListPage",
  components: {
    IsGranted,
    DateTime,
    I18n,
    ContinuousStayFilters,
    PaginationDescription,
    MediaQueryCollapse,
    Card,
  },
  beforeRouteUpdate (to, from, next) {
    this.items = [];
    next();
  },
  props: {
    startDate: {type: String, default: null},
    endDate: {type: String, default: null},
    categories: {type: Array, default: null},
    statuses: {type: Array, default: null},
    patientId: {type: String, default: null},
    page: {type: Number, default: null},
  },
  data() {
    return {
      parseDate: parseDate,
      DATE_FORMAT: DATE_FORMAT,
      currentPage: this.page || 1,
      items: [],
      loading: false,
      pagination: {perPage: 50},
      fields: [
        {key: "patient", label: "Pacjent"},
        {key: "continuousStayCategory", label: "Kategoria"},
        {key: "continuousStayStatus", label: "Status"},
        {key: "startDate", label: "Data rozpoczęcia"},
        {key: "endDate", label: "Data zakończenia"},
        {key: "options", label: "", class: "text-right"},
      ],
    };
  },
  computed: {
    parsedStartDate() {
      const date = this.startDate ? parseDate(this.startDate) : new Date();
      date.setHours(0,0,0,0);
      return date;
    },
    parsedEndDate() {
      if (!this.endDate) {
        return null;
      }
      const date = parseDate(this.endDate);
      date.setHours(23, 59, 59);
      return date;
    },
    filters() {
      return {
        startDate: this.parsedStartDate ? stringifyDate(this.parsedStartDate) : null,
        endDate: this.parsedEndDate ? stringifyDate(this.parsedEndDate) : null,
        categories: this.categories,
        statuses: this.statuses,
        page: this.currentPage,
        perPage: this.pagination.perPage,
        patientId: this.patientId,
      };
    },
    exportUrl() {
      return createUrl("/api/continuous-stay/export", this.filters);
    },
  },
  watch: {
    page(val) {
      this.currentPage = val || 1;
    },
  },
  methods: {
    async changePage(page) {
      this.currentPage = page;
      await updatePageQuery({page: this.currentPage});
    },
    async getContinuousStayList() {
      this.loading = true;
      const response = await read("/api/continuous-stay-list", this.filters);
      this.pagination = response.pagination;
      this.items = response.items;
      setTimeout(() => this.scrollToContent());
      this.loading = false;
    },
    scrollToContent() {
      if(this.$refs.content) {
        const element = this.$refs.content.$vnode.elm;
        element.scrollIntoView(true);
        document.documentElement.scrollTop -= 100;
      }
    },
    editViewPath(continuousStayId) {
      return {name: "continuous_stay_edit", params: {continuousStayId}};
    },
    getBadgeVariant(status) {
      switch(status) {
        case "in_progress":
          return "success";
        case "closed":
          return "primary";
        case "canceled":
          return "danger";
        default:
          throw Error(`Not supported status: ${status}`);
      }
    },
  },
}

</script>

<style scoped lang="scss">
  @import "../../styles/variables";

  @include media-breakpoint-down(lg) {
    .continuous-stay-card {
      padding: 0;
      border: none;
      background-color: transparent;
    }
  }
</style>
