<template>
  <div>
    <address-form-extended
      v-if="displayExtended"
      v-model="dataValue"
      :errors="errors"
      :horizontal="horizontal"
      :disabled="disabled"
      :visible-country="visibleCountry"
      :in-modal="inModal"
      :hide-autocomplete="hideAutocomplete"
      @input="input"
    />
    <address-form-tiny
      v-else
      v-model="dataValue"
      :errors="errors"
      :horizontal="horizontal"
      :visible-country="visibleCountry"
      :disabled="disabled"
      :in-modal="inModal"
      @input="input"
      @clearError="clearErrors"
    />
  </div>
</template>
<script>
import AddressFormTiny from "./AddressFormTiny";
import AddressFormExtended from "./AddressFormExtended";
import {mapState} from "vuex";

export default {
  components: {AddressFormExtended, AddressFormTiny},
  props: {
    value: {type: Object, required: true},
    errors: {type: Array, default: () => []},
    horizontal: {type: Boolean, default: true},
    visibleCountry: {type: Boolean, default: true},
    disabled: {type: Boolean, default: false},
    inModal: {type: Boolean, default: false},
    hideAutocomplete: {type: Boolean, default: false}
  },
  data() {
    return {
      dataValue: {...this.value},
    };
  },
  computed: {
    ...mapState({
      displayExtended: state => state.clinicParameters.parameters.extendedAddress,
    }),
  },
  watch: {
    value() {
      this.dataValue = {...this.value};
    },
  },
  methods: {
    input(value){
      this.$emit("input", value)
    },
    clearErrors(value){
      this.$emit("clearErrors", value)
    }
  }
}
</script>
