import {CodeItem} from "../../types/IcdCodes";

export const code2option = (item: CodeItem) => ({
  code: item.code,
  description: item.description,
  value: item.code,
  name: `${item.code} / ${item.description}`,
});

export const code2optionWithDisabledOptions = (item: CodeItem) => ({
  code: item.code,
  description: item.description,
  value: item.code,
  name: `${item.code} / ${item.description}`,
  $isDisabled: !item.choosable,
});
