










import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {RecurrenceType} from "../../../utils/recurrence/generateDates";

interface Option {
  text: string;
  value: RecurrenceType;
}

@Component({
  name: "RecurrenceTypeRadio",
})
export default class RecurrenceTypeRadio extends Vue {
  @Prop({type: String, default: null}) readonly value!: RecurrenceType;

  get options(): Array<Option>{
    return [
      {
        value: RecurrenceType.NONE,
        text: "Brak",
      },
      {
        value: RecurrenceType.WEEKLY,
        text: "Co tydzień",
      },
      {
        value: RecurrenceType.TWO_WEEKS,
        text: "Co 2 tygodnie",
      },
      {
        value: RecurrenceType.MONTHLY,
        text: "Co miesiąc",
      },
    ];
  }

  change(option: RecurrenceType|null): void {
    this.$emit("input", option);
  }
}
