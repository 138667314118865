<template>
  <loading-mask
    v-if="loadingDailyDescriptions"
    :loading="loadingDailyDescriptions"
  />

  <div v-else>
    <error-message
      :errors="errors"
    />
    <b-alert
      :show="fCodesMonit !== null"
      variant="danger"
    >
      {{ fCodesMonit }}
    </b-alert>
    <ul class="timeline">
      <is-granted
        :privileges="['CREATE_CONTINUOUS_STAY_DAILY_DESCRIPTION']"
        :subject="continuousStayId"
        component="div"
      >
        <continuous-stay-add-daily-specialists-description
          :continuous-stay-id="continuousStayId"
          :patient-id="patientId"
          @updateList="loadDailyDescriptions"
        />
      </is-granted>

      <continuous-stay-display-daily-specialists-description
        v-for="(description, index) in dailyDescriptionsList"
        :key="index"
        :index="index"
        :description="description"
        :continuous-stay-id="continuousStayId"
        :patient="patient"
        :nfz-branch="nfzBranch"
        :icd9-initial-data="allIcd9data.items"
        @updateList="loadDailyDescriptions"
      />
    </ul>
  </div>
</template>

<script>
import ContinuousStayAddDailySpecialistsDescription from "./ContinuousStayAddDailySpecialistsDescription";
import ContinuousStayDisplayDailySpecialistsDescription from "./ContinuousStayDisplayDailySpecialistsDescription";
import read from "../../../../rest/read";
import processResponseException from "../../../../utils/errors/processResponseException";
import IsGranted from "../../../../components/IsGranted";
import {NotFoundException} from "../../../../rest";
import LoadingMask from "@/components/Loading/LoadingMask";
import ErrorMessage from "../../../Form/ErrorMessage.vue";

export default {
  components: {
    ContinuousStayDisplayDailySpecialistsDescription,
    ContinuousStayAddDailySpecialistsDescription,
    IsGranted,
    LoadingMask,
    ErrorMessage
  },
  props: {
    continuousStayId: {type: String, required: true},
    patientId: {type: String , default: null},
    insuranceChanged: {type: Boolean, default: false},
    recognitionsChanged: {type: Boolean, default: false}
  },
  data() {
    return {
      loadingDailyDescriptions: true,
      dailyDescriptionsList: [],
      patient: null,
      nfzBranch: null,
      errors: [],
      allIcd9data: {items: []},
      fCodesMonit: null
    };
  },
  watch: {
    continuousStayId: {
      immediate: true,
      handler() {
        this.loadDailyDescriptions();
      },
    },
    insuranceChanged: {
      immediate: true,
      handler() {
        if(this.insuranceChanged){
          this.loadDailyDescriptions();
          this.$emit("descriptionsReloaded");
        }
      },
    },
    recognitionsChanged: {
      immediate: true,
      handler() {
        if(this.recognitionsChanged){
          this.loadDailyDescriptions();
          this.$emit("reloadedAfterRecognitionsChange");
        }
      },
    },
    patientId: {
      immediate: true,
      handler() {
        if(this.patientId){
          this.getPatientData();
        }
      },
    },
  },
  methods: {
    async loadDailyDescriptions() {
      this.loadingDailyDescriptions = true;
      try {
        const response = await read(
          `/api/continuous-stays/${this.continuousStayId}/daily-descriptions`);
        this.dailyDescriptionsList = response.items;
        this.fCodesMonit = response.fCodesMonit;

        let historyCodes = []
        response.items.forEach(items => {
          historyCodes = historyCodes.concat(...items.history.map(history => history.icd9codes));
        });

        // get all icd9 to prevent multiple request
        const allIcd9codes = [].concat(...response.items.map(items => items.icd9codes), ...historyCodes)

        if (allIcd9codes.length && this.dailyDescriptionsList.length > 0) {
          this.allIcd9data = await read("/api/icd9-codes", {
            codes: Array.from(new Set(allIcd9codes)) // Set - prevent duplicates
          });
        }
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loadingDailyDescriptions = false;
    },
    async getPatientData() {
      try {
        this.patient = await read(`/api/patients/${this.patientId}`);
        this.nfzBranch = await read(`/api/patients/${this.patientId}/nfz_branch`);
      } catch (e) {
        if (!(e instanceof NotFoundException)) {
          this.errors = processResponseException(e);
        }
      }
    },
  },
}
</script>

<style lang="scss">
.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;

  &:before {
    background-color: #eee;
    bottom: 0;
    content: " ";
    left: 50px;
    margin-left: -1.5px;
    position: absolute;
    top: 20px;
    width: 3px;
  }

  .timeline-item {
    margin-bottom: 20px;
    position: relative;

    &:before,
    &:after {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
    }

    .timeline-panel {
      border-radius: 2px;
      border: 1px solid #d4d4d4;
      box-shadow: 0 1px 2px rgba(100, 100, 100, 0.2);
      margin-left: 100px;
      padding: 20px;
      position: relative;
    }

    .timeline-badge {
      border-radius: 50%;
      color: #fff;
      font-size: 1.4em;
      height: 50px;
      left: 50px;
      line-height: 52px;
      margin-left: -25px;
      position: absolute;
      text-align: center;
      top: 16px;
      width: 50px;
      z-index: 100;

      & + .timeline-panel {
        &:before {
          border-bottom: 15px solid transparent;
          border-left: 0 solid #ccc;
          border-right: 15px solid #ccc;
          border-top: 15px solid transparent;
          content: " ";
          display: inline-block;
          position: absolute;
          left: -15px;
          right: auto;
          top: 26px;
        }

        &:after {
          border-bottom: 14px solid transparent;
          border-left: 0 solid #fff;
          border-right: 14px solid #fff;
          border-top: 14px solid transparent;
          content: " ";
          display: inline-block;
          position: absolute;
          left: -14px;
          right: auto;
          top: 27px;
        }
      }
    }
  }

  .timeline-title {
    margin-top: 0;
    color: inherit;
  }
}
</style>

