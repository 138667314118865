<template>
  <div>
    <div
      ref="fakeWrapper"
      class="fake-wrapper"
    >
      <div
        ref="fake"
        class="fake-content"
      />
    </div>

    <div
      ref="calendarWrapper"
      class="table-responsive"
    >
      <div
        ref="calendar"
        :style="{minWidth: '100%'}"
      >
        <full-calendar
          ref="fullCalendar"
          scheduler-license-key="0622967769-fcs-1615507200"
          :all-day-slot="false"
          default-view="timeGridWeek"
          :default-date="start"
          :events="items"
          :header="calendarHeader"
          height="auto"
          :locale="pl"
          :min-time="minTime"
          :max-time="maxTime"
          :plugins="calendarPlugins"
          :slot-duration="slotDuration"
          :slot-label-format="slotLabelFormat"
          :title-format="titleFormat"
          :long-press-delay="0"
          :selectable="true"
          @dateClick="handleDateClick"
          @eventClick="handleVisitClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import pl from "@fullcalendar/core/locales/pl"

export default {
  name: "RegistrationCalendar",
  components: {FullCalendar},
  props: {
    start: {type: String, default: null},
    items: {type: Array, default: ()=>[]},
    dayView: {type: Boolean, default: null},
  },
  data() {
    return {
      viewportWidth: 0,
      pl,
      calendarHeader: {
        left: "",
        center: "title",
        right: "",
      },
      titleFormat: {year: "numeric", month: "long", day: "numeric"},
      calendarPlugins: [resourceTimeGridPlugin, interactionPlugin],
      slotDuration: {minutes: 15},
      slotLabelFormat: {
        hour: "numeric",
        minute: "2-digit",
      },
    };
  },
  computed: {
    minTime() {
      return this.dayView ? "08:00:00" : "00:00:00";
    },
    maxTime() {
      return this.dayView ? "20:00:00" : "24:00:00";
    },
  },
  watch: {
    start(newVal) {
      const calendarApi = this.$refs.fullCalendar.getApi();
      this.$nextTick(() => {
        calendarApi.gotoDate(newVal);
      })
    }
  },
  created() {
    this.viewportWidth = window.innerWidth;
    window.addEventListener("resize", this.onResize, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize, false);
    this.$refs.calendarWrapper.removeEventListener("scroll", this.passCalendarScroll, false);
    this.$refs.fakeWrapper.removeEventListener("scroll", this.passFakeScroll, false);
  },
  methods: {
    onResize() {
      if (this.viewportWidth !== window.innerWidth) {
        this.resizeFake();
        this.viewportWidth = window.innerWidth;
      }
    },
    resizeFake() {
      const width = getComputedStyle(this.$refs.calendar).width;
      this.$refs.fake.setAttribute("style", `width: ${width};`);
    },
    passCalendarScroll() {
      if (this.$refs.fakeWrapper.scrollLeft !== this.$refs.calendarWrapper.scrollLeft) {
        this.$refs.fakeWrapper.scrollLeft = this.$refs.calendarWrapper.scrollLeft;
      }
    },
    passFakeScroll() {
      if (this.$refs.calendarWrapper.scrollLeft !== this.$refs.fakeWrapper.scrollLeft) {
        this.$refs.calendarWrapper.scrollLeft = this.$refs.fakeWrapper.scrollLeft;
      }
    },
    handleDateClick (dateEvent) {
      this.$emit("createAppointment", dateEvent)
    },
    handleVisitClick(info) {
      this.$emit("showDetails", info.event)
    },
  },
}
</script>

<style scoped lang="scss">
  $scrollbar-height: 15px;

  .fake-wrapper {
    width: 100%;
    overflow-x: auto;
    height: $scrollbar-height;
  }

  .fake-content {
    height: $scrollbar-height;
  }

  ::v-deep .fc-event {
    cursor: pointer;
    line-height: 1.2;
  }
  ::v-deep .fc th.fc-widget-header {
    padding-left: 4px;
    padding-right: 4px;
  }

  ::v-deep .fc-time-grid .fc-slats td {
    height: 28px;
  }

  ::v-deep .fc-bgevent {
    opacity: .45;
    cursor: pointer;
  }
</style>
