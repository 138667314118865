<template>
  <div class="d-flex flex-column">
    <is-granted
      :privileges="['GET_APPOINTMENT_WORKERS_WITH_TREATMENT']"
      :subject="appointmentId"
      component="fragment"
    >
      <button
        class="btn btn-sm btn-outline-secondary text-wrap"
        @click="openReportTreatmentTypeModal(appointmentId)"
      >
        Raportuj typ usługi
      </button>
    </is-granted>
    <is-granted
      :privileges="['GET_APPOINTMENT_WORKERS_WITH_ADDED_LATER','UPDATE_ADDED_LATER_APPOINTMENT_ADDITIONAL_WORKERS']"
      :subject="appointmentId"
      component="fragment"
    >
      <button
        class="btn btn-sm btn-outline-secondary text-wrap"
        @click="openChangeAdditionalWorkersModal(appointmentId)"
      >
        Edytuj specjalistów dodatkowych
      </button>
    </is-granted>
    <is-granted
      :privileges="['UNIFY_APPOINTMENT']"
      :subject="appointmentId"
      component="fragment"
    >
      <button
        class="btn btn-sm btn-outline-secondary text-wrap"
        @click="openChangeAppointmentDateModal(appointmentId)"
      >
        Edytuj datę wizyty
      </button>
    </is-granted>
    <is-granted
      :privileges="['CANCEL_APPOINTMENT']"
      :subject="appointmentId"
      component="fragment"
    >
      <button
        class="btn btn-sm btn-outline-secondary text-wrap"
        @click="openCancelAppointmentModal(appointmentId)"
      >
        Odwołaj wizytę
      </button>
    </is-granted>
    <is-granted
      :privileges="['CANCEL_APPOINTMENT_DEFERRED']"
      :subject="appointmentId"
      component="fragment"
    >
      <button
        class="btn btn-sm btn-outline-secondary text-wrap"
        @click="openCancelAppointmentDeferredModal(appointmentId)"
      >
        Wniosek o odwołanie wizyty
      </button>
    </is-granted>
    <is-granted
      :privileges="['FORCE_FINISH_APPOINTMENT']"
      :subject="appointmentId"
      component="fragment"
    >
      <button
        class="btn btn-sm btn-outline-secondary text-wrap"
        @click="openFinishAppointmentModal(appointmentId)"
      >
        Wymuś zamknięcie wizyty
      </button>
    </is-granted>
    <is-granted
      :privileges="['UPDATE_APPOINTMENT_LOCATION']"
      :subject="appointmentId"
      component="fragment"
    >
      <button
        class="btn btn-sm btn-outline-secondary text-wrap"
        @click="openChangeAppointmentLocationTypeModal(appointmentId)"
      >
        Zmiana typu wizyty
      </button>
    </is-granted>
  </div>
</template>

<script>
import IsGranted from "../../IsGranted";
import {mapMutations} from "vuex";

export default {
  name: "AppointmentUnificationOptions",
  components: {IsGranted},
  props: {
    appointmentId: {type: String, required: true},
    status: {type: String, required: true},
    mainWorkerId: {type: String, required: true},
    patientId: {type: String, required: true},
    branchId: {type: String, required: true},
    branchName: {type: String, required: true},
    date: {type: Date, required: true},
    group: {type: Boolean, required: true},
    patients: {type: Array, required: true},
    workers: {type: Array, required: true},
  },
  computed: {
    appointmentData() {
      return {
        appointmentId: this.appointmentId,
        status: this.status,
        mainWorkerId: this.mainWorkerId,
        patientId: this.patientId,
        branchId: this.branchId,
        branchName: this.branchName,
        date: this.date,
        group: this.group,
        patients: this.patients,
        workers: this.workers,
      };
    }
  },
  methods: {
    ...mapMutations("appointmentListItem", ["saveAppointmentListItem",]),
    openReportTreatmentTypeModal() {
      this.saveAppointmentListItem(this.appointmentData);
      setTimeout(() => this.$bvModal.show("report-treatment-type-modal"));
    },
    openChangeAdditionalWorkersModal() {
      this.saveAppointmentListItem(this.appointmentData);
      setTimeout(() => {
        this.$bvModal.show(`changeAdditionalWorkers-${this.appointmentId}`);
      }, 0);
    },
    openChangeAppointmentDateModal() {
      this.saveAppointmentListItem(this.appointmentData);
      setTimeout(() => {
        this.$bvModal.show(`changeAppointmentDate-${this.appointmentId}`);
      }, 0);
    },
    openCancelAppointmentModal() {
      this.saveAppointmentListItem(this.appointmentData);
      setTimeout(() => this.$bvModal.show("cancel-reason-modal"));
    },
    openCancelAppointmentDeferredModal() {
      this.saveAppointmentListItem(this.appointmentData);
      setTimeout(() => this.$bvModal.show("cancel-reason-deferred-modal"));
    },
    openChangeAppointmentLocationTypeModal() {
      this.saveAppointmentListItem(this.appointmentData);
      setTimeout(() => {
        this.$bvModal.show(`changeAppointmentLocationType-${this.appointmentId}`);
      }, 0);
    },
    openFinishAppointmentModal() {
      this.saveAppointmentListItem(this.appointmentData);
      setTimeout(() => {
        this.$bvModal.show("force-finish-appointment");
      }, 0);
    },
  },
}
</script>
