<template>
  <b-modal
    id="edit-finished-document-modal"
    title="Potwierdzenie edycji dokumentu"
    no-close-on-backdrop
    title-tag="div"
    size="md"
    @hide="resetData"
  >
    <b-form-group
      id="editReason"
      label="Podaj przyczyne edycji dokumentu"
      label-for="description"
    >
      <textarea-counter
        v-model="editReason"
        :disabled="loading"
        :max-characters-count="2000"
        :state="state('editFinishedDocumentReason')"
        rows="3"
      />
      <error-message
        :errors="errorsData"
        field="editFinishedDocumentReason"
      />
    </b-form-group>
    <template #modal-footer>
      <b-btn
        class="btn btn-secondary"
        @click="closeModal"
      >
        Anuluj
      </b-btn>

      <b-btn
        class="btn btn-primary"
        @click="submit"
      >
        Zapisz
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
import TextareaCounter from "../../Form/Textarea/TextareaCounter";
import ErrorMessage from "../../Form/ErrorMessage";

const MIN_REASON_LENGTH = 3

export default {
  name: "EditFinishedAppointmentModal",
  components: {ErrorMessage, TextareaCounter},
  props: {
    errors: {type: Array, default: () => []}
  },
  data() {
    return {
      editReason: "",
      loading: false,
      errorsData: this.errors
    }
  },
  watch: {
    errors(){
      this.errorsData = this.errors;
    }
  },
  methods: {
    submit() {
      this.$emit("editFinishedDocumentConfirmed", this.editReason);

      if (this.editReason?.length >= MIN_REASON_LENGTH) { // validate reason length
        this.$bvModal.hide("edit-finished-document-modal");
      }
    },
    closeModal(){
      this.editReason = "";
      this.$bvModal.hide("edit-finished-document-modal");
    },
    state(field) {
      return this.errorsData.find((error) => error.field === field) ? false : null;
    },
    resetData(){
      this.errorsData = [];
      this.editReason = "";
    }
  }
}
</script>

