<template>
  <b-form-group
    label="Upoważnienie do dokumentacji medycznej (wymagane)"
  >
    <b-form-radio-group
      id="nfzMedicalDocumentation"
      v-model="medicalDocumentation"
      name="nfzMedicalDocumentation"
      stacked
      @input="update"
    >
      <b-form-radio
        :value="true"
        :state="state('permissionForNfzMedicalDocumentation')"
      >
        Upoważniam do uzyskiwania dokumentacji medycznej.
      </b-form-radio>
      <b-form-radio
        :value="false"
        :state="state('permissionForNfzMedicalDocumentation')"
      >
        Nie upoważniam do uzyskiwania dokumentacji medycznej.
      </b-form-radio>
    </b-form-radio-group>
    <error-message
      :errors="errors"
      field="permissionForNfzMedicalDocumentation"
    />
  </b-form-group>
</template>

<script>

import ErrorMessage from "../Form/ErrorMessage";

export default {
  name: "NFZMedicalDocumentationPermission",
  components: {ErrorMessage},
  props: {
    value: {type: Boolean, default: null},
    errors: {type: Array, default: () => []},
  },
  data() {
    return {
      medicalDocumentation: this.value
    };
  },
  methods: {
    update(){
      this.$emit("input", this.medicalDocumentation);
    },
    state(field){
      return this.errors.find((error) => error.field === field) ? false : null;
    },
  }
}
</script>

<style scoped>

</style>
