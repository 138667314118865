<template>
  <b-modal
    id="chronic-disease-close-modal"
    no-close-on-backdrop
    title="Zamknij rozpoznanie"
    title-tag="h3"
    @hide="onHide"
  >
    <div class="card-box">
      <error-message :errors="errors" />
      <error-message
        :errors="errors"
        field="chronicDiseaseId.chronicDisease"
      />
      <b-form-group
        label="Data zakończenia leczenia"
      >
        <date-picker
          v-model="endOfCureDate"
          :state="state('endOfCureDate')"
        />
        <error-message
          :errors="errors"
          field="endOfCureDate"
        />
      </b-form-group>
    </div>
    <template #modal-footer>
      <button
        class="btn btn-secondary"
        @click="hideModal"
      >
        Anuluj
      </button>
      <button
        :disabled="isLoading"
        class="btn btn-primary"
        @click="closeChronicDisease"
      >
        <i
          :class="isLoading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import DatePicker from "../../../Form/DatePicker/DatePicker";
import modify from "../../../../rest/modify";
import stringifyDate from "../../../../utils/date/stringifyDate";
import ErrorMessage from "../../../Form/ErrorMessage";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import processResponseException from "../../../../utils/errors/processResponseException";

export default {
  components: {
    ErrorMessage,
    DatePicker,
  },
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true},
    chronicDiseaseId: {type: String, default: null},
  },
  data() {
    return {
      isLoading: false,
      endOfCureDate: null,
    };
  },
  methods: {
    async closeChronicDisease() {
      this.isLoading = true;
      try {
        await modify(`/api/patient/${this.patientId}/chronic-diseases/${this.chronicDiseaseId}`, {
          endOfCureDate: this.endOfCureDate ? stringifyDate(this.endOfCureDate) : null,
        });
        this.$emit("chronicDiseaseUpdated");
        this.hideModal();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.isLoading = false;
    },
    hideModal() {
      this.$bvModal.hide("chronic-disease-close-modal");
    },
    onHide() {
      this.endOfCureDate = null;
      this.errors = [];
    },
  },
}
</script>
