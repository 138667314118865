


















































































































import Component from "vue-class-component";
import {Mixins, Prop} from "vue-property-decorator";
import DiagnosisField from "../../../components/Ipz/DiagnosisField/DiagnosisField.vue";
import read from "../../../rest/read";
import LoadingMask from "../../../components/Loading/LoadingMask.vue";
import ErrorMessage from "../../../components/Form/ErrorMessage.vue";
import ErrorsMixin from "../../../mixins/ErrorsMixin";
import {generateUuid} from "../../../utils/uuid/generateUuid";
import create from "../../../rest/create";
import processResponseException from "../../../utils/errors/processResponseException";
import TargetsList from "../../../components/Ipz/TargetsAndTasks/TargetsList.vue";
import {Target} from "../../../types/TargetsAndTasks";
import remove from "../../../rest/remove";
import {ResponseCollection} from "../../../types/ResponseCollection";
import TherapeuticAndRecoverySnapshotTable
  from "../../../components/TherapeuticAndRecoverySnapshot/TherapeuticAndRecoverySnapshotTable.vue";
import IsGranted from "../../../components/IsGranted.vue";
import EditableDiagnosisField from "../../../components/Ipz/TherapeuticPlan/EditableDiagnosisField.vue";
import stringifyDate from "../../../utils/date/stringifyDate";
import DatePicker from "../../../components/Form/DatePicker/DatePicker.vue";

interface Overview {
  readonly: boolean;
}
interface IpzDetails {
  ipzId: string;
  overview: Overview;
}

interface Creator {
  workerId: string,
  name: string,
  surname: string,
  pwz: string | null
}

interface Task {
  name: string,
  description: string,
  createdAt: string,
  updatedAt: string,
  updatedBy: string,
  status: string,
  creator: Creator,
}

interface Goals {
  name: string,
  description: string,
  createdAt: string,
  updatedAt: string,
  updatedBy: string,
  status: string,
  creator: Creator,
  tasks: Task[]
}

interface Entry {
  content: string,
  createdAt: string,
  creator: Creator
}

interface Needs extends Entry {
}

interface Resources extends Entry{
}

interface SnapshotData {
  goals: Goals[],
  needs: Needs[],
  resources: Resources[],
}

export interface Snapshot {
  snapshotId: string,
  createdBy: string,
  createdAt: string,
  data: SnapshotData
}

@Component({
  name: NfzTherapeuticAndRecoveryPlanPanel.name,
  components: {
    EditableDiagnosisField,
    TherapeuticAndRecoverySnapshotTable, DiagnosisField, LoadingMask, ErrorMessage, TargetsList, IsGranted, DatePicker}
})
export default class NfzTherapeuticAndRecoveryPlanPanel extends Mixins(ErrorsMixin) {

  @Prop({type: String, required: true}) readonly ipzId!: string;

  private fields: Array<String> = [];
  private entries: Array<String> = [];
  private overview: Overview | {} = {};
  private therapeuticPlanId: string | null = null;
  private loading: boolean = false;
  private targets: Array<Target> = [];
  private snapshots: Array<Snapshot> = [];
  private createdAt: Date = new Date();
  private disabledAcceptationButtons: boolean = false;

  private getField(key: string) {
    return undefined !== this.fields[key] ? this.fields[key] : {entries: []};
  };

  private async mounted(): Promise<void> {
    await Promise.all([
      this.loadData(),
      this.loadTherapeuticPlans(),
    ]);
    await this.loadSnapshots();
  }

  private async loadTherapeuticPlans(): Promise<void> {
    this.loading = true;
    const {overview, fields, therapeuticPlanId} = await read(`/api/ipzs/${this.ipzId}/therapeutic-plans`);
    this.overview = overview;
    this.fields = fields;
    this.therapeuticPlanId = therapeuticPlanId;
    this.loading = false;
  };

  get parsedCreatedAt(){
    this.createdAt.setHours(0,0,0);
    return stringifyDate(this.createdAt);
  }

  get createEntryUrl() {
    return `/api/ipzs/therapeutic-plans/${this.therapeuticPlanId}/entries`;
  };

  private async entryUpdated(): Promise<void> {
    this.$emit("update");
  };

  async loadData(): Promise<void> {
    this.errors = [];
    this.loading = true;
    try {
      await Promise.all([
        this.loadTargets(),
        this.loadOverview(),
      ]);
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private async deleteEntry(entryId: string): Promise<void> {
    this.loading = true;
    try{
      await remove("/api/therapeutic-plans/entries", {
        entryId
      });
      await this.loadTherapeuticPlans();
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  async loadTargets(): Promise<void> {
    const {items} = await read<{items: Array<Target>}>(`/api/ipzs/${this.ipzId}/targets`);
    this.targets = items;
  }

  async loadSnapshots(): Promise<void> {
    const {items} = await read<ResponseCollection<Snapshot>>("/api/find-therapeutic-and-recovery-plan-snapshots", {
      ipzId: this.ipzId
    });
    this.snapshots = items;
  }

  async loadOverview(): Promise<void> {
    const {overview} = await read<IpzDetails>(`/api/ipzs/${this.ipzId}/details`);
    this.overview = overview;
  }

  private async newTherapeuticPlans(): Promise<void> {
    this.loading = true;
    this.errors = [];
    try {
      await create(`/api/ipzs/${this.ipzId}/therapeutic-plans`, {
        ipzId: this.ipzId,
        therapeuticPlanId: generateUuid(),
        interventionCardId: generateUuid()
      });
      await this.loadTherapeuticPlans();
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  };

  private async snapshot(): Promise<void> {
    this.loading = true;
    try {
      await create("/api/create-therapeutic-and-recovery-plan-snapshot", {
        snapshotId: generateUuid(),
        therapeuticPlanId: this.therapeuticPlanId,
        ipzId: this.ipzId,
        createdAt: this.parsedCreatedAt
      })
    } catch (e) {
      this.errors = processResponseException(e);
    };
    this.loading = false;
    await this.loadSnapshots();
  }

  private async removeSnapshot(snapshotId: string): Promise<void> {
    this.loading = true;
    await remove("/api/remove-therapeutic-and-recovery-plan-snapshot", {
      snapshotId
    });
    this.loading = false;
    await this.loadSnapshots();
  }

  private async removeTarget(therapeuticPlanId: string): Promise<void> {
    this.loading = true;
    try{
      await remove(`/api/ipzs/${this.ipzId}/targets`, {
        therapeuticPlanId
      });
      await this.loadTargets();
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private async removeTask(taskId: string): Promise<void> {
    this.loading = true;
    try{
      await remove("/api/targets/task", {
        taskId
      });
      await this.loadTargets();
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  validateAcceptationDate() {
    this.errors = [];
    this.disabledAcceptationButtons = false;
    if (this.createdAt.getTime() >= new Date().getTime()) {
      this.disabledAcceptationButtons = true;
      this.errors = [
        {
          message: "Data nie może być z przyszłości",
          field: "createdAt"
        },
      ];
    }
    return;
  };

}
