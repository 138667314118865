<template>
  <card
    title="Świadczenia"
    :loading="loading || changeStatusLoading"
  >
    <template #control>
      <slot name="control" />
    </template>

    <b-tabs
      v-model="tabIndex"
      lazy
      @input="changeTab"
    >
      <b-tab
        v-for="(tab, index) in tabs"
        :key="index"
        :title="tab.title"
      >
        <div
          v-if="tab.status === 'rejected_by_nfz'"
          class="row mb-4"
        >
          <div class="col-10">
            <b-form-input
              v-model="errorName"
              placeholder="Szukaj po opisie błędu"
              type="text"
            />
          </div>
          <div class="col-2">
            <button
              class="btn btn-primary"
              type="button"
              :disabled="disabledSearchByErrorName"
              @click="searchByErrorName"
            >
              <i class="fa fa-search" />
              Szukaj
            </button>
          </div>
        </div>
        <div
          v-if="items.length"
          class="d-flex justify-content-between align-items-center mb-4"
        >
          <span>
            <b>Liczba świadczeń:</b> {{ pagination.totalRows }}
          </span>

          <div>
            <button
              class="btn btn-outline-primary mr-2"
              @click="selectAll"
            >
              Zaznacz wszystkie
            </button>

            <button
              class="btn btn-primary"
              :disabled="!selectedDocumentsId.length"
              @click="changeSelectedItemsStatus(null)"
            >
              {{ status === AppointmentDocumentStatisticsTabStatus.to_send
                ? "Wyślij wybrane"
                : "Cofnij do wysłania wybrane"
              }}
              ({{ selectedDocumentsId.length }})
            </button>

            <button
              v-if="status !== AppointmentDocumentStatisticsTabStatus.non_accountable"
              class="btn btn-dark ml-2"
              :disabled="!selectedDocumentsId.length"
              @click="changeSelectedItemsStatus(AppointmentDocumentStatisticsTabStatus.non_accountable)"
            >
              Nie rozliczaj wybranych
              ({{ selectedDocumentsId.length }})
            </button>

            <button
              v-if="[
                AppointmentDocumentStatisticsTabStatus.dont_report,
                AppointmentDocumentStatisticsTabStatus.sent
              ].includes(status)"
              class="btn btn-danger ml-2"
              :disabled="!selectedDocumentsId.length"
              @click="changeSelectedItemsStatus(AppointmentDocumentStatisticsTabStatus.rejected_by_nfz)"
            >
              {{ "Przenieś do błędów wybrane" }}
              ({{ selectedDocumentsId.length }})
            </button>
          </div>
        </div>

        <appointment-document-statistics-table
          :items="items"
          :selected-documents-id="selectedDocumentsId"
          :status="tabs[tabIndex].status"
          @onCheckboxChange="onCheckboxChange"
          @updateList="$emit('refetch')"
          @changeStatusRequestLoading="(val) => changeStatusLoading = val"
        />

        <pagination-description
          :items-count="items.length"
          :page="page"
          :per-page="pagination.perPage"
          :total-count="pagination.totalRows"
          persisted-slot
        >
          <per-page-select
            v-model="pagination.perPage"
            :options="[10, 25, 50, 100, 200]"
            @input="changePerPage"
          />
          <b-pagination
            :value="page"
            :total-rows="pagination.totalRows"
            :per-page="pagination.perPage"
            align="right"
            class="mb-0"
            @input="changePage"
          />
        </pagination-description>
      </b-tab>
    </b-tabs>
  </card>
</template>

<script>
import Card from "../../Card";
import PaginationDescription from "../../Pagination/PaginationDescription";
import PerPageSelect from "../../Pagination/PerPageSelect";
import {isEqual} from "lodash";
import AppointmentDocumentStatisticsTable
  from "@/components/Appointment/AppointmentDocumentStatistics/AppointmentDocumentStatisticsTable";
import {updatePageQuery} from "@/utils/pageUrl/handlePageQuery";
import {AppointmentDocumentStatisticsTabStatus} from "@/types/AppointmentDocumentStatistics";
import update from "@/rest/update";
import processResponseException from "@/utils/errors/processResponseException";

const tabs = [
  {title: "Do wysłania ", status: AppointmentDocumentStatisticsTabStatus.to_send},
  {title: "Wysłane", status: AppointmentDocumentStatisticsTabStatus.sent},
  {title: "Z błędami", status: AppointmentDocumentStatisticsTabStatus.rejected_by_nfz},
  {title: "Nie raportuj", status: AppointmentDocumentStatisticsTabStatus.dont_report},
  {title: "Nierozliczone", status: AppointmentDocumentStatisticsTabStatus.non_accountable}
];

const DEFAULT_ADS_PER_PAGE = 50;

export default {
  name: "AppointmentDocumentStatisticsTabsCard",
  components: {
    AppointmentDocumentStatisticsTable,
    PerPageSelect, PaginationDescription, Card
  },
  props: {
    loading: {type: Boolean, required: true},
    paginationData: {type: Object, default: () => ({})},
    items: {type: Array, required: true},
    status: {type: String, required: true}, // AppointmentDocumentStatisticsTab
  },
  data() {
    return {
      errorName : null,
      selectedDocumentsId: [],
      tabIndex: tabs.findIndex(tab => tab.status === this.status) || 0,
      tabs: tabs,
      page: this.paginationData.page || 1,
      pagination: {
        perPage: this.paginationData.perPage || DEFAULT_ADS_PER_PAGE,
        totalRows: this.paginationData.totalRows || 0
      },
      changeStatusLoading: false,
      AppointmentDocumentStatisticsTabStatus: AppointmentDocumentStatisticsTabStatus,
    }
  },
  computed: {
    disabledSearchByErrorName(){
      return (this.errorName === null || this.errorName.length < 3);
    }
  },
  watch: {
    paginationData() {
      this.page = this.paginationData.page || 1
      this.pagination = {
        perPage: this.paginationData.perPage || DEFAULT_ADS_PER_PAGE,
        totalRows: this.paginationData.totalRows || 0
      }
    }
  },
  async mounted() {
    this.updateTabIndex();
  },
  methods: {
    changeTab(tabIndex) {
      updatePageQuery({
        status: tabs[tabIndex].status,
        adsPage: 1,
      });
    },
    changePage(page) {
      this.page = page

      updatePageQuery({
        adsPage: page,
      });
    },
    changePerPage(perPage) {
      this.pagination.perPage = perPage;

      updatePageQuery({
        adsPage: 1,
        adsPerPage: perPage === DEFAULT_ADS_PER_PAGE ? undefined : perPage,
      });
    },
    getStatusToChangeForCurrentTab() {
      switch (this.status) {
        case AppointmentDocumentStatisticsTabStatus.to_send:
          return AppointmentDocumentStatisticsTabStatus.sent
        case AppointmentDocumentStatisticsTabStatus.sent:
        case AppointmentDocumentStatisticsTabStatus.non_accountable:
        case AppointmentDocumentStatisticsTabStatus.rejected_by_nfz:
        case AppointmentDocumentStatisticsTabStatus.dont_report:
          return AppointmentDocumentStatisticsTabStatus.to_send
      }
    },
    async changeStatus(appointmentDocumentsId, status) {
      this.changeStatusLoading = true;

      try {
        await update("/api/appointment/document/update-statistics-status", {
          documentsStatuses: appointmentDocumentsId.map(documentId => ({
            status: status,
            documentId,
          }))
        });

        this.$emit("refetch");
      } catch (exception) {
        this.errors = processResponseException(exception);
      }

      this.changeStatusLoading = false;
    },
    searchByErrorName(){
      this.$emit("search-by-error-name", this.errorName);
    },
    updateTabIndex() {
      const index = tabs.findIndex(tab => isEqual(tab.status, this.status))
      this.tabIndex = index > -1 ? index : 0;
    },
    changeSelectedItemsStatus(status) {
      this.changeStatus(this.selectedDocumentsId,
        null === status ? this.getStatusToChangeForCurrentTab() : status
      )
    },
    onCheckboxChange(appointmentDocumentId) {
      const itemWasSelected = this.selectedDocumentsId.includes(appointmentDocumentId)

      if (!itemWasSelected) {
        this.selectedDocumentsId = [
          ...this.selectedDocumentsId,
          appointmentDocumentId
        ]
      } else { // deselect
        this.selectedDocumentsId = this.selectedDocumentsId.filter(id => id !== appointmentDocumentId)
      }
    },
    selectAll() {
      this.selectedDocumentsId = this.items.map(item => item.appointmentDocumentId)
    },
    deselectAll() { // used by ref from parent (AppointmentDocumentStatistics)
      this.selectedDocumentsId = []
    }
  },
}
</script>
