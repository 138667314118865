







































import ErrorsMixin from "../../mixins/ErrorsMixin";
import {Component, Mixins, Prop} from "vue-property-decorator";
import ErrorMessage from "../Form/ErrorMessage.vue";
import processResponseException from "../../utils/errors/processResponseException";
import update from "../../rest/update";
import {
  EstablishmentUnitPriceNumberDiscriminantUnit,
  UpdateUnitPriceNumberDiscriminantEntries
} from "../../types/Establishment";
import UnitTypeSelect from "../Form/Select/UnitTypeSelect.vue";

@Component({
  name: "RemoveUnitPriceNumberContractDataItemModal",
  components: {UnitTypeSelect, ErrorMessage}
})

export default class EstablishmentP1DataModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly establishmentId!: string;
  @Prop({type: String, required: true}) readonly type!: string;
  @Prop({type: String, required: true}) readonly serviceScopeCode!: string;
  @Prop({type: Number, required: true}) readonly unitOrderNumber!: number;
  @Prop({type: Array, required: true}) readonly units!: EstablishmentUnitPriceNumberDiscriminantUnit[];

  loading: boolean = false;
  get modalId(): string {
    return `removeUnitPriceNumberContractDataItemModal${this.unitOrderNumber}`
  }

  closeModal(): void {
    this.$bvModal.hide(this.modalId);
  }

  async submit(): Promise<void> {
    this.errors = [];
    this.loading = true;

    const payloadUnits = [
      ...this.units
        .filter(unit => unit.orderNumber !== this.unitOrderNumber)
        .map(unit => ({
          unitId: unit.id,
          type: unit.type,
          serviceScopeCode: unit.serviceScopeCode,
          entries: unit.entries,
        }))
    ]

    try {
      await update("/api/unit/price-number-discriminant-entries", {
        establishmentId: this.establishmentId,
        entries: payloadUnits
      } as UpdateUnitPriceNumberDiscriminantEntries)

      this.closeModal();
      this.$emit("removed")
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }
}
