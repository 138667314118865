



















































































































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import ReportContainer from "../../components/Report/ReportContainer.vue";
import DatePicker from "../../components/Form/DatePicker/DatePicker.vue";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import read from "../../rest/read";
import IKUPConditions from "./IKUP/IKUPConditions.vue";
import PaginationDescription from "../../components/Pagination/PaginationDescription.vue";
import I18n from "../../components/i18n.vue";
import Card from "../../components/Card.vue";
import ErrorMessage from "../../components/Form/ErrorMessage.vue";
import {changePageQuery} from "../../utils/pageUrl/handlePageQuery";
import BackButton from "../../components/BackButton.vue";
import {generateUuid} from "../../utils/uuid/generateUuid";
import create from "../../rest/create";
import eventBus from "../../eventBus";
import processResponseException from "../../utils/errors/processResponseException";

interface CzpReport {
  id: string;
  createdAt: string;
  status: Statuses;
}

interface Pagination {
  perPage: number;
  totalRows: number;
}

interface CzpReportResponse {
  items: Array<CzpReport> | null;
  pagination: Pagination;
}

enum Statuses {
  PENDING = "pending",
  COMPLETED = "completed",
  REJECTED = "rejected",
}

interface Field {
  key: string;
  label: string;
  class?: string;
}

interface Filters {
  page: number | undefined;
}

interface Query {
  page: number | undefined;
}
@Component({
  components: {BackButton, ErrorMessage, Card, I18n, PaginationDescription, ReportContainer, DatePicker}
})
export default class CZPReportView extends Mixins(ErrorsMixin) {
  @Prop({type: Number, default: null}) readonly page !: number;

  @Watch("page") onPageChange(page: number): void {
    this.currentPage = page;
  }

  private isLoading: boolean = false;
  private startDate: Date|null = null;
  private endDate: Date|null = null;
  private items: Array<CzpReport> | null = null;
  private currentPage: number = this.page || 1;
  private isSaving: boolean = false;
  private pagination: Pagination = {
    perPage: 20,
    totalRows: 0
  };

  get fields(): Array<Field> {
    return [
      {key: "createdAt", label: "Data zlecenia"},
      {key: "status", label: "Status", class: "text-center"},
      {key: "conditions", label: "Warunki"},
      {key: "download", label: "Pobierz", class: "text-center"},
    ];
  }

  mounted(): void {
    this.loadData();
  }

  async loadData() {
    this.isLoading = true;
    const {items, pagination} = await read<CzpReportResponse>("/api/report/czp-report", this.filters());
    this.items = items;
    this.pagination = pagination;
    this.isLoading = false;
  }

  async createReport(): Promise<void>{
    try {
      this.isSaving = true;
      const czpReportId = generateUuid() as string;
      await create("/api/report/czp-report", {
        reportId: czpReportId,
        startDate: !!this.startDate ? stringifyDate(this.startDate, DATE_FORMAT.DATE) : null,
        endDate: !!this.endDate ? stringifyDate(this.endDate, DATE_FORMAT.DATE) : null,
      });
      this.clearForm();
      await this.loadData();
      eventBus.on(czpReportId, ()=>{ this.loadData() });
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.isSaving = false;
  }

  clearForm(): void {
    this.startDate = null;
    this.endDate = null;
    this.errors = [];
  }

  validateReportDates(): void {
    if (this.startDate && this.endDate
      && this.startDate.getTime() >= this.endDate.getTime()) {
      this.errors = [
        {
          message: "Data początkowa powinna być wcześniejsza niż data końcowa",
          field: "startDate"
        },
        {
          message: "Data końcowa powinna być późniejsza niż data początkowa",
          field: "endDate"
        },
      ];
      return;
    } else {
      const errors = []
      if (!this.startDate) {
        errors.push({
          message: "Pole nie powinno być puste",
          field: "startDate"
        })
      }
      if (!this.endDate) {
        errors.push({
          message: "Pole nie powinno być puste",
          field: "endDate"
        })
      }
      this.errors = errors;
      return;
    }
    this.errors = [];
  }

  query():Query {
    return {
      page: this.currentPage || undefined
    }
  }

  changePage(page: number) {
    this.currentPage = page;
    this.loadData();
    changePageQuery(this.query());
  }

  filters():Filters {
    return {
      page: this.currentPage || undefined,
    }
  }

  getBadgeVariant(status: Statuses): string {
    switch(status) {
      case Statuses.PENDING:
        return "success";
      case Statuses.COMPLETED:
        return "primary";
      case Statuses.REJECTED:
        return "danger";
      default:
        throw Error(`Not supported status: ${status}`);
    }
  }

  downloadStatusIconClass(status: Statuses): string | void {
    switch(status)  {
      case Statuses.PENDING:
        return "fa-spin fa-spinner";
      case Statuses.REJECTED:
        return "fa-ban text-danger";
      default:
        return;
    }
  }

}
