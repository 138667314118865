<template>
  <div class="card-box row">
    <b-form-group
      label="Data wizyty"
      class="col-lg-4 col-xl-4"
    >
      <date-picker
        v-model="date"
        :disabled="readOnly"
        @input="emitChanges"
      />
    </b-form-group>
    <b-form-group
      label="Godzina rozpoczęcia"
      class="col-lg-4 col-xl-4"
    >
      <hour-select
        v-model="startTime"
        :disabled="readOnly"
        :time-span="timeSpan"
        @input="emitChanges"
      />
    </b-form-group>
    <b-form-group
      label="Godzina zakończenia"
      class="col-lg-4 col-xl-4"
    >
      <hour-select
        v-model="endTime"
        :disabled="readOnly"
        :time-span="timeSpan"
        :state="state('updatedAppointmentEndDate')"
        @input="emitChanges"
      />
      <error-message
        :errors="errors"
        field="updatedAppointmentEndDate"
      />
    </b-form-group>
  </div>
</template>

<script>
import HourSelect from "../../components/Form/Select/HourSelect";
import DatePicker from "../../components/Form/DatePicker/DatePicker";
import ErrorMessage from "../../components/Form/ErrorMessage";
import parseDate from "../../utils/date/parseDate";
import stringifyDate from "../../utils/date/stringifyDate";

export default {
  name: "AppointmentDateBox",
  components: {
    HourSelect, DatePicker, ErrorMessage,
  },

  props: {
    startDate: {type: Date, required: true},
    endDate: {type: Date, required: true},
    readOnly: {type: Boolean, required: true},
    errors: {type: Array, default: () => []}
  },

  data() {
    return {
      parseDate,
      loading: false,
      endTime: null,
      startTime: null,
      date: this.startDate,
      timeSpan: 1,
    };
  },

  watch: {
    startDate() {
      this.extractStartTime();
    },
    endDate() {
      this.extractEndTime();
    }
  },

  mounted() {
    this.extractStartTime();
    this.extractEndTime();
  },

  methods: {
    emitChanges(){
      this.$emit("input", {
        updatedStartDate: stringifyDate(this.date.setHours(this.startTime.HH, this.startTime.mm)),
        updatedEndDate: stringifyDate(this.date.setHours(this.endTime.HH, this.endTime.mm))
      })
    },
    extractStartTime(){
      this.startTime = this.startDate
        ? {
          HH: this.stringifyNumberWithLeadingZero(this.startDate.getHours()),
          mm: this.stringifyNumberWithLeadingZero(this.startDate.getMinutes()),
        }
        : null;
    },
    extractEndTime(){
      this.endTime = this.endDate
        ? {
          HH: this.stringifyNumberWithLeadingZero(this.endDate.getHours()),
          mm: this.stringifyNumberWithLeadingZero(this.endDate.getMinutes()),
        }
        : null;
    },
    stringifyNumberWithLeadingZero(num) {
      return (`00${  num}`).slice(-2);
    },
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
  }


}

</script>
