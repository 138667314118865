
















































































































































































import ErrorsMixin from "../../mixins/ErrorsMixin";
import {Component, Mixins, Prop} from "vue-property-decorator";
import ErrorMessage from "../Form/ErrorMessage.vue";
import processResponseException from "../../utils/errors/processResponseException";
import update from "../../rest/update";
import read from "../../rest/read";

interface Data {
  csiozId: string;
  unitId: string;
  name: string;
  shortName: string;
  phone: string;
  city: string;
  zipCode: string;
  street: string;
  streetNo: string;
  regon: string;
  branchType?: string;
}

@Component({
  name: "EstablishmentP1DataModal",
  components: {ErrorMessage}
})
export default class EstablishmentP1DataModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly branchId!: string;
  @Prop({type: String, required: true}) readonly branchName!: string;
  @Prop({type: String, required: true}) readonly branchType!: string;

  loading: boolean = false;
  csiozId: string = "";
  unitId: string = "";
  name: string = "";
  shortName: string = "";
  phone: string = "";
  city: string = "";
  zipCode: string = "";
  street: string = "";
  streetNo: string = "";
  regon: string = "";

  get modalId(): string {
    return `establishment-p1data-${this.branchId}`;
  }


  get title(): string {
    return this.branchType === "establishment"
      ? `Dane p1 podmiotu ${this.branchName}` : `Alternatywne dane p1 ${this.branchName}`
  }


  closeModal(): void {
    this.$bvModal.hide(this.modalId);
  }

  async submit(): Promise<void> {
    this.errors = [];
    this.loading = true;
    const data: Data = {
      csiozId: this.csiozId,
      unitId: this.unitId,
      name: this.name,
      shortName: this.shortName,
      phone: this.phone,
      city: this.city,
      zipCode: this.zipCode,
      street: this.street,
      streetNo: this.streetNo,
      regon: this.regon,
      branchType: this.branchType
    };
    try {
      await update(`/api/p1-data/${this.branchId}`, data);
      this.closeModal();
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  async fetchData(): Promise<void> {
    this.loading = true;
    this.errors = [];
    try {
      const {
        csiozId,
        unitId,
        name,
        shortName,
        phone,
        regon,
        city,
        zipCode,
        street,
        streetNo,
      } = await read<Data>(`/api/p1-data/${this.branchId}`);
      this.csiozId = csiozId || "";
      this.unitId = unitId || "";
      this.name = name || "";
      this.shortName = shortName || "";
      this.phone = phone || "";
      this.city = city || "";
      this.zipCode = zipCode || "";
      this.street = street || "";
      this.streetNo = streetNo || "";
      this.regon = regon || "";
      this.loading = false;
    } catch (e) {
      this.errors = processResponseException(e);
    }
  }
}
