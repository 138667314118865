





























































































































































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import {AcceptedReferral} from "../Patient/PatientCard/PatientCardAcceptedReferrals.vue";
import update from "../../rest/update";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import ErrorMessage from "../Form/ErrorMessage.vue";
import create from "../../rest/create";
import RefuseReferralModal from "./RefuseReferralModal.vue";
import CompleteReferralConfirmModal from "./CompleteReferralConfirmModal.vue";

@Component({
  name: AcceptedReferralsTable.name,
  components: {
    CompleteReferralConfirmModal,
    RefuseReferralModal,
    ErrorMessage,
  },
})

export default class AcceptedReferralsTable extends Mixins(ErrorsMixin) {
  @Prop({type: Array, default: ()=>[]}) readonly items!: AcceptedReferral[];
  @Prop({type: String, default: null}) readonly appointmentDocumentId!: string;

  private selectedId: string | null = null;
  private loading: boolean = false;
  private action: string | null = null;

  private async unassign(id: string, appointmentDocumentId: string): Promise<void> {
    this.action = "unassign";
    this.loading = true;
    this.selectedId = id;
    this.errors = [];
    try {
      await create("/api/unassign-referral", {
        acceptedReferralId: id,
        appointmentDocumentId,
      });
      this.$emit("refresh");
    }catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
    this.selectedId = null;
    this.action = null;
  }

  private async refuse(id: string): Promise<void> {
    this.selectedId = id;
    this.$bvModal.show("refuse-referral");
  }

  private async showConfirmModal(id: string): Promise<void> {
    this.selectedId = id;
    this.$bvModal.show("complete-referral-confirm-modal");
  }

  private async complete(): Promise<void> {
    this.action = "complete";
    this.loading = true;
    this.errors = [];
    try {
      await update("/api/complete-referral", {
        acceptedReferralId: this.selectedId
      });
      this.$emit("refresh");
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.$bvModal.hide("complete-referral-confirm-modal");
    this.loading = false;
    this.selectedId = null;
    this.action = null;
  }

  @Watch("items") private async onItemsChange(): Promise<void> {
    this.errors = [];
  }
}

