var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('error-message',{attrs:{"errors":_vm.errors}}),_c('error-message',{attrs:{"errors":_vm.errors,"field":"appointmentDocumentId"}}),_c('b-table',{attrs:{"items":_vm.items,"fields":_vm.fieldsDefinitions,"empty-text":"Brak świadczeń","responsive":"","show-empty":""},scopedSlots:_vm._u([{key:"cell(select)",fn:function(data){return [_c('b-form-checkbox',{attrs:{"checked":_vm.selectedDocumentsId.includes(data.item.appointmentDocumentId)},on:{"change":function () { return _vm.onCheckboxChange(data); }}})]}},{key:"cell(visitId)",fn:function(data){return [(_vm.hasAccessToPatientCard)?_c('router-link',{attrs:{"to":{
          name: 'viewDocumentAppointment',
          params: {appointmentId: data.item.appointmentId}
        }}},[_vm._v(" "+_vm._s(data.item.visitId)+" "),(null !== data.item.sessionId
            && data.item.treatmentBenefitCode
            && _vm.isSessionTreatmentType(data.item.treatmentBenefitCode)
          )?_c('span',[_vm._v(" ("+_vm._s(data.item.sessionId)+") ")]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(data.item.visitId)+" ")])]}},{key:"cell(patient)",fn:function(data){return [(_vm.hasAccessToPatientCard)?_c('router-link',{attrs:{"to":{
          name: 'patientCard',
          params: {patientId: data.item.patientId}
        }}},[_vm._v(" "+_vm._s(_vm.patientName(data.item.patientName, data.item.fileNumber))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.patientName(data.item.patientName, data.item.fileNumber))+" ")])]}},{key:"cell(workerName)",fn:function(data){return [_c('router-link',{attrs:{"to":{
          name: 'workersWorkingHoursPage',
          params: {workerId: data.item.workerId}
        }}},[_vm._v(" "+_vm._s(data.item.workerName)+" ")])]}},{key:"cell(appointmentDate)",fn:function(data){return [_c('date-time',{attrs:{"value":_vm.parseDate(data.item.appointmentDate),"format":_vm.dateTimeFormat}})]}},{key:"cell(errorMessage)",fn:function(data){return [(_vm.status === _vm.AppointmentDocumentStatisticsTabStatus.rejected_by_nfz && data.item)?_c('appointment-document-statistic-item-error-message',{attrs:{"item":data.item},on:{"updateList":function () { return _vm.$emit('updateList'); },"updateErrors":function (er) { return _vm.errors = er; }}}):_vm._e()]}},{key:"cell(options)",fn:function(data){return [_c('div',{staticClass:"d-flex"},[(![
            _vm.AppointmentDocumentStatisticsTabStatus.dont_report,
            _vm.AppointmentDocumentStatisticsTabStatus.to_send
          ].includes(_vm.status))?_c('button',{staticClass:"btn btn-sm btn-outline-secondary ml-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.changeStatus(
            _vm.AppointmentDocumentStatisticsTabStatus.to_send,
            data.item.appointmentDocumentId
          )}}},[_vm._v(" Cofnij do wysłania ")]):_vm._e(),(_vm.status !== _vm.AppointmentDocumentStatisticsTabStatus.non_accountable)?_c('button',{staticClass:"btn btn-sm btn-secondary ml-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.changeStatus(
            _vm.AppointmentDocumentStatisticsTabStatus.non_accountable,
            data.item.appointmentDocumentId
          )}}},[_vm._v(" Nie rozliczaj ")]):_vm._e(),(_vm.status === _vm.AppointmentDocumentStatisticsTabStatus.to_send && null !== data.item.settlementCode)?_c('button',{staticClass:"btn btn-sm btn-success ml-2",attrs:{"type":"submit"},on:{"click":function($event){return _vm.changeStatus(
            _vm.AppointmentDocumentStatisticsTabStatus.sent,
            data.item.appointmentDocumentId
          )}}},[_vm._v(" Wyślij ")]):_vm._e(),([
            _vm.AppointmentDocumentStatisticsTabStatus.dont_report,
            _vm.AppointmentDocumentStatisticsTabStatus.sent
          ].includes(_vm.status))?_c('button',{staticClass:"btn btn-sm btn-danger ml-2",attrs:{"type":"submit"},on:{"click":function($event){return _vm.changeStatus(
            _vm.AppointmentDocumentStatisticsTabStatus.rejected_by_nfz,
            data.item.appointmentDocumentId
          )}}},[_vm._v(" Z błędami ")]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }