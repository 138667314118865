<template>
  <object-select
    :value="value"
    :options="options"
    placeholder="Wybierz status"
    :disabled="false"
    :multiple="true"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "./ObjectSelect";
import t from "../../../i18n";
import {AppointmentDocumentStatisticsTabStatus} from "../../../types/AppointmentDocumentStatistics";

export default {
  name: "AppointmentDocumentStatisticsSelect",
  components: {
    ObjectSelect,
  },
  props:{
    value: {type: Array, default: null}
  },

  computed:{
    options() {
      return Object.values(AppointmentDocumentStatisticsTabStatus).map((value) => ({value, name: t(`@statistic_data_status.${value}`)}));
    },
    selectedValue() {
      return this.options.filter((option) => (this.value.includes(option.value)));
    }
  },
  methods: {
    update(option) {
      this.$emit("input", option);
    },
  }
}
</script>
