<template>
  <div>
    <card
      :loading="loading"
      title="Adres zameldowania"
    >
      <template #control>
        <is-granted
          :privileges="['CHANGE_PATIENT_REGISTERED_ADDRESS']"
        >
          <b-btn
            v-b-modal.registeredAddressModal
            :disabled="loading || errors.length > 0"
            variant="primary"
            class="btn-sm"
          >
            <i class="fa fa-pencil-alt" />
          </b-btn>
        </is-granted>
      </template>
      <error-message :errors="errors" />
      <p>
        <span v-if="registeredAddress.street">
          {{ registeredAddress.street }}
          <!-- eslint-disable-next-line -->
          {{ registeredAddress.buildingNumber }}<span v-if="registeredAddress.apartmentNumber">/{{ registeredAddress.apartmentNumber }}</span>,
        </span>
        {{ registeredAddress.zipCode }}
        {{ registeredAddress.city }}&nbsp;
      </p>
    </card>
    <b-modal
      id="registeredAddressModal"
      hide-footer
      no-close-on-backdrop
      title="Edycja adresu zameldowania"
      title-tag="h3"
    >
      <registered-address-form
        :registered-address="registeredAddress"
        :patient-id="patientId"
        :living-address="livingAddress"
        @updateRegisteredAddress="updateRegisteredAddress"
        @cancel="cancel"
      />
    </b-modal>
  </div>
</template>

<script>
import read from "../../../rest/read";
import processResponseException from "../../../utils/errors/processResponseException";
import ErrorMessage from "../../Form/ErrorMessage";
import RegisteredAddressForm from "../RegisteredAddressForm";
import Card from "../../Card";
import IsGranted from "../../IsGranted";

export default {
  components: {
    IsGranted,
    Card,
    RegisteredAddressForm,
    ErrorMessage,
  },
  props: {
    patientId: {type: String, required: true},
    livingAddress: {type: Object, default: null},
  },
  data() {
    return {
      registeredAddress: {},
      errors: [],
      loading: false,
    };
  },
  watch: {
    patientId() {
      this.fetchData();
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        this.registeredAddress = await read(`/api/patients/${this.patientId}/registered_address`);
        this.$emit("input", this.registeredAddress);
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    cancel() {
      this.$bvModal.hide("registeredAddressModal");
      this.errors = [];
    },
    updateRegisteredAddress(registeredAddress) {
      this.registeredAddress = registeredAddress;
      this.$emit("input", this.registeredAddress);
      this.$emit("patientUpdated");
    },
  },

};
</script>

<style scoped>
.card-box {
  overflow: hidden;
}
</style>
