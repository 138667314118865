<template>
  <div>
    <card
      :loading="loading"
      :title="nfzConfiguration ? 'Koordynator opieki' : 'Case manager'"
    >
      <template #control>
        <is-granted
          :privileges="['CHANGE_PATIENT_CASE_MANAGER']"
        >
          <b-btn
            v-b-modal.caseManagerModal
            :disabled="loading"
            variant="primary"
            class="btn-sm"
          >
            <i class="fa fa-pencil-alt" />
          </b-btn>
        </is-granted>
      </template>
      <p v-if="caseManager">
        {{ caseManager.name }} {{ caseManager.surname }} <br>
        {{ caseManager.email }} / {{ caseManager.phone }}
      </p>
      <p v-else>
        {{ caseManagerNotRequired ? 'Niewymagane' : 'Brak' }}
      </p>
    </card>
    <b-modal
      id="caseManagerModal"
      no-close-on-backdrop
      :title="nfzConfiguration ? 'Edycja koordynatora opieki' : 'Edycja case manager\'a'"
      title-tag="h3"
    >
      <template #default>
        <worker-select
          v-model="newCaseManager"
          :disabled="caseManagerNotRequired"
          clearable
        />
        <b-checkbox
          v-model="caseManagerNotRequired"
          class="m-t-15"
        >
          Niewymagane
        </b-checkbox>
        <div
          v-show="caseManagerHistory.length"
          v-b-toggle="'caseManagerHistory'"
          class="text-center m-2 cursor-pointer"
        >
          ---
          <span class="when-closed">Pokaż historię</span>
          <span class="when-opened">Ukryj historię</span>
          ---
        </div>
        <b-collapse id="caseManagerHistory">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Data zmiany</th>
                <th>Osoba zmieniająca</th>
                <th>{{ historyTableTittle }}</th>
                <th>Niewymagane</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(entry, index) in caseManagerHistory"
                :key="index"
                class="p-3"
              >
                <td>{{ entry.dateOfChange }}</td>
                <td>{{ entry.changerFullName }}</td>
                <td>{{ entry.caseManagerFullName ? entry.caseManagerFullName : 'brak' }}</td>
                <td>{{ entry.caseManagerNotRequired ? 'Tak' : '' }}</td>
              </tr>
            </tbody>
          </table>
        </b-collapse>
      </template>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="cancel()"
        >
          Anuluj
        </button>
        <button
          :disabled="loading"
          class="btn btn-primary"
          @click="submit"
        >
          <i
            :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fa"
          />
          Zapisz
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import update from "../../../../rest/update";
import read from "../../../../rest/read";
import WorkerSelect from "../../../Worker/WorkerSelect";
import processResponseException from "../../../../utils/errors/processResponseException";
import Card from "../../../Card";
import IsGranted from "../../../IsGranted";
import {mapState} from "vuex";

export default {
  components: {
    IsGranted,
    Card,
    WorkerSelect,
  },
  props: {
    patientId: {type: String, required: true},
  },

  data() {
    return {
      caseManager: null,
      errors: [],
      loading: false,
      newCaseManager: null,
      caseManagerHistory: [],
      caseManagerNotRequired: false
    };
  },
  computed: {
    ...mapState({
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration,
    }),
    historyTableTittle(){
      return this.nfzConfiguration ? "Nowy koordynator opieki" : "Nowy case manager";
    }
  },
  watch: {
    patientId() {
      this.loadData();
    },
    caseManagerNotRequired(){
      this.newCaseManager = null;
    }
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        const resource = `/api/patients/${this.patientId}/case_manager`;
        await update(resource, {
          workerId: this.newCaseManager ? this.newCaseManager.workerId : null,
          caseManagerNotRequired: this.caseManagerNotRequired
        });
        this.loadData();
        await this.cancel();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    cancel() {
      this.$bvModal.hide("caseManagerModal");
      this.resetData();
      this.caseManagerNotRequired = false;
    },
    resetData() {
      this.newCaseManager = this.caseManager;
    },
    async loadData() {
      this.loading = true;
      const response = await read(`/api/patients/${this.patientId}/case_manager`);
      this.caseManagerHistory = response.caseManagerHistory.reverse();
      this.caseManager = response.workerId ? response : null;
      this.caseManagerNotRequired = response.caseManagerNotRequired
      this.resetData();
      this.loading = false;
    }
  },
};
</script>


<style scoped>
.card-box {
  overflow: hidden;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
