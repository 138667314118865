<template>
  <div>
    <address-form
      v-model="registeredAddressForm"
      :disabled="loading"
      :errors="subErrors('address')"
      :horizontal="true"
      :visible-country="false"
      :in-modal="true"
    />
    <button
      v-if="!equalAddresses"
      class="btn btn-success m-b-20 ml-auto mr-3 d-block"
      @click="getAddressFromLiving"
    >
      <i class="fa fa-home" />
      Pobierz dane z adresu zamieszkania
    </button>
    <error-message :errors="errors" />
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        @click="cancel"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        type="button"
        class="btn btn-primary"
        @click="onSubmit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </div>
</template>

<script>
import update from "../../rest/update";
import read from "../../rest/read";
import AddressForm from "../Address/AddressForm";
import getSubErrors from "../../utils/errors/subErrors";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../Form/ErrorMessage";
import isEqual from "lodash/isEqual";

export default {
  components: {
    ErrorMessage,
    AddressForm,
  },
  props: {
    patientId: {type: String, required: true},
    registeredAddress: {type: Object, default: null},
    livingAddress: {type: Object, default: null}
  },
  data() {
    return {
      registeredAddressForm: this.registeredAddress ? {...this.registeredAddress} : {},
      errors: [],
      equalAddresses: false,
      loading: false,
    };
  },
  watch: {
    registeredAddress() {
      this.resetData();
    },
    async patientId() {
      await this.getRegisteredAddress();
      this.equalAddresses = this.isAddressesEqual();
    },
  },
  async mounted() {
    if (!this.registeredAddress) {
      await this.getRegisteredAddress();
      this.equalAddresses = this.isAddressesEqual();
    }
  },
  methods: {
    async getRegisteredAddress() {
      this.loading = true;
      try {
        this.registeredAddressForm = await read(`/api/patients/${this.patientId}/registered_address`);
        this.$emit("updateRegisteredAddress", this.address);
      } catch(exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    subErrors(field) {
      return getSubErrors(this.errors, field);
    },
    async onSubmit() {
      this.loading = true;
      try{
        await update(`/api/patients/${this.patientId}/registered_address`, {address: this.registeredAddressForm});
        this.registeredAddressForm = await read(`/api/patients/${this.patientId}/registered_address`);
        this.$emit("updateRegisteredAddress", this.registeredAddressForm);
        this.cancel();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    cancel() {
      this.resetData();
      this.$emit("cancel");
    },
    resetData(){
      this.registeredAddressForm = {...this.registeredAddress};
      this.errors = [];
      this.equalAddresses = this.isAddressesEqual();
    },
    getAddressFromLiving(){
      this.registeredAddressForm = {...this.livingAddress};
      this.equalAddresses = true;
    },
    isAddressesEqual(){
      return isEqual(this.livingAddress, this.registeredAddressForm);
    },
  },
};
</script>
