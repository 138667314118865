<template>
  <div class="card-box">
    <b-form-group
      label="Wywiad"
    >
      <textarea-counter
        :id="'interview-'+id"
        v-model="interview"
        :max-characters-count="10000"
        :disabled="readOnlyData"
        :state="state('interview')"
        rows="3"
        @focus="clearErrors('interview')"
        @input="updateTextValue($event, 'interview')"
      />
      <module-error-message :messages="errors && errors.interview" />
      <b-form-checkbox
        v-if="displayVisitAtHome(treatmentTypeId)"
        v-model="visitAtHomeData"
        :disabled="readOnlyData"
        @input="updateVisitAtHome"
      >
        Wizyta w miejscu zamieszkania pacjenta.
      </b-form-checkbox>
    </b-form-group>
    <b-form-group
      label="Badanie"
    >
      <textarea-counter
        :id="'examination-'+id"
        v-model="examination"
        :max-characters-count="10000"
        :disabled="readOnlyData"
        :state="state('examination')"
        rows="3"
        @focus="clearErrors('examination')"
        @input="updateTextValue($event, 'examination')"
      />
      <module-error-message :messages="errors && errors.examination" />
    </b-form-group>
    <is-granted
      v-if="appointmentStatus==='finished'"
      :privileges="['PRINT_APPOINTMENT_RECOMMENDATIONS']"
    >
      <a
        :href="`/api/appointment/document/recommendations/${appointmentDocumentId}/print`"
        class="btn btn-sm btn-secondary"
        target="_blank"
      >
        <i class="fa fa-print" />
        Wydrukuj
      </a>
    </is-granted>
    <b-form-group
      label="Zalecenia"
    >
      <textarea-counter
        :id="'recommendation-'+id"
        v-model="recommendation"
        :max-characters-count="10000"
        :disabled="readOnlyData"
        :state="state('recommendation')"
        rows="3"
        @focus="clearErrors('recommendation')"
        @input="updateTextValue($event, 'recommendation')"
      />
      <module-error-message :messages="errors && errors.recommendation" />
    </b-form-group>
  </div>
</template>
<script>
import TextareaCounter from "../../Form/Textarea/TextareaCounter";
import ModuleErrorMessage from "../../Form/ModuleErrorMessage";
import {mapState} from "vuex";
import {isGranted} from "../../../security/isGranted";
import IsGranted from "@/components/IsGranted";

export default {
  name: "SimpleVisitModule",
  components: {ModuleErrorMessage, TextareaCounter,IsGranted},
  props: {
    idx: {type: Number, required: true},
    module: {type: Object, required: true},
    readOnly: {type: Boolean, required: true},
    patientId: {type: String, default: null},
    appointmentDocumentId: {type: String, required: true},
    appointmentStatus: {type: String, default: null},
    visitAtHome: {type: Boolean, default: null},
    treatmentTypeId: {type: String, default: null},
  },
  data() {
    return {
      interview: this.module.interview,
      syncInterview: null == this.module.syncInterview ? false : this.module.syncInterview,
      examination: this.module.examination,
      syncExamination: null == this.module.syncExamination ? false : this.module.syncExamination,
      recommendation: this.module.recommendation,
      syncRecommendation: null == this.module.syncRecommendation ? false : this.module.syncRecommendation,
      id: this.idx,
      errors: this.module.errors,
      isReception: false,
      visitAtHomeData: this.visitAtHome
    };
  },
  computed: {
    ...mapState({
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration
    }),
    readOnlyData(){
      return this.isReception ? true : this.readOnly;
    }
  },
  watch: {
    "module.errors"() {
      this.errors = this.module.errors;
    },
    visitAtHome(){
      this.visitAtHomeData = this.visitAtHome;
    }
  },
  mounted() {
    this.isRoleReception();
  },
  methods: {
    displayVisitAtHome(treatmentTypeId){
      return [
        "27212071-715b-4c34-84ec-30fbc458bb77",
        "726e5928-82ba-4269-a10e-f81ad653c07d",
        "059855c1-309a-4021-b645-c63376f8c660"
      ].includes(treatmentTypeId);
    },
    clearErrors: function (field) {
      if (this.errors) {
        this.errors[field] = null;
      }
    },
    updateTextValue(event, field) {
      const module = {...this.module};
      module[field] = this[field];
      this.$emit("input", module)
    },
    state(field) {
      return (this.errors && this.errors[field] ? false : null);
    },
    async isRoleReception(){
      this.isReception = await isGranted("ROLE_RECEPTION_WITH_MEDICAL_RECORDS");
    },
    updateVisitAtHome(value){
      this.$emit("updateVisitAtHome", value)
    }
  },
}
</script>
