



























































































import ErrorMessage from "../../Form/ErrorMessage.vue";
import TargetStatusSelect from "./TargetStatusSelect.vue";
import ResizableTextarea from "../../Form/Textarea/ResizableTextarea.vue";
import processResponseException from "../../../utils/errors/processResponseException";
import create from "../../../rest/create";
import {generateUuid} from "../../../utils/uuid/generateUuid";
import {Component, Prop} from "vue-property-decorator";
import ErrorsMixin from "../../../mixins/ErrorsMixin.ts";
import {mixins} from "vue-class-component";
import type {TargetFormData} from "../../../types/TargetsAndTasks";
import DatePicker from "../../Form/DatePicker/DatePicker.vue";
import stringifyDate from "../../../utils/date/stringifyDate";

@Component({
  name: "CreateTargetModal",
  components: {ResizableTextarea, TargetStatusSelect, ErrorMessage, DatePicker},
})
export default class CreateTargetModal extends mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly ipzId!: string;

  loading: boolean = false;
  name: TargetFormData["name"]|null = null;
  description: TargetFormData["description"]|null = null;
  status: TargetFormData["status"]|null = null;
  createdAt: TargetFormData["createdAt"] = new Date();

  async submit(): Promise<void> {
    this.resetErrors();
    this.loading = true;
    this.createdAt.setHours(0,0,0);
    try {
      const data = {
        targetId: generateUuid(),
        name: this.name ? this.name.trim() : this.name,
        description: this.description ? this.description.trim() : this.description,
        status: this.status,
        createdAt: stringifyDate(this.createdAt)
      };
      await create(`/api/ipzs/${this.ipzId}/targets`, data);
      this.$emit("created");
      this.closeModal();
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  resetErrors(): void {
    this.errors = [];
  }

  resetData(): void {
    this.resetErrors();
    this.name = null;
    this.description = null;
    this.status = null;
    this.createdAt = new Date();
  }

  closeModal(): void {
    this.$bvModal.hide("create-target-modal");
  }
}
