











































































const MAX_FILE_SIZE_MB = 5;
const MAX_FILE_SIZE = {
  megaBytes: MAX_FILE_SIZE_MB,
  bytes: MAX_FILE_SIZE_MB * 1024 * 1024 *5,
};

import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ErrorMessage from "@/components/Form/ErrorMessage.vue";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import processResponseException from "../../utils/errors/processResponseException";
import postDataAndFiles from "../../rest/postDataAndFiles";
import AdvancedFileSelect from "../File/AdvancedFileSelect.vue";

@Component({
  name: "EstablishmentContractDataModal",
  components: {ErrorMessage, AdvancedFileSelect}
})

export default class EstablishmentCertificatesModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly branchId!: string;
  @Prop({type: String, required: true}) readonly branchName!: string;
  @Prop({type: String, required: true}) readonly branchType!: string;


  hasTemplate: boolean = false;
  isSaving: boolean = false;
  contractFile: File|null = null;
  sizeError: string = "";
  successInfo: boolean = false;

  @Watch("contractFile") onContractFileChange(file: File|null): void {
    this.hasTemplate = null !== file;
  }
  get modalId(): string {
    return `establishment-contract-data-${this.branchId}`;
  }

  get title(): string {
    return `Wgraj dane umowy NFZ z pliku dla ${this.branchName}`;
  }

  closeModal(): void {
    this.successInfo = false;
    this.$bvModal.hide(this.modalId);
  }

  addFile(file: File): void {
    this.successInfo = false;
    this.contractFile = file;
    this.sizeError = "";
    if (file.size > (MAX_FILE_SIZE.bytes)) {
      this.sizeError = `Plik ${file.name} jest zbyt duży.
        Maksymalna wielkość pliku to ${MAX_FILE_SIZE.megaBytes}MB`;
    }
  }

  removeFile(): void {
    this.sizeError = "";
    this.contractFile = null;
  }

  async submit(): Promise<void> {
    this.successInfo = false;
    this.errors = [];
    this.isSaving = true;

    const data = new FormData();
    data.append("contractFile", this.contractFile as Blob || undefined);
    try {
      await postDataAndFiles(`/api/branches/${this.branchId}/contract-data`, data);
      this.contractFile = null;
      this.successInfo = true;
    } catch(e) {
      this.errors = processResponseException(e);
    }

    this.isSaving = false;
  }
}
