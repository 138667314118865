<template>
  <object-select
    :clearable="clearable"
    :disabled="disabled"
    :options="options"
    :placeholder="placeholder"
    :state="state"
    :value="selectedValue"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "../Form/Select/ObjectSelect";
import t from "../../i18n";

export default {
  name: "WaitingListStatusSelect",
  components: {ObjectSelect},
  props: {
    value: {type: [String, Object], default: null},
    state: ObjectSelect.props.state, // eslint-disable-line
    placeholder: {type: String, default: "Wybierz status"},
    disabled: {type: Boolean, default: false},
    clearable: {type: Boolean, default: false},

  },
  data() {
    return {
      optionValues: [
        {value: "opened"},
        {value: "finished"},
      ],
    };
  },
  computed: {
    options() {
      return this.optionValues.map(option => ({
        ...option,
        name: t(`@waiting-list-status.${option.value}`),
      }));
    },
    selectedValue() {
      if (!this.value) {
        return null
      } else {
        return this.options.find((option) => option.value === this.value);
      }
    },
  },
  methods: {
    update(option) {
      this.$emit("input", option ? option.value : null);
    },
  },
}
</script>

