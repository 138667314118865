<template>
  <b-modal
    id="createWaitingListRecord"
    title="Nowy wpis na listę oczekujących"
    no-close-on-backdrop
    title-tag="h3"
    size="lg"
    @show="clear"
  >
    <template #default>
      <waiting-list-record-form
        v-model="formData"
        :errors="errors"
      />
      <error-message :errors="errors" />
    </template>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary"
        @click="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Dodaj wpis
      </button>
    </template>
  </b-modal>
</template>

<script>
import ErrorMessage from "../../components/Form/ErrorMessage";
import {generateUuid} from "../../utils/uuid/generateUuid";
import create from "../../rest/create";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import processResponseException from "../../utils/errors/processResponseException";
import WaitingListRecordForm from "../../components/WaitingList/WaitingListRecordCreateForm";

export default {
  name: "WaitingListCreateRecordModal",
  components: {
    WaitingListRecordForm,
    ErrorMessage
  },
  data() {
    return {
      formData: {},
      errors: [],
      loading: false,
    };
  },
  methods: {
    clear() {
      this.formData = {};
      this.errors = [];
    },
    async submit(e) {
      e.preventDefault();
      this.loading = true;
      try {
        const waitingListId = generateUuid();
        await create("/api/waiting-list", {
          waitingListId: waitingListId,
          ...this.formData,
          notificationDate: this.formData.notificationDate
            ? stringifyDate(this.formData.notificationDate, DATE_FORMAT.DATE)
            : null
        });
        this.$emit("recordCreated");
        this.closeModal();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    closeModal() {
      this.$bvModal.hide("createWaitingListRecord");
    },
  }
}
</script>

<style scoped>

</style>
