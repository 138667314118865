<template>
  <div>
    <b-form-group
      label="Specjaliści"
      label-for="additionalWorkers"
    >
      <worker-select
        id="additionalWorkers"
        :value="formData.additionalWorkers"
        :state="state('additionalWorkers')"
        multiple
        @input="updateFormData('additionalWorkers', $event)"
      />
      <error-message
        :errors="errors"
        field="additionalWorkers"
      />
    </b-form-group>
    <b-form-group
      label="Rodzaj kontaktu"
      label-for="categorySelect"
    >
      <interventionNotificationContactCategorySelect
        id="categorySelect"
        :value="formData.category"
        :state="state('category')"
        @input="updateFormData('category', $event)"
      />
      <error-message
        :errors="errors"
        field="category"
      />
    </b-form-group>

    <b-form-group
      v-if="!nfzConfiguration"
      label="Płeć"
      label-for="sexSelect"
    >
      <sexSelect
        id="sexSelect"
        :value="formData.sex"
        :state="state('sex')"
        @input="updateFormData('sex', $event)"
      />
      <error-message
        :errors="errors"
        field="sex"
      />
    </b-form-group>

    <b-form-group
      v-if="!nfzConfiguration"
      label="Przedział wiekowy"
      label-for="ageSelect"
    >
      <interventionNotificationAgeSelect
        id="ageSelect"
        :value="formData.age"
        :state="state('age')"
        @input="updateFormData('age', $event)"
      />
      <error-message
        :errors="errors"
        field="age"
      />
    </b-form-group>

    <b-form-group
      id="description"
      label="Opis"
      label-for="descriptionInput"
    >
      <b-form-textarea
        id="nameInput"
        :value="formData.description"
        :state="state('description')"
        :rows="5"
        @input="updateTextarea"
      />
      <error-message
        :errors="errors"
        field="description"
      />
    </b-form-group>

    <b-form-group
      label="Czas trwania"
    >
      <b-form-group>
        <b-form-checkbox
          v-if="!isEdit"
          :checked="formData.isDuration"
          :state="state('isDuration')"
          @input="updateIsDuration"
        >
          Określ czas trwania
        </b-form-checkbox>
        <error-message
          :errors="errors"
          field="isDuration"
        />
      </b-form-group>

      <b-form-group>
        <date-picker
          v-if="formData.isDuration"
          key="start"
          :state="state('startDate')"
          :minute-step="1"
          :value="formData.startDate"
          format="YYYY-MM-DD HH:mm"
          type="datetime"
          @input="updateFormData('startDate', $event)"
        />
        <error-message
          :errors="errors"
          field="startDate"
        />
      </b-form-group>
      <b-form-group>
        <date-picker
          v-if="formData.isDuration"
          key="end"
          :state="state('endDate')"
          :minute-step="1"
          :value="formData.endDate"
          format="YYYY-MM-DD HH:mm"
          type="datetime"
          @input="updateFormData('endDate', $event)"
        />
        <error-message
          :errors="errors"
          field="endDate"
        />
      </b-form-group>
    </b-form-group>
    <b-form-group
      v-if="nfzConfiguration"
      label="Informacje dodatkowe"
    >
      <b-form-group>
        <b-form-checkbox
          v-model="formData.scopeOfActivitiesAndHealthcareInformation"
          :state="state('scopeOfActivitiesAndHealthcareInformation')"
          @input="updateFormData('scopeOfActivitiesAndHealthcareInformation', $event)"
        >
          Udzielanie informacji o zakresie działania centrum i możliwości uzyskania świadczeń opieki zdrowotnej.
        </b-form-checkbox>
        <error-message
          :errors="errors"
          field="scopeOfActivitiesAndHealthcareInformation"
        />
      </b-form-group>
      <b-form-group>
        <b-form-checkbox
          v-model="formData.benefitsOfSocialAssistance"
          :state="state('benefitsOfSocialAssistance')"
          @input="updateFormData('benefitsOfSocialAssistance', $event)"
        >
          Wskazanie miejsca uzyskania niezbędnego świadczenia z zakresu pomocy społecznej.
        </b-form-checkbox>
        <error-message
          :errors="errors"
          field="benefitsOfSocialAssistance"
        />
      </b-form-group>
      <b-form-group>
        <b-form-checkbox
          v-model="formData.initialAssessmentHealthNeedsAndProvidingSupport"
          :state="state('initialAssessmentHealthNeedsAndProvidingSupport')"
          @input="updateFormData('initialAssessmentHealthNeedsAndProvidingSupport', $event)"
        >
          Wstępna ocena potrzeb zdrowotnych i udzielenie wsparcia oraz uzgodnienie wstępnego planu dalszego postępowania
        </b-form-checkbox>
        <error-message
          :errors="errors"
          field="initialAssessmentHealthNeedsAndProvidingSupport"
        />
      </b-form-group>
      <b-form-group>
        <b-form-checkbox
          v-model="formData.agreeingDateAndPlaceCZPServices"
          :state="state('agreeingDateAndPlaceCZPServices')"
          @input="updateFormData('agreeingDateAndPlaceCZPServices', $event)"
        >
          Uzgodnienie terminu i miejsca uzyskania świadczeń w CZP
        </b-form-checkbox>
        <error-message
          :errors="errors"
          field="agreeingDateAndPlaceCZPBenefits"
        />
      </b-form-group>
      <b-form-group>
        <b-form-checkbox
          v-model="formData.providingHealthServices"
          :state="state('providingHealthServices')"
          @input="updateFormData('providingHealthServices', $event)"
        >
          Udzielenie świadczeń zdrowotnych
        </b-form-checkbox>
        <error-message
          :errors="errors"
          field="providingHealthServices"
        />
      </b-form-group>
    </b-form-group>
  </div>
</template>

<script>
import InterventionNotificationContactCategorySelect from
  "../Form/Select/InterventionNotificationContactCategorySelect.vue";
import SexSelect from "../Form/Select/SexSelect.vue";
import InterventionNotificationAgeSelect from "../Form/Select/InterventionNotificationAgeSelect.vue";
import ErrorMessage from "../Form/ErrorMessage";
import DatePicker from "../Form/DatePicker/DatePicker";
import WorkerSelect from "../Worker/WorkerSelect.vue";
import {mapState} from "vuex";

export default {
  components: {
    DatePicker,
    InterventionNotificationContactCategorySelect,
    SexSelect,
    InterventionNotificationAgeSelect,
    ErrorMessage,
    WorkerSelect
  },

  props:{
    value: {type: Object, required: true},
    errors: {type: Array, required: true},
    isEdit: {type: Boolean, default: false},
  },

  data() {
    return {
      formData: {
        additionalWorkers: [],
        isDuration: false,
        scopeOfActivitiesAndHealthcareInformation: false,
        benefitsOfSocialAssistance: false,
        initialAssessmentHealthNeedsAndProvidingSupport: false,
        agreeingDateAndPlaceCZPServices: false,
        providingHealthServices: false,
      },
    };
  },

  computed: {
    ...mapState({
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration,
      currentUser: state => state.currentUser.user,
    }),
  },
  watch: {
    value(){
      this.formData = {
        ...this.value,
        startDate: this.value.startDate ? new Date(this.value.startDate) : null,
        endDate: this.value.endDate ? new Date(this.value.endDate) : null
      };
    },
  },
  mounted() {
    if (this.isEdit) return
    const {name, surname, workerId} = this.currentUser

    this.formData = {
      ...this.formData,
      additionalWorkers: [{
        name: `${name} ${surname}`,
        value: workerId,
        workerId: workerId,
      }],
    };
    this.update();
  },
  methods: {
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    update(){
      this.$emit("input", this.formData);
    },
    removeWhitespace(value) {
      this.formData.description = value;
      this.update();
    },
    updateTextarea(value) {
      this.removeWhitespace(value);
    },
    updateIsDuration(isDuration) {
      const startDate = isDuration ? this.formData.startDate || new Date() : undefined;
      const endDate = isDuration ? this.formData.endDate || new Date(startDate.getTime() + 15*60000) : undefined;
      this.formData = {
        ...this.formData,
        isDuration,
        startDate,
        endDate,
      };
      this.update();
    },
    updateFormData(field, value) {
      this.formData = {
        ...this.formData,
        [field]: value,
      };
      this.update();
    }
  }
}
</script>
