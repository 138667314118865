<template>
  <div class="card-box">
    <div
      class="d-flex align-items-center justify-content-between"
    >
      <button
        class="btn btn-sm btn-outline-secondary"
        @click="removeMedicalDataAuthorization"
      >
        <i class="fa fa-trash" />
        usuń osobę
      </button>
    </div>
    <FormRow
      label="Imię osoby upoważnionej"
      field="authorizedPersonName"
      :errors="errors"
    >
      <b-form-input
        v-model="authorizedPerson.authorizedPersonName"
        :state="state('authorizedPersonName')"
      />
    </FormRow>

    <FormRow
      :errors="errors"
      label="Nazwisko osoby upoważnionej"
      field="authorizedPersonSurname"
    >
      <b-form-input
        v-model="authorizedPerson.authorizedPersonSurname"
        :state="state('authorizedPersonSurname')"
      />
    </FormRow>

    <FormRow
      :errors="errors"
      label="Data zakończenia upoważnienia (opcjonalnie)"
      field="endOfAuthorization"
    >
      <date-picker
        v-model="authorizedPerson.endOfAuthorization"
        :state="state('endOfAuthorization')"
      />
    </FormRow>

    <FormRow
      :errors="errors"
      label="Numer telefonu osoby upoważnionej (opcjonalnie)"
      field="authorizedPersonPhoneNumber"
    >
      <b-form-input
        v-model="authorizedPerson.authorizedPersonPhoneNumber"
        :state="state('authorizedPersonPhoneNumber')"
      />
    </FormRow>

    <FormRow
      :errors="errors"
      label="Adres e-mail osoby upoważnionej (opcjonalnie)"
      field="authorizedPersonEmail"
    >
      <b-form-input
        v-model.trim="authorizedPerson.authorizedPersonEmail"
        :state="state('authorizedPersonEmail')"
      />
    </FormRow>
  </div>
</template>

<script>
import FormRow from "../../../src/components/Form/FormRow.vue";
import DatePicker from "../Form/DatePicker/DatePicker";

export default {
  name: "CreateMedicalAuthorization",
  components: {
    FormRow,
    DatePicker
  },
  props: {
    person: {type: Object, required: true},
    errors: {type: Array, default: () => []},
  },
  data() {
    return {
      authorizedPerson: this.person,
    };
  },
  methods: {
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    removeMedicalDataAuthorization() {
      this.$emit("removeMedicalDocumentationAuthorization");
    },
  }
}
</script>

<style scoped>

</style>
