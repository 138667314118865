import {AppointmentTreatmentBenefitCode} from "./Appointment";

export type StatisticAppointmentItem = {
  appointmentDocumentId: string;
  appointmentId: string;
  fileNumber: string | null;
  patientId: string;
  patientName: string; // name surname
  workerId: string;
  workerName: string; // name surname
  treatmentType: string;
  appointmentDate: string;
  branchData: string;
  errorMessage: string[] | {[key: string]: string};
  visitId: number;
  sessionId: number;
  treatmentBenefitCode: AppointmentTreatmentBenefitCode | null;
  settlementCode: number|null
}

export interface AppointmentDocumentStatisticsPageQuery {
  page?: number;
  perPage?: number;
  status?: AppointmentDocumentStatisticsTabStatus;
  fromDate?: string; // stringifyDate
  toDate?: string; // stringifyDate
  treatmentTypes?: string; // id[].join
  patientId?: PatientAppointmentDocumentStatisticsFilter["patientId"];
  workers?: string; // id[].join
  branches?: string; // id[].join
  fromCzpArea?: boolean;
  visitId?: number;
  sessionId?: number;
}

export interface AppointmentDocumentStatisticsFilterExportQuery {
  fromDate?: string;
  toDate?: string;
  treatmentTypes?: string[];
  patientId?: PatientAppointmentDocumentStatisticsFilter["patientId"];
  workers?: string[];
  branches?: string[];
  fromCzpArea?: boolean;
  visitId?: number;
  sessionId?: number;
  status: string[];
}

export interface AppointmentDocumentStatisticsFiltersType {
  fromDate: Date|null;
  toDate: Date|null;
  treatmentTypes: string[]|null;
  patient: PatientAppointmentDocumentStatisticsFilter|null;
  workers: string[]|WorkerAppointmentDocumentStatisticsFilter[]|null;
  branches: string[]|null;
  fromCzpArea: boolean|null;
  visitId: number|null;
  sessionId: number|null;
}

export enum AppointmentDocumentStatisticsTabStatus {
  "to_send" = "to_send",
  "sent" = "sent",
  "rejected_by_nfz" = "rejected_by_nfz",
  "non_accountable" = "non_accountable",
  "dont_report" = "dont_report"
}

export interface PatientAppointmentDocumentStatisticsFilter {
  patientId: string;
  displayName?: string;
}

export interface WorkerAppointmentDocumentStatisticsFilter {
  name?: string;
  value: string;
}
