<template>
  <div>
    <h3>{{ headerTitle }}</h3>

    <div 
      id="assignedCategories" 
      class="card card-body"
    >
      <table class="table">
        <tr
          v-for="category in assignedCategories"
          :key="category.icfCode"
        >
          <td>{{ category.icfCode }}</td>
          <td>{{ category.title }}</td>
        </tr>
      </table>
    </div>

    <button
      v-if="!readOnly"
      v-b-modal="'redefineModal'"
      type="button" 
      class="btn btn-lg btn-primary btn-block"
    >
      Redefiniuj kategorie ICF
    </button>

    <b-modal
      id="redefineModal"
      no-close-on-backdrop
      title="UWAGA"
      title-tag="h3"
    >
      Zmiana spowoduje usunięcie wcześniej zdefiniowanych kategorii ICF. Wszystkie ewaluacje pozostaną bez zmian.
      <template #modal-footer>
        <button
          class="btn btn-secondary mr-2"
          @click="onHide"
        >
          Anuluj
        </button>
        <button
          class="btn btn-primary"
          @click="onConfirm"
        >
          Kontynuuj
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import t from "../../../i18n";

export default {
  props: {
    assignedCategories: {type: Array, required: true},
    readOnly: {type:Boolean, required: true},
    assignedCoreSets: {type: Array, required: true},
  },
  computed: {
    headerTitle() {
      return `Wybrane kategorie ICF - ${
        this.assignedCoreSets.map(name => t(name)).join(", ")
      }`;
    },
  },
  methods: {
    onHide() {
      this.$bvModal.hide("redefineModal");
    },
    onConfirm() {
      this.$emit("redefineCategories");
    }
  },
};
</script>

<style scoped>
  #assignedCategories {
    height: 400px;
    overflow-y: scroll;
    margin-bottom: 20px;
  }
</style>
