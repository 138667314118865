










import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import ObjectSelect from "../../Form/Select/ObjectSelect.vue";
import {AdditionalEntitlementsCodes} from "./AdditionalEntitlementsCodes";

@Component({
  name: "AdditionalEntitlementsCodeSelect",
  components: {ObjectSelect}
})
export default class AdditionalEntitlementsCodeSelect extends Vue {
  @Prop({type: String, default: () => null}) value!: string | null;
  @Prop({type: Boolean, default: () => false}) disabled!: boolean;
  @Prop({type: Boolean, default: null}) readonly state!: boolean|null;

  get options() {
    return AdditionalEntitlementsCodes
  }

  get currentOption() {
    return this.options.find((option) => option.value === this.value) || null;
  }
}
