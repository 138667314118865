<template>
  <li
    class="timeline-item"
  >
    <div class="timeline-badge bg-primary">
      <i class="fas fa-circle" />
    </div>
    <div class="timeline-panel">
      <div class="text-right">
        <is-granted
          v-if="patient"
          :privileges="['CREATE_EREFERRAL_FOR_DAILY_DESCRIPTION', 'ACCESS_P1_CLIENT']"
          :subject="description.appointmentDocumentId"
          condition="AND"
        >
          <b-btn
            v-if="patient"
            v-b-modal="`create-referral-${index}`"
            class="ml-2"
            size="sm"
            variant="success"
          >
            <i class="fa fa-plus" />
            Wystaw e-skierowanie
          </b-btn>
        </is-granted>
        <is-granted
          v-if="patient"
          :privileges="['CREATE_ELECTRONIC_PRESCRIPTION_FOR_DAILY_DESCRIPTION', 'ACCESS_P1_CLIENT']"
          :subject="description.appointmentDocumentId"
          condition="AND"
        >
          <b-btn
            v-b-modal="`create-prescription-${index}`"
            class="ml-2"
            size="sm"
            variant="success"
          >
            <i class="fa fa-plus" /> Wystaw e-receptę
          </b-btn>
        </is-granted>
        <is-granted
          :privileges="['UPDATE_CONTINUOUS_STAY_DAILY_DESCRIPTION']"
          :subject="description.continuousStayDailyDescriptionId"
          component="fragment"
        >
          <button
            v-b-modal="`updateContinuousStayDailyDescriptionModal-${description.continuousStayDailyDescriptionId}`"
            class="btn btn-primary m-1 btn-sm"
          >
            <i class="fa fa-pencil" />
            Edytuj wpis
          </button>
        </is-granted>
        <button
          v-if="null !== description.continuousStayDailyDescriptionId"
          v-b-modal="`historyContinuousStayDailyDescriptionModal-${description.continuousStayDailyDescriptionId}`"
          class="btn btn-primary m-1 btn-sm"
        >
          <i class="fa fa-pencil" />
          Historia
        </button>
        <is-granted
          :privileges="['ARCHIVE_CONTINUOUS_STAY_DAILY_DESCRIPTION']"
          :subject="description.continuousStayDailyDescriptionId"
          component="fragment"
        >
          <button
            v-b-modal="`deleteContinuousStayDailyDescriptionModal-${description.continuousStayDailyDescriptionId}`"
            class="btn btn-danger m-1 btn-sm"
          >
            <i class="fa fa-pencil" />
            Usuń wpis
          </button>
        </is-granted>
      </div>

      <continuous-stay-specialists-description-form
        :description="description"
        :icd9-initial-data="icd9InitialData"
        :readonly="true"
      />
      <continuous-stay-daily-description-prescription
        :continuous-stay-id="continuousStayId"
        :appointment-document-id="description.appointmentDocumentId"
        :document-prescriptions="description.prescriptions"
        :patient="patient"
        :nfz-branch="nfzBranch"
        :index="index"
      />
      <continuous-stay-daily-description-referrals
        :appointment-document-id="description.appointmentDocumentId"
        :document-referrals="description.referrals"
        :patient="patient"
        :index-key="index"
      />
      <continuous-stay-update-daily-specialists-description-modal
        v-if="null !== description.continuousStayDailyDescriptionId"
        :description="description"
        :continuous-stay-id="continuousStayId"
        :icd9-initial-data="icd9InitialData"
        @updateList="updateList"
      />
      <continuous-stay-daily-specialists-description-history-modal
        v-if="null !== description.continuousStayDailyDescriptionId"
        :history-list="description.history"
        :icd9-initial-data="icd9InitialData"
        :continuous-stay-daily-description-id="description.continuousStayDailyDescriptionId"
      />
      <continuous-stay-delete-daily-specialists-description-modal
        :description="description"
        @updateList="updateList"
      />
    </div>
  </li>
</template>

<script>
import ContinuousStaySpecialistsDescriptionForm from "./ContinuousStaySpecialistsDescriptionForm";
import ContinuousStayUpdateDailySpecialistsDescriptionModal
  from "./ContinuousStayUpdateDailySpecialistsDescriptionModal";
import ContinuousStayDailySpecialistsDescriptionHistoryModal
  from "./ContinuousStayDailySpecialistsDescriptionHistoryModal";
import IsGranted from "../../../../components/IsGranted";
import ContinuousStayDeleteDailySpecialistsDescriptionModal
  from "./ContinuousStayDeleteDailySpecialistsDescriptionModal";
import ContinuousStayDailyDescriptionPrescription from "./ContinuousStayDailyDescriptionPrescription.vue";
import ContinuousStayDailyDescriptionReferrals from "./ContinuousStayDailyDescriptionReferrals.vue";

export default {
  name: "ContinuousStayDisplayDailySpecialistsDescription",
  components: {
    ContinuousStayDailyDescriptionReferrals,
    ContinuousStayDailyDescriptionPrescription,
    ContinuousStayDeleteDailySpecialistsDescriptionModal,
    ContinuousStayDailySpecialistsDescriptionHistoryModal,
    ContinuousStayUpdateDailySpecialistsDescriptionModal,
    ContinuousStaySpecialistsDescriptionForm,
    IsGranted
  },
  props: {
    description: {type: Object, default: () => {}},
    continuousStayId: {type: String, required: true},
    patient: {type: Object, default: null},
    nfzBranch: {type: Object, default: null},
    index: {type: Number, default: null},
    icd9InitialData: {type: Array, default: () => []}, // CodeItem[]
  },
  data() {
    return {
      patientData: this.patient
    }
  },
  watch: {
    patient() {
      this.patientData = this.patient
    },
  },
  methods: {
    updateList(){
      this.$emit("updateList");
    }
  },
}
</script>
