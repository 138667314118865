<template>
  <div>
    <card
      :loading="loading"
      title="Dokument tożsamości"
    >
      <template #control>
        <is-granted
          :privileges="['CHANGE_PATIENT_IDENTIFICATION_DOCUMENT']"
        >
          <b-btn
            v-b-modal.identificationDocumentData
            :disabled="loading || errors.length > 0"
            variant="primary"
            class="btn-sm"
          >
            <i class="fa fa-pencil-alt" />
          </b-btn>
        </is-granted>
      </template>
      <error-message :errors="errors" />
      <p>Typ dokumentu: {{ identificationDocumentType }}</p>
      <p>Numer dokumentu: {{ identificationDocument.number }}</p>
    </card>
    <b-modal
      id="identificationDocumentData"
      size="lg"
      hide-footer
      no-close-on-backdrop
      title="Edycja dokumentu tożsamości"
      title-tag="h3"
    >
      <identification-document-data-form
        :patient-id="patientId"
        :identification-document="identificationDocument"
        :modal-layout="true"
        @updateIdentificationDocumentData="updateIdentificationDocumentData"
        @cancel="cancel"
      />
    </b-modal>
  </div>
</template>

<script>
import IsGranted from "../../IsGranted";
import Card from "../../Card";
import ErrorMessage from "../../Form/ErrorMessage";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import read from "../../../rest/read";
import processResponseException from "../../../utils/errors/processResponseException";
import t from "../../../i18n";
import IdentificationDocumentDataForm from "../IdentificationDocumentDataForm";

export default {
  name: "IdentificationDocumentData",
  components: {
    IsGranted,
    Card,
    ErrorMessage,
    IdentificationDocumentDataForm
  },
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      identificationDocument: {},
      loading: false,
    };
  },
  computed: {
    identificationDocumentType(){
      if(this.identificationDocument.type){
        return t(`@identification-document.${this.identificationDocument.type}`)
      }
      return null;
    }
  },
  watch: {
    patientId() {
      this.loadIdentificationDocumentData();
    },
  },
  async mounted() {
    await this.loadIdentificationDocumentData();
  },
  methods: {
    async loadIdentificationDocumentData() {
      this.loading = true;
      try {
        this.identificationDocument = await read(`/api/patients/${this.patientId}/identification_document`);
      } catch(exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    updateIdentificationDocumentData(identificationDocumentData) {
      this.identificationDocument = {...identificationDocumentData};
    },
    cancel() {
      this.$bvModal.hide("identificationDocumentData");
      this.errors = [];
    },
  },
}
</script>
