<template>
  <div>
    <card
      :loading="loading"
      title="Status leczenia"
    >
      <template #control>
        <is-granted
          :privileges="['UPDATE_PATIENT_TREATMENT_STATUS']"
        >
          <b-btn
            v-b-modal.changeTreatmentStatus
            :disabled="loading"
            variant="primary"
            class="btn-sm"
          >
            <i class="fa fa-pencil-alt" />
          </b-btn>
        </is-granted>
      </template>
      <p>{{ getLabel(treatmentStatus) }}</p>
    </card>
    <b-modal
      id="changeTreatmentStatus"
      no-close-on-backdrop
      title="Status leczenia"
      title-tag="h3"
    >
      <template #default>
        <b-form-group
          label="Status leczenia"
          label-for="treatment-status"
        >
          <treatment-status-select
            v-model="treatmentStatusForm"
          />
          <error-message
            :errors="errors"
            field="treatment-status"
          />
        </b-form-group>
      </template>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="cancel()"
        >
          Anuluj
        </button>
        <button
          :disabled="loading"
          class="btn btn-primary"
          @click="submit"
        >
          <i
            :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fa"
          />
          Zapisz
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Card from "../../Card.vue";
import TreatmentStatusSelect from "../../Form/Select/TreatmentStatusSelect.vue";
import IsGranted from "../../IsGranted.vue";
import ErrorMessage from "../../Form/ErrorMessage.vue";
import read from "../../../rest/read";
import processResponseException from "../../../utils/errors/processResponseException";
import update from "../../../rest/update";

export default {
  name: "PatientCardTreatmentStatus",
  components: {
    Card,
    TreatmentStatusSelect,
    IsGranted,
    ErrorMessage
  },
  props: {
    patientId: {type: String, required: true}
  },
  data() {
    return {
      loading: false,
      treatmentStatus: null,
      treatmentStatusForm: null
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        const response = await read(`/api/patients/${this.patientId}/treatment-status`)
        this.treatmentStatus = response.treatmentStatus;
        this.treatmentStatusForm = response.treatmentStatus
      } catch (exception) {
        this.errors = processResponseException(exception)
      }
      this.loading = false
    },
    cancel() {
      this.errors = [];
      this.treatmentStatusForm = null;
      this.$bvModal.hide("changeTreatmentStatus")
    },
    getLabel(status) {
      switch(status) {
        case "active":
          return "Aktywne";
        case "finished":
          return "Zakończone"
        case "lack_of_information":
          return "Brak informacji"
        default:
          return "Brak informacji"
      }
    },
    async submit() {
      this.loading = true;
      try {
        const data = {
          treatmentStatus: this.treatmentStatusForm
        }
        await update(`/api/patients/${this.patientId}/treatment-status`, data)
        await this.loadData();
        this.errors = [];
        this.$bvModal.hide("changeTreatmentStatus")
      } catch (exception) {
        this.errors = processResponseException(exception)
      }
      this.loading = false
    }
  }
}
</script>
