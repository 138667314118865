import {Component, Prop, Vue} from "vue-property-decorator";
import {Location} from "vue-router";
import parseDate from "../utils/date/parseDate";
import stringifyDate from "../utils/date/stringifyDate";
import DATE_FORMAT from "../utils/date/DATE_FORMAT";
import {isGranted} from "../security/isGranted";
import t from "../i18n";
import type {AppointmentItem, Worker} from "../types/Appointment";
import type {Patient} from "../store/modules/appointmentListItem/types";

@Component
export default class AppointmentInfoMixin extends Vue {
  @Prop({type: String, default: null}) readonly patientId!: string;

  hasAccessToPatientCard: boolean = false;
  data: AppointmentItem|null = null;

  get parsedDate():Date|null {
    if (!this.data) {
      return null;
    }
    return parseDate(this.data.date);
  }

  get appointmentTime(): string|null {
    if (!this.parsedDate) {
      return null;
    }
    return stringifyDate(this.parsedDate, DATE_FORMAT.TIME);
  }

  get appointmentDate(): string|null {
    if (!this.parsedDate) {
      return null;
    }
    return stringifyDate(this.parsedDate, DATE_FORMAT.DATE);
  }

  get patient(): Patient|null {
    if (!this.data) {
      return null;
    }
    return this.patientId
      ? this.data.patients.find((patient: Patient) => patient.patientId === this.patientId) || null
      : this.data.patients[0];
  }

  get status(): string|null {
    if (this.data && this.data.status) {
      return t(this.data.status);
    }
    return null;
  }

  get cancelReason(): string|null {
    return this.data && this.data.cancelReason ? t(`@cancel-reason.${this.data.cancelReason}`) : null;
  }

  get cancelComment(): string|null {
    return this.data && this.data.cancelComment !== "" ? this.data.cancelComment : "(brak)";
  }

  get displayAddress(): boolean {
    if (!this.data) {
      return false;
    }

    if (this.data.location.isLocal || !this.data.location.address) {
      return false;
    }

    return !!(this.data.location.address.city
      || this.data.location.address.zipCode
      || this.data.location.address.street);
  }

  async mounted() {
    try {
      await Promise.all([
        this.checkAccessToPatientCard(),
      ]);
    } catch (exception) {
      console.error(exception);
    }
  }

  redirectToWorkerRegisterCalendar(worker: Worker): Location {
    const startCalendarDate = new Date();
    const day = startCalendarDate.getDay() || 7;
    startCalendarDate.setDate(startCalendarDate.getDate() + 1 - day);
    startCalendarDate.setHours(0, 0, 0, 0);

    return {
      name: "registration",
      query: {
        selectedWorkerId: worker.workerId,
        start: stringifyDate(startCalendarDate),
        copyId: this.$route.query.copyId ? null : undefined, // force route change to close modal
      },
    }
  }

  async checkAccessToPatientCard(): Promise<void> {
    this.hasAccessToPatientCard = await isGranted(["PATIENT_CARD"]);
  }
}
