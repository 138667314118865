<template>
  <object-select
    :value="selectedOption"
    :options="options"
    :state="state"
    :placeholder="placeholder"
    :disabled="disabled"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "../Form/Select/ObjectSelect";

export default {
  components: {
    ObjectSelect,
  },
  props: {
    value: {type: [Object, String], default: null},
    state: {default: null, type: Boolean},
    placeholder: {type: String, default: "Wybierz osobę z listy powiązanych osób pacjenta"},
    availableOptions: {type: Array, required: true},
    disabled: {type: Boolean, default: false},
  },
  computed: {
    options() {
      return this.availableOptions.map(option => ({
        ...option,
        name: `${option.name  } ${  option.surname}`,
        value: option.id
      }));
    },
    selectedOption() {
      if (!this.value) {
        return null
      } else if (typeof this.value === "string"){
        return this.options.find((option) => option.value === this.value);
      } else {
        return this.value;
      }
    },
  },
  methods: {
    update(option) {
      this.$emit("input", option ? option.value : null);
    },
  },
};
</script>
