<template>
  <form @submit.prevent="submit">
    <div class="form-row">
      <div :class="modalLayout ? 'col-md-6' : 'col-xl-6'">
        <b-form-group
          id="name"
          :label="nfzConfiguration ? 'Imiona' : 'Imię'"
          label-for="nameInput"
        >
          <b-form-input
            id="nameInput"
            v-model.trim="formData.name"
            :disabled="loading"
            :state="state('name')"
          />
          <error-message
            :errors="errors"
            field="name"
          />
        </b-form-group>

        <b-form-group
          id="surname"
          label="Nazwisko"
          label-for="surnameInput"
        >
          <b-form-input
            id="surnameInput"
            v-model.trim="formData.surname"
            :disabled="loading"
            :state="state('surname')"
          />
          <error-message
            :errors="errors"
            field="surname"
          />
        </b-form-group>
        <b-form-group
          id="familyName"
          label="Nazwisko rodowe"
          label-for="familyNameInput"
        >
          <b-form-input
            id="familyNameInput"
            v-model.trim="formData.familyName"
            :disabled="loading"
            :state="state('familyName')"
          />
          <error-message
            :errors="errors"
            field="familyName"
          />
        </b-form-group>
        <b-form-group
          id="birthDate"
          label="Data urodzenia"
          label-for="birthDateInput"
        >
          <birthdate-input
            v-model="formData.birthdate"
            :errors="errors"
          />
          <error-message
            :errors="errors"
            field="birthdate"
          />
        </b-form-group>
        <b-form-group
          id="pesel"
          label="PESEL"
          label-for="peselInput"
        >
          <b-form-input
            id="peselInput"
            v-model.trim="formData.pesel"
            :disabled="loading"
            :state="state('pesel')"
          />
          <error-message
            :errors="errors"
            field="pesel"
          />
        </b-form-group>
        <b-form-group
          v-if="patientAnonymous || (!patientAnonymous && nfzConfiguration)"
          id="sex"
          label="Płeć"
          label-for="sexSelectInput"
        >
          <sexSelect
            id="sexSelectInput"
            v-model="formData.sex"
            :state="state('sex')"
          />
          <error-message
            :errors="errors"
            field="sex"
          />
        </b-form-group>
      </div>
      <div :class="modalLayout ? 'col-md-6' : 'col-xl-6'">
        <b-form-group
          id="email"
          label="E-mail"
          label-for="emailInput"
        >
          <b-form-input
            id="emailInput"
            v-model.trim="formData.emailAddress"
            :disabled="loading"
            :state="state('emailAddress')"
          />
          <error-message
            :errors="errors"
            field="emailAddress"
          />
        </b-form-group>
        <b-form-group
          id="phoneNumber"
          label="Telefon"
          label-for="phoneNumberInput"
        >
          <b-form-input
            id="phoneNumberInput"
            v-model.trim="formData.phoneNumber"
            :disabled="loading"
            :state="state('phoneNumber')"
          />
          <error-message
            :errors="errors"
            field="phoneNumber"
          />
        </b-form-group>
      </div>
    </div>
    <b-form-group
      id="ice"
      label="Kontakt w razie wypadku (ICE)"
      label-for="nameInput"
    >
      <textarea-counter
        v-model="formData.ice"
        :disabled="loading"
        :max-characters-count="200"
        :rows="2"
        :state="state('ice')"
      />
      <error-message
        :errors="errors"
        field="ice"
      />
    </b-form-group>
    <error-message :errors="errors" />
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        @click="cancel"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        type="submit"
        class="btn btn-primary"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </form>
</template>

<script>
import BirthdateInput from "../Form/Input/BirthdateInput.vue";
import SexSelect from "../Form/Select/SexSelect";
import update from "../../rest/update";
import read from "../../rest/read";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import ErrorMessage from "../Form/ErrorMessage";
import TextareaCounter from "../Form/Textarea/TextareaCounter";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import processResponseException from "../../utils/errors/processResponseException";
import parseDate from "../../utils/date/parseDate";
import {mapState} from "vuex";

export default {
  components: {
    BirthdateInput,
    ErrorMessage,
    TextareaCounter,
    SexSelect
  },
  mixins: [errorsMixin],
  props: {
    modalLayout: {type: Boolean, default: false},
    patientId: {type: String, default: null},
    personalData: {type: Object, default: null},
  },
  data() {
    return {
      patient: this.personalData ? {...this.personalData} : {},
      formData: {},
      loading: false,
    };
  },
  computed: {
    ...mapState({
      patientAnonymous: state => state.clinicParameters.parameters.patientAnonymous,
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration
    }),
  },
  watch: {
    personalData() {
      this.patient = {...this.personalData};
      this.resetData();
    },
    async patientId() {
      if (!this.personalData) {
        await this.loadPatientData();
        this.resetData();
      }
    },
  },
  async mounted() {
    if (!this.personalData) {
      await this.loadPatientData();
    }
    this.resetData();
  },
  methods: {
    async loadPatientData() {
      this.loading = true;
      try {
        this.patient = await read(`/api/patients/${this.patientId}/personal_data`);
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    async submit() {
      this.loading = true;
      try {
        const resourceUrl = `/api/patients/${this.patientId}/personal_data`;
        const personalData = {
          ...this.formData,
          birthdate: this.dateString(this.formData.birthdate),
          phoneNumber: (this.formData.phoneNumber === "") ? null : this.formData.phoneNumber
        };
        await update(resourceUrl, personalData);
        this.patient = await read(resourceUrl);
        this.$emit("updatePersonalData", personalData, this.patient);
        this.cancel();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    cancel() {
      this.resetData();
      this.$emit("cancel");
    },
    resetData() {
      this.formData = {
        ...this.patient,
        birthdate: this.stringToDate(this.patient.birthdate),
      };
      this.errors = [];
    },
    dateString(date) {
      return date ? stringifyDate(date, DATE_FORMAT.DATE) : null;
    },
    stringToDate(dateString) {
      return dateString?  parseDate(dateString, DATE_FORMAT.DATE) : null;
    },
  },
};
</script>
