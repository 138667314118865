<template>
  <b-modal
    :id="`previewWaitingListRecord-${waitingListId}`"
    title="Podgląd wpisu listy oczekujących"
    no-close-on-backdrop
    title-tag="h3"
    size="lg"
    @show="fetchData"
  >
    <template #default>
      <waiting-list-record-preview-form
        :record="record"
      />
    </template>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Zamknij
      </button>
    </template>
  </b-modal>
</template>

<script>
import read from "../../rest/read";
import processResponseException from "../../utils/errors/processResponseException";
import WaitingListRecordPreviewForm from "../../components/WaitingList/WaitingListRecordPreviewForm";

export default {
  name: "WaitingListPreviewModal",
  components: {WaitingListRecordPreviewForm},
  props: {
    waitingListId: {type: String, required: true}
  },
  data(){
    return {
      record: {}
    }
  },
  methods: {
    async fetchData(){
      try {
        this.record = await read(`/api/waiting-list/${this.waitingListId}`);
      } catch(e) {
        this.errors = processResponseException(e);
      }
    },
    closeModal(){
      this.$bvModal.hide(`previewWaitingListRecord-${this.waitingListId}`);
    }
  }
}
</script>
