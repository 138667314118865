






























import Vue from "vue";
import Component from "vue-class-component";
import type {ActiveSubstance} from "../../types/Prescription";
import {Prop, Ref, Watch} from "vue-property-decorator";
import {BFormInput} from "bootstrap-vue";
import {Error} from "../../rest";

@Component({
  name: "ActiveSubstanceDose",
})
export default class ActiveSubstanceDose extends Vue {
  @Prop({type: Object, required: true}) readonly value!: ActiveSubstance;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: Array, default: ()=>[]}) readonly errors!: Array<Error>

  @Ref("input") readonly input!: BFormInput;

  private edited: boolean = false;
  private confirmedDoseValue: string = this.value.confirmedDose ?? "";
  @Watch("value")
  onValueChange() {
    this.confirmedDoseValue = this.value.confirmedDose ?? "";
  }

  private get isInvalid(): boolean {
    return !!this.errors.find((error) => error.field === "confirmedDose");
  }

  private update(confDose: string): void {
    const confirmedDose = confDose === "-0" ? "0" : confDose === "" ? null : confDose;
    this.$emit("input", {...this.value, confirmedDose});
  }

  private startEdit(): void {
    if (!this.disabled) {
      this.edited = true;
      this.$nextTick(() => this.input.focus());
    }
  }

  private stopEdit(): void {
    this.edited = false;
  }
}
