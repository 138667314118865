<template>
  <form>
    <div class="form-row">
      <div
        v-if="visibleCountry"
        :class="countryClass"
      >
        <b-form-group label="Kraj">
          <countrySelect
            :value="value.country"
            :state="state('country')"
            :disabled="disabled"
            @input="updateValue({country: $event})"
          />
          <error-message
            :errors="errors"
            field="country"
          />
        </b-form-group>
      </div>
      <div
        :class="cityClass"
      >
        <b-form-group label="Miejscowość">
          <b-form-input
            v-if="value.country !== 'PL' || disabled"
            :value="value.city"
            :state="state('city')"
            :disabled="disabled"
            @input="updateValue({city: null !== $event ? $event.trim() : null})"
            @focus.native="clearError('city')"
          />
          <city-autocomplete
            v-else
            :city="value.city"
            :state="state('city')"
            @input="updateValue({city: null !== $event ? $event.trim() : null})"
            @focus.native="clearError('city')"
          />
          <error-message
            :errors="errors"
            field="city"
          />
        </b-form-group>
      </div>
      <div :class="zipCodeClass">
        <b-form-group label="Kod pocztowy">
          <b-form-input
            :value="value.zipCode"
            :state="state('zipCode')"
            :disabled="disabled"
            @input="updateValue({zipCode: null !== $event ? $event.trim() : null})"
            @focus.native="clearError('zipCode')"
          />
          <error-message
            :errors="errors"
            field="zipCode"
          />
        </b-form-group>
      </div>
      <div class="col-12">
        <b-form-group label="Adres">
          <b-form-input
            v-if="value.country !== 'PL' || disabled"
            :value="value.street"
            :state="state('street')"
            :disabled="disabled"
            @input="updateValue({street: null !== $event ? $event.trim() : null})"
            @focus.native="clearError('street')"
          />
          <street-autocomplete
            v-else
            :street="value.street"
            :city="value.city"
            :state="state('street')"
            @address="updateValue($event)"
            @focus.native="clearError('street')"
          />
          <error-message
            :errors="errors"
            field="street"
          />
        </b-form-group>
      </div>
    </div>
  </form>
</template>
<script>
import CountrySelect from "./CountrySelect";
import ErrorMessage from "../Form/ErrorMessage";
import StreetAutocomplete from "./StreetAutocomplete";
import CityAutocomplete from "./CityAutocomplete";

export default {
  components: {CityAutocomplete, StreetAutocomplete, ErrorMessage, CountrySelect},
  props: {
    value: {type: Object, required: true},
    errors: {type: Array, default: () => []},
    horizontal: {type: Boolean, default: true},
    visibleCountry: {type: Boolean, default: true},
    disabled: {type: Boolean, default: false},
    inModal: {type: Boolean, default: false},
  },
  computed: {
    countryClass() {
      return !this.horizontal || this.inModal ? "col-12" : "col-lg-4";
    },
    cityClass() {
      let city = this.horizontal ? "col-sm-8" : "col-12";
      if (!this.inModal) {
        city += this.visibleCountry ? " col-lg-6" : " col-lg-10";
      }
      return city;
    },
    zipCodeClass() {
      let zipCode = this.horizontal ? "col-sm-4" : "col-12";
      if (!this.inModal) {
        zipCode += " col-lg-2";
      }
      return zipCode;
    },
  },
  methods: {
    async updateValue(diff) {
      Object.entries(diff).forEach(([key,value]) => diff[key] = null !== value ? value.trim() : null);
      this.$emit("input", {...this.value, ...diff});
    },
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    clearError(field) {
      this.$emit("clearError", field);
    },
  }
}
</script>
