<template>
  <div class="form-row m-b-5">
    <b-form-input
      :value="`${mainWorker.name} ${mainWorker.surname}`"
      :state="state"
      class="col-sm-4"
      readonly
    />
    <b-form-input
      :value="`${patient.displayName}`"
      :state="state"
      class="col-sm-5"
      readonly
    />
    <b-form-input
      v-model.lazy="newTimeSpent"
      :number="true"
      :state="state"
      :disabled="readonly"
      class="col-sm-3 text-right"
      type="number"
      @input="update"
    />
  </div>
</template>

<script>
export default {
  name: "AppointmentDocumentTimeSpent",
  props: {
    mainWorker: {type: Object, required: true},
    patient: {type: Object, required: true},
    timeSpent: {type: Number, default: null},
    state: {type: Boolean, default: null},
    readonly: {type: Boolean, default: false}
  },
  data() {
    return {
      newTimeSpent: this.timeSpent,
    };
  },
  methods: {
    update(newTimeSpent) {
      this.$emit("updateTime", newTimeSpent !== "" ? +newTimeSpent : null);
    }
  },
}
</script>
