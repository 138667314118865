<template>
  <tr
    :worker="worker"
    :items="items"
    class="worker"
  >
    <th>
      {{ worker.name }}
      <div class="font-italic font-weight-normal small">
        {{ worker.branchName }}
      </div>
    </th>

    <tooltip
      v-for="(span, index) in spans"
      :key="index"
      :tooltip-content="getTooltipContent(span, index)"
      :class="slotClass(span, index)"
      component="td"
      @click.native.prevent="onClick($event, span)"
      @mouseenter.native="onMouseEnter(span, index)"
      @mouseleave.native="onMouseLeave()"
    />
  </tr>
</template>

<script>
import {mapState} from "vuex";
import {ModalType} from "@/store/modules/scheduleAppointment/types.ts";
import Tooltip from "@/components/Tooltip";
import stringifyDate from "@/utils/date/stringifyDate";
import DATE_FORMAT from "@/utils/date/DATE_FORMAT";

export default {
  components: {Tooltip},
  props: {
    hours: {type: Array, default: null},
    worker: {type: Object, required: true},
    items: {type: Array, required: true},
    startDate: {type: Date, required: true},
    endDate: {type: Date, required: true},
    treatmentDuration: {type: Number, required: true},
    slotDuration: {type: Number, required: true},
    filters: {type: Object, required: true},
    preferredPatient: {type: Object, default: null},
  },
  data() {
    return {
      highlighted: [],
      milisecondsInHour: 60 * 60 * 1000,
      ModalType,
      emptySlotItem: {
        outsideCalendar: true,
        available: true,
        clickable: true,
        editable: true,
        workerId: this.worker.workerId,
        branchId: this.worker.branchId,
        branchName: this.worker.branchName,
      }
    };
  },
  computed: {
    ...mapState({
      partner: state => state.clinicParameters.parameters.partner,
    }),
    noAccess() {
      const {available, clickable, editable} = this.items[0]
      return available && !clickable && !editable
    },
    spans() {
      return this.hours
        .reduce((list, hour) => {
          const hourSpans = [...Array(this.milisecondsInHour / this.slotDuration).keys()]
            .map((timeSpan) => {
              const date = new Date(hour.getTime());
              date.setTime(date.getTime() + timeSpan * this.slotDuration);
              return {
                start: date,
                end: new Date(date.getTime() + this.slotDuration),
              };
            });

          return list.concat(hourSpans);
        }, [])
        .map(timeSpan => {
          const slotItemsFromSchedule = this.items
            .filter(item => item.start.getTime() <= timeSpan.start.getTime()
                && item.end.getTime() >= timeSpan.end.getTime())
            .sort((slotA, slotB) => {
              if (slotA.editable === slotB.editable) {
                return 0;
              }
              return slotA.editable ? -1 : 1;
            });

          return {
            ...timeSpan,
            slots: slotItemsFromSchedule.length
              ? slotItemsFromSchedule // slots from schedule
              : [this.emptySlotItem] // slots outside of schedule
          }
        });
    },
  },
  methods: {
    isSpanHighlighted(index) {
      return -1 !== this.highlighted.indexOf(index);
    },
    getSelectedSlot(span) {
      return span.slots.length ? span.slots[0] : null;
    },
    getAppointmentData(span) {
      const selectedSlot = this.getSelectedSlot(span);

      if (!selectedSlot || !this.highlighted.length || this.noAccess) {
        return null;
      }

      const firstHighlightedSpanIndex = this.highlighted[0];
      const firstHighlightedSpan = this.spans[firstHighlightedSpanIndex];

      const lastHighlightedSpanIndex = this.highlighted[this.highlighted.length-1];
      const lastHighlightedSpan = this.spans[lastHighlightedSpanIndex] || this.spans[this.spans.length - 1];

      const selectedAppointment = {
        ...selectedSlot,
        start: firstHighlightedSpan.start,
        end: lastHighlightedSpan.end,
      };

      return {
        appointmentStart: selectedAppointment.start,
        appointmentEnd: selectedAppointment.end,
        treatmentType: this.filters.treatmentType,
        worker: this.worker,
        appointmentId: selectedAppointment.appointmentId,
        oldAppointmentId: this.filters.appointmentId,
        copyId: this.filters.copyId,
        preferredPatient: this.preferredPatient,
        branchId: selectedAppointment.branchId,
        branchName: selectedAppointment.branchName,
        plan: selectedAppointment.plan,
      };
    },
    getTooltipContent(span, index) {
      const selectedSlot = this.getSelectedSlot(span);

      if (!selectedSlot) {
        return null;
      }

      if (!this.highlighted.length || !this.highlighted.includes(index)) {
        return "termin niedostępny"
      }
      const firstHighlightedSpanIndex = this.highlighted[0];
      const firstHighlightedSpan = this.spans[firstHighlightedSpanIndex];

      const lastHighlightedSpanIndex = this.highlighted[this.highlighted.length-1];
      const lastHighlightedSpan = this.spans[lastHighlightedSpanIndex] || this.spans[this.spans.length - 1];

      return [firstHighlightedSpan.start, lastHighlightedSpan.end]
        .map((date) => stringifyDate(date, DATE_FORMAT.TIME))
        .join(" - ");
    },
    onClick($event, span) {
      const selectedSlot = this.getSelectedSlot(span);

      if (!selectedSlot || !selectedSlot.clickable || this.noAccess) {
        return;
      }

      const appointmentData = this.getAppointmentData(span);

      if (this.filters.copyId) {
        this.setAppointment(appointmentData, ModalType.DEFAULT);
      } if (this.filters.appointmentId || this.filters.continuousStay != null) {
        this.setAppointment(
          appointmentData,
          this.filters.continuousStay ? ModalType.CONTINUOUS_STAY : ModalType.DEFAULT
        );
      } else {
        this.$emit("openContextMenu", $event, appointmentData);
      }
    },
    onMouseEnter(span, index) {
      const length = this.treatmentDuration / this.slotDuration;
      for (let i = 0; i < length; i++) {
        const slot = this.spans[index + i]
          ? this.getSelectedSlot(this.spans[index + i])
          : null;

        if (!!slot && !slot.clickable || this.noAccess) {
          return;
        }
        this.highlighted.push(index + i);
      }
    },
    onMouseLeave() {
      this.highlighted = [];
    },
    slotClass(span, index) {
      const selectedSlot = this.getSelectedSlot(span);

      const highlighted = this.isSpanHighlighted(index);

      if (selectedSlot && selectedSlot.outsideCalendar) {
        return {"bg-white": true, highlighted};
      }

      if (selectedSlot.editable) { // przekładana wizyta
        return {"bg-primary": true, highlighted};
      }

      if (selectedSlot.clickable) { // wolne terminy
        return {"bg-success": true, highlighted};
      }

      if (selectedSlot.available) { // wolne terminy, brak uprawnień do umówienia wizyty
        return "bg-available";
      }

      return "bg-secondary"; // zajęte terminy
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../styles/variables";

.worker {
  height: 1em;

  td {
    height: inherit;
  }
}

.highlighted {
  background-image: linear-gradient(rgba(#000, .5), rgba(#000, .5));
  cursor: pointer;
}

th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: $white;
}
</style>
