<template>
  <div>
    <loading-mask
      class="m-b-30"
      :loading="loadingCreator"
    >
      <icf-creator
        v-if="creatorOverview && evaluationsOverview"
        :read-only="creatorOverview.readonly"
        :ipz-id="ipzId"
        :status="creatorOverview.status"
        :assigned-core-sets="evaluationsOverview.coreSetNames"
        @categoriesAssigned="loadEvaluationsData"
        @redefinedCategories="redefinedCategories"
      />
    </loading-mask>
    <loading-mask :loading="loadingEvaluations">
      <patient-evaluations-list
        v-if="evaluationsOverview"
        :overview="evaluationsOverview"
        :redefine-categories="redefineCategories"
        :hide-link-to-creator="true"
      />
    </loading-mask>
  </div>
</template>

<script>
import LoadingMask from "../../../components/Loading/LoadingMask";
import IcfCreator from "../../../components/Ipz/IcfCreator/IcfCreator";
import read from "../../../rest/read";
import PatientEvaluationsList from "../../../components/Ipz/Evaluation/PatientEvaluationsList";

export default {
  name: "IcfCreatorWithEvaluation",
  components: {LoadingMask, IcfCreator, PatientEvaluationsList},
  props: {
    ipzId: {type: String, required: true},
  },
  data() {
    return {
      creatorOverview: null,
      evaluationsOverview: null,
      loadingCreator: false,
      loadingEvaluations: false,
      redefineCategories: false
    }
  },
  watch: {
    async ipzId() {
      await this.loadCreatorData();
      await this.loadEvaluationsData();
    },
  },
  async mounted() {
    await this.loadCreatorData();
    await this.loadEvaluationsData();
  },
  methods: {
    async loadCreatorData() {
      this.loadingCreator = true;
      const {overview} = await read(`/api/ipzs/${this.ipzId}/icf/creator`);
      this.creatorOverview = overview;
      this.loadingCreator = false;
    },
    async loadEvaluationsData() {
      this.redefineCategories = false;
      this.loadingEvaluations = true;
      const {overview} = await read(`/api/ipzs/${this.ipzId}/details/evaluations`);
      this.evaluationsOverview = overview;
      this.loadingEvaluations = false;
    },
    redefinedCategories(){
      this.redefineCategories = true;
    }
  }
}
</script>
