export const AdditionalEntitlementsCodes: Array<{ value: string, name: string }> = [
  {
    value: "24A",
    name: "24A"
  },
  {
    value: "24BŻ",
    name: "24BŻ"
  },
  {
    value: "24BF",
    name: "24BF"
  },
  {
    value: "24CŻ",
    name: "24CŻ"
  },
  {
    value: "24CF",
    name: "24CF"
  },
  {
    value: "47CZ",
    name: "47CZ"
  },
  {
    value: "47CIB",
    name: "47CIB"
  },
  {
    value: "47CIW",
    name: "47CIW"
  },
  {
    value: "47CK",
    name: "47CK"
  },
  {
    value: "47CC",
    name: "47CC"
  },
  {
    value: "47CDN",
    name: "47CDN"
  },
  {
    value: "47CZN",
    name: "47CZN"
  },
  {
    value: "47CZND",
    name: "47CZND"
  },
  {
    value: "47COA",
    name: "47COA"
  },
  {
    value: "47CRP",
    name: "47CRP"
  },
  {
    value: "47CPP",
    name: "47CPP"
  }
]
