<template>
  <div>
    <card
      :loading="loading"
      title="Uprawnienia dodatkowe"
    >
      <template #control>
        <b-dropdown
          variant="primary"
          size="sm"
          no-caret
        >
          <template #button-content>
            <span class="fas fa-ellipsis-v" />
          </template>

          <is-granted
            :privileges="['GET_PATIENT_ADDITIONAL_ENTITLEMENTS']"
            component="fragment"
          >
            <b-dropdown-item-btn>
              <span class="fas fa-list mr-1" /> Lista uprawnień
            </b-dropdown-item-btn>
          </is-granted>

          <is-granted
            :privileges="['ADD_PATIENT_ADDITIONAL_ENTITLEMENT']"
            component="fragment"
          >
            <b-dropdown-item-btn
              v-b-modal.addAdditionalEntitlement
            >
              <span
                class="fa fa-plus-circle fas mr-1"
              /> Dodaj uprawnienie
            </b-dropdown-item-btn>
          </is-granted>
        </b-dropdown>
      </template>
      <div>{{ latestDocumentName }}</div>
      {{ latestDocumentDates }}
    </card>

    <b-modal
      id="addAdditionalEntitlement"
      no-close-on-backdrop
      title="Dodaj uprawnienia dodatkowe"
      title-tag="h3"
    >
      <template #default>
        <b-form-group
          label="Kod uprawienia"
          label-for="entitlementsCode"
        >
          <additional-entitlements-code-select
            id="entitlementsCode"
            v-model="additionalEntitlementCode"
            :state="state('additionalEntitlementCode')"
          />
          <error-message
            :errors="errors"
            field="additionalEntitlementCode"
          />
        </b-form-group>
        <b-form-group
          label="Nazwa dokumentu (max. 100 znaków)"
          label-for="documentName"
        >
          <b-form-input
            id="documentName"
            v-model="documentName"
            :state="state('documentName')"
            placeholder="Wprowadź nazwe dokumentu"
          />
          <error-message
            :errors="errors"
            field="documentName"
          />
        </b-form-group>
        <b-form-group
          label="Identifikator dokumentu (max. 40 znaków)"
          label-for="documentId"
        >
          <b-form-input
            id="documentId"
            v-model="documentId"
            :state="state('documentId')"
            placeholder="Wprowadź identyfikator dokumentu"
          />
          <error-message
            :errors="errors"
            field="documentId"
          />
        </b-form-group>
        <div class="row">
          <b-form-group
            class="col-6"
            label="Data wystawienia dokumentu"
          >
            <date-picker
              v-model="createdDate"
              :state="state('createdDate')"
              :format="'YYYY-MM-DD'"
            />
            <error-message
              :errors="errors"
              :state="state('createdDate')"
              field="createdDate"
            />
          </b-form-group>
          <b-form-group
            class="col-6"
            label="Data końcowa okresu ważności"
          >
            <date-picker
              v-model="validDate"
              :state="state('validDate')"
              :format="'YYYY-MM-DD'"
            />
            <error-message
              :errors="errors"
              :state="state('validDate')"
              field="validDate"
            />
          </b-form-group>
        </div>
      </template>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="cancel"
        >
          Anuluj
        </button>
        <button
          :disabled="loading"
          class="btn btn-primary"
          @click="submit"
        >
          <i
            :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fa"
          />
          Zapisz
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ErrorMessage from "../../Form/ErrorMessage.vue";
import Card from "../../Card.vue";
import IsGranted from "../../IsGranted.vue";
import AdditionalEntitlementsCodeSelect from "../../Appointment/AdditionalEntitlements/AdditionalEntitlementsCodeSelect";
import DatePicker from "../../Form/DatePicker/DatePicker";
import update from "../../../rest/update";
import processResponseException from "../../../utils/errors/processResponseException";
import stringifyDate from "../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import {generateUuid} from "../../../utils/uuid/generateUuid";
import read from "../../../rest/read";

export default {
  name: "PatientCardAdditionalEntitlements",
  components: {
    AdditionalEntitlementsCodeSelect,
    ErrorMessage,
    Card,
    IsGranted,
    DatePicker
  },
  props: {
    patientId: {type: String, required: true}
  },
  data() {
    return {
      errors: [],
      loading: false,
      additionalEntitlementCode: null,
      documentName: null,
      documentId: null,
      createdDate: null,
      validDate: null,
      latestDocumentDates: null,
      latestDocumentName: null
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    extractLatestDocumentDates(response){
      return (response.createdDate || response.validDate) ? `${response.createdDate} - ${response.validDate}` : "Brak informacji";
    },
    close(){
      this.cancel();
    },
    cancel() {
      this.errors = [];
      this.loading = false;
      this.additionalEntitlementCode = null;
      this.documentName = null;
      this.documentId = null;
      this.createdDate = null;
      this.validDate = null;
      this.$bvModal.hide("addAdditionalEntitlement")
    },
    async loadData(){
      this.loading = true;
      const response = await read(`/api/patient/${this.patientId}/additional-entitlements`);
      this.latestDocumentDates = this.extractLatestDocumentDates(response)
      this.latestDocumentName = response.documentName
      this.loading = false;
    },
    async submit(){
      this.loading = true;
      this.errors = [];
      try {
        const data = {
          id: generateUuid(),
          documentName: this.documentName,
          documentId: this.documentId,
          additionalEntitlementCode: this.additionalEntitlementCode,
          createdDate: (this.createdDate !== null) ? stringifyDate(new Date(this.createdDate) , DATE_FORMAT.DATE) : null,
          validDate: (this.validDate !== null) ? stringifyDate(new Date(this.validDate) , DATE_FORMAT.DATE) : null,
        }
        await update(`/api/patient/${this.patientId}/additional-entitlements/add`, data)
        this.$bvModal.hide("addAdditionalEntitlement")
        this.close();
        await this.loadData()
      } catch (exception) {
        this.errors = processResponseException(exception)
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
