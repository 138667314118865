<template>
  <card
    :title="title"
    :loading="loading"
    :description="description"
  >
    <template #control>
      <slot name="control" />
    </template>

    <b-tabs
      v-model="tabIndex"
      lazy
      @input="changeTab"
    >
      <b-tab
        v-for="(tab, index) in tabs"
        :key="index"
        :title="tab.title"
      >
        <appointment-table
          :items="items"
          :columns="columns"
          :patient-id="patientId"
          :order-desc="orderDesc"
          :display-unreported-treatments="true"
          @appointmentUpdated="loadItems(tab.statuses)"
          @changeOrder="changeOrder"
        />
        <pagination-description
          :items-count="items.length"
          :page="page"
          :per-page="pagination.perPage"
          :total-count="pagination.totalRows"
          persisted-slot
        >
          <per-page-select
            v-model="pagination.perPage"
            :options="[5,10,25, 50, 100]"
            @input="changePerPage"
          />
          <b-pagination
            :value="page"
            :total-rows="pagination.totalRows"
            :per-page="pagination.perPage"
            align="right"
            class="mb-0"
            limit="10"
            @input="changePage"
          />
        </pagination-description>
      </b-tab>
    </b-tabs>
  </card>
</template>

<script>
import {mapState} from "vuex";
import Card from "../Card";
import AppointmentTable from "./AppointmentTable";
import read from "../../rest/read";
import parseDate from "../../utils/date/parseDate";
import PaginationDescription from "../Pagination/PaginationDescription";
import PerPageSelect from "../Pagination/PerPageSelect";
import {updatePageQuery} from "../../utils/pageUrl/handlePageQuery";
import {isGranted} from "../../security/isGranted";
import {isEqual} from "lodash";

export default {
  name: "AppointmentTabsCard",
  components: {PerPageSelect, PaginationDescription, AppointmentTable, Card},
  props: {
    title: {type: String, required: true},
    description: {type: String, default: null},
    columns: {type: Array, required: true},
    patientId: {type: String, default: null},
    filters: {type: Object, default: () => ({})},
    query: {type: Object, default: () => ({})},
  },
  data() {
    return {
      tabIndex: 0,
      loading: false,
      page: this.query.page || 1,
      pagination: {
        perPage: this.query.perPage || 5,
        totalRows: 0
      },
      items: [],
      statuses: this.query.statuses || this.filters.statuses,
      continuousStay: this.query.continuousStay != null ? this.query.continuousStay : this.filters.continuousStay,
      orderDesc: this.query.orderDesc != null ? this.query.orderDesc : this.filters.orderDesc,
      workerIds: null,
    }
  },
  computed: {
    ...mapState({
      ignoreBranchRestrictions: state => state.clinicParameters.parameters.ignoreBranchRestrictions,
      currentUser: state => state.currentUser.user
    }),
    tabs() {
      return [
        {title: "Wszystkie", statuses: this.filters.statuses, continuousStay: null},
        {title: "Zaplanowane", statuses: ["created", "checkedin", "opened"], continuousStay: null},
        {title: "Zamknięte", statuses: ["finished", "canceled"], continuousStay: null},
        {title: "Ambulatoryjne", statuses: this.filters.statuses, continuousStay: false},
        {title: "Oddział dzienny", statuses: this.filters.statuses, continuousStay: true},
      ];
    },
  },
  watch: {
    patientId() {
      this.page = this.query.page || 1;
      this.pagination.perPage = this.query.perPage || 5;
      this.statuses = this.query.statuses || this.filters.statuses;
      this.orderDesc = this.query.orderDesc != null ? this.query.orderDesc : this.filters.orderDesc;
      this.loadItems();
    },
    async "filters.workerId"() {
      await this.getWorkerIds();
      await this.loadItems();
    },
    query() {
      this.page = this.query.page || 1;
      this.pagination.perPage = this.query.perPage || 5;
      this.statuses = this.query.statuses || this.filters.statuses;
      this.orderDesc = this.query.orderDesc != null ? this.query.orderDesc : this.filters.orderDesc;
      this.continuousStay = this.query.continuousStay != null ? this.query.continuousStay : this.filters.continuousStay;
      this.loadItems();
    }
  },
  async mounted() {
    this.updateTabIndex();
    await this.getWorkerIds();
    await this.loadItems();
  },
  methods: {
    async loadItems() {
      this.items = [];
      this.loading = true;
      const {items, pagination} = await read("/api/appointments", {
        workerIds: this.workerIds,
        ...this.filters,
        statuses: this.statuses,
        orderDesc: this.orderDesc,
        page: this.page,
        perPage: this.pagination.perPage,
        continuousStay: this.continuousStay,
        ignoreBranchRestrictions: this.ignoreBranchRestrictions
      });
      this.items = items.map((item) => this.processItem(item));
      this.pagination = pagination;
      this.loading = false;
    },
    processItem(item) {
      return {
        ...item,
        date: parseDate(item.date),
      };
    },
    async getWorkerIds() {
      if (!this.filters.hasOwnProperty("workerIds")) {
        this.workerIds = await isGranted(["ROLE_RECEPTION", "ROLE_ADMIN"]) ? null : [this.user.workerId];
      }
      this.workerIds = this.filters.workerIds;
    },
    changeTab(tabIndex) {
      this.statuses = this.tabs[tabIndex].statuses;
      this.continuousStay = this.tabs[tabIndex].continuousStay != null ? this.tabs[tabIndex].continuousStay : null;
      this.page = 1;

      updatePageQuery({
        atcStatuses: this.statuses.join(",") || undefined,
        atcPage: undefined,
        continuousStay: this.continuousStay != null ? this.continuousStay : undefined,
      });
    },
    changeOrder() {
      this.orderDesc = !this.orderDesc;

      updatePageQuery({
        atcDesc: this.orderDesc,
      });
    },
    changePage(page) {
      this.page = page === 1 ? undefined : page;

      updatePageQuery({
        atcPage: page,
      });
    },
    changePerPage(perPage) {
      this.pagination.perPage = perPage;

      updatePageQuery({
        atcPerPage: perPage === 5 ? undefined : perPage,
      });
    },
    updateTabIndex() {
      const index = this.tabs.findIndex(tab =>
        isEqual(tab.statuses, this.query.statuses)
        && tab.continuousStay === this.query.continuousStay
      );
      this.tabIndex = index > -1 ? index : 0;
    },
  },
}
</script>
