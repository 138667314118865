<template>
  <tr>
    <td>
      <i18n :msgid="`@therapeutic-scope.${task.scope}`" />
      <error-message :errors="errors" />
    </td>
    <td>
      <span v-show="!editing && !updating">{{ task.targetName }}</span>
      <b-form-group
        v-show="editing || updating"
        label-for="targetName"
        class="meeting-control"
      >
        <resizable-textarea
          id="targetName"
          v-model="targetName"
          :disabled="updating"
          :state="state('targetName')"
          class="mb-2"
          placeholder="Cel"
          required
        />
        <error-message
          :errors="errors"
          field="targetName"
        />
      </b-form-group>
    </td>
    <td>
      <span v-show="!editing && !updating">{{ task.tasks }}</span>
      <b-form-group
        v-show="editing || updating"
        label-for="tasks"
        class="meeting-control"
      >
        <resizable-textarea
          id="tasks"
          v-model="tasks"
          :disabled="updating"
          :state="state('tasks')"
          class="mb-2"
          placeholder="Kolejne zadania"
          required
        />
        <error-message
          :errors="errors"
          field="tasks"
        />
      </b-form-group>
    </td>
    <td>
      <span v-show="!editing && !updating">{{ task.realizationTime }}</span>
      <b-form-group
        v-show="editing || updating"
        label-for="realizationTime"
        class="meeting-control"
      >
        <resizable-textarea
          id="realizationTime"
          v-model="realizationTime"
          :disabled="updating"
          :state="state('realizationTime')"
          class="mb-2"
          placeholder="Czas realizacji"
          required
        />
        <error-message
          :errors="errors"
          field="realizationTime"
        />
      </b-form-group>
    </td>
    <td>
      <span v-show="!editing && !updating">{{ task.supportingPerson }}</span>
      <b-form-group
        v-show="editing || updating"
        label-for="supportingPerson"
        class="meeting-control"
      >
        <resizable-textarea
          id="supportingPerson"
          v-model="supportingPerson"
          :disabled="updating"
          :state="state('supportingPerson')"
          class="mb-2"
          placeholder="Osoba wspierająca"
          required
          label="Osoba wspierająca"
        />
        <error-message
          :errors="errors"
          field="supportingPerson"
        />
      </b-form-group>
    </td>
    <td>
      <span v-show="!editing && !updating">{{ task.realizationDegree }}</span>
      <b-form-group
        v-show="editing || updating"
        label-for="realizationDegree"
        class="meeting-control"
      >
        <div class="input-group">
          <resizable-textarea
            id="realizationDegree"
            v-model="realizationDegree"
            :disabled="updating"
            :state="state('realizationDegree')"
            class="mb-2"
            placeholder="Stopień realizacji"
            required
          />
        </div>
        <error-message
          :errors="errors"
          field="realizationDegree"
        />
      </b-form-group>
    </td>
    <td>
      <span v-show="!editing && !updating"><i18n :msgid="`@therapeutic-realization.${task.realization}`" /></span>
      <b-form-group
        v-show="editing || updating"
        label-for="realization"
        class="meeting-control"
      >
        <object-select
          id="realization"
          v-model="realization"
          :disabled="updating"
          :state="state('realization')"
          :options="realizationOptions"
          class="mb-2"
          placeholder="Wykonanie"
          required
        />
        <error-message
          :errors="errors"
          field="realization"
        />
      </b-form-group>
    </td>
    <td
      v-if="!readOnly"
      style="width: 7rem"
    >
      <is-granted
        :privileges="['UPDATE_THERAPEUTIC_TASK']"
        :subject="task.therapeuticTaskId"
        component="div"
        class="text-right"
      >
        <button
          v-show="editing"
          class="btn btn-link btn-sm"
          @click="cancel"
        >
          <i class="fa fa-times" /> Odrzuć
        </button>
        <button
          class="btn btn-outline-primary btn-sm"
          @click="edit"
        >
          <i
            :class="updating ? 'fa-spin fa-spinner' : editing ? 'fa-check' : 'fa-pencil-alt'"
            class="fas"
          /> {{ editing ? "Zapisz" : "Edytuj" }}
        </button>
        <button
          class="btn btn-outline-secondary btn-sm"
          @click="remove"
        >
          <i
            :class="removing ? 'fas-spin fas-spinner' : 'fa-trash'"
            class="fas"
          /> Usuń
        </button>
      </is-granted>
    </td>
  </tr>
</template>

<script>
import update from "../../../../rest/update";
import ErrorMessage from "../../../Form/ErrorMessage";
import processResponseException from "../../../../utils/errors/processResponseException";
import I18n from "../../../i18n";
import t from "../../../../i18n";
import IsGranted from "../../../IsGranted";
import ObjectSelect from "../../../Form/Select/ObjectSelect";
import ResizableTextarea from "../../../Form/Textarea/ResizableTextarea";
import {errorsMixin} from "@/mixins/errorsMixin";

export default {
  name: "TherapeuticTaskRow",
  components: {ResizableTextarea, ObjectSelect, IsGranted, ErrorMessage, I18n},
  mixins: [errorsMixin],
  model: {
    prop: "task",
  },
  props: {
    task: {required: true, type: Object},
    readOnly: {type: Boolean, required: true},
    removing: {type: Boolean, required: true},
  },
  data() {
    return {
      editing: false,
      updating: false,
      tasks: this.task.tasks,
      scope: this.task.scope,
      targetName: this.task.targetName,
      realizationOptions: [
        {value: "done", name: t("@therapeutic-realization.done")},
        {value: "not_done", name: t("@therapeutic-realization.not_done")},
        {value: "in_progress", name: t("@therapeutic-realization.in_progress")},
      ],
      realization: this.getRealizationObject(),
      realizationTime: this.task.realizationTime,
      supportingPerson: this.task.supportingPerson,
      realizationDegree: this.task.realizationDegree,
    };
  },
  methods: {
    getRealizationObject() {
      return {value: this.task.realization, name: t(`@therapeutic-realization.${this.task.realization}`)}
    },
    cancel() {
      this.tasks = this.task.tasks;
      this.targetName = this.task.targetName;
      this.realization = this.getRealizationObject();
      this.realizationTime = this.task.realizationTime;
      this.supportingPerson = this.task.supportingPerson;
      this.realizationDegree = this.task.realizationDegree;

      this.editing = false;
      this.errors = [];
    },
    async edit() {
      if (!this.editing) {
        this.editing = true;
        return;
      }
      await this.save();
    },
    async save() {
      this.updating = true;

      try {
        const realizationValue = this.realization.value !== this.task.realization ? this.realization.value : null;
        const updates = {
          tasks: this.tasks !== this.task.tasks ? this.tasks : null,
          targetName: this.targetName !== this.task.targetName ? this.targetName : null,
          realization: this.task.realization ? realizationValue : null,
          realizationTime: this.realizationTime !== this.task.realizationTime ? this.realizationTime : null,
          supportingPerson: this.supportingPerson !== this.task.supportingPerson ? this.supportingPerson : null,
          realizationDegree: this.realizationDegree !== this.task.realizationDegree ? this.realizationDegree : null,
        };
        Object.keys(updates).forEach((key) => (updates[key] == null) && delete updates[key]);
        if (0 === Object.keys(updates).length) {
          this.editing = false;
          this.updating = false;
          this.errors = [];

          return;
        }
        await update(`/api/therapeutic-tasks/${this.task.therapeuticTaskId}`, updates);
        this.editing = false;
        await this.$emit("task-updated", {...this.task, ...updates});

        this.tasks = this.task.tasks;
        this.scope = this.task.scope;
        this.targetName = this.task.targetName;
        this.realization = this.getRealizationObject();
        this.realizationTime = this.task.realizationTime;
        this.supportingPerson = this.task.supportingPerson;
        this.realizationDegree = this.task.realizationDegree;

        this.errors = [];
      } catch (exception) {
        this.errors = processResponseException(exception);
      }

      this.updating = false;
    },
    async remove () {
      this.$emit("task-remove", this.task.therapeuticTaskId);
    },
  },
}
</script>
