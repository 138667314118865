












































import ErrorsMixin from "../../mixins/ErrorsMixin";
import {Mixins, Prop, Watch} from "vue-property-decorator";
import type {EstablishmentAddress, EstablishmentItem} from "../../types/Establishment";
import isEmpty from "lodash/isEmpty";
import I18n from "../i18n.vue";
import ErrorMessage from "../Form/ErrorMessage.vue";
import EstablishmentChildForm from "./EstablishmentChildForm.vue";
import Component from "vue-class-component";
import processResponseException from "../../utils/errors/processResponseException";
import update from "../../rest/update";
import {EstablishmentParameters, EstablishmentType} from "../../types/Establishment";

interface FormData {
  name?: EstablishmentItem["name"];
  code?: EstablishmentItem["code"];
  address?: EstablishmentAddress|{};
  nip?: EstablishmentItem["nip"];
  regon?: EstablishmentItem["regon"];
  phoneNumber?: EstablishmentItem["phoneNumber"];
  codeEight?: EstablishmentItem["codeEight"];
  shortName?: EstablishmentItem["shortName"];
  krs?: EstablishmentItem["krs"];
  providerNumber?: EstablishmentItem["providerNumber"];
  unitTechnicalId?: EstablishmentItem["unitTechnicalId"];
  unitReportToLIOCZ?: EstablishmentItem["unitReportToLIOCZ"];
  establishmentSystemIdentifier?: EstablishmentItem["establishmentSystemIdentifier"];
  establishmentNfzCode?: EstablishmentItem["establishmentNfzCode"];
  parameters?: EstablishmentParameters|{};
  czpAreaCode?: EstablishmentItem["czpAreaCode"];
  serviceScopeCode?: EstablishmentItem["serviceScopeCode"]
  alternativeResortCode?: EstablishmentItem["alternativeResortCode"]
}

interface SubmitData extends FormData {
  branchId?: EstablishmentItem["branchId"];
  type?: EstablishmentType;
}

@Component({
  name: "EditEstablishmentChildForm",
  components: {EstablishmentChildForm, ErrorMessage, I18n}
})
export default class EditEstablishmentChildForm extends Mixins(ErrorsMixin) {
  @Prop({type: Object, default: null}) readonly item!: EstablishmentItem;

  loading: boolean = false;
  formData: FormData = {};

  @Watch("item", {immediate: true}) onItemChange(item: EstablishmentItem|null): void {
    this.formData = item
      ? {
        name: item.name,
        code: item.code,
        address: item.address || {},
        nip: item.nip,
        regon: item.regon,
        phoneNumber: item.phoneNumber,
        codeEight: item.codeEight,
        shortName: item.shortName,
        krs: item.krs,
        providerNumber: item.providerNumber,
        unitTechnicalId: item.unitTechnicalId,
        unitReportToLIOCZ: item.unitReportToLIOCZ,
        establishmentSystemIdentifier: item.establishmentSystemIdentifier,
        establishmentNfzCode: item.establishmentNfzCode,
        czpAreaCode: item.czpAreaCode,
        parameters: item.parameters || {},
        serviceScopeCode: item.serviceScopeCode,
        alternativeResortCode: item.alternativeResortCode
      }
      : {};
  }

  @Watch("errors") onErrorsChange() {
    this.$nextTick(() => {
      this.$emit("componentUpdate");
    });
  }

  close() {
    this.resetModalData();
    this.$emit("close");
  }

  resetModalData(): void {
    this.errors = [];
    this.formData = {};
  }

  addressRequired(item: EstablishmentType): boolean {
    const types = [EstablishmentType.UNIT, EstablishmentType.ESTABLISHMENT, EstablishmentType.NON_MEDICAL];

    return types.includes(item);
  }

  async submit(): Promise<void> {
    this.errors = [];
    this.loading = true;
    const data: SubmitData = {
      type: this.item ? this.item.type : undefined,
      branchId: this.item ? this.item.branchId : undefined,
      name: this.formData ? this.formData.name : undefined,
      code: this.formData ? this.formData.code : undefined,
      address: this.formData && !isEmpty(this.formData.address)
        ? this.formData.address
        : this.addressRequired(this.item.type) ? {} : undefined,
      nip: this.formData ? this.formData.nip : undefined,
      regon: this.formData ? this.formData.regon : undefined,
      phoneNumber: this.formData ? this.formData.phoneNumber : undefined,
      codeEight: this.formData ? this.formData.codeEight : undefined,
      shortName: this.formData ? this.formData.shortName : undefined,
      krs: this.formData ? this.formData.krs : undefined,
      providerNumber: this.formData ? this.formData.providerNumber : undefined,
      unitTechnicalId: this.formData ? this.formData.unitTechnicalId : undefined,
      unitReportToLIOCZ: this.formData ? this.formData.unitReportToLIOCZ : undefined,
      establishmentSystemIdentifier: this.formData ? this.formData.establishmentSystemIdentifier : undefined,
      establishmentNfzCode: this.formData ? this.formData.establishmentNfzCode : undefined,
      czpAreaCode: this.formData ? this.formData.czpAreaCode : undefined,
      parameters: this.formData && !isEmpty(this.formData.parameters) ? this.formData.parameters : undefined,
      serviceScopeCode: this.formData ? this.formData.serviceScopeCode : undefined,
      alternativeResortCode: this.formData ? this.formData.alternativeResortCode: undefined
    };
    try {
      await update<SubmitData>("/api/branches", data);
      this.close();
      this.$emit("edited");
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }
}
