<template>
  <component :is="component">
    {{ text }}
  </component>
</template>

<script>
import stringifyDate from "../utils/date/stringifyDate";
import DATE_FORMAT from "../utils/date/DATE_FORMAT";

export default {
  name: "DateTime",
  props: {
    component: {type: String, default: "span"},
    value: {type: Date, required: true},
    format: {type: String, default: DATE_FORMAT.DATE_TIME},
  },
  computed: {
    text() {
      if(this.value.getHours() === 0 && this.value.getMinutes() === 0)
      {
        return stringifyDate(this.value, DATE_FORMAT.DATE);
      }
      return stringifyDate(this.value, this.format);
    }
  }
}
</script>

<style scoped>

</style>
