

















































































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ErrorsMixin from "../../../mixins/ErrorsMixin";
import {
  EstablishmentUnitPriceNumberDiscriminantUnit,
  EstablishmentUnitPriceNumberDiscriminantUnitType,
  UnitEntries
} from "../../../types/Establishment";

interface TableEntry {
  year: number;
  month: number;
  unitOrderNumber: number;
  price?: number | string | null;
  number?: number | string | null;
  sum?: number | string | null;
  executionNumber?: number | string | null;
  executionSum?: number | string | null;
  deficienciesOrExcesses?: number | string | null;
}

enum TableEntryType {
  PRICE = "price",
  NUMBER = "number",
  SUM = "sum",
  EXECUTION_NUMBER = "executionNumber",
  EXECUTION_SUM = "executionSum",
  DEFICIENCIES_OR_EXCESSES = "deficienciesOrExcesses"
}

@Component({
  name: "BenefitPerformanceTable",
})

export default class BenefitPerformanceTable extends Mixins(ErrorsMixin) {
  @Prop({type: Number, required: true}) readonly selectedYear!: number;
  @Prop({type: Array, required: true}) readonly units!: UnitEntries[];

  $refs!: {
    tablesWrapper: HTMLDivElement
  }

  public values: TableEntry[] = []
  public TableEntryType = TableEntryType;
  public colsDefinitions = [
    {key: TableEntryType.PRICE, label: "Umowa cena"},
    {key: TableEntryType.NUMBER, label: "Umowa liczba"},
    {key: TableEntryType.SUM, label: "Suma"},
    {key: TableEntryType.EXECUTION_NUMBER, label: "Wykonanie liczba"},
    {key: TableEntryType.EXECUTION_SUM, label: "Wykonanie suma"},
    {key: TableEntryType.DEFICIENCIES_OR_EXCESSES, label: "Braki / nadwykonania"}
  ]

  fieldsDefinitions = [
    {key: "values", label: "Parametry komórki organizacyjnej"},
    {key: "month1", label: "01 styczeń", month: "1"},
    {key: "month2", label: "02 luty", month: "2"},
    {key: "month3", label: "03 marzec", month: "3"},
    {key: "month4", label: "04 kwiecień", month: "4"},
    {key: "month5", label: "05 maj", month: "5"},
    {key: "month6", label: "06 czerwiec", month: "6"},
    {key: "month7", label: "07 lipiec", month: "7"},
    {key: "month8", label: "08 sierpień", month: "8"},
    {key: "month9", label: "09 wrzesień", month: "9"},
    {key: "month10", label: "10 październik", month: "10"},
    {key: "month11", label: "11 listopad", month: "11"},
    {key: "month12", label: "12 grudzień", month: "12"},
  ]

  @Watch("units", {immediate: true}) onUnitsLoad(): void {
    this.getInitialValues()
  }

  getInitialValues() {
    this.values = []
    this.units.forEach(unit => {
      unit.entries.forEach(entry => {
        this.values.push({
          unitOrderNumber: unit.orderNumber,
          year: entry.year,
          month: entry.month,
          price: entry.price,
          number: entry.number,
          sum: entry.price * entry.number,
          executionNumber: entry.executionNumber,
          executionSum: entry.price * entry.executionNumber,
          deficienciesOrExcesses: (entry.executionNumber - entry.number) * entry.price
        })
      })
    })
  }

  getTypeLabel(type: EstablishmentUnitPriceNumberDiscriminantUnitType) {
    switch(type) {
      case EstablishmentUnitPriceNumberDiscriminantUnitType.UNIT:
        return "Lista podmiotów";
      case EstablishmentUnitPriceNumberDiscriminantUnitType.AREA:
        return "Obszar"
      case EstablishmentUnitPriceNumberDiscriminantUnitType.DPS:
        return "DPS"
      default:
        return ""
    }
  }

  formatUnitAddress({zipCode, city, street}: {zipCode?: string, city?: string, street?: string}) {
    const postalCodeCity = (zipCode && city) ? `${zipCode} ${city}` : ""
    return street
      ? `ul. ${street}, ${postalCodeCity}`
      : postalCodeCity
  }

  getInitialValue({unitOrderNumber, type, month}: {unitOrderNumber: number, type: TableEntryType, month: string}) {
    const valueItem = this.values.find(valueItem =>
      valueItem.year === this.selectedYear
      && valueItem.month === Number(month)
      && valueItem.unitOrderNumber === unitOrderNumber
    )

    let val = null;

    if (valueItem) {
      switch (type) {
        case TableEntryType.NUMBER:
          val = valueItem["number"]
          break;
        case TableEntryType.PRICE:
          val = valueItem["price"]
          break;
        case TableEntryType.SUM:
          val = valueItem["sum"]
          break
        case TableEntryType.EXECUTION_NUMBER:
          val = valueItem["executionNumber"]
          break;
        case TableEntryType.EXECUTION_SUM:
          val = valueItem["executionSum"]
          break;
        case TableEntryType.DEFICIENCIES_OR_EXCESSES:
          val = valueItem["deficienciesOrExcesses"]
          break;
        default:
          val = null
      }

      return String(val)
    }

    let initialVal = ""
    this.units.forEach(unit => {
      if (unit.orderNumber === unitOrderNumber) {
        const weightItem = unit.entries.find(weight => weight.month === Number(month) && weight.year === this.selectedYear)
        if (weightItem && weightItem[type] !== null) {
          initialVal = String(weightItem[type])
        }
      }
    })

    return initialVal || ""
  }

  markPositiveOrNegative({unitOrderNumber, keyIdx, month}: {unitOrderNumber: number, keyIdx: TableEntryType, month: string}) {

    const item = this.values.find(valueItem =>
      valueItem.year === this.selectedYear
      && valueItem.month === Number(month)
      && valueItem.unitOrderNumber === unitOrderNumber
    )

    if(keyIdx === TableEntryType.DEFICIENCIES_OR_EXCESSES){
      if(!item){
        return "";
      }
      return (item && item.deficienciesOrExcesses > 0 ) ? "mark-negative" : "mark-positive"
    }
    return "";
  }

}
