






























import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import LoadingMask from "./Loading/LoadingMask";

@Component({components: {LoadingMask}})
export default class Card extends Vue {
  @Prop({type: String, required: false}) readonly title ?: string;
  @Prop({type: String, default: "h4"}) readonly titleClass !: string;
  @Prop({type: Boolean, default: false}) readonly loading !: boolean;
  @Prop({type: String, default: null}) readonly description !: string | null;
}
