<template>
  <div>
    <div class="card-box m-b-5">
      <div class="text-right">
        <b-btn
          v-b-modal.createWaitingListRecord
          class="btn btn-success waves-effect"
        >
          <i class="fa fa-plus" /> Dodaj wpis
        </b-btn>
      </div>
      <form
        class="form-row"
        @submit.prevent="submit"
      >
        <b-form-group
          class="col-lg-3"
          label="Pacjent"
        >
          <patient-select
            :value="filtersForm.patientId"
            @input="updateFiltersPatient"
          />
        </b-form-group>
        <b-form-group
          class="col-lg-3"
          label="Typ świadczenia"
        >
          <waiting-list-type-of-benefits-select
            v-model="filtersForm.typeOfBenefits"
            clearable
          />
        </b-form-group>
        <b-form-group
          class="col-lg-3"
          label="Status"
        >
          <waiting-list-status-select
            v-model="filtersForm.status"
            clearable
          />
        </b-form-group>
        <div class="col-lg-2 no-label">
          <button
            type="submit"
            class="btn btn-primary"
          >
            <i class="fa fa-search" /> Szukaj
          </button>
        </div>
      </form>
    </div>
    <card
      v-if="items"
      ref="content"
      :loading="loading"
      title=""
    >
      <pagination-description
        v-if="pagination"
        :items-count="items.length"
        :page="currentPage"
        :per-page="pagination.perPage"
        :total-count="pagination.totalRows"
      />
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">
                Data utworzenia
              </th>
              <th scope="col">
                Pacjent
              </th>
              <th scope="col">
                Typ świadczenia
              </th>
              <th scope="col">
                Status
              </th>
              <th scope="col">
                Data zakończenia
              </th>
              <th scope="col">
                Opcje
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="record in items"
              :key="record.waitingListId"
            >
              <td>{{ record.notificationDate }}</td>
              <td>{{ record.patient }}</td>
              <td><Translation :msgid="`@type-of-benefit.${record.typeOfBenefit}`" /></td>
              <td><Translation :msgid="`@waiting-list-status.${record.status}`" /></td>
              <td>{{ record.endDate }}</td>
              <td>
                <b-btn
                  class="btn btn-secondary btn-sm mr-1"
                  @click="$bvModal.show(`previewWaitingListRecord-${record.waitingListId}`)"
                >
                  <i class="fas fa-eye" /> Podgląd wpisu
                </b-btn>
                <waiting-list-preview-modal
                  :waiting-list-id="record.waitingListId"
                />
                <is-granted
                  :privileges="['FINISH_WAITING_LIST_RECORD']"
                  :subject="record.waitingListId"
                  component="fragment"
                >
                  <b-btn
                    class="btn btn-danger btn-sm"
                    @click="$bvModal.show(`finishWaitingListRecord-${record.waitingListId}`)"
                  >
                    <i class="fas fa-times" /> Wykreśl wpis
                  </b-btn>
                </is-granted>
                <waiting-list-finish-record-modal
                  :waiting-list-id="record.waitingListId"
                  @finished="fetchData"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <p
          v-if="0 === items.length"
          class="text-center"
        >
          Brak wyników do wyświetlenia
        </p>
      </div>
      <pagination-description
        v-if="pagination"
        :items-count="items.length"
        :page="currentPage"
        :per-page="pagination.perPage"
        :total-count="pagination.totalRows"
      >
        <b-pagination
          v-model="currentPage"
          :per-page="pagination.perPage"
          :total-rows="pagination.totalRows"
          align="right"
          class="mb-0"
          @input="changePage"
        />
      </pagination-description>
    </card>
    <waiting-list-create-record-modal
      @recordCreated="fetchData"
    />
  </div>
</template>

<script>
import isEqual from "lodash/isEqual";
import {changePageQuery} from "../../utils/pageUrl/handlePageQuery";
import PaginationDescription from "../../components/Pagination/PaginationDescription";
import Card from "../../components/Card";
import Translation from "../../components/i18n";
import read from "../../rest/read";
import WaitingListCreateRecordModal from "./WaitingListCreateRecordModal";
import PatientSelect from "../../components/Patient/PatientSelect";
import WaitingListTypeOfBenefitsSelect from "../../components/WaitingList/WaitingListTypeOfBenefitsSelect";
import WaitingListStatusSelect from "../../components/WaitingList/WaitingListStatusSelect";
import WaitingListPreviewModal from "./WaitingListPreviewModal";
import WaitingListFinishRecordModal from "./WaitingListFinishRecordModal";
import IsGranted from "../../components/IsGranted";

export default {
  name: "WaitingListView",
  components: {
    IsGranted,
    WaitingListFinishRecordModal,
    WaitingListPreviewModal,
    WaitingListStatusSelect,
    WaitingListTypeOfBenefitsSelect,
    PatientSelect,
    WaitingListCreateRecordModal,
    PaginationDescription,
    Card,
    Translation
  },
  props: {
    page: {type: Number, default: null},
    status: {type: String, default: null},
    patientId: {type: String, default: null},
    typeOfBenefits: {type: String, default: null}
  },
  data() {
    return {
      items: [],
      pagination: {
        currentPage: 1,
        perPage: 20,
      },
      currentPage: this.page || 1,
      loading: false,
      filtersForm: {
        patientId: this.patientId,
        typeOfBenefits: this.typeOfBenefits,
        status: this.status ? this.status : "opened"
      }
    }
  },
  computed: {
    filters() {
      return {
        page: this.currentPage,
        patientId: this.filtersForm.patientId,
        typeOfBenefits: this.filtersForm.typeOfBenefits,
        status: this.filtersForm.status
      };
    },
  },
  watch: {
    page(val) {
      this.currentPage = val || 1;
    },
    async "$route"() {
      await this.fetchData();
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData(){
      this.loading = true;
      const {items, pagination} = await read("/api/waiting-list", this.filters);
      this.items = items;
      this.pagination = pagination;
      this.loading = false;
    },
    updatePageUrl() {
      if (isEqual(this.$route.query, this.filters)) {
        this.fetchData();
        return;
      }
      changePageQuery(this.filters);
    },
    async changePage(page) {
      this.currentPage = page;
      this.updatePageUrl();
    },
    submit() {
      this.currentPage = 1;
      this.updatePageUrl();
    },
    updateFiltersPatient(patient) {
      this.filtersForm.patientId = patient ? patient.patientId : null;
    }
  }
}
</script>


