<template>
  <card
    :loading="loading"
    title=""
  >
    <error-message :errors="errors" />

    <registration-legend />

    <registration-calendar
      :start="filters.start"
      :items="items"
      :day-view="filters.dayView"
      @createAppointment="createAppointment"
      @showDetails="showEventDetails"
    />

    <vue-simple-context-menu
      ref="menu"
      :element-id="'contextMenu'"
      :options="contextMenuOptions"
      @option-clicked="onContextMenuItemClick"
    />

    <appointment-box-modals
      @appointmentUpdated="fetchData"
    />
  </card>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import Card from "../Card";
import stringifyDate from "../../utils/date/stringifyDate";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import parseDate from "../../utils/date/parseDate";
import moveDateByDays from "../../utils/moveDateByDays";
import read from "../../rest/read";
import ErrorMessage from "../Form/ErrorMessage";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import AppointmentBoxModals from "../Appointment/AppointmentBox/AppointmentBoxModals";
import RegistrationCalendar from "./RegistrationCalendar";
import colors from "../../styles/style.scss";
import processResponseException from "../../utils/errors/processResponseException";
import VueSimpleContextMenu from "vue-simple-context-menu";
import {ModalType} from "@/store/modules/scheduleAppointment/types";
import RegistrationLegend from "@/components/Registration/RegistrationLegend";

const quarterDuration = 15 * 60 * 1000 // 15 min
const defaultAppointmentDuration = quarterDuration * 2 // 30 min

export default {
  name: "RegistrationWidget",
  components: {
    RegistrationLegend,
    RegistrationCalendar,
    AppointmentBoxModals,
    ErrorMessage,
    Card,
    VueSimpleContextMenu,
  },
  mixins: [errorsMixin],
  beforeRouteLeave(to, from, next) {
    this.saveAppointmentListItem(null)
    next()
  },
  props: {
    filters: {type: Object, default: ()=>({})},
    copyId: {type: String, default: null}
  },
  data() {
    return {
      loading: false,
      items: [],
      DATE_FORMAT,
      mainWorker: null,
      ModalType,
      selectedAppointment: null
    };
  },
  computed: {
    ...mapState({
      preferredPatient: state => state.stickyPatient.patient,
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration
    }),
    startDate() {
      const date = this.filters.start
        ? parseDate(this.filters.start)
        : new Date();

      date.setHours(0, 0, 0, 0);
      return date;
    },
    endDate() {
      const end = moveDateByDays(this.startDate, 6)
      end.setHours(23, 59, 59);
      return end;
    },
    contextMenuOptions() {
      const dividerOption = {type: "divider"}
      const options = [
        {name: "Umów wizytę", modalType: ModalType.DEFAULT, renderIf: true},
        {name: "Umów usługę - pobyt ciągły", modalType: ModalType.CONTINUOUS_STAY, renderIf: !this.nfzConfiguration},
      ]

      return options.reduce((menuOptions, option) => {
        return option.renderIf
          ? (menuOptions.length ? [...menuOptions, dividerOption, option] : [option])
          : menuOptions
      }, [])
    }
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      async handler() {
        await this.fetchData()
      },
    },
  },
  methods: {
    ...mapMutations("appointmentListItem", ["saveAppointmentListItem",]),
    ...mapActions("scheduleAppointment", ["openModal", "setAppointmentData"]),
    async fetchData() {
      this.errors = [];
      this.mainWorker = null;

      if (!this.filters.selectedWorkerId) {
        return
      }
      this.loading = true;
      try {
        const params = {
          startDate: this.startDate ? stringifyDate(this.startDate, DATE_FORMAT.DATE_TIME) : null,
          endDate: this.endDate ? stringifyDate(this.endDate, DATE_FORMAT.DATE_TIME) : null,
          workerId: [this.filters.selectedWorkerId]
        };

        const {items, workers} = await read("/api/workers/availabilities", params)

        this.mainWorker = workers[0];

        const appointmentItems = items
          .filter(item => item.appointmentId && item.status !== "canceled")
          .map(item => ({
            ...item,
            id: item.appointmentId,
            resourceId: item.workerId,
            start: parseDate(item.start),
            end: parseDate(item.end),
            title: this.getAppointmentTitle(item),
            backgroundColor: this.getBackgroundColor(item.status),
            patients: item.patientsList,
          }))

        const availableAreas = items
          .filter(item => item.status !== "canceled")
          .map(item => ({
            ...item,
            start: parseDate(item.start),
            end: parseDate(item.end),
            rendering: "background",
            color: "green",
          }))

        this.items = [...appointmentItems, ...availableAreas]
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    showEventDetails(event) {
      this.showAppointmentInfoModal(event);
    },
    createAppointment (eventData) {
      const startDate = eventData.date
      const endDate = new Date(startDate)
      const nextQuarterDate = new Date(startDate)
      endDate.setTime(startDate.getTime() + defaultAppointmentDuration)
      nextQuarterDate.setTime(startDate.getTime() + quarterDuration)

      const selectedSlot = this.items.find(item => {
        return startDate.getTime() >= item.start.getTime() && startDate.getTime() < item.end.getTime()
      })

      if (selectedSlot && !selectedSlot.available) {
        return
      }

      const isNextSlotAvailable = !this.items.find(item => {
        return (nextQuarterDate.getTime() === item.start.getTime())
          && !item.available
      })

      let appointmentData = {
        appointmentStart: startDate,
        appointmentEnd: isNextSlotAvailable ? endDate : nextQuarterDate,
        worker: {
          ...this.mainWorker,
          branchId: selectedSlot ? selectedSlot.branchId : "",
          branchName: selectedSlot ? selectedSlot.branchName : "",
        },
        branchId: "",
        branchName: "",
        treatmentType: selectedSlot ? selectedSlot.treatmentType : {},
        plan: selectedSlot ? selectedSlot.plan : "",
        preferredPatient: this.preferredPatient,
      }

      setTimeout(()=>{
        const handledEvent = (eventData.jsEvent.pageX || eventData.jsEvent.pageY)
          ? eventData.jsEvent
          : ((eventData.jsEvent.changedTouches && eventData.jsEvent.changedTouches.length) // touchend event on tablet
            ? eventData.jsEvent.changedTouches[0]
            : null
          )

        this.$refs.menu.showMenu(handledEvent, {appointmentData});
      }, 0);
    },
    onContextMenuItemClick(itemData) {
      this.setAppointmentData({
        ...itemData.item.appointmentData,
        copyId: this.copyId || null
      });
      this.openModal(itemData.option.modalType);
    },
    async showAppointmentInfoModal(calendarEvent) {
      const {extendedProps: item} = calendarEvent;

      try {
        this.selectedAppointment = await read(`/api/appointments/${item.appointmentId}`);
      } catch(e) {
        console.error(e);
      }

      const currentItem = {
        appointmentId: item.appointmentId,
        removePatientAllowedFromGroup: false,
        cancelAllowedFromGroup: true,
        changePatientsAllowedFromGroup: !item.continuousStay,
        date: calendarEvent.start,
        group: item.group,
        patients: item.patients,
        status: item.status,
        mobile: !item.isLocal,
        mainWorker: this.mainWorker,
        workers: this.selectedAppointment
          ? [this.selectedAppointment.mainSpecialist, ...this.selectedAppointment.additionalSpecialists]
          : [this.mainWorker],
        continuousStay: item.continuousStay,
        patientContinuousStays: item.patientContinuousStays,
        branchId: this.selectedAppointment ? this.selectedAppointment.branch.branchId : item.branchId,
        branchName: this.selectedAppointment ? this.selectedAppointment.branch.branchId : item.branchName,
      };

      this.saveAppointmentListItem(currentItem);
      setTimeout(() => this.$bvModal.show("appointment-info-modal"));
    },
    getAppointmentTitle(item) {
      if (!item.patientsList.length) {
        return "Wizyta bez pacjentów";
      }
      if (item.patientsList.length > 1) {
        return "Wizyta grupowa";
      }

      const fileNumber = item.patientsList[0].fileNumber
      return fileNumber ? fileNumber : item.patientsList[0].displayName
    },
    getBackgroundColor(status) {
      switch(status) {
        case "created":
          return colors.warning;
        case "opened":
          return colors.success;
        case "finished":
          return colors.inverse;
        default:
          return undefined;
      }
    },
  }
}
</script>
