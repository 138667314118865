<template>
  <div>
    <b-form-checkbox
      :checked="value.hasContract"
      class="mb-3"
      @input="updateValue({hasContract: $event})"
    >
      Pracownik posiada podpisaną umowę z NFZ
    </b-form-checkbox>
    <error-message
      :errors="errors"
      field="reimbursementContract"
      root
    />
    <transition>
      <div v-if="value.hasContract">
        <b-form-group label="Oddział NFZ">
          <nfz-branch-select
            :value="value.branchId ? {branchId: value.branchId} : null"
            :state="state('branchId')"
            :disabled="disabled"
            @input="updateValue({branchId: $event ? $event.branchId : null})"
          />
          <error-message
            :errors="errors"
            field="branchId"
          />
        </b-form-group>
        <b-form-group label="Typ kontraktu">
          <contract-type-select
            :value="value.typeSuffix"
            :state="state('typeSuffix')"
            :disabled="disabled"
            @input="updateValue({typeSuffix: $event})"
          />
          <error-message
            :errors="errors"
            field="typeSuffix"
          />
        </b-form-group>
        <b-form-group label="Numer kontraktu">
          <b-form-input
            :value="value.contractNumber"
            :state="state('contractNumber')"
            :disabled="disabled"
            @input="updateValue({contractNumber: $event ? $event.trim() : null})"
          />
          <error-message
            :errors="errors"
            field="contractNumber"
          />
        </b-form-group>
      </div>
    </transition>
  </div>
</template>

<script>
import NfzBranchSelect from "../Form/Select/NfzBranchSelect";
import ContractTypeSelect from "../Form/Select/ContractTypeSelect";
import ErrorMessage from "../Form/ErrorMessage";
import subErrors from "../../utils/errors/subErrors";

export default {
  name: "ReimbursementContractForm",
  components: {ContractTypeSelect, NfzBranchSelect, ErrorMessage},
  props: {
    value: {type: Object, required: true},
    disabled: {type: Boolean, default: false},
    errors: {type: Array, default: () => []},
  },
  methods: {
    getSubErrors(field) {
      return subErrors(this.errors, field);
    },
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    updateValue(diff) {
      const newVal = {...this.value, ...diff};
      this.$emit("input", newVal);
    }
  }
}
</script>
