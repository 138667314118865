










import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import {mapActions, mapGetters} from "vuex";
import debounce from "lodash/debounce";
import isEquals from "lodash/isEqual";

interface Privilege {
  attribute: String,
  subject?: String
}

interface Access {
  attribute: String,
  subject?: String,
  access: boolean
}

interface Queue {
  subscribe: Array<Privilege>,
  unsubscribe: Array<Privilege>
}

const queue : Queue = {
  subscribe: [],
  unsubscribe: []
};

const debouncedSubscribe = debounce((f: any) => f(), 100, {maxWait: 500});
const debouncedUnsubscribe = debounce((f: any) => f(), 100, {maxWait: 500});

export enum Condition {
  OR = "OR",
  AND = "AND"
}

@Component({
  computed: {...mapGetters("security",["accesses"])},
  methods: {...mapActions("security", {
    securitySubscribe: "subscribe",
    securityUnsubscribe: "unsubscribe",
  })
  },
})
export default class IsGranted extends Vue {

  @Prop({type: String, default: "span"}) readonly component!: string;
  @Prop({type: Array, required: true}) readonly privileges!: Array<String>;
  @Prop({type: String, default: null}) readonly subject!: string;
  @Prop({type: Object, default: null}) readonly to!: Object;
  @Prop({type: String, default: Condition.OR}) readonly condition!: Condition;
  @Prop({type: Boolean, default: false}) readonly startWithUnsubscribe!: boolean;

  @Watch("privileges") onPrivilegesChanged(newPrivileges: Array<Privilege>, oldPrivileges: Array<Privilege>) : void
  {
    if (!isEquals(newPrivileges.sort(), oldPrivileges.sort())) {
      this.unsubscribe();
      this.subscribe();
    }
  };

  @Watch("subject") onSubjectChanged(newSubject: String, oldSubject: String): void
  {
    if (newSubject !== oldSubject) {
      this.unsubscribe();
      this.subscribe();
    }
  };

  subscribed: boolean = false;
  queue: Queue = queue;

  get granted() : boolean
  {
    if(Condition.AND === this.condition){
      return this.privileges
        .every((attribute) => this.accesses.find(
          (a: Access) => (a.attribute === attribute && a.subject === this.subject))
        )
    }
    return null != this.privileges
      .find((attribute) => this.accesses.find((a: Access) => (a.attribute === attribute && a.subject === this.subject)))
  }

  mounted(): void {
    if (this.startWithUnsubscribe) {
      this.unsubscribe();
    }
    this.subscribe();
  };
  beforeDestroy(): void
  {
    this.unsubscribe();
  };

  subscribe(): void
  {
    this.privileges.forEach((attribute: String) => this.queue.subscribe.push({attribute, subject: this.subject}));
    debouncedSubscribe(() => {
      this.securitySubscribe(this.queue.subscribe);
      this.queue.subscribe = [];
    });
    this.subscribed = true;
  };
  unsubscribe(): void
  {
    this.privileges.forEach((attribute: String) => this.queue.unsubscribe.push({attribute, subject: this.subject}));
    debouncedUnsubscribe(() => {
      this.securityUnsubscribe(this.queue.unsubscribe);
      this.queue.unsubscribe = [];
    });
    this.subscribed = false;
  }
};

