<template>
  <div class="col-12">
    <h4>Zgody pacjenta</h4>
    <hr>
    <b-form-group
      v-for="(patientPermission, index) in patientPermissionsList"
      :key="index"
    >
      <b-form-checkbox
        v-model="permissions[patientPermission.propertyName]"
        size="lg"
        switch
        :state="state(patientPermission.propertyName)"
        @input="update"
      >
        {{ patientPermission.createLabel }}
        <error-message
          :errors="errors"
          :field="setError(patientPermission.propertyName)"
        />
      </b-form-checkbox>
    </b-form-group>
    <b-form-group
      label="Upoważnienie do dokumentacji medycznej (wymagane)"
    >
      <b-form-radio-group
        id="nfzMedicalDocumentation"
        v-model="permissions.permissionForNfzMedicalDocumentation"
        :state="state('permissionForNfzMedicalDocumentation')"
        name="nfzMedicalDocumentation"
        stacked
        @input="update"
      >
        <b-form-radio
          :value="true"
        >
          Upoważniam do uzyskiwania dokumentacji medycznej.
        </b-form-radio>
        <b-form-radio
          :value="false"
        >
          Nie upoważniam do uzyskiwania dokumentacji medycznej.
        </b-form-radio>
      </b-form-radio-group>
      <error-message
        :errors="errors"
        field="permissions.permissionForNfzMedicalDocumentation"
      />
    </b-form-group>
    <b-form-group
      label="Upoważnienie do uzyskiwania informacji o stanie zdrowia i udzielonych świadczeń zdrowotnych (wymagane)"
    >
      <b-form-radio-group
        id="nfzHealthInformationAndServices"
        v-model="permissions.permissionForHealthInformationAndProvidedHealthServices"
        :state="state('permissionForHealthInformationAndProvidedHealthServices')"
        name="nfzHealthInformationAndServices"
        stacked
        @input="update"
      >
        <b-form-radio
          :value="true"
        >
          Upoważniam do uzyskiwania informacji o stanie zdrowia i udzielonych świadczeń zdrowotnych.
        </b-form-radio>
        <b-form-radio
          :value="false"
        >
          Nie upoważniam do uzyskiwania informacji o stanie zdrowia i udzielonych świadczeń zdrowotnych..
        </b-form-radio>
      </b-form-radio-group>
      <error-message
        :errors="errors"
        field="permissions.permissionForHealthInformationAndProvidedHealthServices"
      />
    </b-form-group>
    <div
      v-if="medicalDataAuthorizationsPersons.length"
    >
      <create-medical-authorization
        v-for="(medicalDataAuthorizationsPerson, index) in medicalDataAuthorizationsPersons"
        :key="medicalDataAuthorizationsPerson.id"
        :person="medicalDataAuthorizationsPerson"
        :errors="getErrorsMedicalDataAuthorizations(index)"
        @removeMedicalDocumentationAuthorization="removeMedicalDocumentationAuthorization(index)"
      />
    </div>
    <button
      class="btn btn-success"
      @click="createMedicalDocumentationAuthorization"
    >
      <i class="fa fa-plus" />
      Dodaj kolejne upoważnienie
    </button>
  </div>
</template>

<script>
import ErrorMessage from "../../components/Form/ErrorMessage.vue";
import permissionsList from "../../components/Patient/patientPermissions.ts";
import CreateMedicalAuthorization from "../Patient/CreateMedicalAuthorization";
import subErrors from "../../utils/errors/subErrors";
import {v4 as uuid} from "uuid"

export default {
  components: {
    ErrorMessage,
    CreateMedicalAuthorization
  },
  props: {
    value: {type: Object, required: true},
    errors: {type: Array, default: () => []},
    displayType: {type: String, default: null}
  },
  data() {
    return {
      permissionsList,
      medicalDataAuthorizationsPersons: [],
      patientPermissionsList: permissionsList.filter(
        permission => permission.types.includes(this.displayType)
      ),
      permissions: this.value
    };
  },
  created() {
    this.patientPermissionsList.forEach(permission => this.permissions[permission.propertyName] = false);
  },
  methods: {
    getSubErrors(field) {
      return subErrors(this.errors, field);
    },
    setError(error){
      return `permissions.${error}`
    },
    getErrorsMedicalDataAuthorizations(index) {
      return subErrors(this.errors, `medicalDataAuthorizationsPersons[${index}]`);
    },
    update(){
      this.$emit("input",{"permissionsList":this.permissions,"medicalDataAuthorizationsPersons":this.medicalDataAuthorizationsPersons});
    },
    state(field){
      return this.errors.find((error) => error.field === this.setError(field))? false : null;
    },
    removeMedicalDocumentationAuthorization(index) {
      this.medicalDataAuthorizationsPersons.splice(index, 1);
    },
    createMedicalDocumentationAuthorization(){
      this.medicalDataAuthorizationsPersons = [...this.medicalDataAuthorizationsPersons,
        {
          "id": uuid(),
          "authorizedPersonName" : null,
          "authorizedPersonSurname" : null,
          "endOfAuthorization" : null,
          "authorizedPersonPhoneNumber" : null,
          "authorizedPersonEmail" : null,
        }
      ];
      this.$emit("input",{"permissionsList":this.permissions,"medicalDataAuthorizationsPersons":this.medicalDataAuthorizationsPersons});
    },
  }
}
</script>

<style scoped>

</style>
