













import {Component,  Prop, Watch} from "vue-property-decorator";
import ErrorsMixin from "../../../mixins/ErrorsMixin.ts";
import {mixins} from "vue-class-component";
import TargetsList from "../../../components/Ipz/TargetsAndTasks/TargetsList.vue";
import LoadingMask from "../../../components/Loading/LoadingMask.vue";
import read from "../../../rest/read";
import processResponseException from "../../../utils/errors/processResponseException";
import ErrorMessage from "../../../components/Form/ErrorMessage.vue";
import type {Target} from "../../../types/TargetsAndTasks";

interface Overview {
  readonly: boolean;
}
interface IpzDetails {
  ipzId: string;
  overview: Overview;
}


@Component({
  name: "TargetsAndTasksPanel",
  components: {ErrorMessage, LoadingMask, TargetsList},
})
export default class TargetsAndTasksPanel extends mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly ipzId!: string;

  loading: boolean = false;
  targets: Array<Target> = [];
  overview: Overview | {} = {};

  @Watch("ipzId")
  async onIpzIdChange(): Promise<void> {
    await this.loadData();
  }

  async mounted(): Promise<void> {
    await this.loadData();
  }

  async loadData(): Promise<void> {
    this.errors = [];
    this.loading = true;
    try {
      await Promise.all([
        this.loadTargets(),
        this.loadOverview(),
      ]);
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  async loadTargets(): Promise<void> {
    const {items} = await read<{items: Array<Target>}>(`/api/ipzs/${this.ipzId}/targets`);
    this.targets = items;
  }

  async loadOverview(): Promise<void> {
    const {overview} = await read<IpzDetails>(`/api/ipzs/${this.ipzId}/details`);
    this.overview = overview;
  }

  private async removeTarget(): Promise<void> {
  }

}
