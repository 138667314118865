


















































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorsMixin from "../../mixins/ErrorsMixin.ts";
import WorkerEwusAccess from "../../views/Worker/WorkerEwusAccess.vue";
import LoadingMask from "../Loading/LoadingMask.vue";
import EwusAccessForm from "./EwusAccessForm.vue";
import ErrorMessage from "../Form/ErrorMessage.vue";
import read from "../../rest/read";
import {generateUuid} from "../../utils/uuid/generateUuid";
import create from "../../rest/create";

export interface Access {
  login: string|null;
  password: string|null;
  operatorType: string|null;
  doctorId: string|null;
  domain: string|null;
}

interface EwusData {
  ewusLogin: string;
  ewusPassword: string;
  ewusOperatorType: string|null;
  ewusDoctorId: string|null;
  ewusDomain: string;
}

interface SubmittedData extends EwusData {
  id: string;
  workerId: string;
}

const accessInitialState:Access = {
  login: null,
  password: null,
  operatorType: null,
  domain: null
};

@Component({
  name: "EwusWorkerSettings",
  components: {ErrorMessage,
    EwusAccessForm,
    LoadingMask,
    WorkerEwusAccess,
  },
})

export default class EwusWorkerSettings extends Mixins(ErrorsMixin) {
  @Prop({type: String, default: null}) readonly workerId!: string;

  private loading: boolean = false;
  private displayInfo: boolean = false;
  private savedAccess: Access = {...accessInitialState};
  private access: Access = {...accessInitialState};

  @Watch("workerId", {immediate: true}) async handleData(value: string|null): Promise<void> {
    if (!!value) {
      await this.loadData();
    } else {
      this.savedAccess = {...accessInitialState};
      this.access = {...accessInitialState};
      this.displayInfo = false;
    }
  }

  private async loadData(): Promise<void> {
    this.loading = true;
    try {
      const {
        ewusDomain: domain,
        ewusLogin: login,
        ewusOperatorType: operatorType,
        ewusPassword: password,
        ewusDoctorId: doctorId,
      } = await read<EwusData>(`/api/worker/${this.workerId}/ewus-access`);

      const access: Access = {domain, login, operatorType, doctorId, password};
      this.savedAccess = access;
      this.access = access;
      this.displayInfo = true;
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private clearErrors(): void {
    this.errors = [];
  }

  private close(): void {
    this.$bvModal.hide("ewus-worker-settings");
  }

  private onHide():void {
    this.clearErrors();
    this.access = this.savedAccess;
  }

  private async submit(): Promise<void> {
    this.clearErrors();
    this.loading = true;

    try {
      const data: SubmittedData = {
        id: generateUuid(),
        workerId: this.workerId,
        ewusLogin: this.access.login,
        ewusPassword: this.access.password ? this.access.password : "",
        ewusOperatorType: this.access.operatorType,
        ewusDoctorId: this.access.doctorId,
        ewusDomain: this.access.domain
      };
      await create<SubmittedData>(`/api/worker/${this.workerId}/ewus-access`, data);
      this.savedAccess = this.access;
      this.close();
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }
}
