var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tablesWrapper"},_vm._l((_vm.units),function(unit){return _c('div',{key:unit.orderNumber,staticClass:"mt-4 pt-4 card"},[_c('span',{staticClass:"pointToScroll",attrs:{"id":("pointToScroll" + (unit.orderNumber))}}),_c('div',{staticClass:"pl-4 pb-3"},[_c('p',{staticClass:"mb-1"},[_c('b',[_vm._v("Nazwa")]),_vm._v(": "+_vm._s(unit.name)+" ")]),_c('div',{staticClass:"d-flex justify-content-between align-items-end"},[_c('p',{staticClass:"mb-1"},[_c('b',[_vm._v("Kod zakresu świadczeń:")]),_vm._v(" "+_vm._s(unit.serviceScopeCode)+" ")])]),_c('p',{staticClass:"mb-1"},[_c('b',[_vm._v("Typ")]),_vm._v(" "),_c('i',[_vm._v("(\"Lista podmiotów\" dla relanych, \"Obszar\" dla 9900.400, \"DPS\" dla 9900.401)")]),_vm._v(": "),_c('b',[_vm._v(_vm._s(_vm.getTypeLabel(unit.type)))])]),_c('p',{staticClass:"mb-1"},[_c('b',[_vm._v("Kod resortowy VII / VIII:")]),_vm._v(" "+_vm._s(unit.resortCode)+" ")]),_c('p',{staticClass:"mb-1"},[_c('b',[_vm._v("Adres:")]),_vm._v(" "+_vm._s(_vm.formatUnitAddress({city: unit.city, zipCode: unit.zipCode, street: unit.street}))+" ")]),_c('p',{staticClass:"mb-1"},[_c('b',[_vm._v("Nr techniczny:")]),_vm._v(" "+_vm._s(unit.technicalNumber)+" ")])]),(_vm.units.length > 0)?_c('b-table',{attrs:{"items":_vm.colsDefinitions,"fields":_vm.fieldsDefinitions,"empty-text":"","responsive":"","show-empty":""},scopedSlots:_vm._u([{key:"cell()",fn:function(data){return [(data.field.key === 'values')?_c('span',[_vm._v(" "+_vm._s(data.item.label)+" ")]):_c('span',[_c('input',{staticClass:"input form-control",class:_vm.markPositiveOrNegative({
              unitOrderNumber: unit.orderNumber,
              keyIdx: data.item.key,
              month: data.field.month
            }),attrs:{"disabled":true,"placeholder":'0'},domProps:{"value":_vm.getInitialValue({
              unitOrderNumber: unit.orderNumber,
              type: data.item.key,
              month: data.field.month,
            })}})])]}}],null,true)}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }