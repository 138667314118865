



































import Component from "vue-class-component";

@Component({
  name: "CompleteReferralConfirmModal",
})

export default class CompleteReferralConfirmModal {
  closeModal() {
    this.$bvModal.hide("complete-referral-confirm-modal");
    this.$emit("canceled");
  }

  submit() {
    this.$emit("confirm");
  }
}
