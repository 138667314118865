<template>
  <div>
    <card
      :loading="loading"
      title=""
    >
      <div class="row">
        <div class="col-8">
          <b-form-input
            v-model="search"
            placeholder="szukaj"
            type="text"
            @keyup.enter="startSearch"
          />
        </div>
        <div class="col-2">
          <button
            class="btn btn-primary ml-2"
            type="button"
            @click="startSearch"
          >
            <i class="fa fa-search" />
            Szukaj
          </button>
        </div>
      </div>
      <template v-if="!loading">
        <nfz-communication-table
          :items="items"
        />
        <pagination-description
          :items-count="items.length"
          :page="currentPage"
          :per-page="pagination.perPage"
          :total-count="pagination.totalRows"
        >
          <b-pagination
            :total-rows="pagination.totalRows"
            :per-page="pagination.perPage"
            :value="currentPage"
            align="right"
            class="mb-0"
            @input="changePage"
          />
        </pagination-description>
      </template>
    </card>
  </div>
</template>

<script>
import PaginationDescription from "@/components/Pagination/PaginationDescription";
import Card from "@/components/Card";
import NfzCommunicationTable from "@/components/NfzCommunication/NfzCommunicationTable";
import {updatePageQuery} from "@/utils/pageUrl/handlePageQuery";
import read from "@/rest/read";

export default {
  name: "NfzCommunicationList",
  components: {
    NfzCommunicationTable,
    PaginationDescription,
    Card,
  },
  props: {
    page: {type: Number, default: null}
  },
  data() {
    return {
      currentPage: this.page || 1,
      loading: false,
      search:"",
      items: [],
      pagination: {
        perPage: 20,
      },
    };
  },
  computed: {
    filters() {
      return {
        perPage: this.pagination.perPage,
        page: this.currentPage,
        search:this.search
      };
    },
  },
  watch: {
    page(val) {
      this.currentPage = val || 1;
    },
    "$route"() {
      this.getList();
    },
  },
  async mounted() {
    await this.getList();
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      updatePageQuery({page: this.currentPage});
    },
    async getList() {
      this.loading = true;
      const response = await read("/api/nfz-communication", this.filters);
      this.pagination = response.pagination;
      this.items = response.items;
      this.loading = false;
    },
    async startSearch() {
      this.currentPage=1
      await this.getList()
    },
  },
}
</script>

<style scoped>
</style>
