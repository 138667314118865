<template>
  <form @submit.prevent="submit">
    <b-form-group
      label="Data rejestracji"
      label-for="registeredDate"
    >
      <date-picker
        id="registeredDate"
        v-model="formData.registeredDate"
        :disabled="loading"
        :state="state('registeredDate')"
        :append-to-body="false"
      />
      <error-message
        :errors="errors"
        field="registeredDate"
      />
    </b-form-group>
    <b-form-group
      label="Data zakończenia uczestnictwa w projekcie"
      label-for="dateOfEndParticipation"
    >
      <date-picker
        id="dateOfEndParticipation"
        v-model="formData.dateOfEndParticipation"
        :disabled="loading"
        :state="state('dateOfEndParticipation')"
        :clearable="true"
        :append-to-body="false"
      />
      <error-message
        :errors="errors"
        field="dateOfEndParticipation"
      />
    </b-form-group>
    <div>
      <b-form-checkbox
        v-model="patientDeath"
        class="m-b-10"
      >
        Zgon pacjenta
      </b-form-checkbox>
      <b-form-group
        v-if="patientDeath"
        label="Data zgonu"
        label-for="deathDate"
      >
        <date-picker
          id="deathDate"
          v-model="formData.deathDate"
          :disabled="loading"
          :state="state('deathDate')"
          :clearable="true"
          :append-to-body="false"
        />
        <error-message
          :errors="errors"
          field="deathDate"
        />
      </b-form-group>
    </div>
    <b-form-group
      v-if="!nfzConfiguration"
      label="Stan cywilny"
      label-for="maritalStatusSelect"
    >
      <marital-status-select
        id="maritalStatusSelect"
        v-model="formData.maritalStatus"
        :disabled="loading"
        :state="state('maritalStatus')"
      />
      <error-message
        :errors="errors"
        field="maritalStatus"
      />
    </b-form-group>
    <b-form-group
      v-if="!nfzConfiguration"
      id="education"
      label="Wykształcenie"
      label-for="educationInput"
    >
      <education-level-select
        id="educationInput"
        v-model="formData.education"
        :disabled="loading"
        :state="state('education')"
      />
      <error-message
        :errors="errors"
        field="education"
      />
    </b-form-group>
    <b-form-group
      v-if="!nfzConfiguration"
      label="Poziom niepełnosprawności"
      label-for="disabilityLevelSelect"
    >
      <disability-level-select
        id="disabilityLevelSelect"
        v-model="formData.disabilityLevel"
        :clearable="false"
        :disabled="loading"
        :state="state('disabilityLevel')"
      />
      <error-message
        :errors="errors"
        field="disabilityLevel"
      />
    </b-form-group>
    <b-form-group
      v-if="!nfzConfiguration"
      label="Źródło informacji o ŚCZP"
      label-for="patientSourceOfInformation"
    >
      <source-of-information-select
        v-if="!loading && formData"
        id="patientSourceOfInformation"
        v-model="formData.sourceOfInformation"
        :clearable="false"
        :state="state('sourceOfInformation')"
      />
      <b-input
        v-else
        disabled
      />
      <error-message
        :errors="errors"
        field="sourceOfInformation"
      />
    </b-form-group>
    <b-form-group
      v-if="!nfzConfiguration"
      id="nationality"
      label="Narodowość"
      label-for="nationalityInput"
    >
      <country-select
        id="nationalityInput"
        v-model="formData.nationality"
        :disabled="loading"
        :state="state('nationality')"
      />
      <error-message
        :errors="errors"
        field="nationality"
      />
    </b-form-group>
    <b-form-group
      v-if="!nfzConfiguration"
      id="fileNumber"
      label="Numer teczki"
      label-for="fileNumber"
    >
      <b-input
        v-model="formData.fileNumber"
        :disabled="loading"
        :state="state('fileNumber')"
      />
      <error-message
        :errors="errors"
        field="fileNumber"
      />
    </b-form-group>
    <b-form-group
      v-if="!nfzConfiguration"
      id="externalNumber"
      label="Numer zewnętrzny"
      label-for="externalNumber"
    >
      <b-input
        v-model="formData.externalNumber"
        :disabled="loading"
        :state="state('externalNumber')"
      />
      <error-message
        :errors="errors"
        field="externalNumber"
      />
    </b-form-group>
    <b-form-group
      v-if="!nfzConfiguration"
      id="ops"
      label="Pacjent skierowany z OPS"
      label-for="opsInput"
    >
      <ops-select
        id="opsInput"
        v-model="formData.ops"
        :state="state('ops')"
      />
      <error-message
        :errors="errors"
        field="ops"
      />
    </b-form-group>
    <error-message :errors="errors" />
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        @click="cancel"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        type="submit"
        class="btn btn-primary"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </form>
</template>

<script>
import CountrySelect from "../Address/CountrySelect.vue";
import update from "../../rest/update";
import read from "../../rest/read";
import ErrorMessage from "../Form/ErrorMessage";
import MaritalStatusSelect from "../Form/Select/MaritalStatusSelect";
import DisabilityLevelSelect from "../Form/Select/DisabilityLevelSelect";
import SourceOfInformationSelect from "../SourcesOfInformation/SourceOfInformationSelect";
import EducationLevelSelect from "../Form/Select/EducationLevelSelect";
import processResponseException from "../../utils/errors/processResponseException";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import DatePicker from "../Form/DatePicker/DatePicker.vue"
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import validate, {notEmptyRule, notEmptySubvalueRule} from "../../utils/validate";
import parseDate from "../../utils/date/parseDate";
import OpsSelect from "@/components/Form/Select/OpsSelect";
import {mapState} from "vuex";

export default {
  components: {
    EducationLevelSelect,
    DisabilityLevelSelect,
    MaritalStatusSelect,
    CountrySelect,
    ErrorMessage,
    DatePicker,
    SourceOfInformationSelect,
    OpsSelect
  },
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true},
    additionalData: {type: Object, default: null},
  },
  data() {
    return {
      patient: this.additionalData ? this.additionalData : {},
      formData: {},
      loading: false,
      patientDeath: this.additionalData?.deathDate !== null
    };
  },
  computed: {
    ...mapState({
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration
    }),
  },
  watch: {
    additionalData() {
      this.patient = {...this.additionalData};
      this.resetData();
    },
  },
  async patientId() {
    if (!this.additionalData) {
      await this.getAdditionalData();
      this.resetData();
    }
  },
  async mounted() {
    if (!this.additionalData) {
      await this.getAdditionalData();
    }
    this.resetData();
  },
  methods: {
    async getAdditionalData() {
      this.loading = true;
      try {
        this.patient = await read(`/api/patients/${this.patientId}/additional_data`);
        this.patientDeath = null !== this.patient.deathDate
      } catch(exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    validateUnificationData() {
      const rules = [
        {field: "registeredDate", rule: notEmptyRule},
        {field: "maritalStatus", rule: notEmptyRule},
        {field: "education", rule: notEmptyRule},
        {field: "disabilityLevel", rule: notEmptyRule},
        {field: "sourceOfInformation", rule: notEmptySubvalueRule, subvalue: "sourceOfInformation"},
      ];
      this.errors = validate(rules, null, this.errors)(this.patient);
    },
    dateString(date) {
      return date ? stringifyDate(date, DATE_FORMAT.DATE) : null;
    },
    stringToDate(dateString) {
      return dateString?  parseDate(dateString, DATE_FORMAT.DATE) : null;
    },
    async submit() {
      this.errors = [];
      this.loading = true;
      this.validateDeathDate();
      if(this.errors.length === 0){
        try {
          const resourceUrl = `/api/patients/${this.patientId}/additional_data`;
          const registeredDate = this.dateString(this.formData.registeredDate);
          const dateOfEndParticipation = this.dateString(this.formData.dateOfEndParticipation);
          const deathDate = this.patientDeath ? this.dateString(this.formData.deathDate) : null;
          const additionalData = {...this.formData, registeredDate, dateOfEndParticipation, deathDate};
          await update(resourceUrl, additionalData);
          this.patient = await read(resourceUrl);
          this.$emit("updateAdditionalData", this.patient);
          this.cancel();
        } catch (exception) {
          this.errors = processResponseException(exception);
          this.validateUnificationData();
        }
      }
      this.loading = false;
    },
    cancel() {
      this.resetData();
      this.$emit("cancel");
    },
    resetData() {
      this.formData = {
        ...this.patient,
        registeredDate: this.stringToDate(this.patient.registeredDate),
        dateOfEndParticipation: this.stringToDate(this.patient.dateOfEndParticipation),
        deathDate: this.stringToDate(this.patient.deathDate)
      };
      this.errors = [];
      this.validateUnificationData();
    },
    validateDeathDate() {
      if(this.patientDeath && null === this.formData.deathDate) {
        this.errors = [
          {
            message: "Data zgonu nie może być pusta",
            field: "deathDate"
          },
        ];
      }
    }
  },
};
</script>
