<template>
  <b-modal
    :id="modalId"
    :title="title"
    no-close-on-backdrop
    title-tag="h3"
    size="lg"
    @hide="resetData"
    @show="getAppointmentData"
  >
    <loading-mask :loading="loading">
      <error-message
        :errors="errors"
        class="m-t-20"
      />
      <template v-if="changeDateAllowed">
        <b-form-group label="Data wizyty">
          <date-picker
            v-model="startDate"
          />
        </b-form-group>
        <b-form-group label="Godzina rozpoczęcia">
          <hour-select
            v-model="startTime"
            :time-span="timeSpan"
          />
        </b-form-group>
        <b-form-group label="Godzina zakończenia">
          <hour-select
            v-model="endTime"
            :time-span="timeSpan"
          />
        </b-form-group>
        <error-message
          :errors="errors"
          field="startDate"
        />
        <error-message
          :errors="errors"
          field="endDate"
        />
      </template>
      <template v-if="requireReason">
        <b-form-group label="Powód zmiany">
          <textarea-counter
            v-model="reason"
            :disabled="loading"
            :max-characters-count="2000"
            :state="state('reason')"
            rows="3"
          />
          <error-message
            :errors="errors"
            field="reason"
          />
        </b-form-group>
      </template>
      <template
        v-if="displayHistory"
      >
        <div
          v-show="historyList.length"
          v-b-toggle="'appointmentDateHistory'"
          class="text-center m-2 cursor-pointer"
        >
          ---
          <span class="when-closed">Pokaż historię</span>
          <span class="when-opened">Ukryj historię</span>
          ---
        </div>
        <change-appointment-date-history
          v-for="(item, index) in historyList"
          :key="index"
          :history-item="item"
          :idx="index"
          class="mb-2"
        />
      </template>
    </loading-mask>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        type="button"
        class="btn btn-primary"
        @click="save"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import LoadingMask from "../../Loading/LoadingMask";
import ErrorMessage from "../../Form/ErrorMessage";
import processResponseException from "../../../utils/errors/processResponseException";
import read from "../../../rest/read";
import stringifyDate from "../../../utils/date/stringifyDate";
import parseDate from "../../../utils/date/parseDate";
import DatePicker from "../../Form/DatePicker/DatePicker";
import HourSelect from "../../Form/Select/HourSelect";
import modify from "../../../rest/modify";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import TextareaCounter from "../../Form/Textarea/TextareaCounter";
import {generateUuid} from "../../../utils/uuid/generateUuid";
import ChangeAppointmentDateHistory from "./ChangeAppointmentDateHistory";

export default {
  name: "ChangeAppointmentDate",
  components: {
    ChangeAppointmentDateHistory,
    TextareaCounter,
    HourSelect, DatePicker, ErrorMessage, LoadingMask},
  mixins: [errorsMixin],
  props: {
    appointmentId: {required: true, type: String},
    title: {required: true, type: String},
    status: {required: true, type: String},
    patientId: {default: null, type: String},
    withReason: {type: Boolean, default: false},
    displayHistory: {type: Boolean, default: true}
  },
  data() {
    return {
      parseDate,
      loading: false,
      errors: [],
      endDate: null,
      endTime: null,
      endDateString: null,
      startDate: null,
      startTime: null,
      dateString: null,
      documents: [],
      documentsData: [],
      reason: null,
      historyList: [],
      timeSpan: 1,
    };
  },
  computed: {
    modalId() {
      return `changeAppointmentDate-${this.appointmentId}`;
    },
    requireReason() {
      return this.status ===  "finished" && this.withReason;
    },
    changeDateAllowed() {
      return this.status ===  "finished" || this.status === "canceled" || this.status ===  "opened";
    },
    changeTimeSpentAllowed() {
      return this.status === "finished";
    },
    patientFinishedDocuments() {
      if(null !== this.patientId){
        return this.documents.filter(document =>
          document.patient.patientId === this.patientId
          && document.status === "finished"
        )
      } else {
        return this.documents.filter(document => document.status === "finished")
      }
    },
  },
  watch: {
    dateString() {
      const startDate = this.dateString ? parseDate(this.dateString) : null;
      this.startDate = startDate;
      this.startTime = startDate
        ? {
          HH: this.stringifyNumberWithLeadingZero(startDate.getHours()),
          mm: this.stringifyNumberWithLeadingZero(startDate.getMinutes()),
        }
        : null;
    },
    endDateString() {
      const endDate = this.endDateString ? parseDate(this.endDateString) : null;
      this.endDate = endDate;
      this.endTime = endDate
        ? {
          HH: this.stringifyNumberWithLeadingZero(endDate.getHours()),
          mm: this.stringifyNumberWithLeadingZero(endDate.getMinutes()),
        }
        : null;
    },
    patientFinishedDocuments() {
      this.documentsData = this.patientFinishedDocuments
        .map(document => ({
          documentId: document.appointmentDocumentId,
          timeSpent: document.timeSpent,
        }))
    },
  },
  methods: {
    async getAppointmentData() {
      this.loading = true;
      try {
        await this.getAppointmentDates();
        await this.getAppointmentTimeSpent();
        await this.getHistory();
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    async getHistory(){
      const history = await read(`/api/appointments/${this.appointmentId}/unify-date-history`);
      this.historyList = history.items
    },
    async getAppointmentDates() {
      if (this.changeDateAllowed) {
        const data = await read(`/api/appointments/${this.appointmentId}`);
        this.dateString = data.date;
        let endDate = new Date(this.dateString);
        endDate = new Date(endDate.getTime() + data.duration * 60000);
        this.endDateString = stringifyDate(endDate);
      }
    },
    async getAppointmentTimeSpent() {
      if (this.changeTimeSpentAllowed) {
        const {documents} = await read(`/api/appointment/${this.appointmentId}/appointment`);
        this.documents = documents;
      }
    },
    resetData() {
      this.dateString = null;
      this.reason = null;
      this.documents = [];
      this.errors = [];
    },
    closeModal() {
      this.resetData();
      this.$bvModal.hide(this.modalId);
    },
    async save() {
      this.loading = true;
      try {
        const data = {appointmentId: this.appointmentId};

        if (this.changeDateAllowed) {
          const startDate = new Date(this.startDate);
          startDate.setHours(this.startTime.HH);
          startDate.setMinutes(this.startTime.mm);

          const endDate = new Date(this.startDate);
          endDate.setHours(this.endTime.HH);
          endDate.setMinutes(this.endTime.mm);


          data.startDate = stringifyDate(startDate);
          data.endDate = stringifyDate(endDate);
          data.duration = this.duration;
          data.documents = [];
          data.reason = this.withReason ? this.reason : null;
          data.saveHistory = this.requireReason;
          data.historyId = this.withReason ? generateUuid() : null;
        }
        if (this.changeTimeSpentAllowed) {
          data.documents = this.documentsData;
        }
        await modify(`/api/appointment/${this.appointmentId}`, data);
        this.closeModal();
        this.$emit("appointmentUpdated");
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    stringifyNumberWithLeadingZero(num) {
      return (`00${  num}`).slice(-2);
    },
    updateTime(timeSpent, documentId) {
      const document = this.documentsData.find(data => data.documentId === documentId);
      document.timeSpent = timeSpent;
    },
  },
}
</script>

<style scoped>
.card-box {
  overflow: hidden;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
