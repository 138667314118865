<template>
  <div :class="{'row': option && option.textFieldAllowed}">
    <div 
      :class="{'col-sm-6': option && option.textFieldAllowed}"
    >
      <treeselect
        :value="value.sourceOfInformation"
        :options="options"
        :flat="true"
        :multiple="false"
        :default-expand-level="1"
        :name="name"
        :load-options="loadOptions"
        :placeholder="placeholder"
        :clearable="clearable"
        :no-options-text="noOptionsText"
        :class="{invalid: isInvalid}"
        clear-all-text="Odznacz wszystko"
        clear-value-text="Odznacz"
        loading-text="Pobieranie opcji..."
        no-children-text="Brak rozwijanych opcji"
        no-results-text="Brak wyników wyszukiwania"
        retry-text="Pobrać ponownie?"
        retry-title="Kliknij by pobrać ponownie"
        search-prompt-text="Zacznij pisać..."
        @input="update"
        @select="select"
      />
    </div>
    <div
      v-if="option && option.textFieldAllowed"
      :class="{'col-sm-6': option.textFieldAllowed}"
    >
      <b-input
        placeholder="Podaj źródło"
        :value="value.comment"
        @input="$emit('input',{...value, comment: $event ? $event.trim() : $event})"
      />
    </div>
  </div>
</template>

<script>
import read from "../../rest/read";
import Treeselect from "@hobbytowo/vue-treeselect";
import "@hobbytowo/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {Treeselect},
  props: {
    name: {default: "sourceOfInformationSelect", type: String},
    value: {type: Object, default: () => {return {sourceOfInformation: null, comment: null}}},
    disabled: {default: false, type: Boolean},
    clearable: {default: true, type: Boolean},
    placeholder: {type: String, default: "Wybierz źródło informacji o ŚCZP"},
    multiple: {default: false, type: Boolean},
    state: {default: null, type: Boolean},
    clearOnOpen: {type: Boolean, default: false},
    noOptionsText: {type: String, default: "Brak opcji do wyświetlenia"},
  },
  data() {
    return {
      options: null,
      option: null
    };
  },
  computed: {
    isInvalid() {
      return false === this.state;
    },
  },
  methods: {
    async loadOptions() {
      const {items} = await read("/api/source-of-informations", {});

      this.options = items;
      this.option = this.options.map((item) => {return item.children}).reduce((acc, item) => {return acc.concat(item)})
        .find((item) => {return item.id === this.value.sourceOfInformation});
    },
    update(option) {
      if(option === undefined) {
        this.select(null);
      } else {
        const comment = option.textFieldAllowed ? this.value.comment : null;
        this.$emit("input", {sourceOfInformation: option, comment: comment});
      }
    },
    select(option) {
      this.option = option;
      const comment = option && option.textFieldAllowed ? this.value.comment : null;
      this.$emit("select", {sourceOfInformation: option, comment: comment});
    }
  },
}
</script>
