<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div
          class="page-title-box"
          style="min-height: 70px;"
        >
          <loading-mask
            v-if="loading"
            loading
          />

          <appointment-document-title-box
            v-else
            :access-to-patient-card="hasAccessToPatientCard"
            :appointment-document-id="formData.appointmentDocumentId"
            :appointment-id="appointmentId"
            :branch-id="formData.branchId"
            :close="formData.close"
            :date-info="appointmentDateInfo"
            :is-autosave="isAutosave"
            :is-dirty="formData.isDirty"
            :is-saving="formData.isSaving"
            :patient="formData.patient"
            :printable="formData.printable"
            :read-only="formData.readOnly"
            :return-url="returnUrl"
            :scheduled-date="documentsData[0].scheduledDate"
            :status="formData.status"
            :treatment-name="formData.treatmentName"
            :treatment-benefit-code="formData.treatmentBenefitCode"
            :nfz-appointment-id="formData.nfzAppointmentId"
            :nfz-document-id="formData.nfzDocumentId"
            :edit-finished-document="editFinishedDocument"
            :telephone="formData.telephone"
            :confirm-signer="confirmSigner"
            :worker-data="formData.mainWorker"
            @autosaveToggle="isAutosave = !isAutosave"
            @fetchData="fetchData"
            @save="save"
            @updateBranchId="updateBranchId"
            @allowToUpdate="allowToUpdate"
            @editFinishedDocumentConfirmed="updateFinishedDocument"
          />
          <b-form-checkbox
            v-if="!formData.readOnly"
            v-model="skipReportToNfz"
            class="text-right m-b-20 m-r-10"
          >
            Nie raportuj wizyty do NFZ
          </b-form-checkbox>
          <div v-if="confirmSigner && !canSign">
            <b-alert
              show
              variant="danger"
              class="ml-auto"
            >
              <div v-if="!certificateState.hasCertificate">
                Brak certyfikatu.
              </div>
              <div v-else-if="certificateState.expired">
                Certyfikat wygasł.
              </div>
            </b-alert>
          </div>
          <div v-if="hasSWIADValidationErrors">
            <b-alert
              show
              variant="danger"
              class="text-right ml-auto"
            >
              <error-message
                :errors="formData.errors"
                field="sameAppointment"
              />
              <error-message
                :errors="formData.errors"
                field="missingInsurance"
              />
              <error-message
                :errors="formData.errors"
                field="missingReferral"
              />
              <error-message
                :errors="formData.errors"
                field="missingAdditionalEntitlements"
              />
            </b-alert>
            <b-form-checkbox
              v-model="closeWithSWIADErrors"
              class="text-right m-b-20 m-r-10"
            >
              Zamknij wizytę pomimo błędów
            </b-form-checkbox>
          </div>
          <div v-if="confirmSigner && canSign">
            <b-alert
              show
              warning
              class="text-right ml-auto"
            >
              Autoryzujesz zmiany w przebiegu wizyty?

              <b-form-group
                v-if="!certificateState.withPassword"
                label="Hasło do certyfikatu"
              >
                <b-form-input
                  v-model="password"
                  type="password"
                  autocomplete="new-password"
                  :state="stateFormData('password')"
                />
                <error-message
                  :errors="formData.errors"
                  field="password"
                />
              </b-form-group>
              <b-btn
                variant="primary"
                class="m-l-5"
                @click="save(true)"
              >
                <i class="fas fa-check" />
                Autoryzuj i zamknij
              </b-btn>
            </b-alert>
          </div>
        </div>
      </div>
    </div>
    <template v-if="!loading">
      <error-message :errors="errors" />
      <error-message
        :errors="errors"
        :bordered="true"
        field="continuousStayRange"
      />
      <appointment-hospitalizations
        v-if="formData.patient"
        :patient-id="formData.patient.patientId"
      />
      <appointment-document-cancel-reason
        v-if="formData.cancelReason"
        :cancel-comment="formData.cancelComment"
        :cancel-reason="formData.cancelReason"
        :title="formData.title"
      />
      <appointment-plan :appointment-id="appointmentId" />

      <div class="row">
        <appointment-document-select
          v-if="documentsData.length > 1"
          class="col-lg-3"
          :documents-data="documentsData"
          :selected-document="selectedDocumentIdx"
          @select="changeDocument"
        />

        <div
          :class="{'col-lg-9': documentsData.length > 1 }"
          class="col-12"
        >
          <template v-if="displayDocument">
            <div
              class="text-right m-b-10"
            >
              <is-granted
                component="span"
                :privileges="['SHOW_APPOINTMENT_DOCUMENT_HISTORY']"
                :subject="formData.appointmentDocumentId"
              >
                <b-btn
                  v-b-modal="`appointment-document-history-modal`"
                  class="m-1"
                  size="sm"
                  variant="warning"
                >
                  Poprzednie wersje dokumentu
                </b-btn>
              </is-granted>
              <is-granted
                component="span"
                :privileges="['CHANGE_FINISHED_APPOINTMENT_DOCUMENT']"
                :subject="formData.appointmentDocumentId"
              >
                <b-btn
                  v-if="!editFinishedDocument"
                  size="sm"
                  variant="primary"
                  @click="allowToUpdate"
                >
                  Edytuj Dokument
                </b-btn>
                <b-btn
                  v-if="editFinishedDocument"
                  size="sm"
                  variant="primary"
                  @click="cancelUpdateFinished"
                >
                  Anuluj Edycje Dokumentu
                </b-btn>
              </is-granted>
            </div>
            <div class="row">
              <div class="col-lg-12 col-xl-12">
                <appointment-date-box
                  :start-date="appointmentDate"
                  :end-date="appointmentEndDate"
                  :read-only="formData.readOnly"
                  :errors="formData.errors"
                  @input="updateAppointmentDate"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-xl-8">
                <appointment-nfz-configuration-box
                  :treatment-type-id="formData.treatmentType"
                  :worker-id="formData.mainWorker.workerId"
                  :icd9="formData.icd9"
                  :nfz-worker-types="formData.nfzWorkerTypes"
                  :errors="formData.errors"
                  :read-only="formData.readOnly"
                  :patient="formData.patient"
                  :alternative-treatment-type="formData.alternativeTreatmentType"
                  :appointment-type="formData.appointmentType"
                  :appointment-id="appointmentId"
                  :admission-mode="formData.admissionMode"
                  :beneficiary-category="formData.beneficiaryCategory"
                  :treatment-follow-up="formData.treatmentFollowUp"
                  :branch-id="formData.branchId"
                  :status="formData.status"
                  @changeTreatmentType="changeTreatmentType"
                  @changeNfzWorkerTypes="changeNfzWorkerTypes"
                  @changeIcd9="changeIcd9"
                  @changeAlternativeTreatmentType="changeAlternativeTreatmentType"
                  @changeAppointmentType="changeAppointmentType"
                  @changeAdmissionMode="changeAdmissionMode"
                  @changeBeneficiaryCategory="changeBeneficiaryCategory"
                  @changeTreatmentFollowUp="changeTreatmentFollowUp"
                  @changeDocumentBranch="changeDocumentBranch"
                />
              </div>

              <div class="col-lg-6 col-xl-4">
                <patient-insurance
                  v-if="formData && formData.patient"
                  card-title="Status ubezpieczenia w dniu przebiegu wizyty"
                  :patient-id="formData.patient.patientId"
                  :date="appointmentDateToCheckInsurance"
                />
              </div>
            </div>

            <div
              v-if="displayAdditionalEntitlements"
              class="row"
            >
              <div class="col-12">
                <div class="card-box">
                  <additional-entitlements-document
                    :value="formData.additionalEntitlements"
                    :errors="formData.errors"
                    :read-only="formData.readOnly"
                    @input="updateAdditionalEntitlements"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="formData.isPzk"
              class="row"
            >
              <div class="col-lg-12">
                <pzk-form
                  :pzk="formData.pzk"
                  @input="updatePzkData"
                />
              </div>
            </div>

            <appointment-document-modules
              v-if="!formData.cancelReason"
              :modules="formData.modules"
              :selected-document-idx="selectedDocumentIdx"
              :patient="formData.patient"
              :read-only="formData.readOnly || !changeOwnAppointmentDocumentModules"
              :appointment-document-id="formData.appointmentDocumentId"
              :continuous-stay-id="formData.continuousStayId"
              :appointment-date="appointmentDate"
              :appointment-id="appointmentId"
              :canceled="formData.cancelReason !== null"
              :appointment-end-date="appointmentEndDate"
              :appointment-status="formData.appointmentStatus"
              :visit-at-home="formData.visitAtHome"
              :treatment-type-id="formData.treatmentType"
              @saveCreateRedirect="saveCreateRedirect"
              @updateModule="updateModule"
              @updateContinuousStayId="updateContinuousStayId"
              @updateVisitAtHome="updateVisitAtHome"
            />

            <patient-attachments-card
              v-if="formData.patient && formData.appointmentDocumentId"
              :appointment-id="appointmentId"
              :appointment-document-id="formData.appointmentDocumentId"
              :patient-id="formData.patient.patientId"
              :continuous-stay-id="documentsData[selectedDocumentIdx].continuousStayId"
              :scope="documentsData[selectedDocumentIdx].continuousStay ? ['continuousStay'] : ['ambulatory']"
              hide-appointment-ref-options
            />
          </template>

          <appointment-document-hidden-info
            v-else
            :blocked="formData.blocked"
            :display-medical-content="formData.displayMedicalContent"
          />
        </div>
      </div>

      <div class="d-flex justify-content-between flex-wrap w-100">
        <router-link
          class="btn btn-default mr-auto"
          :to="returnUrl"
        >
          <i class="fa fa-chevron-left" />
          Wróć na {{ hasAccessToPatientCard && formData.patient ? "kartę pacjenta" : "listę wizyt" }}
        </router-link>
        <appointment-document-save-buttons
          class="text-right ml-auto"
          :close="formData.close"
          :is-saving="formData.isSaving"
          :read-only="formData.readOnly"
          :scheduled-date="documentsData[0].scheduledDate"
          :status="formData.status"
          :edit-finished-document="editFinishedDocument"
          :errors="formData.errors"
          :confirm-signer="confirmSigner"
          @save="save"
        />
      </div>

      <finish-appointment-modal
        :value="{timeSpent: formData.scheduledDuration, branchId: formData.branchId}"
        :document-errors="errors"
        :finished-documents="selectedPatientFinishedDocuments"
        :loading="loading"
        @forceFinishAppointment="forceFinishAppointment"
        @hide="forceStopAutosave = false"
        @input="updateFinishAppointmentData"
        @show="forceStopAutosave = true"
      />
      <edit-finished-appointment-modal
        :errors="formData.errors"
        @editFinishedDocumentConfirmed="updateFinishedDocument"
      />
      <appointment-document-history-modal
        :document-id="formData.appointmentDocumentId"
      />
    </template>
  </div>
</template>

<script>
import ErrorMessage from "../../components/Form/ErrorMessage";
import read from "../../rest/read";
import processResponseException from "../../utils/errors/processResponseException";
import update from "../../rest/update";
import {BadRequestException, ForbiddenException} from "../../rest";
import scrollPageToTop from "../../utils/scrollPageToTop";
import AppointmentDocumentSelect from "../../components/Appointment/AppointmentDocumentSelect";
import {isGranted} from "../../security/isGranted";
import parseDate from "../../utils/date/parseDate";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import {mapActions, mapMutations, mapState} from "vuex";
import FinishAppointmentModal from "../../components/Appointment/AppointmentActions/FinishAppointmentModal";
import LoadingMask from "../../components/Loading/LoadingMask";
import PatientAttachmentsCard from "../../components/Patient/PatientCard/Attachment/PatientAttachmentsCard";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import AppointmentPlan from "../../components/Appointment/AppointmentPlan";
import AppointmentDocumentHiddenInfo from "../../components/Appointment/AppointmentDocumentHiddenInfo";
import AppointmentDocumentCancelReason from "../../components/Appointment/AppointmentDocumentCancelReason";
import AppointmentDocumentSaveButtons from "../../components/Appointment/AppointmentDocumentSaveButtons";
import AppointmentHospitalizations from "../../components/Appointment/AppointmentHospitalizations";
import AppointmentDocumentTitleBox from "../../components/Appointment/AppointmentDocumentTitleBox";
import AppointmentDocumentModules from "../../components/Appointment/DocumentModules/AppointmentDocumentModules";
import AppointmentNfzConfigurationBox from "./AppointmentNfzConfigurationBox";
import IsGranted from "../../components/IsGranted";
import {generateUuid} from "../../utils/uuid/generateUuid";
import EditFinishedAppointmentModal from "../../components/Appointment/AppointmentActions/EditFinishedAppointmentModal";
import AppointmentDocumentHistoryModal
  from "../../components/Appointment/AppointmentActions/AppointmentDocumentHistoryModal";
import PatientInsurance from "../../components/Patient/Insurance/PatientInsurance";
import PzkForm from "@/components/InterventionNotification/PzkForm";
import AppointmentDateBox from "./AppointmentDateBox";
import AdditionalEntitlementsDocument from "./AdditionalEntitlementsDocument";

const deferred = debounce((f) => f(), 60000, {maxWait: 100000});

export default {
  name: "Document",
  components: {
    AdditionalEntitlementsDocument,
    AppointmentDateBox,
    PzkForm,
    PatientInsurance,
    AppointmentDocumentHistoryModal,
    EditFinishedAppointmentModal,
    IsGranted,
    AppointmentNfzConfigurationBox,
    AppointmentDocumentModules,
    AppointmentDocumentTitleBox,
    AppointmentDocumentCancelReason,
    AppointmentPlan,
    AppointmentDocumentHiddenInfo,
    AppointmentDocumentSaveButtons,
    AppointmentHospitalizations,
    PatientAttachmentsCard,
    LoadingMask,
    AppointmentDocumentSelect,
    ErrorMessage,
    FinishAppointmentModal,
  },
  mixins: [errorsMixin],
  beforeRouteUpdate(to, from, next) {
    this.changeView(to, from, next);
  },
  beforeRouteLeave(to, from, next) {
    this.changeView(to, from, next);
  },
  props: {
    appointmentId: {required: true, type: String}
  },
  data() {
    return {
      changeOwnAppointmentDocumentModules: false,
      formData: {
        errors: []
      },
      errors: [],
      password: "",
      documentsData: [],
      documentData: null,
      selectedDocumentIdx: 0,
      isAutosave: true,
      loading: true,
      forceStopAutosave: false,
      hasAccessToPatientCard: false,
      editFinishedDocument: false,
      editFinishedDocumentReason: null,
      isPzk: false,
      pzk: null,
      confirmSigner: false,
      certificateState: {},
      skipReportToNfz: false,
      hasSWIADValidationErrors: false,
      closeWithSWIADErrors: false,
      updatedAppointmentStartDate: null,
      updatedAppointmentEndDate: null,
      appointmentDateToCheckInsurance: null,
    };
  },
  computed: {
    displayDocument() {
      const {blocked, displayMedicalContent, medicalContent} = this.formData;
      return !blocked && (!medicalContent || (medicalContent && displayMedicalContent));
    },
    displayAdditionalEntitlements() {
      // see AdmissionModes.ts to understand values [8,9]
      return [8,9].includes(this.formData.admissionMode);
    },
    ...mapState({
      currentUser: state => state.currentUser.user,
    }),
    appointmentDate() {
      return parseDate(this.documentsData[this.selectedDocumentIdx].scheduledDate);
    },
    appointmentEndDate() {
      return parseDate(this.documentsData[this.selectedDocumentIdx].endDate);
    },
    appointmentDateString() {
      return stringifyDate(this.appointmentDate, DATE_FORMAT.DATE);
    },
    appointmentTimeString() {
      return stringifyDate(this.appointmentDate, DATE_FORMAT.TIME);
    },
    appointmentEndTimeString() {
      return stringifyDate(this.appointmentEndDate, DATE_FORMAT.TIME);
    },
    appointmentDateInfo() {
      return `(${this.appointmentDateString} godz. ${this.appointmentTimeString} - ${this.appointmentEndTimeString})`;
    },
    canSign() {
      return this.certificateState.hasCertificate && !this.certificateState.expired;
    },
    hash() {
      return this.$route.hash.substring(1);
    },
    selectedPatientFinishedDocuments() {
      const selectedPatientId = this.formData.patient?.patientId || null;
      return this.documentsData.reduce((list, doc) => {
        const docPatientId = doc.patient?.patientId || null;
        if (doc.status === "finished" && selectedPatientId === docPatientId) {
          list = [...list, doc];
        }
        return list;
      }, []);
    },
    returnUrl() {
      return this.hasAccessToPatientCard && this.formData.patient
        ? `/patient/${this.formData.patient.patientId}/details`
        : "/appointments/list";
    }
  },
  watch: {
    hash(val) {
      if (val.length) {
        this.loadFromHash(val);
      }
    },
    "$route"(to, from) {
      if (to.path !== from.path) {
        this.fetchData();
      }
    },
  },
  async mounted() {
    try {
      await Promise.all([
        this.checkAccessToPatientCard(),
      ]);
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    await this.fetchData();
  },
  methods: {
    ...mapMutations("toastNotification", ["hideToast"]),
    ...mapActions("lastEditedAppointment", [
      "setAppointmentDetails",
      "clearAppointmentDetails",
    ]),
    ...mapActions("toastNotification", ["showToast"]),
    async checkAccessToPatientCard() {
      this.hasAccessToPatientCard = await isGranted(["PATIENT_CARD"]);
    },
    updateAppointmentDate(value){
      if(value){
        this.updatedAppointmentStartDate = value.updatedStartDate;
        this.updatedAppointmentEndDate = value.updatedEndDate;
        this.appointmentDateToCheckInsurance = new Date(value.updatedStartDate);
      }
    },
    checkSWIADValidationErrors(){
      if(!this.formData.errors){
        return false;
      }
      const fields = ["missingInsurance", "sameAppointment", "missingReferral", "missingAdditionalEntitlements"];
      this.hasSWIADValidationErrors = this.formData.errors.find((error) => fields.includes(error.field));
    },
    allowToUpdate(){
      this.formData.readOnly = false;
      this.isAutosave = false;
      this.forceStopAutosave = true;
      this.editFinishedDocument = true;
    },
    async cancelUpdateFinished() {
      this.editFinishedDocument = false;
      await this.fetchData();
    },
    async changeTreatmentType(value){
      if(value) {
        const {tags} = await read(`/api/get-treatment-type-tags/${value}`);
        this.formData.isPzk = tags.includes("pzk");
        this.formData.pzk = null;
      }
      let visitAtHomeIds = [
        "27212071-715b-4c34-84ec-30fbc458bb77",
        "726e5928-82ba-4269-a10e-f81ad653c07d",
        "059855c1-309a-4021-b645-c63376f8c660"
      ];
      this.formData.visitAtHome = this.formData.appointmentStatus === "opened" && visitAtHomeIds.includes(value);
      this.formData.treatmentType = value;
      this.formData.isDirty = true;
    },
    changeNfzWorkerTypes(value){
      this.formData.nfzWorkerTypes = value;
      this.formData.isDirty = true;
    },
    changeIcd9(value){
      this.formData.icd9 = value;
      this.formData.isDirty = true;
    },
    changeAlternativeTreatmentType(value){
      this.formData.alternativeTreatmentType = value;
      this.formData.isDirty = true;
    },
    changeAppointmentType(value) {
      this.formData.appointmentType = value;
      this.formData.isDirty = true;
    },
    changeAdmissionMode(value) {
      this.formData.admissionMode = value;
      this.formData.isDirty = true;
    },
    changeBeneficiaryCategory(value) {
      this.formData.beneficiaryCategory = value;
      this.formData.isDirty = true;
    },
    changeTreatmentFollowUp(value) {
      this.formData.treatmentFollowUp = value;
      this.formData.isDirty = true;
    },
    changeDocumentBranch(value) {
      this.formData.branchId = value;
      this.formData.isDirty = true;
    },
    updateAdditionalEntitlements(value) {
      this.formData.additionalEntitlements = value;
      this.formData.isDirty = true;
    },
    async fetchData() {
      this.loading = true;
      const {documents} = await read(`/api/appointment/${this.appointmentId}/appointment`);
      this.documentsData = documents.map(doc => ({
        ...doc,
        additionalEntitlements: {
          ...doc.additionalEntitlements,
          createdDate: null !== doc.additionalEntitlements.createdDate
            ? new Date(doc.additionalEntitlements.createdDate) : null,
          validDate: null !== doc.additionalEntitlements.validDate
            ? new Date(doc.additionalEntitlements.validDate) : null
        },
        readOnly: true,
        patientCardUrl: "/appointments/list",
        indicators: [],
        isSaving: false,
        close: false,
        isDirty: false,
        treatmentFollowUp: doc.treatmentFollowUp === null 
          ? "specialist_clinic"
          : doc.treatmentFollowUp
      }));
      if (!this.$route.hash) {
        await this.$router.replace({
          hash: `#appointmentDocumentId=${this.documentsData[this.selectedDocumentIdx].appointmentDocumentId}`,
        });
      } else {
        this.loadFromHash(this.hash);
      }
      this.documentData = this.documentsData[this.selectedDocumentIdx];
      this.formData = {...this.documentData};
      this.appointmentDateToCheckInsurance = new Date(this.documentData.scheduledDate)
      await this.checkPermissions();
      this.clearAppointmentDetails();
      if (this.documentsData[0].appointmentStatus === "opened") {
        this.setAppointmentDetails({
          id: this.appointmentId,
          treatmentTypeName: this.lastEditedAppointmentTreatmentName(),
          datesString: this.appointmentDateInfo,
        });
      }
      this.loading = false;
    },
    loadFromHash(hash) {
      const splitted = hash.split("=");
      if (splitted.length === 2 && splitted[0] === "appointmentDocumentId" && splitted[1].length) {
        const documentIndex = this.documentsData.map((doc) => {return doc.appointmentDocumentId}).indexOf(splitted[1]);
        if (this.selectedDocumentIdx !== documentIndex) {
          this.changeDocument(documentIndex);
        }
      }
    },
    async checkPermissions() {
      this.formData.readOnly = !(await isGranted("CHANGE_APPOINTMENT_DOCUMENT", this.formData.appointmentDocumentId));
      this.changeOwnAppointmentDocumentModules = await isGranted("CHANGE_OWN_APPOINTMENT_DOCUMENT_MODULES",this.formData.appointmentDocumentId);

      this.documentsData.forEach(async (value, index) => {
        this.documentsData[index].readOnly =
          !(await isGranted("CHANGE_APPOINTMENT_DOCUMENT", value.appointmentDocumentId));
      });
    },
    updateFinishedDocument(value) {
      this.editFinishedDocumentReason = value;
      this.save(true);
    },
    async save(close) {
      this.formData.files = [];
      this.formData.errors = [];
      this.hasSWIADValidationErrors = false;
      this.errors = [];
      if(!this.confirmSigner && close) {
        const certificateState = await read("/api/certificate-state");
        this.confirmSigner = true;
        this.certificateState = certificateState;
        return;
      }

      this.formData.isSaving = true;
      this.formData.close = close;
      this.resetErrors();

      try {
        await update(`/api/appointment/${this.appointmentId}/appointment`, {
          appointmentDocumentId: this.formData.appointmentDocumentId,
          modules: this.formData.modules,
          type: this.formData.type,
          name: this.formData.name,
          patient: this.formData.patient,
          mainWorker: this.formData.mainWorker,
          readOnly: close,
          timeSpent: (this.formData.timeSpent !== undefined)
            ? this.formData.timeSpent
            : this.formData.scheduledDuration,
          branchId: this.formData.branchId,
          treatmentType: this.formData.treatmentType,
          nfzWorkerTypes: this.formData.nfzWorkerTypes,
          icd9: this.formData.icd9,
          appointmentType: this.formData.appointmentType,
          admissionMode: this.formData.admissionMode,
          beneficiaryCategory: this.formData.beneficiaryCategory,
          treatmentFollowUp: this.formData.treatmentFollowUp,
          alternativeTreatmentType: this.formData.alternativeTreatmentType,
          editFinishedDocument: this.editFinishedDocument,
          editFinishedDocumentReason: "" !== this.editFinishedDocumentReason ? this.editFinishedDocumentReason : null,
          finishedDocumentEditId: this.editFinishedDocument ? generateUuid() : null,
          isPzk: this.formData.isPzk,
          pzk: this.formData.pzk,
          password: this.password,
          withPassword: this.certificateState.withPassword,
          skipReportToNfz: this.skipReportToNfz,
          closeWithSWIADErrors: this.closeWithSWIADErrors,
          updatedAppointmentStartDate: this.updatedAppointmentStartDate,
          updatedAppointmentEndDate: this.updatedAppointmentEndDate,
          benefitDate: this.formData.benefitDate,
          visitAtHome: this.formData.visitAtHome,
          additionalEntitlements: this.displayAdditionalEntitlements ?
            {
              ...this.formData.additionalEntitlements,
              createdDate: null !== this.formData.additionalEntitlements.createdDate
                ? stringifyDate(this.formData.additionalEntitlements.createdDate)
                : null,
              validDate: null !== this.formData.additionalEntitlements.validDate
                ? stringifyDate(this.formData.additionalEntitlements.validDate)
                : null,
            } : null
        });
        this.confirmSigner = false;
        this.editFinishedDocument = false;
        this.formData.readOnly = close;
        if (close) {
          this.formData.status = "finished";
        }
        close && this.documentsData.every(doc =>
          doc.appointmentDocumentId === this.formData.appointmentDocumentId || doc.readOnly)
          && this.clearAppointmentDetails();
        this.formData.isDirty = false;

        // eslint-disable-next-line max-len
        const wasTreatmentTypeChanged = this.formData.treatmentType !== this.documentsData[this.selectedDocumentIdx].treatmentType
        if (close ||
          wasTreatmentTypeChanged // reload data to check new treatmentBenefitCode
        ) {
          await this.fetchData()
        }

        this.documentsData[this.selectedDocumentIdx] = {
          ...this.formData,
          isSaving: false,
        };

        if (this.documentsData.every(doc => doc.status === "finished")) {
          this.formData.printable = true;
        }
        this.closeConfirmDocumentModal();
        this.showToast({
          message: "Zmiany zostały zapisane",
          variant: "success",
        });
      } catch (exception) {
        if (exception instanceof BadRequestException) {
          const errors = exception.errors;
          this.hasBadRequestErr(errors, "continuousStayRange");
          const hasBranchIdErr = this.hasBadRequestErr(errors, "branchId");
          const hasTimeSpentErr = this.hasBadRequestErr(errors, "timeSpent");

          if (!hasBranchIdErr && !hasTimeSpentErr) {
            this.closeConfirmDocumentModal();
          }

          this.formData.modules = this.formData.modules.map((module, idx) => ({
            ...module, errors: errors[idx]
          }));

          this.formData.errors = errors.filter(function(err){
            if(null !== err){
              return err;
            }
          });

          this.showToast({
            message: "Formularz zawiera błędy.\nUzupełnij dane przed zamknięciem wizyty. ",
            variant: "danger",
          });
        }
        else if(exception instanceof ForbiddenException){
          this.closeConfirmDocumentModal();
          this.showToast({
            message: "Wystapił błąd podczas zapisywania dokumentu wizyty.\nDokument odświeży się w ciągu 5 sekund.",
            variant: "danger",
          });
          setTimeout(async ()=>{
            this.hideToast();
            await this.fetchData();
          }, 5000);
        } else {
          this.errors = processResponseException(exception);
          this.showToast({
            message: "Wystąpił błąd.\nZmiany nie zostały zapisane",
            variant: "danger",
          });
        }
        this.checkSWIADValidationErrors();
      }
      this.formData.isSaving = false;
      this.formData.close = false;
    },
    async changeDocument(docIdx) {
      this.selectedDocumentIdx = docIdx;
      this.formData = {...this.documentsData[docIdx]};
      if(!this.displayDocument){
        this.formData.printable = false;
      }
      this.editFinishedDocument = false;
    },
    async changeView(to, from, next) {
      if(!this.editFinishedDocument){
        this.forceStopAutosave = true;
        if (this.formData.isDirty) {
          await this.save(false);
        }
      }
      next();
    },
    reload() {
      window.location.reload(true);
    },
    resetErrors() {
      this.formData.modules = this.formData.modules.map((module) => ({...module, errors: null}));
      this.hideToast();
    },
    updateBranchId(branchId) {
      this.formData.branchId = branchId;
    },
    updateModule({module, index}) {
      this.formData.modules = this.formData.modules.map((m, idx) => {
        if (index === idx) {
          if (!isEqual(m, module)) {
            this.formData.isDirty = true;
            this.hideToast();
          }
          return module;
        }
        return m;
      });
      if (this.isAutosave && this.formData.isDirty && !this.formData.readOnly) {
        this.forceStopAutosave = false;
        deferred(this.handleAutosave);
      }
    },
    async updateContinuousStayId(continuousStayId) {
      this.formData.continuousStayId = continuousStayId;
      if (!this.formData.readOnly) {
        await this.save(false);
      }
    },
    async handleAutosave() {
      if (this.forceStopAutosave) {
        return;
      }
      if (!this.formData.readOnly) {
        await this.save(false);
      }
    },
    async saveCreateRedirect(redirectUrl) {
      await this.save(false);
      await this.$router.push(redirectUrl);
    },
    lastEditedAppointmentTreatmentName() {
      const doc = this.documentsData.find(doc => doc.mainWorker.workerId === this.currentUser.workerId);
      return doc ? doc.treatmentName : this.documentsData[0].treatmentName;
    },
    closeConfirmDocumentModal(){
      this.$bvModal.hide("force-finish-appointment");
    },
    async forceFinishAppointment() {
      scrollPageToTop();
      await this.save(true);
    },
    updateFinishAppointmentData({timeSpent, branchId}) {
      this.formData.timeSpent = timeSpent;
      this.formData.branchId = branchId;
    },
    updatePzkData(data) {
      this.formData.pzk = data;
    },
    hasBadRequestErr(errors, field) {
      const error = errors.find(
        error => error !== null && error.field === field
      );
      if (error !== undefined) {
        this.errors.push(error);
      }
      return !!error;
    },
    stateFormData(field) {
      return this.formData.errors.find((error) => error.field === field) ? false : null;
    },
    updateVisitAtHome(value) {
      this.formData.visitAtHome = value;
    }
  },
};
</script>
