<template>
  <div>
    <div class="d-flex justify-content-between align-items-start">
      <span class="font-16 mr-1">
        {{ treatmentName }} {{ dateInfo }} {{ telephone ? 'Telefoniczna' : '' }}
      </span>
      <div class="text-right ml-auto">
        <b-btn
          v-if="!readOnly && !editFinishedDocument"
          :variant="isAutosave ? 'success' : 'warning'"
          class="m-1"
          size="sm"
          @click="$emit('autosaveToggle')"
        >
          {{ isAutosave ? 'Auto zapis Wł.' : 'Auto zapis Wył.' }}
        </b-btn>
        <b-btn
          v-if="!readOnly && !editFinishedDocument"
          :disabled="isSaving && close"
          class="m-1"
          size="sm"
          @click="$emit('save', false)"
        >
          <i
            :class="isSaving ? 'fa-spin fa-spinner' : (isDirty ? 'fa-history' : 'fa-check')"
            class="fas"
          />
          {{ isDirty ? 'Niezapisane zmiany' : 'Wszystko zapisane' }}
        </b-btn>
        <is-granted
          component="fragment"
          :privileges="['DOWNLOAD_HL7_CONSULTATION_NOTE']"
          :subject="appointmentDocumentId"
        >
          <a
            class="btn btn-sm btn-primary m-1"
            download="wizyta.hl7.xml"
            :href="`/api/appointment/document/${appointmentDocumentId}/hl7`"
          >
            Pobierz dokument HL7
          </a>
        </is-granted>
        <is-granted
          v-if="!nfzConfiguration"
          :privileges="['CHANGE_BRANCH']"
          :subject="appointmentId"
          component="fragment"
        >
          <b-btn
            v-b-modal.change-branch-modal
            class="m-1"
            size="sm"
            variant="primary"
          >
            <i class="fas fa-cog" />
            Zmień komórkę organizacyjną
          </b-btn>
        </is-granted>
        <is-granted
          class="text-right m-b-10"
          component="fragment"
          :privileges="['CHANGE_APPOINTMENT_DATE']"
          :subject="appointmentId"
        >
          <b-btn
            v-b-modal="`changeAppointmentDate-${appointmentId}`"
            class="m-1"
            size="sm"
            variant="primary"
          >
            Zmień datę wizyty
          </b-btn>
        </is-granted>
        <b-btn
          v-if="patient"
          v-b-modal="`finished-appointments-modal`"
          class="m-1"
          size="sm"
          variant="primary"
        >
          Poprzednie wizyty pacjenta
        </b-btn>
        <is-granted
          v-if="!readOnly && !editFinishedDocument"
          :privileges="['CANCEL_APPOINTMENT']"
          :subject="appointmentId"
          component="fragment"
        >
          <b-btn
            v-b-modal.cancel-reason-modal
            class="m-1"
            size="sm"
            variant="danger"
          >
            <i class="fas fa-ban" />
            Odwołaj wizytę
          </b-btn>
        </is-granted>
        <a
          v-if="printable"
          :href="`/api/appointment/document/${appointmentDocumentId}/print`"
          class="btn btn-sm btn-secondary m-1"
          target="_blank"
        >
          <i class="fas fa-print" />
          Drukuj kartę informacyjną
        </a>
      </div>
    </div>
    <div v-if="nfzConfiguration">
      <p
        v-if="null !== nfzAppointmentId"
        class="font-16 font-bold mr-1"
      >
        Nr wizyty: {{ nfzDocumentId }}
      </p>
      <p
        v-if="null !== nfzAppointmentId && treatmentBenefitCode && isSessionTreatmentType"
        class="font-16 font-bold mr-1"
      >
        Nr sesji: {{ nfzAppointmentId }}
      </p>
    </div>
    <div class="d-flex justify-content-between align-items-end">
      <div>
        <h4
          v-if="patient"
          class="mr-1"
        >
          <component
            :is="accessToPatientCard ? 'router-link' : 'span'"
            :to="returnUrl"
          >
            {{ patient.displayName }}
          </component>
          <is-granted
            :privileges="['IPZ_LIST']"
            component="fragment"
          >
            <patient-bmi-alert
              class="ml-1"
              :patient-id="patient.patientId"
            />
          </is-granted>
        </h4>
        <p>
          {{ workerData.name }}
          {{ workerData.surname }}
        </p>
      </div>
      <appointment-document-save-buttons
        class="text-right ml-auto"
        :read-only="readOnly"
        :is-saving="isSaving"
        :close="close"
        :status="status"
        :scheduled-date="scheduledDate"
        :edit-finished-document="editFinishedDocument"
        :confirm-signer="confirmSigner"
        @editFinishedDocumentConfirmed="$emit('editFinishedDocumentConfirmed', $event)"
        @save="$emit('save', $event)"
      />
    </div>
    <change-branch-modal
      :appointment-document-id="appointmentDocumentId"
      :branch-id="branchId"
      @changed="$emit('updateBranchId', $event)"
    />
    <finished-appointments-list-modal
      v-if="patient"
      :patient-id="patient.patientId"
      :patient-display-name="patient.displayName"
      :current-appointment-id="appointmentId"
    />
    <cancel-reason-modal
      :appointment-id="appointmentId"
      title="Czy anulować wizytę?"
      @appointmentCanceled="$emit('fetchData')"
    />
    <change-appointment-date
      :appointment-id="appointmentId"
      :status="status"
      with-reason
      title="Edycja daty wizyty"
      @appointmentUpdated="$emit('fetchData')"
    />
  </div>
</template>

<script>
import IsGranted from "../IsGranted";
import ChangeBranchModal from "./AppointmentActions/ChangeBranchModal";
import FinishedAppointmentsListModal from "./AppointmentActions/FinishedAppointmentsListModal";
import CancelReasonModal from "./AppointmentActions/CancelReasonModal";
import AppointmentDocumentSaveButtons from "./AppointmentDocumentSaveButtons";
import PatientBmiAlert from "@/components/Patient/PatientBmiAlert";
import {mapState} from "vuex";
import ChangeAppointmentDate from "./AppointmentUnificationOptions/ChangeAppointmentDate";
import {AppointmentTreatmentBenefitCode} from "../../types/Appointment"

export default {
  name: "AppointmentDocumentTitleBox",
  components: {
    ChangeAppointmentDate,
    PatientBmiAlert,
    AppointmentDocumentSaveButtons,
    CancelReasonModal,
    FinishedAppointmentsListModal,
    ChangeBranchModal,
    IsGranted
  },
  props: {
    accessToPatientCard: {type: Boolean, required: true},
    appointmentDocumentId: {type: String, required:true},
    appointmentId: {type: String, required: true},
    branchId: {type: String, default: null},
    close: {type: Boolean, required: true},
    dateInfo: {type: String, required: true},
    isAutosave: {type: Boolean, required: true},
    isDirty: {type: Boolean, required: true},
    isSaving: {type: Boolean, required: true},
    patient: {type: Object, default: null},
    printable: {type: Boolean, required: true},
    readOnly: {type: Boolean, required: true},
    returnUrl: {type: String, required: true},
    scheduledDate: {type: String, required: true},
    status: {type: String, required: true},
    treatmentName: {type: String, required: true},
    treatmentBenefitCode: {type: String, default: null},
    nfzAppointmentId: {type: Number, default: null},
    nfzDocumentId: {type: Number, default: null},
    editFinishedDocument: {type: Boolean, required: true},
    telephone: {type: Boolean, default: false},
    confirmSigner: {type: Boolean, default: false},
    workerData: {type: Object, default: null}
  },
  computed: {
    ...mapState({
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration
    }),
    isSessionTreatmentType() {
      return [
        AppointmentTreatmentBenefitCode.SESSION,
      ].includes(this.treatmentBenefitCode)
    }
  }
}
</script>
