<template>
  <component
    :is="controls? 'form' : 'div'"
    @submit.prevent="submit"
  >
    <b-form-group
      id="identificationDocumentType"
      label="Typ dokumentu toższamości"
      label-for="identificationDocumentTypeSelect"
    >
      <identification-document-select
        id="identificationDocumentTypeSelect"
        v-model="identificationDocumentForm.type"
        :state="state('identificationDocument.type')"
        :clearable="false"
      />
      <error-message
        :errors="errors"
        field="identificationDocument.type"
      />
    </b-form-group>
    <b-form-group
      id="identificationDocumentNumber"
      label="Numer dokumentu tożsamości"
      label-for="identificationDocumentNumberInput"
    >
      <b-form-input
        id="identificationDocumentNumberInput"
        v-model.trim="identificationDocumentForm.number"
        :state="state('identificationDocument.number')"
        placeholder="Wpisz numer dokumentu tożsamości"
      />
      <error-message
        :errors="errors"
        field="identificationDocument.number"
      />
    </b-form-group>
    <error-message :errors="errors" />
    <div
      v-if="controls"
      class="modal-footer"
    >
      <button
        type="button"
        class="btn btn-secondary"
        @click="cancel"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        type="submit"
        class="btn btn-primary"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </component>
</template>

<script>
import IdentificationDocumentSelect from "../Form/Select/IdentificationDocumentSelect";
import ErrorMessage from "../Form/ErrorMessage";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import update from "../../rest/update";
import processResponseException from "../../utils/errors/processResponseException";
import read from "../../rest/read";

export default {
  name: "IdentificationDocumentDataForm",
  components: {IdentificationDocumentSelect, ErrorMessage},
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true},
    identificationDocument: {type: Object, default: null},
    controls: {type: Boolean, default: true},
  },
  data() {
    return {
      identificationDocumentForm: this.identificationDocument ? {...this.identificationDocument} : {},
      loading: false,
    };
  },
  watch: {
    identificationDocument() {
      this.identificationDocumentForm = this.identificationDocument ? {...this.identificationDocument} : {};
      this.errors = [];
    },
    async patientId() {
      this.errors = [];
      await this.loadIdentificationDocumentData();
    },
  },
  async mounted() {
    if (!this.identificationDocument) {
      await this.loadIdentificationDocumentData();
    }
  },
  methods: {
    async loadIdentificationDocumentData() {
      this.loading = true;
      try {
        this.identificationDocumentForm = await read(`/api/patients/${this.patientId}/identification_document`);
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    async submit() {
      this.loading = true;
      try {
        await update(`/api/patients/${this.patientId}/identification_document`, {
          identificationDocument: {...this.identificationDocumentForm}
        });
        this.identificationDocumentForm = await read(`/api/patients/${this.patientId}/identification_document`);
        this.$emit("updateIdentificationDocumentData", this.identificationDocumentForm);
        this.cancel();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    cancel() {
      this.errors = [];
      this.$emit("cancel");
    },
  }
}
</script>

<style scoped>

</style>
