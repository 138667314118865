











































































const MAX_FILE_SIZE_MB = 5;
const MAX_FILE_SIZE = {
  megaBytes: MAX_FILE_SIZE_MB,
  bytes: MAX_FILE_SIZE_MB * 1024 * 1024 *5,
};

import {Component, Mixins, Watch} from "vue-property-decorator";
import ErrorMessage from "@/components/Form/ErrorMessage.vue";
import ErrorsMixin from "../../../mixins/ErrorsMixin";
import processResponseException from "../../../utils/errors/processResponseException";
import postDataAndFiles from "../../../rest/postDataAndFiles";
import AdvancedFileSelect from "../../File/AdvancedFileSelect.vue";

@Component({
  name: "AppointmentDocumentStatisticsFileErrorsModal",
  components: {ErrorMessage, AdvancedFileSelect}
})

export default class AppointmentDocumentStatisticsFileErrorsModal extends Mixins(ErrorsMixin) {

  hasTemplate: boolean = false;
  isSaving: boolean = false;
  errorsFile: File|null = null;
  sizeError: string = "";
  successInfo: boolean = false;

  @Watch("errorsFile") onErrorsFileChange(file: File|null): void {
    this.hasTemplate = null !== file;
  }
  get modalId(): string {
    return "load-errors-file";
  }

  get title(): string {
    return "Wczytaj plik z błędami";
  }

  closeModal(): void {
    this.errorsFile = null;
    this.successInfo = false;
    this.$bvModal.hide(this.modalId);
  }

  addFile(file: File): void {
    this.successInfo = false;
    this.errorsFile = file;
    this.sizeError = "";
    if (file.size > (MAX_FILE_SIZE.bytes)) {
      this.sizeError = `Plik ${file.name} jest zbyt duży.
        Maksymalna wielkość pliku to ${MAX_FILE_SIZE.megaBytes}MB`;
    }
  }

  removeFile(): void {
    this.sizeError = "";
    this.errorsFile = null;
  }

  async submit(): Promise<void> {
    this.successInfo = false;
    this.errors = [];
    this.isSaving = true;

    const data = new FormData();
    data.append("errorsFile", this.errorsFile as Blob || undefined);
    try {
      await postDataAndFiles("/api/document-statistics/import-errors-form-file", data);
      this.errorsFile = null;
      this.successInfo = true;
    } catch(e) {
      this.errors = processResponseException(e);
    }

    this.isSaving = false;
  }
}
