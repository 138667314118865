
const maritalStatus = [
  {
    value: "single",
    label: "kawaler/panna",
  },
  {
    value: "informal-relationship",
    label: "związek nieformalny",
  },
  {
    value: "married",
    label: "żonaty/zamężna",
  },
  {
    value: "in-separation",
    label: "w separacji",
  },
  {
    value: "divorced",
    label: "rozwiedziony/a",
  },
  {
    value: "widowed",
    label: "wdowiec/wdowa",
  },
];

const education = [
  {
    value: "basic",
    label: "podstawowe",
  },
  {
    value: "gymnasium",
    label: "gimnazjalne",
  },
  {
    value: "secondary",
    label: "średnie",
  },
  {
    value: "vocational",
    label: "zawodowe",
  },
  {
    value: "higher",
    label: "wyższe",
  },
];

const sourceOfIncome = [
  {
    value: "full-time-job",
    label: "praca etatowa",
  },
  {
    value: "odd-job",
    label: "praca dorywcza",
  },
  {
    value: "self-employed",
    label: "samodzielna działalność gospodarcza",
  },
  {
    value: "pension",
    label: "emerytura",
  },
  {
    value: "family",
    label: "rodzina",
  },
  {
    value: "social-assistance",
    label: "pomoc społeczna: renta, zasiłek",
  },
  {
    value: "other",
    label: "inne",
  },
];

const residentialSituation = [
  {
    value: "alone",
    label: "samotnie",
  },
  {
    value: "spouse",
    label: "z mężem/żoną",
  },
  {
    value: "partner",
    label: "z partnerem/partnerką",
  },
  {
    value: "kids",
    label: "z dziećmi",
  },
  {
    value: "parents",
    label: "z rodzicem/rodzicami",
  },
  {
    value: "other-relatives",
    label: "z innymi krewnymi",
  },
  {
    value: "not-related",
    label: "z osobami nie spokrewnionymi (np. akademik, stancja)",
  },
  {
    value: "social-help",
    label: "dom pomocy społ. / inne ośrodki",
  },
];

const treatedPsychiatrically = [
  {
    value: "yes",
    label: "Tak",
  },
  {
    value: "no",
    label: "Nie",
  },
];

const addictionsGeneral = [
  {
    value: "lack",
    label: "Brak",
  },
  {
    value: "active-addiction",
    label: "Obecne czynne uzależnienie",
  },
  {
    value: "takes-abstinence",
    label: "Uzależnienie w wywiadzie; utrzymuje abstynencję od:",
  },
];

const addictionsActive = [
  {
    value: "cigarettes",
    label: "Papierosy",
  },
  {
    value: "alcohol",
    label: "Alkohol",
  },
  {
    value: "sedative-hypnotics",
    label: "Leki uspokajające/nasenne",
  },
  {
    value: "drugs",
    label: "Narkotyki",
  },
  {
    value: "behavioral-addictions",
    label: "Uzależnienia behawioralne",
  }
]

const lackPresent = [
  {
    value: "lack",
    label: "Brak",
  },
  {
    value: "present",
    label: "Obecne",
  }
];

const lackPresentSuspicion = [
  {
    value: "lack",
    label: "Brak",
  },
  {
    value: "present",
    label: "Obecne",
  },
  {
    value: "suspicion",
    label: "Podejrzenie obecności",
  }
];

const yesNo4Weeks = [
  {
    value: "no",
    label: "Nie",
  },
  {
    value: "yes",
    label: "Tak",
  },
  {
    value: "in4weeks",
    label: "Tak, w ciągu ostatnich 4 tygodni",
  }
]

const attemptedSuicide   = [
  {
    value: "no",
    label: "Nie",
  },
  {
    value: "yesOne",
    label: "Tak, 1x",
  },
  {
    value: "moreThanOne",
    label: "Więcej niż 1x"
  },
  {
    value: "in4weeks",
    label: "Tak, w ciągu ostatnich 4 tygodni",
  }
];

const yesNo = [
  {
    value: "no",
    label: "Nie",
  },
  {
    value: "yes",
    label: "Tak",
  },
];

const contactWithPatient = [
  {
    value: "correct",
    label: "Prawidłowy",
  },
  {
    value: "difficult",
    label: "Utrudniony",
  },
  {
    value: "lack",
    label: "Brak",
  },
  {
    value: "other",
    label: "Inne"
  }
];

const autopsychicOrientation = [
  {
    value: "correct",
    label: "Prawidłowa",
  },
  {
    value: "disrupted",
    label: "Zaburzona",
  },
  {
    value: "other",
    label: "Inne"
  }
];

const allopsychicOrientation = [
  {
    value: "correct",
    label: "Prawidłowa",
  },
  {
    value: "disrupted",
    label: "Zaburzona",
  },
  {
    value: "other",
    label: "Inne"
  }
];

const eyeContact = [
  {
    value: "normal",
    label: "Normalny",
  },
  {
    value: "intense",
    label: "Intensywny",
  },
  {
    value: "avoid",
    label: "Unikający",
  },
  {
    value: "other",
    label: "Inne"
  }
];

const appearance = [
  {
    value: "well-kept",
    label: "Zadbany",
  },
  {
    value: "neglected",
    label: "Zaniedbany",
  },
  {
    value: "other",
    label: "Inne",
  },
];

const physique = [
  {
    value: "underweight",
    label: "Niedowaga",
  },
  {
    value: "normal",
    label: "Normalna",
  },
  {
    value: "Overweight",
    label: "Nadwaga",
  },
  {
    value: "obesity",
    label: "Otyłość",
  },
  {
    value: "other",
    label: "Inne"
  }
];

const attention = [
  {
    value: "correct",
    label: "Prawidłowa",
  },
  {
    value: "attention-disturbance",
    label: "Zaburzenia uwagi obecne",
  },
  {
    value: "other",
    label: "Inne"
  }
];

const speech = [
  {
    value: "correct",
    label: "Prawidłowa",
  },
  {
    value: "verbiage",
    label: "Wielomówność",
  },
  {
    value: "taciturnity",
    label: "Małomówność",
  },
  {
    value: "other",
    label: "Inne",
  },
];

const psychomotorRestlessness = [
  {
    value: "lack",
    label: "Brak",
  },
  {
    value: "present",
    label: "Obecny",
  },
  {
    value: "other",
    label: "Inne"
  }
];

const drive = [
  {
    value: "in-normal",
    label: "W normie",
  },
  {
    value: "intensified",
    label: "Wzmożony",
  },
  {
    value: "reduced",
    label: "Obniżony",
  },
  {
    value: "other",
    label: "Inne"
  }
];

const mood = [
  {
    value: "in-normal",
    label: "W normie",
  },
  {
    value: "elevated",
    label: "Podwyższony",
  },
  {
    value: "reduced",
    label: "Obniżony",
  },
  {
    value: "changeable",
    label: "Zmienny",
  },
  {
    value: "other",
    label: "Inne"
  }
];

const affect = [
  {
    value: "correct-adequate",
    label: "Prawidłowy/adekwatny",
  },
  {
    value: "inadequate",
    label: "Nieadekwatny",
  },
  {
    value: "other",
    label: "Inne"
  }
];

const anxiety = [
  {
    value: "lack",
    label: "Brak",
  },
  {
    value: "paroxysmal",
    label: "Napadowy",
  },
  {
    value: "free-flowing",
    label: "Wolnopłynący",
  },
  {
    value: "other",
    label: "Inne"
  }
];

const compulsions = [
  {
    value: "lack",
    label: "Brak",
  },
  {
    value: "present",
    label: "Obecne",
  },
  {
    value: "other",
    label: "Inne"
  }
];

const delusions = [
  {
    value: "lack",
    label: "Brak",
  },
  {
    value: "present",
    label: "Obecne",
  },
  {
    value: "suspicion-presence",
    label: "Podejrzenie obecności",
  },
  {
    value: "other",
    label: "Inne"
  }
];

const blockingFormOfThinking = [
  {
    value: "lack",
    label: "Brak",
  },
  {
    value: "distraction-nconsistent-threads",
    label: "Rozkojarzenie/niespójne wątki",
  },
  {
    value: "lengthiness-meticulousness",
    label: "Rozwlekłość/drobiazgowość",
  },
  {
    value: "other",
    label: "Inne",
  }
]

const memory = [
  {
    value: "correct",
    label: "Prawidłowa",
  },
  {
    value: "memory-impairment",
    label: "Zaburzenia pamięci obecne",
  },
  {
    value: "other",
    label: "Inne"
  }
];

const view = [
  {
    value: "full",
    label: "Pełny",
  },
  {
    value: "partial",
    label: "Częściowy",
  },
  {
    value: "no-insight",
    label: "Brak wglądu w chorobę",
  },
  {
    value: "other",
    label: "Inne"
  }
];

const hallucinations = [
  {
    value: "lack",
    label: "Brak",
  },
  {
    value: "auditory",
    label: "Obecne – słuchowe",
  },
  {
    value: "visual",
    label: "Obecne – wzrokowe",
  },
  {
    value: "other",
    label: "Obecne – inne",
  },
];

const sleepDisturbance = [
  {
    value: "lack",
    label: "Brak",
  },
  {
    value: "present",
    label: "Obecne",
  },
  {
    value: "periodic-occasional",
    label: "Okresowe/sporadyczne"
  },
  {
    value: "other",
    label: "Inne"
  }
];

const appetite = [
  {
    value: "in-normal",
    label: "W normie",
  },
  {
    value: "intensified",
    label: "Wzmożony",
  },
  {
    value: "reduced",
    label: "Obniżony",
  },
  {
    value: "changeable",
    label: "Zmienny"
  },
  {
    value: "other",
    label: "Inne"
  }
];

const items = {
  "maritalStatus" : maritalStatus,
  "education" : education,
  "sourceOfIncome" : sourceOfIncome,
  "residentialSituation" : residentialSituation,
  "treatedPsychiatrically" : treatedPsychiatrically,
  "addictionsGeneral" : addictionsGeneral,
  "addictionsActive" : addictionsActive,
  "lackPresent" : lackPresent,
  "lackPresentSuspicion" : lackPresentSuspicion,
  "yesNo4Weeks" : yesNo4Weeks,
  "attemptedSuicide" : attemptedSuicide,
  "yesNo" : yesNo,
  "contactWithPatient" : contactWithPatient,
  "autopsychicOrientation" : autopsychicOrientation,
  "allopsychicOrientation" : allopsychicOrientation,
  "eyeContact" : eyeContact,
  "appearance" : appearance,
  "physique" : physique,
  "attention" : attention,
  "speech" : speech,
  "psychomotorRestlessness" : psychomotorRestlessness,
  "drive" : drive,
  "mood" : mood,
  "affect" : affect,
  "anxiety" : anxiety,
  "compulsions" : compulsions,
  "delusions" : delusions,
  "blockingFormOfThinking" : blockingFormOfThinking,
  "memory" : memory,
  "view" : view,
  "hallucinations" : hallucinations,
  "sleepDisturbance" : sleepDisturbance,
  "appetite" : appetite
};

export default items;
