











































































































import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import read from "../../../rest/read";
import Card from "../../Card.vue";
import PaginationDescription from "../../Pagination/PaginationDescription.vue";
import type {Pagination} from "../../../types/Pagination";
import Tooltip from "../../Tooltip.vue";
import I18n from "../../i18n.vue";
import type {PrescriptionListFilters} from "../../../types/Prescription";

interface Prescription {
  packageId: string,
  type: string,
  number: string,
  createdAt: string,
  drug: string,
  status: string,
  workerName: string,
  lastChangeStatusDate: string | null,
  fileId: string | null,
  accessCode: string
}

interface PrescriptionsResponse {
  items: Array<Prescription> | null,
  pagination: Pagination
}

@Component({components: {I18n, Card, PaginationDescription, Tooltip}})
export default class Prescriptions extends Vue {
  @Prop({type: String, required: true}) readonly patientId!: string;
  @Prop({type: Object, default: ()=>({})}) readonly filters!: PrescriptionListFilters;
  @Prop({type: String, default: "Recepty pacjenta"}) readonly title!: string;

  pagination: Pagination = {
    perPage: 20,
    totalRows: 0,
    currentPage: 1
  };

  prescriptions: Array<Prescription> | null = null;
  isLoading: boolean = false;

  @Watch("filters.continuousStayId") async onPatientIdChange() {
    this.pagination.currentPage = 1;
    await this.loadData();
  }

  mounted(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.isLoading = true;
    const {
      items,
      pagination
    } = await read<PrescriptionsResponse>(`/api/patient/${this.patientId}/prescriptions`, {
      page: this.pagination.currentPage,
      perPage: this.pagination.perPage,
      ...this.filters,
    });
    this.prescriptions = items;
    this.pagination = pagination;
    this.isLoading = false;
  }

  changePage(page: number): void {
    this.pagination.currentPage = page;
    this.loadData();
  }

  getVariant(status: string): string{
    switch (status) {
      case "verified":
        return "secondary";
      case "created":
        return "primary";
      case "canceled":
        return "danger";
      case "completed":
        return "success";
      case "queued":
        return "warning";
      default:
        return "light";
    }
  }

}
