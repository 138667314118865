<template>
  <div>
    <error-message :errors="errors" />
    <div class="form-row">
      <b-form-group
        label="Zakres"
        label-for="scope"
        class="col-md-6 col-xl-3"
      >
        <object-select
          id="scope"
          v-model="scope"
          :disabled="loading"
          :state="state('scope')"
          :options="scopeOptions"
          class="mb-2"
          placeholder="Zakres"
          required
        />
        <error-message
          :errors="errors"
          field="scope"
        />
      </b-form-group>

      <b-form-group
        label="Cel"
        label-for="targetName"
        class="col-md-6 col-xl-3"
      >
        <resizable-textarea
          id="targetName"
          v-model="targetName"
          :disabled="loading"
          :state="state('targetName')"
          class="mb-2"
          placeholder="Cel"
          required
        />
        <error-message
          :errors="errors"
          field="targetName"
        />
      </b-form-group>

      <b-form-group
        label="Zadania"
        label-for="tasks"
        class="col-md-6 col-xl-3"
      >
        <resizable-textarea
          id="tasks"
          v-model="tasks"
          :disabled="loading"
          :state="state('tasks')"
          class="mb-2"
          placeholder="Zadania"
          required
          @input="removeWhiteSpace($event, 'tasks')"
        />
        <error-message
          :errors="errors"
          field="tasks"
        />
      </b-form-group>

      <b-form-group
        label="Czas realizacji"
        label-for="time"
        class="col-md-6 col-xl-3"
      >
        <resizable-textarea
          id="time"
          v-model="realizationTime"
          :disabled="loading"
          :state="state('realizationTime')"
          class="mb-2"
          placeholder="Czas realizacji"
          required
          @input="removeWhiteSpace($event, 'realizationTime')"
        />
        <error-message
          :errors="errors"
          field="realizationTime"
        />
      </b-form-group>

      <b-form-group
        label="Osoba wspierająca"
        label-for="person"
        class="col-md-6 col-xl-3"
      >
        <resizable-textarea
          id="person"
          v-model="supportingPerson"
          :disabled="loading"
          :state="state('supportingPerson')"
          class="mb-2"
          placeholder="Osoba wspierająca"
          required
          @input="removeWhiteSpace($event, 'supportingPerson')"
        />
        <error-message
          :errors="errors"
          field="supportingPerson"
        />
      </b-form-group>

      <b-form-group
        label="Stopień realizacji"
        label-for="degree"
        class="col-md-6 col-xl-3"
      >
        <resizable-textarea
          id="degree"
          v-model="realizationDegree"
          :disabled="loading"
          :state="state('realizationDegree')"
          class="mb-2"
          placeholder="Stopień realizacji"
          required
          @input="removeWhiteSpace($event, 'realizationDegree')"
        />
        <error-message
          :errors="errors"
          field="realizationDegree"
        />
      </b-form-group>

      <b-form-group
        label="Wykonanie"
        label-for="realization"
        class="col-md-6 col-xl-3"
      >
        <object-select
          id="realization"
          v-model="realization"
          :disabled="loading"
          :state="state('realization')"
          :options="realizationOptions"
          class="mb-2"
          placeholder="Wykonanie"
          required
        />
        <error-message
          :errors="errors"
          field="realization"
        />
      </b-form-group>

      <div class="col text-right no-label">
        <button
          :disabled="loading"
          class="btn btn-primary submit"
          @click="addTherapeuticTask"
        >
          <i
            v-show="loading"
            class="fa fa-spin fa-spinner"
          />
          <i
            v-show="!loading"
            class="fa fa-plus"
          />
          Dodaj
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {generateUuid} from "@/utils/uuid/generateUuid";
import ErrorMessage from "../../../Form/ErrorMessage";
import processResponseException from "../../../../utils/errors/processResponseException";
import create from "../../../../rest/create";
import t from "../../../../i18n";
import ResizableTextarea from "../../../Form/Textarea/ResizableTextarea";
import ObjectSelect from "../../../Form/Select/ObjectSelect";
import {errorsMixin} from "@/mixins/errorsMixin";

export default {
  name: "TherapeuticTaskAdd",
  components: {
    ObjectSelect,
    ResizableTextarea,
    ErrorMessage,
  },
  mixins: [errorsMixin],
  props: {
    therapeuticPlanId: {type: String, required: true},
  },
  data() {
    return {
      tasks: null,
      scope: null,
      loading: false,
      targetName: null,
      realization: null,
      realizationTime: null,
      supportingPerson: null,
      realizationDegree: null,
      realizationOptions: [
        {value: "done", name: t("@therapeutic-realization.done")},
        {value: "not_done", name: t("@therapeutic-realization.not_done")},
        {value: "in_progress", name: t("@therapeutic-realization.in_progress")},
      ],
      scopeOptions: [
        {value: "psycho_medical", name: t("@therapeutic-scope.psycho_medical")},
        {value: "social", name: t("@therapeutic-scope.social")},
        {value: "support_net", name: t("@therapeutic-scope.support_net")},
      ],
    };
  },
  methods: {
    async addTherapeuticTask() {
      this.loading = true;
      const therapeuticTaskId = generateUuid();
      const newTask = {
        therapeuticTaskId,
        ipzId: this.ipzId,
        tasks:this.tasks,
        scope: this.scope ? this.scope.value : null,
        targetName: this.targetName,
        realizationTime: this.realizationTime,
        supportingPerson: this.supportingPerson,
        realizationDegree: this.realizationDegree,
        realization: this.realization ? this.realization.value : null,
      };

      try {
        await create(`/api/ipzs/therapeutic-plans/${this.therapeuticPlanId}/therapeutic-tasks`, newTask);
        this.$emit("new-task-added", newTask);

        this.errors = [];
        this.tasks = null;
        this.scope = null;
        this.targetName = null;
        this.realization = null;
        this.realizationTime = null;
        this.supportingPerson = null;
        this.realizationDegree = null;
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
  },
}
</script>

<style scoped>

</style>
