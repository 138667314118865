<template>
  <div>
    <error-message :errors="errors" />
    <loading-mask
      v-if="referrals.length"
      :loading="loading"
    >
      <b-card no-body>
        <b-card-header
          v-b-toggle="`referrals-${indexKey}`"
          class="text-left waves-effect waves-float"
          header-tag="header"
        >
          Skierowania
        </b-card-header>
        <b-collapse :id="`referrals-${indexKey}`">
          <b-card-body>
            <referrals-table
              :items="referrals"
              @refresh="getReferralsList"
            />
          </b-card-body>
        </b-collapse>
      </b-card>
    </loading-mask>

    <create-referral-modal
      v-if="patient"
      :index-key="indexKey"
      :appointment-document-id="appointmentDocumentId"
      :patient="patient"
      @created="getReferralsList"
      @prepared="getReferralsList"
    />
  </div>
</template>

<script>
import LoadingMask from "../../../Loading/LoadingMask.vue";
import ReferralsTable from "../../../Referrals/ReferralsTable.vue";
import CreateReferralModal from "../../../Referrals/CreateReferralModal.vue";
import ErrorMessage from "../../../Form/ErrorMessage.vue";
import read from "../../../../rest/read";
import processResponseException from "../../../../utils/errors/processResponseException";

export default {
  name: "ContinuousStayDailyDescriptionReferrals",
  components: {
    LoadingMask,
    ReferralsTable,
    CreateReferralModal,
    ErrorMessage
  },
  props: {
    appointmentDocumentId: {required: true, type: String},
    documentReferrals: {type: Array, default: () => []},
    patient: {type: Object, default: null},
    indexKey: {type: Number, default: null}
  },
  data() {
    return {
      errors: [],
      referrals: this.documentReferrals.map(referral => ({
        ...referral,
        createdAt: referral.createdAt.split("T").join(" ")
      })),
      loading: false,
    }
  },
  watch: {
    patient(){
      this.getReferralsList();
    },
    appointmentDocumentId(){
      this.getReferralsList();
    }
  },
  methods: {
    async getReferralsList() {
      if(!this.patient){
        return;
      }
      this.loading = true;
      try {
        const {items} = await read(`/api/patients/${this.patient.patientId}/referrals`);
        this.referrals = items.filter(item => item.appointmentDocumentId === this.appointmentDocumentId);
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    }
  }
}
</script>

<style scoped>

</style>
