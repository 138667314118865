<template>
  <loading-mask :loading="loadingContinuousStay && loadingEntries">
    <div class="page-title-box d-flex justify-content-between flex-wrap">
      <h4 class="page-title">
        Pobyt ciągły
        <template v-if="patientId">
          - <router-link :to="patientCardPath">
            {{ patientFullName }}
          </router-link>
        </template>
      </h4>
      <continuous-stay-select-with-status
        v-if="patientId"
        ref="continuousStaySelect"
        :patient-id="patientId"
        style="max-width: 450px"
        :value="continuousStayId"
        @input="$router.push({params: {continuousStayId: $event}, query: {}})"
      />
    </div>
    <last-edited-appointment />
    <div class="row">
      <div class="col-lg-4 order-lg-1">
        <div class="card-box">
          <router-link
            v-if="patientId"
            :to="patientCardPath"
            class="btn btn-sm btn-secondary m-1"
          >
            <i class="fa fa-user" />
            Karta pacjenta
          </router-link>
          <is-granted
            :privileges="['CLOSE_CONTINUOUS_STAY']"
            :subject="continuousStayId"
            component="fragment"
          >
            <button
              v-b-modal.closeContinuousStayModal
              class="btn btn-success m-1 btn-sm"
            >
              <i class="fa fa-lock" />
              Zakończ pobyt
            </button>
          </is-granted>
          <is-granted
            :privileges="['CHANGE_CONTINUOUS_STAY_BRANCH']"
            :subject="continuousStayId"
            component="fragment"
          >
            <button
              v-b-modal.changeContinuousStayBranchModal
              class="btn btn-primary m-1 btn-sm"
            >
              <i class="fa fa-cog" />
              Zmień komórkę organizacyjną
            </button>
          </is-granted>
          <is-granted
            :privileges="['CHANGE_CONTINUOUS_STAY_DATES']"
            :subject="continuousStayId"
            component="fragment"
          >
            <button
              v-b-modal.changeContinuousStayDatesModal
              class="btn btn-info m-1 btn-sm"
            >
              <i class="fa fa-clock" />
              Zmień daty pobytu
            </button>
          </is-granted>
          <is-granted
            :privileges="['REOPEN_CONTINUOUS_STAY']"
            :subject="continuousStayId"
            component="fragment"
          >
            <button
              v-b-modal.reopenContinuousStayModal
              class="btn btn-success m-1 btn-sm"
            >
              <i class="fa fa-unlock" />
              Otwórz pobyt ponownie
            </button>
          </is-granted>
          <is-granted
            :privileges="['CANCEL_CONTINUOUS_STAY']"
            :subject="continuousStayId"
            component="fragment"
          >
            <button
              v-b-modal.cancelContinuousStayModal
              class="btn btn-danger m-1 btn-sm"
            >
              <i class="fa fa-ban" />
              Odwołaj pobyt
            </button>
          </is-granted>
          <is-granted
            v-if="entries.length > 0"
            :privileges="['PRINT_CONTINUOUS_STAY']"
            :subject="continuousStayId"
            component="fragment"
          >
            <a
              :href="`/api/continuous-stays/${continuousStayId}/print`"
              class="btn btn-primary m-1 btn-sm"
              target="_blank"
            >
              <i class="fa fa-print" />
              Drukuj podsumowanie
            </a>
          </is-granted>
          <is-granted
            :privileges="['PRINT_CONTINUOUS_STAY_INFORMATION_CARD']"
            :subject="continuousStayId"
            component="fragment"
          >
            <a
              :href="`/api/continuous-stays/${continuousStayId}/information-card/print`"
              class="btn btn-secondary m-1 btn-sm"
              target="_blank"
            >
              <i class="fa fa-print" />
              Drukuj kartę informacyjną
            </a>
          </is-granted>
          <is-granted
            :privileges="['PRINT_CONTINUOUS_STAY_DAILY_DESCRIPTIONS']"
            :subject="continuousStayId"
            component="fragment"
          >
            <a
              :href="`/api/continuous-stays/${continuousStayId}/daily-descriptions/print`"
              class="btn btn-secondary m-1 btn-sm"
              target="_blank"
            >
              <i class="fa fa-print" />
              Drukuj codzienne opisy specjalistów
            </a>
          </is-granted>
        </div>
        <div>
          <is-granted
            :privileges="['GET_INSURANCE_BY_PATIENT_ID']"
            component="fragment"
          >
            <patient-insurance
              :patient-id="patientId"
              @insuranceChanged="insuranceWasChanged(true)"
            />
          </is-granted>
        </div>
        <div class="card-box font-16">
          <continuous-stay-details
            :patient-full-name="patientFullName"
            :continuous-stay-status="continuousStayStatus"
            :worker-full-name="workerFullName"
            :continuous-stay-category="continuousStayCategory"
            :start-date="startDate"
            :end-date="endDate"
            :branch="branch"
          />
        </div>
      </div>
      <div class="col-lg-8 order-lg-0">
        <div
          v-if="patientId"
          class="row"
        >
          <is-granted
            :privileges="['GET_DAILY_CASE_MANAGER']"
            component="fragment"
          >
            <case-manager-daily
              class="col-12 col-sm-6"
              :patient-id="patientId"
            />
          </is-granted>
          <patient-card-leading-specialist
            class="col-12 col-sm-6"
            :patient-id="patientId"
          />
        </div>
        <error-message :errors="errors" />
        <continuous-stay-canceled-module
          v-if="continuousStayStatus === 'canceled'"
          :cancel-reason="cancelReason"
          class="card-box"
        />
        <b-card no-body>
          <b-tabs>
            <b-tab>
              <template #title>
                Codzienne opisy specjalistów
              </template>
              <continuous-stay-daily-specialists-descriptions
                :continuous-stay-id="continuousStayId"
                :patient-id="patientId"
                :insurance-changed="insuranceChanged"
                :recognitions-changed="recognitionsChanged"
                class="card-box"
                @descriptionsReloaded="insuranceWasChanged(false)"
                @reloadedAfterRecognitionsChange="recognitionsWasChanged(false)"
              />
            </b-tab>
            <b-tab>
              <template #title>
                Karta informacyjna
              </template>
              <continuous-stay-information-card-edit
                :disabled="continuousStayStatus !== 'in_progress'"
                :continuous-stay-id="continuousStayId"
                class="card-box"
              />
            </b-tab>
            <b-tab v-if="continuousStayStatus !== 'canceled' && entries.length">
              <template #title>
                Archiwum: Wpisy
                <b-badge
                  class="ml-1"
                  pill
                  variant="primary"
                >
                  {{ entries.length }}
                </b-badge>
              </template>
              <continuous-stay-entries
                :continuous-stay-id="continuousStayId"
                :continuous-stay-status="continuousStayStatus"
                :entries="entries"
                :loading="loadingEntries"
              />
            </b-tab>

            <is-granted
              v-if="patientId"
              :privileges="['FIND_PRESCRIPTIONS_BY_PATIENT_ID']"
              component="fragment"
            >
              <b-tab>
                <template #title>
                  Recepty
                </template>
                <prescriptions
                  :filters="{continuousStayId}"
                  :patient-id="patientId"
                  title=""
                />
              </b-tab>
            </is-granted>

            <is-granted
              v-if="patientId"
              :privileges="['GET_TEST_RESULTS_LIST']"
              component="fragment"
            >
              <b-tab>
                <template #title>
                  Wyniki testów
                </template>
                <test-results-card
                  :continuous-stay-id="continuousStayId"
                  :patient-id="patientId"
                  :read-only="continuousStayStatus === 'canceled'"
                  :status="[TestResultStatus.OPENED, TestResultStatus.FINISHED]"
                  title=""
                />
              </b-tab>
            </is-granted>
            <b-tab v-if="patientId">
              <template #title>
                Załączniki
              </template>
              <patient-attachments-card
                :continuous-stay-id="continuousStayId"
                :patient-id="patientId"
                hide-appointment-ref-options
                title=""
              />
            </b-tab>
            <b-tab v-if="patientId">
              <template #title>
                Rozpoznania
              </template>
              <patient-card-chronic-diseases
                :patient-id="patientId"
                title=""
                @chronicDiseaseCreatedOrUpdated="recognitionsWasChanged(true)"
              />
            </b-tab>
            <is-granted
              v-if="patientId"
              :privileges="['CREATE_REQUEST_MEDICAL_RECORDS']"
              component="fragment"
            >
              <b-tab>
                <template #title>
                  Dokumentacja medyczna
                </template>
                <request-medical-records-list
                  :columns="['createdAt', 'requester', 'branches', 'options']"
                  :continuous-stay-id="continuousStayId"
                  :patient="{
                    patientId,
                    displayName: patientFullName,
                  }"
                  request-type="continuousStay"
                />
              </b-tab>
            </is-granted>
          </b-tabs>
        </b-card>
      </div>
    </div>
    <change-continuous-stay-dates-modal
      v-if="!loadingContinuousStay"
      :patient-id="patientId"
      :continuous-stay-id="continuousStayId"
      :start="startDate"
      :end="endDate"
      @closed="reloadContinuousStayData"
    />
    <patient-card-close-continuous-stay
      v-if="!loadingContinuousStay"
      :patient-id="patientId"
      :continuous-stay-id="continuousStayId"
      :start="startDate"
      :end="endDate"
      @closed="reloadContinuousStayData"
    />
    <patient-card-cancel-continuous-stay
      v-if="!loadingContinuousStay"
      :patient-id="patientId"
      :continuous-stay-id="continuousStayId"
      @canceled="reloadContinuousStayData"
    />
    <reopen-continuous-stay
      v-if="!loadingContinuousStay"
      :patient-id="patientId"
      :continuous-stay-id="continuousStayId"
      @reopened="reloadContinuousStayData"
    />
    <change-continuous-stay-branch-modal
      v-if="!loadingContinuousStay"
      :stay-id="continuousStayId"
      :branch-id="branch.branchId"
      @saved="reloadContinuousStayData"
    />
  </loading-mask>
</template>

<script>
import ContinuousStayCanceledModule
  from "../../components/Patient/PatientCard/ContinuousStay/ContinuousStayCanceledModule";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import read from "../../rest/read";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../../components/Form/ErrorMessage";
import PatientCardCloseContinuousStay
  from "../../components/Patient/PatientCard/ContinuousStay/PatientCardCloseContinuousStay";
import PatientCardCancelContinuousStay
  from "../../components/Patient/PatientCard/ContinuousStay/PatientCardCancelContinuousStay";
import IsGranted from "../../components/IsGranted";
import ContinuousStayEntries from "../../components/Patient/PatientCard/ContinuousStay/ContinuousStayEntries";
import ContinuousStayDetails from "../../components/Patient/PatientCard/ContinuousStay/ContinuousStayDetails";
import ContinuousStayInformationCardEdit
  from "../../components/Patient/PatientCard/ContinuousStay/ContinuousStayInformationCardEdit";
import LastEditedAppointment from "../../components/Appointment/LastEditedAppointment";
import LoadingMask from "../../components/Loading/LoadingMask";
import ReopenContinuousStay from "../../components/Patient/PatientCard/ContinuousStay/ReopenContinuousStay";
import t from "../../i18n";
import Prescriptions from "@/components/Patient/PatientCard/Prescriptions";
import PatientAttachmentsCard from "@/components/Patient/PatientCard/Attachment/PatientAttachmentsCard";
import PatientCardChronicDiseases from "@/components/Patient/PatientCard/ChronicDisease/PatientCardChronicDiseases";
import PatientCardLeadingSpecialist from "@/components/Patient/PatientCard/PatientCardLeadingSpecialist";
import CaseManagerDaily from "@/components/Patient/PatientCard/CaseManager/CaseManagerDaily";
import RequestMedicalRecordsList
  from "@/components/Patient/PatientCard/RequestMedicalRecords/RequestMedicalRecordsList";
import ContinuousStaySelectWithStatus
  from "@/components/Patient/PatientCard/ContinuousStay/ContinuousStaySelectWithStatus";
import TestResultsCard from "@/components/TestResults/TestResultsCard";
import {TestResultStatus} from "@/types/TestResult";
import ChangeContinuousStayBranchModal
  from "../../components/Patient/PatientCard/ContinuousStay/ChangeContinuousStayBranchModal";
import ChangeContinuousStayDatesModal
  from "../../components/Patient/PatientCard/ContinuousStay/ChangeContinuousStayDatesModal";
import ContinuousStayDailySpecialistsDescriptions
  from "../../components/Patient/PatientCard/ContinuousStay/ContinuousStayDailySpecialistsDescriptions";
import parseDate from "../../utils/date/parseDate";
import PatientInsurance from "../../components/Patient/Insurance/PatientInsurance.vue";

export default {
  name: "ContinuousStayEditView",
  components: {
    PatientInsurance,
    ContinuousStayDailySpecialistsDescriptions,
    ChangeContinuousStayDatesModal,
    TestResultsCard,
    ContinuousStaySelectWithStatus,
    RequestMedicalRecordsList,
    CaseManagerDaily,
    PatientCardLeadingSpecialist,
    PatientAttachmentsCard,
    PatientCardChronicDiseases,
    Prescriptions,
    ReopenContinuousStay,
    LastEditedAppointment,
    ContinuousStayDetails,
    ContinuousStayEntries,
    ContinuousStayInformationCardEdit,
    IsGranted,
    ErrorMessage,
    PatientCardCancelContinuousStay,
    PatientCardCloseContinuousStay,
    ContinuousStayCanceledModule,
    ChangeContinuousStayBranchModal,
    LoadingMask
  },
  mixins: [errorsMixin],
  props: {
    continuousStayId: {type: String, required: true},
    csaPage: {type: Number, default: null},
  },
  data() {
    return {
      parseDate,
      entries: [],
      loadingContinuousStay: true,
      loadingEntries: true,
      patientId: null,
      workerFullName: null,
      continuousStayCategory: null,
      startDate: null,
      endDate: null,
      branch: null,
      continuousStayStatus: null,
      patientFullName: null,
      cancelReason: null,
      appointmentsLength: 0,
      insuranceChanged: false,
      recognitionsChanged: false,
      TestResultStatus,
    };
  },
  computed: {
    patientCardPath() {
      return {name: "patientCard", params: {patientId: this.patientId}};
    },
  },

  watch: {
    continuousStayId: {
      immediate: true,
      async handler() {
        await Promise.all([
          this.loadEntries(),
          this.loadData(),
        ]);
      }
    }
  },
  methods: {
    async loadData() {
      this.loadingContinuousStay = true;
      const data = await read(`/api/continuous-stays/${this.continuousStayId}`);
      this.patientId = data.patientId;
      this.workerFullName = data.workerFullName;
      this.continuousStayCategory = data.continuousStayCategory;
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      this.branch = {
        branchId: data.branchId,
        name: data.branchName,
      };
      this.continuousStayStatus = data.continuousStayStatus;
      this.patientFullName = data.patientFullName;
      this.cancelReason = t(`@cancel-reason-categories.${data.cancelReasonCategory}`);
      if(data.cancelReasonCategory === "other"){
        this.cancelReason = data.cancelReason;
      }

      this.loadingContinuousStay = false;
    },
    async loadEntries() {
      this.errors = [];
      this.loadingEntries = true;
      try {
        const {entries} = await read(`/api/continuous-stays/${this.continuousStayId}/entries`);
        this.entries = entries;
      } catch(exception) {
        this.errors = processResponseException(exception);
      }
      this.loadingEntries = false;
    },
    async reloadContinuousStayData() {
      await Promise.all([
        this.loadEntries(),
        this.loadData(),
        this.$refs.continuousStaySelect.loadOptions(),
      ]);
    },
    insuranceWasChanged(value){
      this.insuranceChanged = value;
    },
    recognitionsWasChanged(value){
      this.recognitionsChanged = value;
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";

  ::v-deep.tabs .nav.nav-tabs {
    background-color: $light5 !important;
  }
</style>

