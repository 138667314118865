<template>
  <b-tabs>
    <loading-mask :loading="loading">
      <b-tab :title-link-class="{'bg-danger text-white': basicDataTabErrors.length}">
        <template #title>
          Dane podstawowe
          <span
            v-show="basicDataTabErrors.length"
            class="fas fa-exclamation-circle"
          />
        </template>
        <worker-personal-data-form
          v-model="personalData"
          edit
          :errors="errors"
        />
        <div class="card-box">
          <h4>Adres zamieszkania</h4>
          <hr>
          <address-form
            v-model="livingAddress"
            :errors="getSubErrors('livingAddress')"
          />
          <div class="row">
            <b-form-checkbox v-model="differentAddresses">
              Adres korespondencyjny jest inny niż zamieszkania
            </b-form-checkbox>
          </div>
        </div>
        <div
          v-if="differentAddresses"
          class="card-box"
        >
          <h4>Adres korespondencyjny</h4>
          <hr>
          <address-form
            v-model="correspondenceAddress"
            :errors="getSubErrors('correspondenceAddress')"
          />
        </div>
      </b-tab>
      <b-tab
        v-if="!nfzConfiguration"
        :title-link-class="{'bg-danger text-white': treatmentTabErrors.length}"
      >
        <template #title>
          Wykonywane usługi
          <span
            v-show="treatmentTabErrors.length"
            class="fas fa-exclamation-circle"
          />
        </template>
        <div class="card-box">
          <h4>Domyślna usługa specjalisty</h4>
          <hr>
          <worker-default-treatment-type-select
            v-model="treatmentTypeDefaultId"
            :selected-treatment-types="treatmentTypes"
            :state="state('treatmentTypeDefaultId')"
          />
          <error-message
            :errors="errors"
            field="treatmentTypeDefaultId"
          />
        </div>
        <div class="card-box">
          <h4>Wykonywane usługi</h4>
          <hr>
          <worker-treatment-types
            v-model="treatmentTypes"
            col-class="col-11 col-lg-6 col-xl-5"
            :errors="errors"
          />
        </div>
      </b-tab>
      <b-tab
        :title-link-class="{'bg-danger text-white': certificateTabErrors.length}"
        :disabled="!certificate"
      >
        <template #title>
          Certyfikat
          <span
            v-show="certificateTabErrors.length"
            class="fas fa-exclamation-circle"
          />
        </template>
        <div class="card-box">
          <h4>Certyfikat</h4>
          <hr>
          <certificate-p12
            :value="pkcs12FileId"
            :errors="errors"
            @input="changeCertificate"
          />
        </div>
      </b-tab>
      <b-tab
        :title-link-class="{'bg-danger text-white': nfzTabErrors.length}"
        :disabled="!canHaveReimbursementContract"
      >
        <template #title>
          Umowa z NFZ (dla lekarzy)
          <span
            v-show="nfzTabErrors.length"
            class="fas fa-exclamation-circle"
          />
        </template>
        <div class="card-box">
          <h4>Umowa z NFZ</h4>
          <hr>
          <reimbursement-contract-form
            v-model="reimbursementContract"
            :errors="getSubErrors('reimbursementContract')"
          />
        </div>
      </b-tab>
      <error-message :errors="errors" />
    </loading-mask>
    <div class="text-right">
      <router-link
        :to="{name: 'worker_list'}"
        class="btn btn-secondary"
      >
        Anuluj
      </router-link>
      <button
        :disabled="loading"
        type="submit"
        class="btn btn-primary ml-2"
        @click="submit"
      >
        <i
          :class="loading? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </b-tabs>
</template>

<script>
import update from "../../rest/update";
import workerPersonalDataForm from "../../components/Worker/WorkerPersonalData.vue";
import AddressForm from "../../components/Address/AddressForm";
import WorkerTreatmentTypes from "../../components/Worker/WorkerTreatmentTypes";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../../components/Form/ErrorMessage";
import subErrors from "../../utils/errors/subErrors";
import {BadRequestException} from "../../rest";
import ReimbursementContractForm from "../../components/Worker/ReimbursementContractForm";
import read from "../../rest/read";
import {mapActions, mapState} from "vuex";
import WorkerDefaultTreatmentTypeSelect from "../../components/TreatmentType/WorkerDefaultTreatmentTypeSelect";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import LoadingMask from "../../components/Loading/LoadingMask";
import CertificateP12 from "../../components/Worker/CertificateP12";
import {isGranted} from "../../security/isGranted";

const allowedProfessions = ["doctor", "nurse", "physiotherapist"];

const basicDataTabFields = [
  "name",
  "surname",
  "academicDegreeTitle",
  "medicalSpecializations",
  "professionalTitle",
  "mainProfession",
  "additionalProfessions",
  "role",
  "roleInProcess",
  "pesel",
  "phoneNumber",
  "businessPhoneNumber",
  "personalEmail",
  "branchId",
  "professionType",
  "professionNumber",
  "cooperatingUnitId",
  "teamCoordinator",
  "coordinator",
  "livingAddress",
  "correspondenceAddress"
];

const treatmentTabFields = [
  "treatmentTypeDefaultId",
  "treatmentTypes",
];

const nfzTabFields = [
  "reimbursementContract",
];

const certificateTabFields = [
  "pkcs12FileId"
];

export default {
  name: "WorkerUpdate",
  components: {
    CertificateP12,
    LoadingMask,
    WorkerDefaultTreatmentTypeSelect,
    ErrorMessage,
    WorkerTreatmentTypes,
    AddressForm,
    workerPersonalDataForm,
    ReimbursementContractForm,
  },
  mixins: [errorsMixin],
  props: {
    workerId: {type: String, required: true},
  },
  data() {
    return {
      personalData: {},
      treatmentTypes: [],
      livingAddress: {},
      correspondenceAddress: {},
      reimbursementContract: {},
      differentAddresses: false,
      loading: false,
      treatmentTypeDefaultId: null,
      pkcs12FileId: null,
      pkcs12FilePassword: null,
      certificate: false,
      allowToEditReimbursementContract: false
    };
  },
  computed: {
    ...mapState({
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration,
      clinicParameters: state => state.clinicParameters.parameters,
    }),
    canHaveReimbursementContract() {
      if (this.clinicParameters && this.clinicParameters.patientAnonymous === true) {
        return false;
      }
      if(this.nfzConfiguration){
        return this.allowToEditReimbursementContract;
      }

      if (!allowedProfessions.includes(this.personalData.mainProfession)) {
        return false;
      }

      return "pwz_number" === this.personalData.professionType;
    },
    basicDataTabErrors() {
      return this.errors
        .filter(error => basicDataTabFields
          .findIndex(tabField => error.field.startsWith(tabField)) !== -1);
    },
    treatmentTabErrors() {
      return this.errors
        .filter(error => treatmentTabFields
          .findIndex(tabField => error.field.startsWith(tabField)) !== -1);
    },
    nfzTabErrors() {
      return this.errors
        .filter(error => nfzTabFields
          .findIndex(tabField => error.field.startsWith(tabField)) !== -1);
    },
    certificateTabErrors() {
      return this.errors
        .filter(error => certificateTabFields
          .findIndex(tabField => error.field.startsWith(tabField)) !== -1);
    }
  },
  async mounted() {
    await this.loadData();
    await this.canHaveCertificate();
    await this.allowToEditContract();
  },
  methods: {
    ...mapActions("toastNotification", ["showToast"]),
    async canHaveCertificate() {
      if(this.nfzConfiguration){
        this.certificate = await isGranted(
          ["WORKER_P12_CERTIFICATE"],
          this.workerId,
        );
      } else {
        this.certificate = this.canHaveReimbursementContract;
      }
    },
    async allowToEditContract() {
      if(this.nfzConfiguration){
        this.allowToEditReimbursementContract = await isGranted(
          ["WORKER_REIMBURSEMENT_CONTRACT"],
          this.workerId,
        );
      }
    },
    async changeCertificate(cert) {
      this.pkcs12FileId = cert.id;
      this.pkcs12FilePassword = cert.password;
    },
    async loadData(){
      this.loading = true;
      try {
        const {
          livingAddress,
          academicDegreeTitle,
          additionalProfessions,
          cooperatingUnitId,
          role,
          mainProfession,
          name,
          personalEmail,
          pesel,
          phoneNumber,
          businessPhoneNumber,
          professionNumber,
          professionType,
          surname,
          active,
          branchId,
          treatmentTypes,
          correspondenceAddress,
          differentAddresses,
          reimbursementContract,
          roleInProcess,
          treatmentTypeDefaultId,
          professionalTitle,
          specializations,
          teamCoordinator,
          coordinator,
          medicalSpecializations,
          nfzWorkerType,
          pkcs12FileId
        } = await read(`/api/worker/${this.workerId}/update`);
        this.livingAddress = livingAddress;
        this.personalData = {
          academicDegreeTitle: academicDegreeTitle,
          additionalProfessions: additionalProfessions,
          cooperatingUnitId: cooperatingUnitId,
          role: role,
          mainProfession: mainProfession,
          name: name,
          personalEmail: personalEmail,
          pesel: pesel,
          phoneNumber: phoneNumber,
          businessPhoneNumber:businessPhoneNumber,
          professionNumber: professionNumber,
          professionType: professionType,
          surname: surname,
          accountInactive: !active,
          branchId: branchId,
          roleInProcess: roleInProcess,
          professionalTitle: professionalTitle,
          specializations: specializations,
          teamCoordinator: teamCoordinator,
          coordinator: coordinator,
          medicalSpecializations: medicalSpecializations,
          nfzWorkerType: nfzWorkerType
        };
        this.role = role;
        this.correspondenceAddress = correspondenceAddress;
        this.treatmentTypes = treatmentTypes;
        this.differentAddresses = differentAddresses;
        this.reimbursementContract = {
          branchId: reimbursementContract ? reimbursementContract.branchId : null,
          typeSuffix: reimbursementContract ? reimbursementContract.typeSuffix : null,
          contractNumber: reimbursementContract ? reimbursementContract.contractNumber : null,
          hasContract: reimbursementContract && !!(
            reimbursementContract.branchId
            || reimbursementContract.pkcs12FileId
            || reimbursementContract.typeSuffix
            || reimbursementContract.contractNumber
          ),
        };
        this.pkcs12FileId = pkcs12FileId;
        this.treatmentTypeDefaultId = treatmentTypeDefaultId;
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    getSubErrors(field) {
      return subErrors(this.errors, field);
    },
    async submit() {
      this.loading = true;
      const personalData = {
        ...this.personalData,
        phoneNumber: this.personalData.phoneNumber || null,
        businessPhoneNumber:this.personalData.businessPhoneNumber || null,
        cooperatingUnitId: this.personalData.cooperatingUnitId,
        role: this.personalData.role ? this.personalData.role.value: null,
        active: !this.personalData.accountInactive,
        professionNumber: this.personalData.professionNumber === "" ? null : this.personalData.professionNumber,
        professionType: this.personalData.professionType === "" ? null : this.personalData.professionType,
      };

      const reimbursementContract = this.canHaveReimbursementContract && this.reimbursementContract.hasContract
        ? {
          ...this.reimbursementContract,
          hasContract: undefined,
        }
        : null;

      try {
        await update(`/api/worker/${this.workerId}/update`, {
          ...personalData,
          treatmentTypes: this.treatmentTypes,
          treatmentTypeDefaultId: this.treatmentTypeDefaultId,
          livingAddress: this.isLivingAddressNotEmpty() || this.differentAddresses
            ? {...this.livingAddress} : null,
          correspondenceAddress: this.differentAddresses
            ? {...this.correspondenceAddress}
            : this.isLivingAddressNotEmpty() ? {...this.livingAddress} : null,
          differentAddresses: this.differentAddresses,
          coordinator: this.personalData.coordinator?this.personalData.coordinator.workerId:null,
          pkcs12FileId: this.pkcs12FileId,
          pkcs12FilePassword: this.pkcs12FilePassword,
          reimbursementContract,
        });
        await this.$router.push({name: "worker_list"});
      } catch (exception) {
        this.errors = processResponseException(exception);
        if(!this.differentAddresses) {
          this.clearCorrespondenceAddressErrors();
        }
        if (exception instanceof BadRequestException) {
          const message = "Formularz nie został wypełniony prawidłowo.\nZmiany nie zostały zapisane.";
          const variant = "danger";
          this.showToast({message, variant});
        }
      }
      this.loading = false;
    },
    isLivingAddressNotEmpty() {
      return !(this.livingAddress
        && !this.livingAddress.city && !this.livingAddress.zipCode && !this.livingAddress.street);
    },
    clearCorrespondenceAddressErrors(){
      const prefix ="correspondenceAddress";
      this.errors = this.errors.filter((error) => !error.field || !error.field.startsWith(prefix));
    },
  },
}
</script>

