<template>
  <div
    class="col-10 offset-1"
  >
    <div class="text-right m-b-10">
      <button
        v-if="readonly && (ipzStatus === 'opened')"
        class="btn btn-sm btn-secondary"
        @click="allowEdit"
      >
        <i class="fa fa-pencil-alt" />
        Edytuj formularz
      </button>
      <is-granted
        v-if="!editable"
        :privileges="['GET_IPZ_APPLICATION_FORM_PDF']"
        :subject="ipzApplicationFormId"
        component="fragment"
      >
        <a
          :href="`/api/ipz-application-form-pdf/${ipzApplicationFormId}`"
          class="btn btn-sm btn-primary m-l-15"
          target="_blank"
        >
          <i class="fa fa-print" />
          Drukuj formularz zgłoszeniowy
        </a>
      </is-granted>
    </div>
    <div class="row m-b-20">
      <h2 class="text-center col-12">
        Formularz zgłoszeniowy
      </h2>
    </div>
    <div class="row m-b-30">
      <b-form-group
        label="Data"
        label-for="date"
        class="col-6"
      >
        <date-picker
          id="date"
          v-model="date"
          :disabled="readonly"
          :state="state('applicationDate')"
        />
        <error-message
          :errors="errors"
          field="applicationDate"
        />
      </b-form-group>
      <b-form-group
        label="Godzina"
        label-for="hour"
        class="col-6"
      >
        <hour-select
          id="hour"
          v-model="hour"
          :disabled="readonly"
          :state="state('applicationDate')"
        />
        <error-message
          :errors="errors"
          field="applicationDate"
        />
      </b-form-group>
      <b-form-group
        label-for="applicationType"
        class="col-12"
      >
        <b-form-radio-group
          id="applicationType"
          v-model="applicationCategory"
          :options="applicationOptions"
          :disabled="readonly"
          :state="state('applicationCategory')"
          size="lg"
          text-field="label"
          value-field="value"
        />
        <error-message
          :errors="errors"
          field="applicationCategory"
        />
      </b-form-group>
    </div>
    <div
      class="row m-b-30"
    >
      <div class="col-12 m-b-20">
        <h3 class="text-center">
          Kto dokonuje zgłoszenia
        </h3>
        <h4 class="text-center">
          (Wypełnić w sytuacji gdy zgłoszenia nie dokonuje pacjent)
        </h4>
      </div>
      <b-form-group
        label="Imie"
        label-for="applicantNameInput"
        class="col-6"
      >
        <b-form-input
          id="applicantNameInput"
          v-model.trim="applicant.name"
          :disabled="readonly"
          :state="state('applicant.name')"
          placeholder="Imię"
        />
        <error-message
          :errors="errors"
          field="applicant.name"
        />
      </b-form-group>
      <b-form-group
        label="Nazwisko"
        label-for="applicantSurnameInput"
        class="col-6"
      >
        <b-form-input
          id="applicantSurnameInput"
          v-model.trim="applicant.surname"
          :disabled="readonly"
          :state="state('applicant.surname')"
          placeholder="Nazwisko"
        />
        <error-message
          :errors="errors"
          field="applicant.surname"
        />
      </b-form-group>
      <b-form-group
        id="eligible_person"
        class="col-6"
        label="stopień pokrewieństwa"
        label-for="nameInput"
      >
        <eligible-person-type-select
          :value="applicant.eligiblePersonType"
          :disabled="readonly"
          :state="state('applicant.eligiblePersonType')"
          :clearable="true"
          @input="updateEligiblePerson"
        />
        <error-message
          :errors="errors"
          field="applicant.eligiblePersonType"
        />
      </b-form-group>
      <b-form-group
        id="institution"
        label="Instytucja"
        label-for="institutionInput"
        class="col-6"
      >
        <b-form-input
          id="institutionInput"
          v-model.trim="applicant.institution"
          :disabled="readonly"
          :state="state('applicant.institution')"
          placeholder="Instytucja"
        />
        <error-message
          :errors="errors"
          field="applicant.institution"
        />
      </b-form-group>
      <b-form-group
        label="Kontakt"
        label-for="applicantContactInput"
        class="col-6"
      >
        <b-form-input
          id="applicantContactInput"
          v-model.trim="applicant.contact"
          :disabled="readonly"
          :state="state('applicant.contact')"
          placeholder="Kontakt"
        />
        <error-message
          :errors="errors"
          field="applicant.contact"
        />
      </b-form-group>
    </div>
    <div class="row m-b-30">
      <div class="col-12 m-b-20">
        <h3
          class="text-center"
        >
          Kto dokonuje zgłoszenia (dane pacjenta)
        </h3>
      </div>
      <b-form-group
        label="Imie"
        label-for="patientNameInput"
        class="col-6"
      >
        <b-form-input
          id="patientNameInput"
          v-model.trim="patient.name"
          :disabled="true"
          :state="state('patient.name')"
          placeholder="Imię"
        />
        <error-message
          :errors="errors"
          field="patient.name"
        />
      </b-form-group>
      <b-form-group
        label="Nazwisko"
        label-for="patientSurnameInput"
        class="col-6"
      >
        <b-form-input
          id="patientSurnameInput"
          v-model.trim="patient.surname"
          :disabled="true"
          :state="state('patient.surname')"
          placeholder="Nazwisko"
        />
        <error-message
          :errors="errors"
          field="patient.surname"
        />
      </b-form-group>
      <b-form-group
        label="PESEL"
        label-for="peselInput"
        class="col-6"
      >
        <b-form-input
          id="peselInput"
          v-model.trim="patient.pesel"
          :disabled="true"
          :state="state('patient.pesel')"
          placeholder="PESEL"
        />
        <error-message
          :errors="errors"
          field="patient.pesel"
        />
      </b-form-group>
      <b-form-group
        class="col-12"
      >
        <address-form
          v-model="patient.address"
          :disabled="true"
          :errors="subErrors('patient.address')"
          :hide-autocomplete="true"
        />
      </b-form-group>
      <b-form-group
        id="birthplace"
        label="Miejsce urodzenia"
        label-for="birthplaceInput"
        class="col-6"
      >
        <b-form-input
          id="birthplaceInput"
          v-model.trim="patient.birthPlace"
          :disabled="readonly"
          :state="state('patient.birthPlace')"
          placeholder="Miejsce urodzenia"
        />
        <error-message
          :errors="errors"
          field="patient.birthPlace"
        />
      </b-form-group>
      <b-form-group
        label="Imię ojca"
        label-for="fatherNameInput"
        class="col-6"
      >
        <b-form-input
          id="fatherNameInput"
          v-model.trim="patient.fatherName"
          :disabled="readonly"
          :state="state('patient.fatherName')"
          placeholder="Imię ojca"
        />
        <error-message
          :errors="errors"
          field="patient.fatherName"
        />
      </b-form-group>
      <b-form-group
        label="Imię matki"
        label-for="motherNameInput"
        class="col-6"
      >
        <b-form-input
          id="motherNameInput"
          v-model.trim="patient.motherName"
          :disabled="readonly"
          :state="state('patient.motherName')"
          placeholder="Imię matki"
        />
        <error-message
          :errors="errors"
          field="patient.motherName"
        />
      </b-form-group>
      <b-form-group
        label="Numer dowodu osobistego"
        label-for="idNumberInput"
        class="col-6"
      >
        <b-form-input
          id="idNumberInput"
          :value="patient.identityCard"
          :disabled="readonly"
          :state="state('patient.identityCard')"
          placeholder="Numer dowodu osobistego"
          @input="updateIdentityCard"
        />
        <error-message
          :errors="errors"
          field="patient.identityCard"
        />
      </b-form-group>
      <b-form-group
        label="Kontakt"
        label-for="patientContactInput"
        class="col-6"
      >
        <b-form-input
          id="patientContactInput"
          v-model.trim="patient.phoneNumber"
          :state="state('patient.phoneNumber')"
          :disabled="readonly"
          placeholder="Kontakt"
        />
        <error-message
          :errors="errors"
          field="patient.phoneNumber"
        />
      </b-form-group>
    </div>
    <div class="row m-b-30">
      <div class="col-12 m-b-20">
        <h3
          class="text-center"
        >
          Powód zgłoszenia / opis sytuacji
        </h3>
      </div>
      <b-form-group
        label-for="description"
        class="col-12"
      >
        <textarea-counter
          id="description"
          v-model="description"
          :disabled="readonly"
          :state="state('description')"
          :max-characters-count="5000"
          rows="5"
        />
        <error-message
          :errors="errors"
          field="description"
        />
      </b-form-group>
      <p
        class="p-20"
        style="padding-top:0px;"
      >
        Pacjentowi udzielono informacji o zakresie działań Centrum Zdrowia Psychicznego i możliwości uzyska-
        nia świadczeń opieki zdrowotnej. Uzgodniono wstępny plan realizacji potrzeb pacjenta i wskazano termin
        i miejsce uzyskania niezbędnego świadczenia psychiatrycznego.
      </p>
      <b-form-group
        label-for="caseType"
        class="col-12"
      >
        <b-form-radio-group
          id="caseType"
          v-model="caseType"
          :options="caseOptions"
          :disabled="readonly"
          :state="state('caseType')"
          size="lg"
          text-field="label"
          value-field="value"
        />
        <error-message
          :errors="errors"
          field="caseType"
        />
      </b-form-group>
      <template
        v-if="caseType && caseType === 'sudden'"
        class="m-t-20"
      >
        <b-form-group
          label="Skierowany do Izby przyjęć CZP / oddziału stacjonarnego"
          label-for="directedToPlace"
          class="col-6"
        >
          <b-form-input
            id="directedToPlace"
            v-model.trim="directedToPlace"
            :state="state('directedToPlace')"
            :disabled="readonly"
            placeholder="Skierowany do Izby przyjęć CZP / oddziału stacjonarnego"
          />
          <error-message
            :errors="errors"
            field="directedToPlace"
          />
        </b-form-group>
      </template>
      <template
        v-if="caseType && caseType !== 'sudden'"
        class="m-t-20"
      >
        <b-form-group
          label="Termin świadczenia"
          label-for="termOfService"
          class="col-6"
        >
          <date-picker
            id="termOfService"
            v-model="termOfService"
            :state="state('termOfService')"
            :disabled="readonly"
          />
          <error-message
            :errors="errors"
            field="termOfService"
          />
        </b-form-group>
        <b-form-group
          label="Miejsce świadczenia"
          label-for="placeOfService"
          class="col-6"
        >
          <b-form-input
            id="placeOfService"
            v-model.trim="placeOfService"
            :disabled="readonly"
            :state="state('placeOfService')"
            placeholder="Miejsce świadczenia"
          />
          <error-message
            :errors="errors"
            field="placeOfService"
          />
        </b-form-group>
      </template>
    </div>
    <div class="row">
      <div class="col-12 m-b-10">
        <error-message
          :errors="errors"
        />
      </div>
      <div class="col-12 text-right m-b-30">
        <button
          v-if="!readonly"
          :disabled="loading"
          type="submit"
          class="btn btn-primary ml-2 waves-effect waves-float"
          @click="submit(false)"
        >
          <i
            :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fa"
          />
          Zapisz
        </button>
        <button
          v-if="!readonly"
          :disabled="loading"
          type="submit"
          class="btn btn-secondary ml-2 waves-effect waves-float"
          @click="submit(true)"
        >
          <i
            :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fa"
          />
          Zapisz i zatwierdź
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import HourSelect from "../../../components/Form/Select/HourSelect";
import DatePicker from "../../../components/Form/DatePicker/DatePicker";
import EligiblePersonTypeSelect from "../../../components/EligiblePersons/EligiblePersonTypeSelect";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import AddressForm from "../../../components/Address/AddressForm";
import TextareaCounter from "../../../components/Form/Textarea/TextareaCounter";
import {generateUuid} from "../../../utils/uuid/generateUuid";
import create from "../../../rest/create";
import update from "../../../rest/update";
import processResponseException from "../../../utils/errors/processResponseException";
import ErrorMessage from "../../../components/Form/ErrorMessage";
import getSubErrors from "../../../utils/errors/subErrors";
import stringifyDate from "../../../utils/date/stringifyDate";
import read from "../../../rest/read";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import {ApplicationFormStatus} from "../../../types/Ipz";
import IsGranted from "../../../components/IsGranted";
import {mapActions} from "vuex";

export default {
  name: "ApplicationFormPanel",
  components: {
    HourSelect,
    DatePicker,
    EligiblePersonTypeSelect,
    AddressForm,
    TextareaCounter,
    ErrorMessage,
    IsGranted
  },
  mixins: [errorsMixin],
  props: {
    ipzId: {type: String, required: true},
  },
  data() {
    return {
      loading:false,
      date: null,
      hour: null,
      applicationCategory: null,
      applicant: {
        eligiblePersonType: null
      },
      patient: {
        address: {}
      },
      description: null,
      caseType: null,
      termOfService: null,
      placeOfService: null,
      directedToPlace: null,
      readonly: false,
      status: null,
      ipzApplicationFormId: null,
      editable: false,
      ipzStatus: null
    };
  },
  computed: {
    applicationOptions() {
      return [
        {
          value: "personal",
          label: "Zgłoszenie osobiste",
        },
        {
          value: "telephone",
          label: "Zgłoszenie telefoniczne",
        },
        {
          value: "email",
          label: "Zgłoszenie e-mail",
        },
      ];
    },
    caseOptions() {
      return [
        {
          value: "sudden",
          label: "Przypadek nagły",
        },
        {
          value: "urgent",
          label: "Przypadek pilny",
        },
        {
          value: "stable",
          label: "Przypadek stabilny",
        },
      ];
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions({
      showToast: "toastNotification/showToast"
    }),
    allowEdit() {
      this.editable = true;
      this.readonly = false;
    },
    async submit(close) {
      this.loading = true;
      this.editable = false;
      this.errors = [];

      if(null !== this.hour){
        this.date.setHours(this.hour.HH, this.hour.mm);
      }

      if(null === this.status){
        await this.create(close);
      } else if(ApplicationFormStatus.DRAFT === this.status || ApplicationFormStatus.CREATED) {
        await this.update(close);
      }

      this.loading = false;
    },
    async create(close){
      const data = {
        ipzApplicationFormId: generateUuid(),
        ipzId: this.ipzId,
        applicationDate: null !== this.date ? stringifyDate(this.date) : null,
        applicationCategory: this.applicationCategory,
        applicant: {...this.applicant},
        patient: {...this.patient},
        description: this.description,
        caseType: this.caseType,
        termOfService: null !== this.termOfService ? stringifyDate(this.termOfService) : null,
        placeOfService: this.placeOfService,
        directedToPlace: this.directedToPlace,
        status: close ? ApplicationFormStatus.CREATED : ApplicationFormStatus.DRAFT
      };

      try {
        await create("/api/ipz/application-form", {...data});
        this.showToast({
          message: "Dane zostały zapisane.",
          variant: "success",
        });
        await this.getData();
      } catch (exception) {
        this.showToast({
          message: "Formularz zawiera błędy.\nDane nie zostały zapisane.",
          variant: "danger"
        });
        this.errors = processResponseException(exception);
      }
    },

    async update(close){
      const data = {
        ipzApplicationFormId: this.ipzApplicationFormId,
        applicationDate: null !== this.date ? stringifyDate(this.date) : null,
        applicationCategory: this.applicationCategory,
        applicant: {...this.applicant},
        patient: {...this.patient},
        description: this.description,
        caseType: this.caseType,
        termOfService: null !== this.termOfService ? stringifyDate(this.termOfService) : null,
        placeOfService: this.placeOfService,
        directedToPlace: this.directedToPlace,
        status: close ? ApplicationFormStatus.CREATED : ApplicationFormStatus.DRAFT
      };

      try {
        await update(`/api/ipz/application-form/${this.ipzApplicationFormId}`, {...data});
        this.showToast({
          message: "Dane zostały zapisane.",
          variant: "success",
        });
        await this.getData();
      } catch (exception) {
        this.showToast({
          message: "Formularz zawiera błędy.\nDane nie zostały zapisane.",
          variant: "danger"
        });
        this.errors = processResponseException(exception);
      }

    },

    async getData(){
      const data = await read(`/api/ipz/${this.ipzId}/application-form`);
      this.ipzApplicationFormId = data.ipzApplicationFormId;
      this.readonly = ApplicationFormStatus.CREATED === data.status;
      this.date = null !== data.applicationDate ? new Date(data.applicationDate) : null;
      this.hour = null !== this.date ? {
        HH: stringifyDate(this.date, DATE_FORMAT.HOUR),
        mm: stringifyDate(this.date, DATE_FORMAT.MINUTE)
      } : null;
      this.applicationCategory = data.applicationCategory;
      this.applicant = null !== data.applicant ? data.applicant : {};
      this.patient = data.patient;
      this.description = data.description;
      this.caseType = data.caseType;
      this.termOfService = null !== data.termOfService ? new Date(data.termOfService): null;
      this.placeOfService = data.placeOfService;
      this.directedToPlace = data.directedToPlace;
      this.status = data.status;
      this.ipzStatus = data.ipzStatus;
    },
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    subErrors(field) {
      return getSubErrors(this.errors, field);
    },
    updateEligiblePerson(val){
      this.applicant.eligiblePersonType = null !== val ? val.value : null;
    },
    updateIdentityCard(val){
      this.patient.identityCard = val.replace(/\s+/g, "");
    }
  }
}
</script>

<style scoped>

</style>
