<template>
  <b-modal
    id="createMedicalDataAuthorization"
    no-close-on-backdrop
    title="Dodaj upoważnienie do danych medycznych"
    title-tag="h3"
    size="lg"
    @hide="clearForm"
  >
    <div class="card-box">
      <error-message :errors="errors" />
      <FormRow
        :errors="errors"
        label="Imię osoby upoważnionej"
        field="authorizedPersonName"
      >
        <b-form-input
          v-model.trim="authorizedPersonName"
          :state="state('authorizedPersonName')"
        />
      </FormRow>

      <FormRow
        :errors="errors"
        label="Nazwisko osoby upoważnionej"
        field="authorizedPersonSurname"
      >
        <b-form-input
          v-model.trim="authorizedPersonSurname"
          :state="state('authorizedPersonSurname')"
        />
      </FormRow>

      <FormRow
        :errors="errors"
        label="Data zakończenia upoważnienia (opcjonalnie)"
        field="endOfAuthorization"
      >
        <date-picker
          v-model="endOfAuthorization"
          :state="state('endOfAuthorization')"
          :append-to-body="false"
        />
      </FormRow>

      <FormRow
        :errors="errors"
        label="Numer telefonu osoby upoważnionej"
        field="authorizedPersonPhoneNumber"
      >
        <b-form-input
          v-model.trim="authorizedPersonPhoneNumber"
          :state="state('authorizedPersonPhoneNumber')"
        />
      </FormRow>

      <FormRow
        :errors="errors"
        label="Adres e-mail osoby upoważnionej"
        field="authorizedPersonEmail"
      >
        <b-form-input
          v-model.trim="authorizedPersonEmail"
          :state="state('authorizedPersonEmail')"
        />
      </FormRow>
    </div>
    <template #modal-footer>
      <button
        class="btn btn-secondary"
        @click="hideModal"
      >
        Anuluj
      </button>
      <button
        :disabled="isLoading"
        class="btn btn-primary"
        @click="createMedicalDataAuthorization"
      >
        <i
          :class="isLoading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import FormRow from "../../Form/FormRow";
import DatePicker from "../../Form/DatePicker/DatePicker";
import {generateUuid} from "@/utils/uuid/generateUuid";
import create from "../../../rest/create";
import stringifyDate from "../../../utils/date/stringifyDate";
import processResponseException from "../../../utils/errors/processResponseException";
import ErrorMessage from "../../Form/ErrorMessage";
import errorsMixin from "../../../mixins/ErrorsMixin";

export default {
  components: {
    ErrorMessage,
    DatePicker,
    FormRow
  },
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      isLoading: false,
      authorizedPersonName: null,
      authorizedPersonSurname: null,
      endOfAuthorization: null,
      authorizedPersonPhoneNumber: null,
      authorizedPersonEmail: null
    };
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("createMedicalDataAuthorization");
    },
    clearForm() {
      this.authorizedPersonName = null;
      this.authorizedPersonSurname = null;
      this.endOfAuthorization = null;
      this.authorizedPersonPhoneNumber = null;
      this.authorizedPersonEmail = null;
      this.errors = [];
    },
    async createMedicalDataAuthorization() {
      try {
        this.isLoading = true;
        this.errors = [];
        const medicalDataAuthorizationId = generateUuid();
        await create(`/api/patient/${this.patientId}/medical-data-authorizations`, {
          medicalDataAuthorizationId: medicalDataAuthorizationId,
          patientId: this.patientId,
          authorizedPersonName: this.authorizedPersonName,
          authorizedPersonSurname: this.authorizedPersonSurname,
          endOfAuthorization: this.endOfAuthorization === null ? null : stringifyDate(this.endOfAuthorization),
          authorizedPersonPhoneNumber: this.authorizedPersonPhoneNumber,
          authorizedPersonEmail: this.authorizedPersonEmail,
        }
        );
        this.clearForm();
        this.$emit("medicalDataAuthorizationCreated");
        this.hideModal();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.isLoading = false;
    },
  },
}
</script>
