export default [
  {
    value: "patient_data",
    name:"na podstawie danych przekazanych przez pacjenta",
  },
  {
    value: "id_document",
    name:"okazanie przez pacjenta dokumentu potwierdzającego tożsamość",
  },
  {
    value: "electronic_patient_account",
    name:"wykorzystanie elektronicznego konta pacjenta",
  },
  {
    value: "other",
    name: "inny"
  }
];
