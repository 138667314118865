var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a',{staticClass:"btn btn-secondary btn-sm m-b-10",attrs:{"href":("/api/appointments/plan?patientId=" + _vm.patientId),"target":"_blank","title":"Pobierz plan wizyt"}},[_c('i',{staticClass:"fa fa-print"}),_vm._v(" Drukuj ")]),_c('is-granted',{attrs:{"privileges":[
      'SCHEDULE_APPOINTMENT', 'SCHEDULE_APPOINTMENT_SPECIALIST', 'SCHEDULE_APPOINTMENT_RECOVERY_ASSISTANT'
    ]}},[(_vm.nfzConfiguration)?_c('router-link',{staticClass:"btn btn-success btn-sm m-b-10 ml-2",attrs:{"to":{name: 'registration'}}},[_c('i',{staticClass:"fa fa-calendar-plus"}),_vm._v(" Umów wizytę ")]):_vm._e(),(!_vm.nfzConfiguration)?_c('router-link',{staticClass:"btn btn-success btn-sm m-b-10 ml-2",attrs:{"to":{name: 'calendarView', query: {continuousStay: false}}}},[_c('i',{staticClass:"fa fa-calendar-plus"}),_vm._v(" Umów wizytę ")]):_vm._e(),(!_vm.nfzConfiguration)?_c('router-link',{staticClass:"btn btn-outline-success btn-sm m-b-10 ml-2",attrs:{"to":{name: 'calendarView', query: {
        continuousStay: true,
        view: 'day',
        startDate: _vm.startDate,
        endDate: _vm.endDate,
      }}}},[_c('i',{staticClass:"fa fa-calendar-plus"}),_vm._v(" Przyjmij na OD ")]):_vm._e()],1),_c('router-link',{staticClass:"btn btn-primary btn-sm m-b-10 ml-2",attrs:{"to":("/appointments/list?patientId=" + _vm.patientId),"title":"Przejdź na stronę planu wizyt"}},[_c('i',{staticClass:"fa fa-arrow-right"}),_vm._v(" Pokaż plan wizyt ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }