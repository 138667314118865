<template>
  <div>
    <card
      :loading="loading"
      title="Obszar CZP"
    >
      <template #control>
        <b-btn
          v-b-modal.historyCZP
          :disabled="loading"
          variant="primary"
          class="btn-sm"
        >
          <i class="fa fa-pencil-alt" />
        </b-btn>
      </template>
      <p v-if="null !== outsideCZP">
        Pacjent spoza obszaru CZP: {{ outsideCZP ? "Tak" : "Nie" }}
      </p>
      <b-alert
        v-else
        show
        variant="warning"
      >
        Fukncjonalność niedostępna. Skontaktuj się z administratorem.
      </b-alert>
    </card>
    <b-modal
      id="historyCZP"
      title="Historia Zmian"
      title-tag="h3"
    >
      <table
        class="table table-sm"
      >
        <thead>
          <tr>
            <th>Pacjent spoza CZP</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(entry, index) in outsideCZPHistory"
            :key="index"
            class="p-3"
          >
            <td>{{ entry.outsideCZP ? "Tak" : "Nie" }}</td>
            <td>{{ formatDate(entry.createdAt) }}</td>
            <is-granted
              :privileges="['REMOVE_PATIENT_OUTSIDE_CZP_HISTORY']"
              component="fragment"
            >
              <td>
                <b-btn
                  :disabled="loading"
                  variant="outline-danger"
                  size="sm"
                  @click="remove(entry.id)"
                >
                  <i class="fa fa-trash" />
                </b-btn>
              </td>
            </is-granted>
          </tr>
        </tbody>
      </table>
      <template #modal-footer>
        <b-btn @click="closeModal">
          Zamknij
        </b-btn>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Card from "../../Card";
import read from "../../../rest/read";
import stringifyDate from "../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import processResponseException from "../../../utils/errors/processResponseException";
import IsGranted from "../../IsGranted";
import update from "../../../rest/update";

export default {
  name: "PatientCardOutsideCZP",
  components: {
    Card,
    IsGranted
  },
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      outsideCZP: null,
      loading: false,
      outsideCZPHistory: [],
    };
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("historyCZP");
    },
    formatDate(date) {
      return (date)? stringifyDate(new Date(date), DATE_FORMAT.DATE): "";
    },
    async remove(czpHistoryId) {
      this.loading = true;
      try {
        await update(`/api/patients/${this.patientId}/czp-history/${czpHistoryId}`, []);
        await this.loadData();
        this.errors = [];
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    async loadData() {
      this.loading = true;
      const response = await read(`/api/patients/${this.patientId}/outside-czp`);
      this.outsideCZP = response.outsideCZP;
      this.outsideCZPHistory = response.items;
      this.loading = false;
    }
  }
}
</script>
