<template>
  <div>
    <b-btn
      v-if="copyId"
      variant="outline-secondary"
      class="m-b-20"
      @click="backToNewAppointmentRegistration"
    >
      <span class="fas fa-arrow-left" />
      Zarejestruj nową wizytę
    </b-btn>

    <registration-filters
      :filters="{start, selectedWorkerId, dayView}"
      @reload="fetchData"
    />

    <transition
      name="fade"
      mode="out-in"
    >
      <registration-widget
        v-if="selectedWorkerId"
        key="registrationCalendar"
        ref="registrationWidget"
        :copy-id="copyId"
        :filters="{start, selectedWorkerId, dayView}"
      />

      <div
        v-else
        key="registrationInfo"
        class="card-box"
      >
        <span class="d-block text-center">
          Należy wybrać specjalistę i wcisnąć przycisk szukaj, aby zobaczyć jego kalendarz dostępności
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapState} from "vuex";
import RegistrationFilters from "../../components/Registration/RegistrationFilters";
import RegistrationWidget from "../../components/Registration/RegistrationWidget";
import isEmpty from "lodash/isEmpty";

export default {
  name: "Registration",
  components: {RegistrationWidget, RegistrationFilters},
  props: {
    start: {type: String, default: null},
    selectedWorkerId: {type: String, default: null},
    dayView: {type: Boolean, default: true},
    copyId: {type: String, default: null}
  },
  computed: {
    ...mapState("registrationFilters", ["savedFilters",]),
  },
  watch: {
    "$route": {
      immediate: true,
      async handler() {
        this.$bvModal.hide("appointment-info-modal");

        if (isEmpty(this.$route.query) && this.savedFilters) {
          await this.$router.replace({
            path: this.$route.path,
            query: this.savedFilters
          });
          return;
        }

        await this.fetchData();
      }
    },
  },
  methods: {
    async fetchData() {
      setTimeout(() => {
        this.$refs.registrationWidget && this.$refs.registrationWidget.fetchData()
      });
    },
    backToNewAppointmentRegistration() {
      this.$router.push({
        name: "registration",
      });
    }
  },
}
</script>

<style scoped lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .4s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
