<template>
  <collapse-list
    :collapsed="collapsed"
    :items="items"
  >
    <template slot-scope="data">
      <component
        :is="component"
        :key="data.item.patientId"
        :to="linkTo(data.item.patientId)"
        class="item d-block"
      >
        <insurance-status-icon
          v-if="undefined !== data.item.insured"
          :status="data.item.insured"
          size="10px"
          style="display: inline-block"
        />
        {{ data.item.displayName }}
      </component>
    </template>
  </collapse-list>
</template>
<script>
import CollapseList from "../Collapse/CollapseList";
import InsuranceStatusIcon from "../Patient/Insurance/InsuranceStatusIcon";

export default {
  components: {InsuranceStatusIcon, CollapseList},
  props: {
    items: {type: Array, required: true},
    link: {type: Boolean, default: false},
    collapsed: {type: Boolean, default: true},
  },
  computed: {
    component() {
      return this.link ? "router-link" : "div";
    },
  },
  methods: {
    linkTo(patientId) {
      return this.link ? {
        name: "patientCard",
        params: {patientId},
      } : undefined;
    },
  }
}
</script>
<style scoped lang="scss">
  .collapse, .collapsing {
    .item {
      margin-top: 5px;
    }
  }
</style>
