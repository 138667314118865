import { render, staticRenderFns } from "./PatientCardPermissions.vue?vue&type=template&id=5ff5c2ed&scoped=true&"
import script from "./PatientCardPermissions.vue?vue&type=script&lang=js&"
export * from "./PatientCardPermissions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ff5c2ed",
  null
  
)

export default component.exports