<template>
  <div>
    <card
      :loading="loading"
      title="DPS"
    >
      <template #control>
        <is-granted
          :privileges="['PATIENT_CHANGE_DPS']"
        >
          <b-btn
            v-b-modal.changeDps
            :disabled="loading"
            variant="primary"
            class="btn-sm"
          >
            <i class="fa fa-pencil-alt" />
          </b-btn>
        </is-granted>
      </template>
      <div>
        <p>
          Pacjent w DPS: {{ null !== currentDpsStatus ? currentDpsStatus ? "Tak" : "Nie" : "Brak Informacji" }}
        </p>
        <p v-if="currentDpsStatus">
          Data rozpoczęcia: {{ lastDps.startDate }}
        </p>
      </div>
    </card>
    <b-modal
      id="changeDps"
      no-close-on-backdrop
      title="Pacjent w DPS"
      title-tag="h3"
      @hide="resetFormData"
      @shown="setLastRecordToFormData"
    >
      <template #default>
        <b-form-group
          label="Pacjent w DPS"
          label-for="dps"
        >
          <dps-select
            v-model="formData.dps"
            :state="state('dps')"
            :disabled="editable"
          />
          <error-message
            :errors="errors"
            field="dps"
          />
        </b-form-group>
        <b-form-group
          v-if=" (true !== currentDpsStatus && true === formData.dps) || editable"
          label="Data rozpoczęcia"
        >
          <date-picker
            v-model="formData.startDate"
            :disabled="loading"
            :state="state('startDate')"
          />
          <error-message
            :errors="errors"
            field="startDate"
          />
        </b-form-group>
        <b-form-group
          v-if="(true === currentDpsStatus && false === formData.dps) || editable"
          label="Data zakończenia"
        >
          <date-picker
            v-model="formData.endDate"
            :disabled="loading"
            :state="state('endDate')"
          />
          <error-message
            :errors="errors"
            field="endDate"
          />
        </b-form-group>

        <div
          v-show="dpsHistory.length"
          v-b-toggle="'dpsHistoryHistory'"
          class="text-center m-2 cursor-pointer"
        >
          ---
          <span class="when-closed">Pokaż historię</span>
          <span class="when-opened">Ukryj historię</span>
          ---
        </div>
        <b-collapse id="dpsHistoryHistory">
          <table
            v-show="dpsHistory.length"
            class="table table-sm"
          >
            <thead>
              <tr>
                <th>DPS</th>
                <th>Data rozpoczęcia</th>
                <th>Data zakończenia</th>
                <th>Akcje</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(entry, index) in dpsHistory"
                :key="index"
                class="p-3"
              >
                <td>{{ entry.dps ? "Tak" : "Nie" }}</td>
                <td>{{ formatDate(entry.startDate) }}</td>
                <td>{{ formatDate(entry.endDate) }}</td>
                <td v-if="null !== entry.endDate">
                  <b-btn
                    :disabled="loading"
                    variant="outline-secondary"
                    class="mr-1"
                    size="sm"
                    @click="edit(entry)"
                  >
                    <i class="fas fa-pencil-alt" />
                  </b-btn>

                  <b-btn
                    :disabled="loading"
                    variant="outline-danger"
                    size="sm"
                    @click="remove(entry.id)"
                  >
                    <i class="fa fa-trash" />
                  </b-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </b-collapse>
      </template>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="close()"
        >
          Anuluj
        </button>
        <button
          :disabled="loading"
          class="btn btn-primary"
          @click="updateDpsData"
        >
          <i
            :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fa"
          />
          Zapisz
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Card from "../../Card";
import IsGranted from "../../IsGranted";
import {errorsMixin} from "../../../mixins/errorsMixin";
import ErrorMessage from "../../Form/ErrorMessage";
import read from "../../../rest/read";
import update from "../../../rest/update";
import processResponseException from "../../../utils/errors/processResponseException";
import DpsSelect from "../../Form/Select/OpsSelect";
import DatePicker from "../../Form/DatePicker/DatePicker"
import stringifyDate from "../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import {DpsActionType} from "../../../types/Dps";
import parseDate from "../../../utils/date/parseDate";

export default {
  name: "PatientCardDps",
  components: {
    DpsSelect,
    Card,
    IsGranted,
    ErrorMessage,
    DatePicker
  },
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      errors: [],
      dpsHistory: [],
      loading: false,
      lastDps: null,
      formData: {
        dps: null,
        startDate: null,
        endDate: null
      },
      editable: false,
      currentDpsStatus: null
    };
  },
  computed: {
    DpsActionType() {
      return DpsActionType
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async remove(dpsHistoryId) {
      this.loading = true;
      try {
        const data = {
          dpsHistoryId: dpsHistoryId,
          actionType: DpsActionType.delete,
        }
        await update(`/api/patients/${this.patientId}/dps`, data);
        await this.loadData();

        this.errors = [];
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    async edit(item) {
      this.editable = true
      this.formData = {
        ...item,
        startDate: this.stringToDate(item.startDate),
        endDate: this.stringToDate(item.endDate),
      }
    },
    async loadData(){
      this.loading = true;
      const response = await read(`/api/patients/${this.patientId}/dps`);
      this.lastDps = response.items[0] ?? null;
      this.currentDpsStatus = response.dps
      this.setLastRecordToFormData();
      this.dpsHistory = response.items;
      this.loading = false;
    },
    stringToDate(dateString) {
      return dateString ? parseDate(dateString, DATE_FORMAT.DATE) : null;
    },
    formatDate(date) {
      return (date) ? stringifyDate(new Date(date), DATE_FORMAT.DATE): "";
    },
    state(field){
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    resetFormData(){
      this.formData = {
        dps: null,
        startDate: null,
        endDate: null
      }
      this.editable = false;
    },
    close(){
      this.resetFormData()
      this.$bvModal.hide("changeDps");
    },
    async updateDpsData(){
      this.loading = true;
      try {
        const data = {
          dpsHistoryId: this.formData?.id,
          actionType: this.editable ? DpsActionType.update : DpsActionType.add,
          dps: this.formData.dps,
          startDate: (this.formData.startDate !== null) ? stringifyDate(new Date(this.formData.startDate) , DATE_FORMAT.DATE) : null,
          endDate: (this.formData.endDate !== null) ? stringifyDate(new Date(this.formData.endDate) , DATE_FORMAT.DATE) : null,
          statusBeforeUpdate: this.currentDpsStatus
        }
        await update(`/api/patients/${this.patientId}/dps`, data);
        await this.loadData();
        this.errors = [];
        this.close()
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    setLastRecordToFormData() {
      if(this.currentDpsStatus === true){
        this.formData = {
          ...this.formData,
          dps: true,
        }
      } else if(this.currentDpsStatus === false){
        this.formData = {
          ...this.formData,
          dps: false,
        }
      } else {
        this.formData = {
          ...this.formData,
          dps: null,
        }
      }
    }
  }
}
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
