














































































































































import {Component, Mixins, Prop} from "vue-property-decorator";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import IsGranted from "../../components/IsGranted.vue";
import ErrorMessage from "../../components/Form/ErrorMessage.vue";
import Card from "../../components/Card.vue";
import read from "../../rest/read";
import {
  BranchLioczNumerators,
  EstablishmentNumerators,
} from "../../types/Establishment";
import processResponseException from "../../utils/errors/processResponseException";
import update from "../../rest/update";


@Component({
  name: "EstablishmentNumeratorsPage",
  components: {
    IsGranted,
    ErrorMessage,
    Card,
  },
})
export default class EstablishmentNumeratorsPage extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly establishmentId!:string;

  private loading: boolean = true;

  private swiadNrGen: number = 0;

  private newSwiadNrGen: null|string = null;

  private lioczNrGen: number = 0;

  private newLioczNrGen: null|string = null;

  private newDailyUnitsLiocz: object[] = [];

  private newMonthlyUnitsLiocz: object[] = [];

  private branchLioczNumerators: BranchLioczNumerators[] = [];

  private editing: boolean = false;

  private saving: boolean = false;


  async mounted():Promise<void> {
    await this.fetchData();
  }
  async fetchData(): Promise<void> {
    this.loading = true;
    try {
      const {swiadNrGen, lioczNrGen, branchLioczNumerators} = await read<EstablishmentNumerators>(
        `/api/branches/${this.establishmentId}/nfz-numerators`
      );
      this.swiadNrGen = swiadNrGen;
      this.lioczNrGen = lioczNrGen;
      this.branchLioczNumerators = branchLioczNumerators;
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  async submit() {
    this.saving = true;
    try {
      await update(`/api/branches/${this.establishmentId}/nfz-numerators`, {
        swiadNrGen: null !== this.newSwiadNrGen ? parseInt(this.newSwiadNrGen) : null,
        lioczNrGen: null !== this.newLioczNrGen ? parseInt(this.newLioczNrGen) : null,
        lioczDailyVersions: this.newDailyUnitsLiocz,
        lioczMonthlyVersions: this.newMonthlyUnitsLiocz
      });
      this.editing = false;
      this.resetData();
      await this.fetchData();
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.saving = false;
  }

  private resetData(){
    this.newSwiadNrGen = null;
    this.newLioczNrGen = null;
    this.newDailyUnitsLiocz = [];
    this.newMonthlyUnitsLiocz = [];
  }

  private updateMonthlyBranchLiocz(number : string, branchId : string) {

    this.newMonthlyUnitsLiocz = this.newMonthlyUnitsLiocz.filter(object => {return object.branchId !== branchId})

    let version = parseInt(number);

    if(Number.isInteger(version)){
      this.newMonthlyUnitsLiocz.push({
        branchId: branchId,
        version: version
      })
    }
  }

  private updateDailyBranchLiocz(number : string, branchId : string) {

    this.newDailyUnitsLiocz = this.newDailyUnitsLiocz.filter(object => {return object.branchId !== branchId})

    let version = parseInt(number);

    if(Number.isInteger(version)){
      this.newDailyUnitsLiocz.push({
        branchId: branchId,
        version: version
      })
    }
  }

  private searchValue(branchId: string, type: string){
    if(type === "daily"){
      let value = this.newDailyUnitsLiocz.filter(obj => {
        return obj.branchId === branchId
      });

      return value.length > 0 ? value[0].version : null;
    }
    if(type === "monthly"){
      let value = this.newMonthlyUnitsLiocz.filter(obj => {
        return obj.branchId === branchId
      });

      return value.length > 0 ? value[0].version : null;
    }
    return null;
  }

  private startEditing() {
    this.editing = true;
  }

  private cancelEditing() {
    this.editing = false;
  }
}
