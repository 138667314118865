<template>
  <b-form-group>
    <ewus-permissions-select
      v-model="ewus"
      :state="state('ewus')"
      @input="update"
    />
    <error-message
      :errors="errors"
      field="ewus"
    />
  </b-form-group>
</template>

<script>
import ErrorMessage from "../Form/ErrorMessage";
import EwusPermissionsSelect from "../Form/Select/EwusPermissionsSelect";

export default {
  name: "CreatePatientEwusPermissions",
  components: {
    ErrorMessage,
    EwusPermissionsSelect
  },
  props: {
    value: {type: Array, default: () => []},
    errors: {type: Array, default: () => []},
  },
  data() {
    return {
      ewus: this.value
    }
  },
  methods: {
    state(field){
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    update(value){
      this.$emit("input", value);
    }
  }
}
</script>

<style scoped>

</style>
