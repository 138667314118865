<template>
  <div>
    <card
      :loading="loading"
      title="Forma opieki"
    >
      <template #control>
        <is-granted
          :privileges="['CHANGE_PATIENT_FORM_OF_CARE']"
        >
          <b-btn
            v-b-modal.changeFormOfCareModal
            :disabled="loading"
            variant="primary"
            class="btn-sm"
          >
            <i class="fa fa-pencil-alt" />
          </b-btn>
        </is-granted>
      </template>
      <div v-if="lastFormOfCare">
        <p>Typ: {{ formOfCareType(lastFormOfCare.formOfCareType) }}</p>
        <p>Data rozpoczęcia: {{ lastFormOfCare.startDate }}</p>
        <p>
          Data zakończenia:
          <span v-if="lastFormOfCare.endDate">
            {{ lastFormOfCare.endDate }}
          </span>
          <span v-else>w trakcie</span>
        </p>
      </div>
      <p v-else>
        Brak
      </p>
    </card>
    <b-modal
      id="changeFormOfCareModal"
      no-close-on-backdrop
      :title="modalTitle"
      title-tag="h3"
    >
      <template #default>
        <b-form-group
          label="Forma opieki"
          label-for="formOfCare"
        >
          <patient-form-of-care-select
            v-model="formData.formOfCareType"
            :disabled="loading || editable"
            :state="state('formOfCare.formOfCareType')"
          />
          <error-message
            :errors="errors"
            field="formOfCare.formOfCareType"
          />
        </b-form-group>
        <b-form-group
          label="Data rozpoczęcia"
          label-for="startDate"
        >
          <date-picker
            id="startDate"
            v-model="formData.startDate"
            :disabled="loading"
            :state="state('formOfCare.startDate')"
            :append-to-body="false"
          />
          <error-message
            :errors="errors"
            field="formOfCare.startDate"
          />
        </b-form-group>
        <b-form-group
          v-if="editable"
          label="Data zakończenia"
          label-for="endDate"
        >
          <date-picker
            id="endDate"
            v-model="formData.endDate"
            :disabled="loading"
            :state="state('formOfCare.endDate')"
          />
          <error-message
            :errors="errors"
            field="formOfCare.endDate"
          />
        </b-form-group>
        <div
          v-show="formOfCareHistory.length"
          v-b-toggle="'formOfCareHistory'"
          class="text-center m-2 cursor-pointer"
        >
          ---
          <span class="when-closed">Pokaż historię</span>
          <span class="when-opened">Ukryj historię</span>
          ---
        </div>
        <b-collapse id="formOfCareHistory">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Forma Opieki</th>
                <th>Data rozpoczęcia</th>
                <th>Data zakończenia</th>
                <th>Akcje</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(entry, index) in formOfCareHistory"
                :key="index"
                class="p-3"
              >
                <td>{{ formOfCareType(entry.formOfCareType) }}</td>
                <td>{{ entry.startDate }}</td>
                <td>{{ entry.endDate }}</td>
                <td>
                  <b-btn
                    :disabled="loading"
                    variant="outline-secondary"
                    class="mr-1"
                    size="sm"
                    @click="edit(entry)"
                  >
                    <i class="fas fa-pencil-alt" />
                  </b-btn>

                  <b-btn
                    :disabled="loading"
                    variant="outline-danger"
                    size="sm"
                    @click="remove(entry.id)"
                  >
                    <i class="fa fa-trash" />
                  </b-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </b-collapse>
      </template>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="cancel()"
        >
          Anuluj
        </button>
        <button
          :disabled="loading"
          class="btn btn-primary"
          @click="submit"
        >
          <i
            :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fa"
          />
          Zapisz
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Card from "../../../Card";
import IsGranted from "../../../IsGranted";
import PatientFormOfCareSelect from "../../../Form/Select/PatientFormOfCareSelect";
import ErrorMessage from "../../../Form/ErrorMessage";
import DatePicker from "../../../Form/DatePicker/DatePicker";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import read from "../../../../rest/read";
import update from "../../../../rest/update";
import remove from "../../../../rest/remove";
import processResponseException from "../../../../utils/errors/processResponseException";
import {generateUuid} from "../../../../utils/uuid/generateUuid";
import stringifyDate from "../../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../../utils/date/DATE_FORMAT";
import parseDate from "../../../../utils/date/parseDate";
import t from "../../../../i18n";

export default {
  name: "PatientCardFormOfCare",
  components: {
    PatientFormOfCareSelect,
    IsGranted,
    Card,
    ErrorMessage,
    DatePicker,
  },
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      lastFormOfCare: null,
      formData: {},
      formOfCareHistory: [], // Array<{id, startDate, endDate, formOfCareType}>
      loading: false,
      editable: false
    };
  },
  computed: {
    modalTitle() {
      return this.editable ? "Zakończ formę opieki" : "Dodaj formę opieki";
    }
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData(){
      this.loading = true;
      const response = await read(`/api/patients/${this.patientId}/form_of_care`);
      this.formOfCareHistory = response.items;
      this.lastFormOfCare = response.items[0] ?? null;
      this.setLastRecordToFormData();
      this.editable = this.formData && null === this.formData.endDate;
      this.loading = false;
    },
    cancel(){
      this.errors = [];
      this.setLastRecordToFormData();
      this.$bvModal.hide("changeFormOfCareModal");
    },
    edit(item) {
      this.editable = true;
      this.formData = {
        ...item,
        startDate: this.stringToDate(item.startDate),
        endDate: this.stringToDate(item.endDate),
      };
    },
    async submit(){
      this.loading = true;
      try {
        const data = this.editable
          ? {actionType: "update",
            formOfCare: {
              ...this.formData,
              startDate: this.dateString(this.formData.startDate),
              endDate: this.dateString(this.formData.endDate)
            }
          }
          : {actionType: "add",
            formOfCare: {
              ...this.formData,
              id: generateUuid(),
              startDate: this.dateString(this.formData.startDate)
            }
          };

        await update(`/api/patients/${this.patientId}/form_of_care`, data);

        if (!this.editable) {
          this.$bvModal.hide("changeFormOfCareModal");
        }

        await this.loadData();
        this.errors = [];
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    async remove(formOfCareId) {
      this.loading = true;
      try {
        await remove(`/api/patients/${this.patientId}/form_of_care/${formOfCareId}`, {});
        await this.loadData();
        this.errors = [];
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    dateString(date) {
      return date ? stringifyDate(date, DATE_FORMAT.DATE) : null;
    },
    stringToDate(dateString) {
      return dateString?  parseDate(dateString, DATE_FORMAT.DATE) : null;
    },
    formOfCareType(formOfCareType){
      if(formOfCareType){
        return t(`@patient-form-of-care.${formOfCareType}`)
      }
      return null;
    },
    setLastRecordToFormData(){
      this.formData = this.lastFormOfCare && null === this.lastFormOfCare.endDate ? {
        ...this.lastFormOfCare,
        startDate: this.stringToDate(this.lastFormOfCare.startDate)
      } : {};
    }
  }

}
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
