












































































import {Component, Mixins} from "vue-property-decorator";
import ReportContainer from "../../../components/Report/ReportContainer.vue";
import ErrorsMixin from "../../../mixins/ErrorsMixin";
import ErrorMessage from "../../../components/Form/ErrorMessage.vue";
import EstablishmentSelect from "../../../components/Branch/EstablishmentSelect.vue";
import read from "../../../rest/read";
import {
  BenefitPerformanceReportInterface,
  EstablishmentUnitPriceNumberDiscriminant,
  EstablishmentUnitPriceNumberDiscriminantUnit, UnitEntries
} from "../../../types/Establishment";
import processResponseException from "../../../utils/errors/processResponseException";
import Card from "../../../components/Card.vue";
import BenefitPerformanceTable from "./BenefitPerformanceTable.vue";

@Component({
  name: "BenefitPerformanceReport",
  components: {BenefitPerformanceTable, ReportContainer, ErrorMessage, EstablishmentSelect, Card},
})

export default class BenefitPerformanceReport extends Mixins(ErrorsMixin) {
  private establishment: Object | null = null;
  private establishmentId: string | null = null;
  private loading: boolean = false;
  public units: UnitEntries[] = [];
  public establishmentName: string = "";

  public availableYears: number[] = [2022, 2023, 2024, 2025]

  public selectedYear: number = new Date().getFullYear()

  private setEstablishmentId(est){
    this.establishmentId = est.value
  }

  async fetchData(): Promise<void> {
    this.loading = true;
    try {
      this.errors = [];
      const {establishmentName, units} = await read<BenefitPerformanceReportInterface>(
        "/api/reports/benefit-performance-report", {establishmentId: this.establishmentId}
      );
      this.establishmentName = establishmentName;
      this.units = units;
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private openTableData(year: number): void {
    this.selectedYear = year;
  }

}

