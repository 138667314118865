<template>
  <object-select
    :value="selectedValues"
    :options="options"
    :state="state"
    placeholder="Wybierz kategorię pobytu"
    :multiple="multiple"
    @input="update"
  />
</template>

<script>
import t from "../../../../i18n";
import ObjectSelect from "../../../Form/Select/ObjectSelect";

export default {
  components: {ObjectSelect},
  props: {
    value: {type: [Array, String], default: () => []},
    state: ObjectSelect.props.state, //eslint-disable-line
    multiple: {type: Boolean, default: false},
  },
  data() {
    const options = [
      {name: t("@continuousStay.daily"), value: "daily"},
      {name: t("@continuousStay.daily_up_to_4_hours"), value: "daily_up_to_4_hours"},
      {name: t("@continuousStay.full_day"), value: "full_day"},
      {name: t("@continuousStay.in_secured_flat"), value: "in_secured_flat"},
      {name: t("@continuousStay.overnight_intervention_spaces"), value: "overnight_intervention_spaces"},
      {name: t("@continuousStay.rehabilitation_sanatorium"), value: "rehabilitation_sanatorium"},
      {name: t("@continuousStay.in_hostel"), value: "in_hostel"},
      {name: t("@continuousStay.away_workshops"), value: "away_workshops"},
    ];

    return {
      options
    };
  },
  computed: {
    selectedValues() {
      return Array.isArray(this.value) ? this.value.map(element => {
        return this.options.find(option => option.value === element);
      }) : this.options.find(option => option.value === this.value);
    },
  },
  methods: {
    update(continuousStayCategories) {
      this.$emit("input", this.multiple ?
        continuousStayCategories.map(category => category.value) :
        continuousStayCategories.value
      )
    }
  },
}
</script>
