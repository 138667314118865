










































import {Component, Mixins, Prop, Ref} from "vue-property-decorator";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import ErrorMessage from "../Form/ErrorMessage.vue";
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import read from "../../rest/read";
import processResponseException from "../../utils/errors/processResponseException";
import {ResponseCollection} from "../../types/ResponseCollection";
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";

export interface AcceptedReferral {
  id: string,
  creator: string,
  createdAt: Date,
  status: string,
  extension: string,
  appointmentDocumentId: string,
  document: Document | null,
}

@Component({
  name: ReferralSelect.name,
  components: {ErrorMessage, VueMultiselect,},
  directives: {
    tabindex: {
      inserted(el) {
        el.setAttribute("tabindex", "0");
      },
    },
  },
})
export default class ReferralSelect extends Mixins(ErrorsMixin) {
  @Prop({type: Object, default: null}) readonly value!: AcceptedReferral;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: String, default: null}) readonly patientId!: string;
  @Prop({type: String, default: null}) readonly appointmentDocumentId!: string;
  @Prop({type: String, default: "Wybierz skierowanie"}) readonly placeholder!: string;
  @Ref("multiselect") readonly multiselect!: VueMultiselect;

  private options: AcceptedReferral[] =  [];
  private loading: boolean =  false;

  private subject: Subject<string> = new Subject();

  async mounted(): Promise<void> {
    this.subject.pipe(
      debounceTime(500)
    ).subscribe(this.loadOptions);
    this.subject.next("");
  }

  private async search(phrase: string = ""): Promise<void> {
    this.subject.next(phrase);
  }

  private async loadOptions(phrase: string = ""): Promise<void> {
    this.loading = true;
    try {
      const {items} = await read<ResponseCollection<AcceptedReferral>>("/api/find-accepted-referral", {
        patientId: this.patientId,
        appointmentDocumentId: this.appointmentDocumentId,
        toAssign: true,
        phrase,
      });
      this.options = items;
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private update(referral: AcceptedReferral|null): void {
    this.$emit("input", referral);
  }

  private onSelect(referral: AcceptedReferral|null): void {
    this.$emit("select", referral);
  }

  private cancelLoading(): void {
    this.loading = false;
  }

  private onBlur(): void {
    this.$emit("blur");
  }

}
