<template>
  <b-modal
    :id="`finishWaitingListRecord-${waitingListId}`"
    title="Wykreślenie wpisu listy z oczekujących"
    no-close-on-backdrop
    title-tag="h3"
    size="lg"
    @hide="clearModal"
  >
    <template #default>
      <b-form-group
        label="Data wykreślenia"
      >
        <date-picker
          v-model="endDate"
          :state="state('endDate')"
          clearable
        />
        <error-message
          :errors="errors"
          field="endDate"
        />
      </b-form-group>
      <b-form-group
        v-if="displayReasonTextarea"
        id="endReason"
        label="W systemie jest aktualny nowszy wpis tego typu. Podaj przyczynę zamknięcia wybranego poza kolejnością."
        label-for="endReasonInput"
      >
        <b-form-textarea
          id="endReasonInput"
          :value="endReason"
          :state="state('endReason')"
          :rows="5"
          @input="updateTextarea"
        />
        <error-message
          :errors="errors"
          field="endReason"
        />
      </b-form-group>
    </template>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary"
        @click="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Wykreśl wpis z listy oczekujących
      </button>
    </template>
  </b-modal>
</template>

<script>
import modify from "../../rest/modify";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../../components/Form/ErrorMessage";
import DatePicker from "../../components/Form/DatePicker/DatePicker";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import stringifyDate from "../../utils/date/stringifyDate";

export default {
  name: "WaitingListFinishRecordModal",
  components: {
    ErrorMessage,
    DatePicker
  },
  props: {
    waitingListId: {type: String, required: true}
  },
  data(){
    return {
      loading: false,
      errors: [],
      endReason: null,
      displayReasonTextarea: false,
      endDate: null
    }
  },
  methods: {
    async submit(){
      this.loading = true;
      try{
        await modify (`/api/waiting-list/${this.waitingListId}/finish`, {
          waitingListId: this.waitingListId,
          endDate: this.endDate ? stringifyDate(this.endDate, DATE_FORMAT.DATE): null,
          endReason: this.endReason,
        });
        this.closeModal();
        this.$emit("finished");
      } catch (exception) {
        this.errors = processResponseException(exception);
        this.displayReasonTextarea = (null !== this.endReason && this.endReason.length > 0) || this.endReason === ""
          || this.errors.find((error) => error.field === "endReasonRequired");
      }
      this.loading = false
    },
    closeModal(){
      this.$bvModal.hide(`finishWaitingListRecord-${this.waitingListId}`);
    },
    updateTextarea(value) {
      this.endReason = value;
    },
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    clearModal(){
      this.endReason = null;
      this.endDate = null;
      this.displayReasonTextarea = false;
      this.errors = [];
    }
  }
}
</script>

