



















































































































































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import {Snapshot} from "../../views/Ipz/Tabs/NfzTherapeuticAndRecoveryPlanPanel.vue";
import t from "../../i18n";
import Card from "../Card.vue";
import IsGranted from "../IsGranted.vue";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import TherapeuticAndRecoveryDeleteModal from "./TherapeuticAndRecoveryDeleteModal.vue";

@Component({
  name: TherapeuticAndRecoverySnapshotTable.name,
  components: {
    Card,
    IsGranted, 
    TherapeuticAndRecoveryDeleteModal
  }
})
export default class TherapeuticAndRecoverySnapshotTable extends Mixins(ErrorsMixin) {
  @Prop({type: Array, default: ()=>[]}) readonly items!: Snapshot[];
  private snapshots: Snapshot[] = [];
  private translateStatus(status: string) {
    return t(`@ipz-target-status.${status}`);
  }

  private async removeSnapshot(snapshotId: string): Promise<void>
  {
    this.$emit("removeSnapshot", snapshotId);
  }

  private parseDate(date: string): string
  {
    return stringifyDate(new Date(date), DATE_FORMAT.DATE);
  }

  private showDeleteModal() {
    this.$bvModal.show("therapeutic-and-recovery-delete-modal")
  }

  @Watch("items", {immediate: true}) async onChange(): Promise<void>
  {
    this.snapshots = this.items;
  }
}
