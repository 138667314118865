




















































































































































































































































import {Component, Mixins, Prop, Ref, Watch} from "vue-property-decorator";
import ReferralTypeSelect from "./ReferralTypeSelect.vue";
import ErrorMessage from "../Form/ErrorMessage.vue";
import NfzBranchSelect from "../Form/Select/NfzBranchSelect.vue";
import ResortCodeSelect from "./ResortCodeSelect.vue";
import RecognitionForm from "./RecognitionForm.vue";
import ReferralRecognitions from "./ReferralRecognitions.vue";
import Icd9CodeSelect from "./Icd9CodeSelect.vue";
import TextareaCounter from "../Form/Textarea/TextareaCounter.vue";
import type {Patient, Recognition, RecognitionSubmit, Referral,} from "../../types/Referrals";
import {ReferralStatus, ReferralType,} from "../../types/Referrals";
import create from "../../rest/create";
import {generateUuid} from "../../utils/uuid/generateUuid";
import processResponseException from "../../utils/errors/processResponseException";
import {Error} from "../../rest";
import subErrors from "../../utils/errors/subErrors";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import read from "../../rest/read";
import eventBus from "../../eventBus";
import {BModal} from "bootstrap-vue";
import type {Icd9CodeOption} from "../../types/IcdCodes";
import type {Option} from "../../types/MultiSelectTypes";

interface SubmitData {
  eReferralId: string;
  appointmentDocumentId: string;
  patientId: string;
  birthPlace: string;
  type?: Referral["type"];
  nfzBranch?: string;
  cito: boolean;
  referralTo?: Option;
  icd10MainRecognition?: RecognitionSubmit;
  icd10AdditionalRecognitions: RecognitionSubmit[];
  procedures: string[];
  comment: string;
  password: string;
}

interface ReferralStatusResponse {
  status: ReferralStatus;
  fileId: string;
  code: string;
}

const computeRecognitionData = (recognition: Recognition): RecognitionSubmit => ({
  code: recognition.code?.value || undefined,
  comment: recognition.comment || "",
});

@Component({
  components: {
    TextareaCounter,
    Icd9CodeSelect,
    ReferralRecognitions,
    RecognitionForm,
    ResortCodeSelect,
    NfzBranchSelect,
    ErrorMessage,
    ReferralTypeSelect,
  },
})
export default class CreateReferralModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly appointmentDocumentId!: string;
  @Prop({type: Object, required: true}) readonly patient!: Patient

  @Prop({type: Number, required: true}) readonly indexKey!: number

  @Ref() readonly createReferral!: BModal

  private loading: boolean = false;
  private birthPlace: string = this.patient.birthPlace || "";
  private referralType: ReferralType|null = null;
  private nfzBranch: string|null = null;
  private cito: boolean = false;
  private referralTo: Option|null = null;
  private mainRecognition: Recognition = {};
  private additionalRecognitions: Recognition[] = [{}];
  private procedures: Icd9CodeOption[] = [];
  private comment: string = "";
  private password: string = "";

  private referralId: string|null = null;
  private referralStatus: ReferralStatus|null = null;
  private fileId: string|null = null;
  private timeout: number = 0;

  private get isCompleted(): boolean {
    return this.referralStatus == ReferralStatus.COMPLETED;
  }

  private get readonly(): boolean {
    return !!this.referralStatus;
  }

  private get statusInfo(): string {
    switch(this.referralStatus) {
      case ReferralStatus.CREATED:
        return "e-Skierowanie zostało zweryfikowane i przekazane do realizacji." +
          " Otrzymasz powiadomienie po jego wystawieniu.";
      case ReferralStatus.COMPLETED:
        return "e-Skierowanie zostało przygotowane. Kliknij przycisk \"Drukuj\" aby pobrać wydruk.";
      case ReferralStatus.REJECTED:
        return "Wystawianie e-Skierowania nie powiodło się.";
      default:
        return "";
    }
  }

  private get statusInfoVariant(): string {
    switch(this.referralStatus) {
      case ReferralStatus.CREATED:
      case ReferralStatus.COMPLETED:
        return "success";
      case ReferralStatus.REJECTED:
        return "danger";
      default:
        return "";
    }
  }

  @Watch("patient") onPatientChange(newVal: Patient): void {
    this.birthPlace = newVal.birthPlace || "";
  }

  private getSubErrors(field: string): Error[] {
    return subErrors(this.errors, field);
  }

  hideModal(): void {
    this.$bvModal.hide(`create-referral-${this.indexKey}`);
  }

  onHide(): void {
    if (this.referralId && this.referralStatus === ReferralStatus.CREATED){
      this.$emit("prepared");
      eventBus.on(this.referralId, ()=>{ this.$emit("created") });
    } else if (this.referralStatus === ReferralStatus.COMPLETED) {
      this.$emit("created");
    }
    this.resetData();
  }

  async submit(): Promise<void> {
    this.loading = true;
    this.clearErrors();
    const eReferralId = generateUuid();
    const icd10MainRecognition: RecognitionSubmit|undefined = this.mainRecognition.code
      ? computeRecognitionData(this.mainRecognition)
      : undefined;
    const icd10AdditionalRecognitions = this.additionalRecognitions.reduce<RecognitionSubmit[]>((list,recognition) => {
      if (recognition.code) {
        list.push(computeRecognitionData(recognition));
      }
      return list;
    }, []);
    try {
      const data: SubmitData = {
        eReferralId,
        appointmentDocumentId: this.appointmentDocumentId,
        patientId: this.patient.patientId,
        birthPlace: this.birthPlace,
        type: this.referralType || undefined,
        nfzBranch: this.nfzBranch || undefined,
        cito: this.cito,
        referralTo: this.referralTo || undefined,
        icd10MainRecognition,
        icd10AdditionalRecognitions,
        procedures: this.procedures.map(procedure => procedure.value),
        comment: this.comment,
        password: this.password,
      };
      await create<SubmitData>("/api/ereferrals", data);
      this.referralId = eReferralId;
      await this.getStatus(eReferralId);
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private async getStatus(referralId: string): Promise<void> {
    const {status, fileId} = await read<ReferralStatusResponse>(`/api/referral-status/${referralId}`);
    this.referralStatus = status;
    this.fileId = fileId;
    if (this.createReferral.$data.isShow && status === ReferralStatus.CREATED) {
      this.timeout = window.setTimeout(async () => {
        await this.getStatus(referralId);
      }, 2000);
    }
  }

  resetData(): void {
    this.birthPlace = this.patient.birthPlace || "";
    this.loading = false;
    this.referralType = null;
    this.nfzBranch = null;
    this.cito = false;
    this.referralTo = null;
    this.mainRecognition = {};
    this.additionalRecognitions = [{}];
    this.procedures = [];
    this.comment = "";
    this.password = "";
    this.referralId = null;
    this.referralStatus = null;
    this.fileId = null;
    clearTimeout(this.timeout);
    this.clearErrors();
  }

  clearErrors(): void {
    this.errors = [];
  }
}
