<template>
  <div>
    <div class="page-title-box">
      <h4 class="page-title">
        {{ nfzConfiguration ? "Zgłoszenie PZK" : "Zgłoszenie interwencyjne nr" }} {{ ordinalNumber }}
      </h4>
      <div class="clearfix" />
    </div>
    <last-edited-appointment />
    <div class="card-box m-t-10">
      <div class="row">
        <div class="col-12 m-b-20 border-bottom border-light">
          <div class="text-right">
            <is-granted
              :privileges="['UPDATE_INTERVENTION_NOTIFICATION']"
              :subject="interventionNotificationId"
            >
              <router-link
                :to="{name: nfzConfiguration
                  ? 'application_pzk_update_view'
                  : 'intervention_notification_update_view', params: {interventionNotificationId}}"
                class="btn btn-primary waves-effect waves-light m-b-20"
              >
                <i class="fa fa-pencil-alt" />
                Edytuj
              </router-link>
            </is-granted>
            <a
              :href="`/api/intervention-notifications/${interventionNotificationId}/print`"
              target="_blank"
              rel="noopener noreferrer"
              class="btn btn-secondary waves-effect waves-light m-b-20 ml-2"
            >
              <i class="fa fa-print" />
              Wydrukuj
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="font-18 text-line">
            <p>Numer: {{ ordinalNumber }}</p>
          </div>
          <div class="font-18 text-line">
            <p>Rodzaj kontaktu: <trans :msgid="category ? category : '-'" /></p>
          </div>
          <div
            v-if="sex"
            class="font-18 text-line"
          >
            <p>Płeć: <trans :msgid="sex ? `@sex.${sex}` : '-'" /></p>
          </div>
          <div
            v-if="age"
            class="font-18 text-line"
          >
            <p>Przedział wiekowy:<trans :msgid="age ? `@age.${age}` : '-'" /></p>
          </div>
          <div class="font-18 text-line">
            <p>Początek interwencji: {{ stringifyDate(startDate) }}</p>
          </div>
          <div class="font-18 text-line">
            <p>
              Czas trwania interwencji:
              <template v-if="duration">
                {{ duration }} minut
              </template>
            </p>
          </div>
        </div>
        <div class="col-6">
          <div class="font-18 text-line">
            <p>Utworzono: {{ createdAt }}</p>
          </div>
          <div class="font-18 text-line">
            <p>Autor: {{ authorName }}</p>
          </div>
          <div
            v-if="additionalWorkers"
            class="font-18 text-line"
          >
            Specjaliści dodatkowi:
            <ul>
              <li
                v-for="additionalWorker in additionalWorkers"
                :key="additionalWorker.workerId"
                class="font-18 text-line"
              >
                {{ additionalWorker.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12">
          <div
            v-if="additionalInformations"
            class="font-18 text-line"
          >
            Informacje dodatkowe:
            <ul>
              <li>
                Udzielanie informacji o zakresie działania centrum i możliwości uzyskania świadczeń opieki zdrowotnej:
                {{ scopeOfActivitiesAndHealthcareInformation ? "Tak" : "Nie" }}
              </li>
              <li>
                Wskazanie miejsca uzyskania niezbędnego świadczenia z zakresu pomocy społecznej:
                {{ benefitsOfSocialAssistance ? "Tak" : "Nie" }}
              </li>
              <li>
                <!-- eslint-disable -->
                Wstępna ocena potrzeb zdrowotnych i udzielenie wsparcia oraz uzgodnienie wstępnego planu dalszego postępowania:
                {{ initialAssessmentHealthNeedsAndProvidingSupport ? "Tak" : "Nie" }}
              </li>
              <li>
                Uzgodnienie terminu i miejsca uzyskania świadczeń w CZP:
                {{ agreeingDateAndPlaceCZPServices ? "Tak" : "Nie" }}
              </li>
              <li>
                Udzielenie świadczeń zdrowotnych:
                {{ providingHealthServices ? "Tak" : "Nie" }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 m-t-30">
          <p style="white-space: pre-line; overflow: hidden;">
            {{ description }}
          </p>
        </div>
      </div>
    </div>
    <router-link
      :to="{name: nfzConfiguration ? 'pzk_applications_list' : 'intervention_notification_list'}"
      class="btn btn-secondary float-right m-r-15"
    >
      Powrót do listy
    </router-link>
  </div>
</template>

<script>
import Trans from "../../components/i18n"
import IsGranted from "../../components/IsGranted";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import read from "../../rest/read";
import LastEditedAppointment from "../../components/Appointment/LastEditedAppointment";
import {mapState} from "vuex";

export default {

  components: {
    LastEditedAppointment,
    IsGranted,
    Trans
  },
  props: {
    interventionNotificationId: {type: String, required: true}
  },
  data() {
    return {
      ordinalNumber: null,
      category: null,
      sex: null,
      age: null,
      description: null,
      createdAt: null,
      authorName: null,
      startDate: null,
      duration: null,
      additionalWorkers: null,
      scopeOfActivitiesAndHealthcareInformation: null,
      benefitsOfSocialAssistance: null,
      displayAdditionalInformations: false,
      initialAssessmentHealthNeedsAndProvidingSupport: false,
      agreeingDateAndPlaceCZPServices: false,
      providingHealthServices: false,
    }
  },
  computed: {
    ...mapState({
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration
    }),
    additionalInformations(){
      return null !== this.scopeOfActivitiesAndHealthcareInformation || null !== this.benefitsOfSocialAssistance;
    }
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      const data = await read(`/api/intervention-notifications/${this.interventionNotificationId}`);
      this.ordinalNumber = data.ordinalNumber;
      this.category = data.category;
      this.sex = data.sex;
      this.age = data.age;
      this.description = data.description;
      this.createdAt = data.createdAt;
      this.authorName = data.authorName;
      this.startDate = data.startDate;
      this.duration = data.duration;
      this.additionalWorkers = data.additionalWorkers;
      this.scopeOfActivitiesAndHealthcareInformation = data.scopeOfActivitiesAndHealthcareInformation;
      this.benefitsOfSocialAssistance = data.benefitsOfSocialAssistance;
      this.displayAdditionalInformations = data.displayAdditionalInformations;
      this.initialAssessmentHealthNeedsAndProvidingSupport = data.initialAssessmentHealthNeedsAndProvidingSupport
      this.agreeingDateAndPlaceCZPServices = data.agreeingDateAndPlaceCZPServices;
      this.providingHealthServices = data.providingHealthServices;
    },
    stringifyDate(date) {
      return null !== date ? stringifyDate(new Date(date), DATE_FORMAT.DATE_TIME) : null;
    },
  },
}
</script>
<style scoped>
  .text-line {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }
</style>
