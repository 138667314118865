<template>
  <vue-multiselect
    v-tabindex
    :options="options"
    :value="options.find((option) => option.value === value)"
    track-by="value"
    label="label"
    :multiple="multiple"
    :state="state"
    placeholder="Obszar CZP"
    :disabled="disabled"
    :clear-on-select="!multiple"
    :internal-search="false"
    close-on-select
    allow-empty
    deselect-label="Odznacz"
    select-label="Wybierz"
    selected-label="Wybrany"
    @input="update"
    @open="open"
  >
    <template #noResult>
      <span>Brak wyników wyszukiwania</span>
    </template>
    <template #noOptions>
      <span />
    </template>
  </vue-multiselect>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect";

export default {
  name: "CzpAreaSelect",
  components: {VueMultiselect},
  directives: {
    tabindex: {
      inserted(el) {
        el.setAttribute("tabindex", 0);
      },
    },
  },
  props: {
    value: {type: [Array, Object, String], default: null},
    multiple: {type: Boolean, default: false},
    clearOnClick: {type: Boolean, default: false},
    state: {type: Boolean, default: null},
    disabled: {type: Boolean, default: false},
  },
  data() {
    return {
      options: [
        {label: "Tak", value: "true"},
        {label: "Nie", value: "false"},
      ],
      selectedOption: null,
    };
  },
  methods: {
    update(option) {
      this.$emit("input", option ? option.value : null);
    },
    open() {
      this.$emit("open");
      if (this.clearOnClick) {
        this.options = [];
      }
    },
  },
}
</script>

<style scoped>

</style>
