<template>
  <div class="d-inline">
    <div v-if="!onlyLabel">
      <error-message :errors="errors" />

      <object-select
        ref="multiselect"
        :value="branch"
        :options="options"
        :disabled="loading || disabled"
        :loading="loading"
        :state="state"
        placeholder="Wybierz Oddział NFZ"
        @blur="blur"
        @close="close"
        @input="update"
        @select="select"
      />
    </div>

    <span v-else>
      <loading-icon
        v-if="loading"
        size="sm"
      />

      <span v-else>
        {{ branch
          ? `${branch.value} (${branch.name})`
          : "Brak"
        }}
      </span>
    </span>
  </div>
</template>

<script>
import ObjectSelect from "./ObjectSelect";
import read from "../../../rest/read";
import processResponseException from "../../../utils/errors/processResponseException";
import ErrorMessage from "../ErrorMessage";
import LoadingIcon from "@/components/Loading/LoadingIcon";

export default {
  name: "NfzBranchSelect",
  components: {
    LoadingIcon,
    ErrorMessage,
    ObjectSelect,
  },
  props: {
    value: {type: Object, default: null},
    state: ObjectSelect.props.state, // eslint-disable-line
    disabled: {type: Boolean, default: false},
    onlyLabel: {type: Boolean, default: false},
  },
  data() {
    return {
      loading: true,
      branch: null,
      options: [],
      errors: [],
    };
  },
  watch: {
    value(value) {
      this.branch = value ? this.options
        .find(function (branchOption) {
          return branchOption ? branchOption.branchId === value.branchId : false;
        })
        : null;
    },
  },
  async mounted() {
    try {
      const {items} = await read("/api/nfz-branches");
      this.options = items.map(item => ({
        ...item,
        value: item.branchId,
      }));

      this.branch = this.options
        .find((branchOption) => (branchOption && this.value) ? branchOption.branchId === this.value.branchId : false);
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.loading = false
  },
  methods: {
    update(branch) {
      this.branch = branch;
      this.$emit("input", branch ? branch : null);
    },
    activate() {
      this.$refs.multiselect.activate();
    },
    select(option) {
      this.$emit("select", option);
    },
    blur() {
      this.$emit("blur");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
