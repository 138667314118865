






























































import ErrorsMixin from "../../mixins/ErrorsMixin";
import {Component, Prop} from "vue-property-decorator";
import ErrorMessage from "../Form/ErrorMessage.vue";
import ResizableTextarea from "../Form/Textarea/ResizableTextarea.vue";
import create from "../../rest/create";
import processResponseException from "../../utils/errors/processResponseException";

@Component({
  name: RefuseReferralModal.name,
  components: {ErrorMessage,ResizableTextarea,}
})
export default class RefuseReferralModal extends (ErrorsMixin) {
  @Prop({type: String, default: null}) readonly id!: string;

  private reason: string = "";
  private loading: boolean = false;

  private async refuse(): Promise<void>
  {
    try{
      this.loading = true;
      await create("/api/refuse-accepted-referral", {
        acceptReferralId: this.id,
        reason: this.reason
      });
      this.hideModal();
      this.$emit("refresh");
    }catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  hideModal(): void {
    this.reason = "";
    this.errors = [];
    this.$bvModal.hide("refuse-referral");
  }

}
