<template>
  <div>
    <card
      :loading="loading"
      title="Adres korespondencyjny"
    >
      <template #control>
        <is-granted
          :privileges="['CHANGE_PATIENT_CORRESPONDENCE_ADDRESS']"
        >
          <b-btn
            v-b-modal.correspondenceAddressModal
            :disabled="loading || errors.length > 0"
            variant="primary"
            class="btn-sm"
          >
            <i class="fa fa-pencil-alt" />
          </b-btn>
        </is-granted>
      </template>
      <error-message :errors="errors" />
      <p>
        <span v-if="correspondenceAddress.street">
          {{ correspondenceAddress.street }}
          <!-- eslint-disable-next-line -->
          {{ correspondenceAddress.buildingNumber }}<span v-if="correspondenceAddress.apartmentNumber">/{{ correspondenceAddress.apartmentNumber }}</span>,
        </span>
        {{ correspondenceAddress.zipCode }}
        {{ correspondenceAddress.city }}&nbsp;
      </p>
    </card>
    <b-modal
      id="correspondenceAddressModal"
      hide-footer
      no-close-on-backdrop
      title="Edycja adresu korespondencyjnego"
      title-tag="h3"
    >
      <correspondence-address-form
        :correspondence-address="correspondenceAddress"
        :living-address="livingAddress"
        :patient-id="patientId"
        @updateCorrespondenceAddress="updateCorrespondenceAddress"
        @cancel="cancel"
      />
    </b-modal>
  </div>
</template>

<script>
import read from "../../../rest/read";
import processResponseException from "../../../utils/errors/processResponseException";
import ErrorMessage from "../../Form/ErrorMessage";
import CorrespondenceAddressForm from "../CorrespondenceAddressForm";
import Card from "../../Card";
import IsGranted from "../../IsGranted";

export default {
  components: {
    IsGranted,
    Card,
    CorrespondenceAddressForm,
    ErrorMessage,
  },
  props: {
    patientId: {type: String, required: true},
    livingAddress: {type: Object, default: null},
  },
  data() {
    return {
      correspondenceAddress: {},
      errors: [],
      loading: false,
    };
  },
  watch: {
    patientId() {
      this.fetchData();
    },
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        this.correspondenceAddress = await read(`/api/patients/${this.patientId}/correspondence_address`);
      } catch(exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    updateCorrespondenceAddress(correspondenceAddress) {
      this.correspondenceAddress = correspondenceAddress;
    },
    cancel() {
      this.$bvModal.hide("correspondenceAddressModal");
      this.errors = [];
    },
  },
};
</script>

<style scoped>
  .card-box {
    overflow: hidden;
  }
</style>
