















































































import Vue from "vue";
import Component from "vue-class-component";
import read from "../../rest/read";
import {Error} from "../../rest"
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import stringifyDate from "../../utils/date/stringifyDate";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../Form/ErrorMessage.vue"

interface Item {
  id: string
  name: string
  surname: string
  birthDate: string
  registeredDate: string
}

@Component({
  name: "PatientArchiveSearchModal",
  components: {
    ErrorMessage,
  }
})
export default class extends Vue {
  pesel: string = ""
  errors: Error[] = []
  loading: boolean = false
  result: Item[] = []

  resetData() {
    this.pesel = ""
    this.loading = false
    this.errors = []
    this.result = []
  }

  async fetch() {
    this.loading = true

    const pesel = this.pesel.trim()

    try {
      const response = await read<{items: Item[]}>(`/api/archival/patient/${pesel}`)
      this.result = response.items.map((item) => Object.freeze({...item, pesel}))
      this.pesel = pesel
    } catch (error) {
      this.errors = processResponseException(error)
    }

    this.loading = false
  }

  formatDate(date: string) {
    return stringifyDate(new Date(date), DATE_FORMAT.DATE);
  }

  selectItem(item: Item) {
    this.$bvModal.hide("patientArchiveSearchModal")
    this.$emit("selected", item)
    this.resetData()
  }
}
