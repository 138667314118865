<template>
  <vue-multi-select
    ref="select"
    v-tabindex
    :internal-search="false"
    :value="value"
    :class="{invalid: state === false}"
    :options="options"
    :loading="loading"
    :disabled="disabled"
    :close-on-select="true"
    :allow-empty="false"
    :show-no-results="false"
    deselect-label=""
    hide-selected
    preserve-search
    tag-placeholder=""
    select-label="Wybierz"
    label="label"
    track-by="id"
    placeholder=""
    @open="presetPhrase"
    @input="select"
    @search-change="searchChange"
  >
    <template #caret>
      <span />
    </template>
    <template #noOptions>
      <span />
    </template>
  </vue-multi-select>
</template>

<script>
import VueMultiSelect from "vue-multiselect";
import rest from "../../rest";

export default {
  components: {VueMultiSelect},
  directives: {
    tabindex: {
      inserted(el) {
        el.setAttribute("tabindex", 0);
      },
    },
  },
  props: {
    state: {type: Boolean, default: null},
    excludeStreet: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false}
  },
  data() {
    return {
      value: null,
      loading: false,
      typingTimeout: null,
      options: [],
    };
  },
  methods: {
    searchChange(phrase) {
      this.loadOptions(phrase);
    },
    async loadOptions(phrase) {
      if (!phrase || phrase.length <= 3) {
        return;
      }
      clearTimeout(this.typingTimeout);
      this.typingTimeout = setTimeout(async () => {
        this.loading = true;
        try {
          this.options = await this.fetchData(phrase);
        } catch (e) {
          console.error(e);
        }
        this.loading = false;
      }, 500);
    },
    async fetchData(phrase) {
      if(this.excludeStreet){
        const include = ["city", "commune", "province"];
        const params = {phrase, include: include.join(","), excludeStreet: true};
        const {data, included} = await rest.read("/api/addresses", params);
        const resolveIncluded = (relation) => included.find(
          ({type, id}) => type === relation.type && id === relation.id
        );

        return data.map(({id,relationships}) => {
          const city = resolveIncluded(relationships.city.data).attributes;
          const commune = resolveIncluded(relationships.commune.data).attributes;
          const province = resolveIncluded(relationships.province.data).attributes;

          const label = `${city.name} (${commune.type} ${commune.name}, ${province.type} ${province.name})`;

          return Object.freeze({
            terytId: id,
            label,
            city: city.name,
            commune: commune.type !== "dzielnica" ? commune.name : null,
            district: commune.type === "dzielnica" ? commune.name : null,
            province: province.name
          });
        });
      } else {
        const include = ["street", "city", "commune", "province"];
        const params = {phrase, include: include.join(",")};
        const {data, included} = await rest.read("/api/addresses", params);
        const resolveIncluded = (relation) => included.find(
          ({type, id}) => type === relation.type && id === relation.id
        );

        return data.map(({id, attributes,relationships}) => {
          const {apartmentNumber, buildingNumber} = attributes;
          const street = relationships.street ? resolveIncluded(relationships.street.data).attributes: null;
          const city = resolveIncluded(relationships.city.data).attributes;
          const commune = resolveIncluded(relationships.commune.data).attributes;
          const province = resolveIncluded(relationships.province.data).attributes;

          const suffix = [buildingNumber, apartmentNumber].filter(noEmpty).join("/");
          const value = street
            ? [street.prefix, street.name,suffix].filter(noEmpty).join(" ")
            : [city.name, suffix].filter(noEmpty).join(" ");
          const label = `${value}, ${city.name} (${commune.type} ${commune.name}, ${province.type} ${province.name})`;

          return Object.freeze({
            terytId: id,
            label,
            street: street ? street.name : city.name,
            city: city.name,
            buildingNumber: buildingNumber,
            apartmentNumber: apartmentNumber,
            commune: commune.type !== "dzielnica" ? commune.name : null,
            district: commune.type === "dzielnica" ? commune.name : null,
            province: province.name
          });
        });
      }
    },
    presetPhrase() {
      this.$refs.select.search = this.street;
    },
    select(option) {
      if (!option) {
        return;
      }
      this.$emit("address", option);
    },
  }
}
const noEmpty = a => a;
</script>
