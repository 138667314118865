












































































import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import FormRow from "../Form/FormRow.vue";
import NfzBranchSelect from "../Form/Select/NfzBranchSelect.vue";
import InsuranceProviderOperatorType from "../Form/Select/InsuranceProviderOperatorType.vue";
import Vue from "vue";
import {Error} from "../../rest";
import {Access as EwusAccess} from "./EwusWorkerSettings.vue";

@Component({
  name: "EwusAccessForm",
  components: {InsuranceProviderOperatorType, NfzBranchSelect, FormRow},
})
export default class EwusAccessForm extends Vue {
  @Prop({type: Object, required: true}) readonly value!: Partial<EwusAccess>;
  @Prop({type: Boolean, default: false}) readonly displayInfo!: boolean;
  @Prop({type: Array, default: ()=>[]}) readonly propErrors!: Error[];

  update($event: string | null | undefined, field: string) {
    this.$emit("input", {
      ...this.value,
      [field]: $event,
    });
  }

  updateDomainValue($event: {branchId: string} | null) {
    const domain = $event ? $event.branchId : undefined;
    const access: Partial<EwusAccess> = {
      ...this.value,
      domain,
    };
    this.$emit("input", access)
  }

  state(field: string) {
    return this.propErrors.find((error) => error.field === field) ? false : null;
  }

  @Watch("value", {immediate: true})
  watchDomain(value: Partial<EwusAccess>) {
    if (value.doctorId && !this.showDoctorId(value)) {
      this.update(undefined, "doctorId");
    }
  }

  showDoctorId(value: Partial<EwusAccess> = this.value) {
    if (!value.domain || !["01", "04", "05", "06", "08", "09", "11", "12"].includes(value.domain)) {
      return false;
    }

    return value.operatorType === "LEK";
  }
}
