




































































import ErrorsMixin from "../../mixins/ErrorsMixin";
import {Component, Mixins, Prop} from "vue-property-decorator";
import ErrorMessage from "../Form/ErrorMessage.vue";
import processResponseException from "../../utils/errors/processResponseException";
import update from "../../rest/update";
import {
  EstablishmentUnitPriceNumberDiscriminantUnit,
  EstablishmentUnitPriceNumberDiscriminantUnitType,
  UnitPriceNumberDiscriminantDTO,
  UpdateUnitPriceNumberDiscriminantEntries
} from "../../types/Establishment";
import UnitTypeSelect from "../Form/Select/UnitTypeSelect.vue";

interface UnitFormData {
  type: EstablishmentUnitPriceNumberDiscriminantUnitType;
  serviceScopeCode: string;
}

@Component({
  name: "AddUnitPriceNumberContractDataFormModal",
  components: {UnitTypeSelect, ErrorMessage}
})
export default class EstablishmentP1DataModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly establishmentId!: string;
  @Prop({type: Array, required: true}) readonly units!: EstablishmentUnitPriceNumberDiscriminantUnit[];

  loading: boolean = false;
  modalId: string = "addUnitPriceNumberContractDataFormModal"

  type: EstablishmentUnitPriceNumberDiscriminantUnitType | null = null
  serviceScopeCode: string = ""

  closeModal(): void {
    this.$bvModal.hide(this.modalId);

    this.type = null;
    this.serviceScopeCode = ""
  }

  async submit(): Promise<void> {
    this.errors = [];

    if (!this.type || !this.serviceScopeCode) {
      if (!this.type) {
        this.errors = [{field: "type", message: "Pole jest wymagane"}]
      }
      if (!this.serviceScopeCode) {
        this.errors = [...this.errors, {field: "serviceScopeCode", message: "Pole jest wymagane"}]
      }

      return
    }

    this.loading = true;

    const newUnitData: UnitPriceNumberDiscriminantDTO = {
      type: this.type!,
      serviceScopeCode: this.serviceScopeCode,
      entries: []
    };

    const payloadUnits = [
      ...this.units.map(unit => ({
        unitId: unit.id,
        type: unit.type,
        serviceScopeCode: unit.serviceScopeCode,
        entries: unit.entries,
      })),
      newUnitData
    ]

    try {
      await update("/api/unit/price-number-discriminant-entries", {
        establishmentId: this.establishmentId,
        entries: payloadUnits
      } as UpdateUnitPriceNumberDiscriminantEntries)

      this.closeModal();
      this.$emit("added")
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }
}
