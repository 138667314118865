<template>
  <div
    class="col-12 loaded-pdf-data white-bg font-options"
  >
    <div class="col-12 m-b-40 p-t-10">
      <h1 class="text-center">
        Arkusz Przyjęciowy
      </h1>
      <h2 class="text-center">
        {{ parsedAdmissionDate }}
      </h2>
    </div>
    <div class="col-12 m-b-40">
      <p><b>Imię i nazwisko:</b> {{ patient.name }} {{ patient.surname }}</p>
      <p><b>PESEL:</b> {{ patient.pesel }}</p>
      <p><b>Specjalista:</b> {{ creatorName }}</p>
    </div>
    <div class="col-12 m-b-40">
      <h3
        class="text-center m-b-20"
      >
        Informacje ogólne
      </h3>
      <p><b>Stan cywilny:</b> {{ translate("maritalStatus", generalInformation.maritalStatus) }}</p>
      <p><b>Wykształcenie:</b> {{ translate("education", generalInformation.education) }}</p>
      <p><b>Źródło utrzymania:</b> {{ translate("sourceOfIncome", generalInformation.sourceOfIncome) }}</p>
      <p>
        <b>Sytuacja mieszkaniowa:</b>
        {{ translate("residentialSituation", generalInformation.residentialSituation) }}
      </p>
      <div class="m-t-15">
        <b>Opieka Ośrodka Pomocy Społecznej:</b>
      </div>
      <p>{{ generalInformation.careOfSocialWelfare }}</p>
      <div class="m-t-15">
        <b>Sytuacja rodzinna:</b>
      </div>
      <p>{{ generalInformation.familySituation }}</p>
    </div>
    <div class="col-12 m-b-40">
      <h3
        class="text-center m-b-20"
      >
        Dane ogólnomedyczne
      </h3>
      <div class="m-t-15">
        <b>Schorzenia współistniejące:</b>
      </div>
      <p>{{ medicalData.concomitantConditions }}</p>
      <div class="m-t-15">
        <b>Leki przyjmowane na stałe:</b>
      </div>
      <p>{{ medicalData.permanentMedications }}</p>
      <div class="m-t-15">
        <b>Uczulenia:</b>
      </div>
      <p>{{ medicalData.allergies }}</p>
      <div class="m-t-15">
        <b>Inne istotne dane medyczne (urazy, operacje, zabiegi, itp.):</b>
      </div>
      <p>{{ medicalData.otherMedical }}</p>
    </div>
    <div class="col-12 m-b-40">
      <h3
        class="text-center m-b-20"
      >
        Dane dotyczące przebiegu dotychczasowego leczenia
      </h3>
      <p>
        <b>Czy dotychczas leczony/a psychiatrycznie?:</b>
        {{ translate("treatedPsychiatrically", previousTreatment.treatedPsychiatrically) }}
      </p>
      <div class="m-t-15">
        <b>Przebieg leczenia (pierwsza konsultacja psychiatryczna, ostatnia konsultacja psychiatryczna):</b>
      </div>
      <p>{{ previousTreatment.courseOfTreatment }}</p>
      <div class="m-t-15">
        <b>Aktualnie pod opieką:</b>
      </div>
      <p>{{ previousTreatment.currentlyUnderCare }}</p>
      <div class="m-t-15">
        <b>Pomoc psychologa (grupy wsparcia, AA, AN, psychoterapia):</b>
      </div>
      <p>{{ previousTreatment.psychologistsHelp }}</p>
      <div class="m-t-15">
        <b>Przymusowe leczenie:</b>
      </div>
      <p>{{ previousTreatment.compulsoryTreatment }}</p>
    </div>
    <div class="col-12 m-b-40">
      <h3
        class="text-center m-b-20"
      >
        Diagnostyka – uzależnienia
      </h3>
      <p><b>Uzależnienia:</b> {{ translate("addictionsGeneral", addictions.general) }}</p>
      <div v-if="addictions.general === 'active-addiction'">
        <p>
          {{ addictionsActive }}
        </p>
        <p><b>Inne, jakie?</b></p>
        <p>{{ addictions.activeOther }}</p>
      </div>
      <p v-if="addictions.general === 'takes-abstinence'">
        <b>Data startu abstynecji:</b> {{ addictionsAbstinenceDate }}
      </p>
    </div>
    <div class="col-12 m-b-40">
      <h3
        class="text-center m-b-20"
      >
        Ocena ryzyka samobójstwa (autoagresji) / zachowań agresywnych
      </h3>
      <p><b>Myśli rezygnacyjne:</b> {{ translate("lackPresent", suicideRisk.resignationThoughts) }}</p>
      <p>
        <b>Wypowiadanie gróźb, złowrogie zamiary:</b>
        {{ translate("lackPresentSuspicion", suicideRisk.sinisterIntentions) }}
      </p>
      <p>
        <b>Myśli samobójcze:</b>
        {{ translate("lackPresentSuspicion", suicideRisk.thoughtsOfSuicide) }}
      </p>
      <p>
        <b>Zachowania agresywne czynne:</b> {{ translate("lackPresent", suicideRisk.activeAggressiveBehavior) }}
      </p>
      <p><b>Plany samobójcze:</b> {{ translate("lackPresentSuspicion", suicideRisk.suicidePlans) }}</p>
      <p>
        <b>Zachowania agresywne w przeszłości:</b>
        {{ translate("yesNo4Weeks", suicideRisk.aggressiveBehaviorInPast) }}
      </p>
      <p>
        <b>Próba samobójcza w przeszłości:</b>
        {{ translate("attemptedSuicide", suicideRisk.pastAttemptedSuicide) }}
      </p>
      <p>
        <b>Autoagresja:</b>
        {{ translate("yesNo", suicideRisk.autoaggression) }}
      </p>
      <p>{{ suicideRisk.other }}</p>
    </div>
    <div class="col-12 m-b-40">
      <h3
        class="text-center m-b-20"
      >
        Wywiad dotyczący potrzeb / trudności / powodu zgłoszenia pacjenta
      </h3>
      <p>{{ interviewOnNeeds.general }}</p>
      <div class="m-t-15">
        <b>Postrzeganie problemu / trudności pacjenta przez otoczenie</b>
      </div>
      <p>{{ interviewOnNeeds.perceptionOfProblem }}</p>
    </div>
    <div class="col-12 m-b-40">
      <h3
        class="text-center m-b-20"
      >
        Ocena stanu psychicznego
      </h3>
      <p>
        <b>Kontakt z pacjentem:</b> {{ translate("contactWithPatient", mentalCondition.contactWithPatient) }}
        <br>{{ mentalCondition.contactWithPatientOther }}
      </p>
      <p>
        <b>Orientacja autopsychiczna:</b>
        {{ translate("autopsychicOrientation", mentalCondition.autopsychicOrientation) }}
        <br>{{ mentalCondition.autopsychicOrientationOther }}
      </p>
      <p>
        <b>Orientacja allopsychiczna:</b>
        {{ translate("allopsychicOrientation", mentalCondition.allopsychicOrientation) }}
        <br>{{ mentalCondition.allopsychicOrientationOther }}
      </p>
      <p>
        <b>Kontakt wzrokowy:</b> {{ translate("eyeContact", mentalCondition.eyeContact) }}
        <br>{{ mentalCondition.eyeContactOther }}
      </p>
      <p>
        <b>Wygląd:</b> {{ translate("appearance", mentalCondition.appearance) }}
        <br>{{ mentalCondition.appearanceOther }}
      </p>
      <p>
        <b>Budowa ciała:</b> {{ translate("physique", mentalCondition.physique) }}
        <br>{{ mentalCondition.physiqueOther }}
      </p>
      <p>
        <b>Uwaga:</b> {{ translate("attention", mentalCondition.attention) }}
        <br>{{ mentalCondition.attentionOther }}
      </p>
      <p>
        <b>Mowa:</b> {{ translate("speech", mentalCondition.speech) }}
        <br>{{ mentalCondition.speechOther }}
      </p>
      <p>
        <b>Niepokój psychoruchowy:</b>
        {{ translate("psychomotorRestlessness", mentalCondition.psychomotorRestlessness) }}
        <br>{{ mentalCondition.psychomotorRestlessnessOther }}
      </p>
      <p>
        <b>Napęd:</b> {{ translate("drive", mentalCondition.drive) }}
        <br>{{ mentalCondition.driveOther }}
      </p>
      <p>
        <b>Nastrój:</b> {{ translate("mood", mentalCondition.mood) }}
        <br>{{ mentalCondition.moodOther }}
      </p>
      <p>
        <b>Afekt:</b> {{ translate("affect", mentalCondition.affect) }}
        <br>{{ mentalCondition.affectOther }}
      </p>
      <p>
        <b>Lęk:</b> {{ translate("anxiety", mentalCondition.anxiety) }}
        <br>{{ mentalCondition.anxietyOther }}
      </p>
      <p>
        <b>Kompulsje/Natręctwa:</b> {{ translate("compulsions", mentalCondition.compulsions) }}
        <br>{{ mentalCondition.compulsionsOther }}
      </p>
      <p>
        <b>Urojenia:</b> {{ translate("delusions", mentalCondition.delusions) }}
        <br>{{ mentalCondition.delusionsOther }}
      </p>
      <p>
        <b>Zab. formy myślenia:</b>
        {{ translate("blockingFormOfThinking", mentalCondition.blockingFormOfThinking) }}
        <br>{{ mentalCondition.blockingFormOfThinkingOther }}
      </p>
      <p>
        <b>Pamięć:</b> {{ translate("memory", mentalCondition.memory) }}
        <br>{{ mentalCondition.memoryOther }}
      </p>
      <p>
        <b>Wgląd:</b> {{ translate("view", mentalCondition.view) }}
        <br>{{ mentalCondition.viewOther }}
      </p>
      <p>
        <b>Omamy:</b> {{ translate("hallucinations", mentalCondition.hallucinations) }}
        <br>{{ mentalCondition.hallucinationsOther }}
      </p>
      <p>
        <b>Zaburzenia snu:</b> {{ translate("sleepDisturbance", mentalCondition.sleepDisturbance) }}
        <br>{{ mentalCondition.sleepDisturbanceOther }}
      </p>
      <p>
        <b>Apetyt:</b> {{ translate("appetite", mentalCondition.appetite) }}
        <br>{{ mentalCondition.appetiteOther }}
      </p>
    </div>
  </div>
</template>

<script>
import read from "../../rest/read";
import {AdmissionSheetStatus} from "../../types/Ipz";
import items from "../../utils/ipz/AdmissionSheetItems";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import stringifyDate from "../../utils/date/stringifyDate";
import t from "../../i18n";

export default {
  name: "AdmissionSheetPdfTemplate",
  props: {
    ipzId: {type: String, required: true},
  },
  data() {
    return {
      items,
      loading:false,
      patient: {},
      generalInformation: {},
      medicalData: {},
      previousTreatment: {},
      addictions: {
        general: null
      },
      suicideRisk: {},
      interviewOnNeeds: {},
      mentalCondition: {},
      readonly: false,
      status: null,
      admissionDate: null,
      creatorName: null,
    };
  },
  computed: {
    addictionsAbstinenceDate(){
      return null !== this.addictions.abstinence ? stringifyDate(this.addictions.abstinence, DATE_FORMAT.DATE) : null;
    },
    addictionsActive(){
      return this.addictions.active.map(item => this.translate("addictionsActive", item)).join(", ") || null;
    },
    parsedAdmissionDate(){
      return null !== this.admissionDate ? stringifyDate(this.admissionDate, DATE_FORMAT.DATE) : null;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    translate(prop, val){
      if(val){
        if(Array.isArray(val)){
          const options = val.map(value => (
            this.items[prop].find(item => item.value === value).label
          ))
          return options.filter(noEmpty).join(", ")
        } else {
          const item = this.items[prop].find(item => item.value === val)
          return item.label;
        }
      }
    },
    async getData(){
      const data = await read(`/api/ipz/${this.ipzId}/admission-sheet`);
      this.readonly = AdmissionSheetStatus.CREATED === data.status;
      this.patient = data.patient;
      this.generalInformation = null !== data.generalInformation ? data.generalInformation : {};
      this.medicalData = null !== data.medicalData ? data.medicalData : {};
      this.previousTreatment = null !== data.previousTreatment ? data.previousTreatment : {};
      this.addictions = null !== data.addictions
        ? {...data.addictions,
          abstinence: null !== data.addictions.abstinence ? new Date(data.addictions.abstinence) : null
        }
        : {general: null, active: []};
      this.suicideRisk = null !== data.suicideRisk ? data.suicideRisk : {};
      this.interviewOnNeeds = null !== data.interviewOnNeeds ? data.interviewOnNeeds : {};
      this.mentalCondition = null !== data.mentalCondition ? data.mentalCondition : {};
      this.status = data.status;
      this.admissionDate = null !== data.admissionDate ? new Date(data.admissionDate) : null
      this.creatorName = data.creatorName;
    },
  }
}

const noEmpty = a => a;
</script>

<style scoped>
.white-bg{
  background-color: #FFFFFF;
  height: 100%;
}
.font-options{
  font-size: 1.4rem;
}
.small-font {
  font-size: 1.1rem;
}
h1 {
  font-size: 2.3rem;
  font-weight: bold;
}
h2 {
  font-size: 1.8rem;
}
h3 {
  font-size: 1.6rem;
  font-weight: bold;
}
h4 {
  font-size: 1.4rem;
  font-weight: bold;
}
.m-b-40 {
  margin-bottom: 40px;
}
</style>
