<template>
  <div class="row">
    <template v-for="(module, index) in modules">
      <div
        v-if="module.type==='test_results' && patient"
        :key="moduleKey(index, selectedDocumentIdx)"
        class="col-12"
      >
        <test-results-module
          :idx="index"
          :module="module"
          :read-only="readOnly"
          :patient-id="patient.patientId"
          :appointment-document-id="appointmentDocumentId"
          :continuous-stay-id="continuousStayId"
          :appointment-date="appointmentDate"
        />
      </div>

      <div
        v-if="module.type==='module_targets_and_tasks' && module.ipzId"
        :key="moduleKey(index, selectedDocumentIdx)"
        class="col-12"
      >
        <targets-module
          :idx="index"
          :module="module"
          :read-only="readOnly"
          @input="updateModule($event, index)"
        />
      </div>

      <div
        v-if="!nfzConfiguration && module.type === 'module_remarks' && readOnly && !canceled"
        :key="moduleKey(index, selectedDocumentIdx)"
        class="col-12"
      >
        <remarks-module
          :idx="index"
          :module="module"
          :read-only="readOnly"
          :appointment-id="appointmentId"
          :appointment-document-id="appointmentDocumentId"
        />
      </div>

      <div
        v-if="module.type==='module_continuous_stay_select'"
        :key="moduleKey(index, selectedDocumentIdx)"
        class="col-12"
      >
        <continuous-stay-select-module
          :appointment-id="appointmentId"
          :continuous-stay-id="continuousStayId"
          :idx="index"
          :module="module"
          :patient-id="patient.patientId"
          :read-only="readOnly"
          :start="appointmentDate"
          :end="appointmentEndDate"
          @input="updateContinuousStayModule($event, index)"
        />
      </div>
      <div
        v-if="module.type==='module_continuous_stay_information_card'"
        :key="moduleKey(index, selectedDocumentIdx)"
        class="col-12"
      >
        <continuous-stay-information-card-module
          :idx="index"
          :module="module"
          :continuous-stay-id="continuousStayId"
          :read-only="readOnly"
          @input="updateModule($event, index)"
        />
      </div>
      <div
        v-if="module.type==='module_simple_visit'"
        :key="moduleKey(index, selectedDocumentIdx)"
        class="col-12"
      >
        <simple-visit-module
          :idx="index"
          :module="module"
          :patient-id="patient && patient.patientId"
          :appointment-document-id="appointmentDocumentId"
          :read-only="readOnly"
          :appointment-status="appointmentStatus"
          :visit-at-home="visitAtHome"
          :treatment-type-id="treatmentTypeId"
          @input="updateModule($event, index)"
          @updateVisitAtHome="updateVisitAtHome"
        />
      </div>
      <div
        v-if="module.type==='module_attendance_list' && moduleVisible(readOnly, module.patients)"
        :key="moduleKey(index, selectedDocumentIdx)"
        class="col-12"
      >
        <attendance-list-module
          :idx="index"
          :module="module"
          :read-only="readOnly"
          @input="updateModule($event, index)"
        />
      </div>
      <div
        v-if="module.type==='module_icd10' && patient && moduleVisible(readOnly, module.codes)"
        :key="moduleKey(index, selectedDocumentIdx)"
        class="col-12"
      >
        <icd10-module
          :idx="index"
          :module="module"
          :read-only="readOnly"
          :patient-id="patient.patientId"
          @input="updateModule($event, index)"
        />
      </div>

      <div
        v-if="module.type==='module_single_textarea' && moduleVisible(readOnly, module.description)"
        :key="moduleKey(index, selectedDocumentIdx)"
        class="col-12"
      >
        <single-text-area-module
          :idx="index"
          :module="module"
          :read-only="readOnly"
          @input="updateModule($event, index)"
        />
      </div>

      <div
        v-if="module.type==='module_referrals' && patient"
        :key="moduleKey(index, selectedDocumentIdx)"
        class="col-12"
      >
        <referrals-module
          :idx="index"
          :module="module"
          :read-only="readOnly"
          :patient-id="patient.patientId"
          :appointment-id="appointmentId"
          :appointment-document-id="appointmentDocumentId"
        />
      </div>
      <div
        v-if="module.type==='module_accepted_referrals' && patient"
        :key="moduleKey(index, selectedDocumentIdx)"
        class="col-12"
      >
        <accepted-referrals-module
          :idx="index"
          :module="module"
          :read-only="readOnly"
          :patient-id="patient.patientId"
          :appointment-id="appointmentId"
          :appointment-document-id="appointmentDocumentId"
        />
      </div>
      <div
        v-if="module.type==='module_prescription' && patient"
        :key="moduleKey(index, selectedDocumentIdx)"
        class="col-12"
      >
        <prescription-module
          :idx="index"
          :module="module"
          :read-only="readOnly"
          :patient-id="patient.patientId"
          :appointment-document-id="appointmentDocumentId"
          :continuous-stay-id="continuousStayId"
          @input="updateModule($event, index)"
        />
      </div>
      <div
        v-if="module.type==='module_sick_leave' && patient"
        :key="moduleKey(index, selectedDocumentIdx)"
        class="col-12"
      >
        <sick-leave-module
          :idx="index"
          :module="module"
          :read-only="readOnly"
          :patient-id="patient.patientId"
          :appointment-id="appointmentId"
          :appointment-document-id="appointmentDocumentId"
          @saveCreate="saveCreateRedirect"
        />
      </div>
      <div
        v-if="module.type==='module_additional_persons' && moduleVisible(readOnly, module.persons)"
        :key="moduleKey(index, selectedDocumentIdx)"
        class="col-12"
      >
        <additional-persons-module
          :idx="index"
          :module="module"
          :read-only="readOnly"
          @input="updateModule($event, index)"
        />
      </div>
      <div
        v-if="module.type==='module_logo_neuro'"
        :key="moduleKey(index, selectedDocumentIdx)"
        class="col-12"
      >
        <logo-neuro-module
          :idx="index"
          :module="module"
          :read-only="readOnly"
          @input="updateModule($event, index)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import TestResultsModule from "./TestResultsModule";
import TargetsModule from "./TargetsModule";
import RemarksModule from "./RemarksModule";
import ContinuousStaySelectModule from "./ContinuousStaySelectModule";
import ContinuousStayInformationCardModule from "./ContinuousStayInformationCardModule";
import SimpleVisitModule from "./SimpleVisitModule";
import AttendanceListModule from "./AttendanceListModule";
import Icd10Module from "./Icd10Module";
import SingleTextAreaModule from "./SingleTextAreaModule";
import ReferralsModule from "./ReferralsModule";
import PrescriptionModule from "./PrescriptionModule";
import SickLeaveModule from "./SickLeaveModule";
import AdditionalPersonsModule from "./AdditionalPersonsModule";
import LogoNeuroModule from "./LogoNeuroModule";
import {documentMixin} from "@/mixins/Appointment/documentMixin";
import {mapState} from "vuex";
import AcceptedReferralsModule from "@/components/Appointment/DocumentModules/AcceptedReferralsModule";

export default {
  name: "AppointmentDocumentModules",
  components: {
    LogoNeuroModule,
    AdditionalPersonsModule,
    SickLeaveModule,
    PrescriptionModule,
    ReferralsModule,
    AcceptedReferralsModule,
    SingleTextAreaModule,
    Icd10Module,
    AttendanceListModule,
    SimpleVisitModule,
    ContinuousStayInformationCardModule,
    ContinuousStaySelectModule,
    RemarksModule,
    TargetsModule,
    TestResultsModule,
  },
  mixins: [documentMixin],
  props: {
    modules: {type: Array, required: true},
    selectedDocumentIdx: {type: Number, required: true},
    patient: {type: Object, default: null},
    readOnly: {type: Boolean, required: true},
    appointmentDocumentId: {type: String, required: true},
    continuousStayId: {type: String, default: null},
    appointmentDate: {type: Date, required: true},
    appointmentEndDate: {type: Date, required: true},
    appointmentId: {type: String, required: true},
    canceled: {type: Boolean, required: true},
    appointmentStatus: {type: String, default: null},
    visitAtHome: {type: Boolean, default: null},
    treatmentTypeId: {type: String, default: null},
  },
  computed: {
    ...mapState({
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration
    }),
  },
  methods: {
    updateModule(module, index) {
      this.$emit("updateModule", {module, index});
    },
    updateContinuousStayModule(module, index) {
      this.updateModule(module, index);
      this.$emit("updateContinuousStayId", module.continuousStayId);
    },
    saveCreateRedirect(redirectUrl) {
      this.$emit("saveCreateRedirect", redirectUrl);
    },
    moduleKey(index, selectedDocumentIdx) {
      return [index, selectedDocumentIdx].join("-");
    },
    updateVisitAtHome(value) {
      this.$emit("updateVisitAtHome", value);
    }
  }
}
</script>
