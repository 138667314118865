<template>
  <div class="d-flex flex-row">
    <b-form-input
      :id="id"
      :disabled="loadingClient"
      :placeholder="placeholder"
      :state="state"
      :value="inputValue"
      @input="update"
      @blur.native="onBlur"
    />
    <span
      v-if="loadingClient"
      class="fa fa-spinner fa-spin fa-2x"
    />
    <duplicated-patient-pesel-modal
      :modal-open="modalOpen"
      :client="client"
      :loading-client="loadingClient"
      @close="closeModal"
    />
  </div>
</template>

<script>
import DuplicatedPatientPeselModal from "./DuplicatedPatientPeselModal"
import read from "../../rest/read";

export default {
  components: {DuplicatedPatientPeselModal},
  props: {
    id: {type: String, default: null},
    placeholder: {type: String, default: "Wprowadź PESEL"},
    state: {type: Boolean, default: null},
    value: {type: String, default: null},
  },
  data() {
    return {
      inputValue: this.value,
      loadingClient: false,
      modalOpen: false,
      client: null,
    };
  },
  watch: {
    value(value) {
      this.inputValue = value;
      this.loadingClient = false
      this.modalOpen = false
      this.client = null
    },
  },
  methods: {
    update(inputValue) {
      this.$emit("input", inputValue);
    },
    onBlur() {
      if (this.value && 11 === this.value.length) {
        this.getClientByPesel();
      }
    },
    async getClientByPesel() {
      this.loadingClient = true;
      try {
        this.client = await read(`/api/patient/get-by-pesel/${this.value}`);
        this.loadingClient = false;
        this.modalOpen = true;
      } catch (exception) {
        this.loadingClient = false;
      }
    },
    closeModal() {
      this.modalOpen = false;
    },
  }
};
</script>
