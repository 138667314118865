<template>
  <b-collapse id="appointmentDateHistory">
    <b-card no-body>
      <b-card-header class="d-flex flex-nowrap">
        <date-time
          :value="parseDate(historyItem.changeDate)"
          :format="`DD.MM.YYYY HH:mm:ss`"
          class="appointment-date"
          component="div"
        />
        <div class="appointment-worker">
          <div class="d-flex flex-nowrap mr-2">
            <div>{{ historyItem.changerFullName }}</div>
          </div>
        </div>
        <div class="appointment-visibility-status text-right">
          <button
            class="btn btn-sm btn-secondary m-1"
            @click="toggleVisibility"
          >
            {{ documentVisible ? 'Ukryj' : 'Pokaż' }}
          </button>
        </div>
      </b-card-header>
      <b-collapse
        :id="`details-${idx}`"
        :visible="documentVisible"
      >
        <b-card-body>
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Stara Data</th>
                <th>Nowa Data</th>
                <th>Zarezerwowany czas trwania wizyty</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="p-3"
              >
                <td style="width:33%">
                  <date-time
                    :value="parseDate(historyItem.oldStartDate)"
                    :format="`DD.MM.YYYY HH:mm:ss`"
                    class="appointment-date"
                    component="div"
                  />
                </td>
                <td style="width:33%">
                  <date-time
                    :value="parseDate(historyItem.newStartDate)"
                    :format="`DD.MM.YYYY HH:mm:ss`"
                    class="appointment-date"
                    component="div"
                  />
                </td>
                <td style="width:33%">
                  {{ historyItem.duration }}
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <b-form-group label="Faktyczny czas trwania wizyty">
              <div
                v-for="(document,index) in historyItem.documents"
                :key="index"
              >
                <appointment-document-time-spent
                  v-bind="prepareDocument(document)"
                  :readonly="true"
                />
              </div>
            </b-form-group>
            <b>Powód zmiany:</b>
            <p>
              {{ historyItem.reason }}
            </p>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </b-collapse>
</template>

<script>
import parseDate from "../../../utils/date/parseDate";
import DateTime from "../../DateTime";
import AppointmentDocumentTimeSpent from "../AppointmentDocumentTimeSpent";

export default {
  name: "ChangeAppointmentDateHistory",
  components: {
    DateTime,
    AppointmentDocumentTimeSpent
  },
  props: {
    historyItem: {type: Object, required: true},
    idx: {type: Number, required: true},
  },
  data() {
    return {
      parseDate,
      documentVisible: false
    }
  },
  methods: {
    toggleVisibility() {
      this.documentVisible = !this.documentVisible
    },
    prepareDocument(document){
      return {
        mainWorker: {
          name: document.name,
          surname: document.surname
        },
        timeSpent: document.timeSpent,
        patient: {
          displayName: document.patient
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../styles/variables";

.appointment {
  &-date {
    flex: 0 0 200px;
  }

  &-worker {
    flex: 0 1 100%;
  }

  &-visibility-status {
    flex: 0 0 145px;
  }
}
</style>
