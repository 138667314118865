<template>
  <is-granted
    :privileges="['APPOINTMENT_LIST']"
    component="div"
  >
    <appointments-card
      :filters="{statuses, workerIds}"
      :columns="['hour','date','specialistTypeStatus','patientsLinks', 'options']"
      title="Lista niezakończonych odbytych wizyt"
      description="Poniżej znajdziesz twoje odbyte niezamknięte wizyty"
      empty-text="Wszystkie odbyte wizyty zostały zamknięte"
      go-to-appointment
    />
  </is-granted>
</template>
<script>
import AppointmentsCard from "./AppointmentsCard";
import IsGranted from "../IsGranted";
import {mapState} from "vuex";

export default {
  name: "OpenedAppointmentListWidget",
  components: {AppointmentsCard, IsGranted},
  computed: {
    ...mapState("currentUser", ["user"]),
    statuses() {
      return ["opened", "created", "checkedin"];
    },
    workerIds() {
      return [this.user.workerId]
    }
  }
}
</script>
