
















































































































































































































































































































































import read from "../../rest/read";
import IsGranted from "../IsGranted.vue";
import TreatmentTypeName from "../TreatmentType/TreatmentTypeName.vue";
import AppointmentStatus from "./AppointmentStatus.vue";
import AppointmentInfoMixin from "../../mixins/AppointmentInfoMixin";
import {Component, Mixins, Prop} from "vue-property-decorator";
import RegistrationNotes from "./RegistrationNotes.vue";
import FormRow from "../Form/FormRow.vue";
import AppointmentPatients from "./AppointmentPatients.vue";
import LoadingMask from "../Loading/LoadingMask.vue";
import stringifyDate from "../../utils/date/stringifyDate";

@Component({
  name: "AppointmentInfo",
  components: {
    LoadingMask,
    AppointmentPatients,
    FormRow,
    RegistrationNotes,
    AppointmentStatus,
    TreatmentTypeName,
    IsGranted,
  },
})
export default class AppointmentInfo extends Mixins(AppointmentInfoMixin) {
  @Prop({type: String, default: null}) readonly id!: string;
  @Prop({type: String, required: true}) readonly appointmentId!: string;
  @Prop({type: Boolean, required: true}) readonly removePatientAllowedFromGroup!: boolean;
  @Prop({type: Boolean, required: true}) readonly changePatientsAllowedFromGroup!: boolean;
  @Prop({type: Boolean, required: true}) readonly cancelAllowed!: boolean;
  @Prop({type: String, default: null}) readonly treatmentType!: string;
  @Prop({type: Boolean, default: false}) readonly continuousStay!: boolean;

  private loading: boolean = false;
  private error: boolean =  false;

  private get statusOriginal(): string|null {
    return this.data?.status || null;
  }

  private openModal(): void {
    this.getModalData(this.appointmentId);
  }

  private closeModal(): void {
    this.$bvModal.hide("appointment-info-modal");
  }

  private async getModalData(appointmentId: string): Promise<void> {
    try {
      this.loading = true;
      this.data = await read(`/api/appointments/${appointmentId}`);
    } catch(exception) {
      this.error = true;
    }
    this.loading = false;
  }

  private clearModalData(): void {
    this.error = false;
    this.data = null;
  }

  private async edit(): Promise<void> {
    let scheduleData = null;
    try {
      this.loading = true;
      scheduleData = await read(`/api/appointment/${this.appointmentId}/schedule`);
      this.closeModal();
      this.$emit("edit", scheduleData);
    } catch(exception) {
      this.error = true;
    }
    this.loading = false;
    this.closeModal();
  }

  private checkIn(): void {
    this.closeModal();
    this.$emit("check-in");
  }

  private checkInWithdraw(): void {
    this.closeModal();
    this.$emit("check-in-withdraw");
  }

  private openAppointment(): void {
    this.closeModal();
    this.$emit("open-appointment");
  }

  private showDocument(): void {
    this.closeModal();
    this.$emit("show-document");
  }

  private removePatients(): void {
    this.closeModal();
    this.$emit("remove-patient");
  }

  private changePatients(): void {
    this.closeModal();
    this.$emit("change-patients");
  }
  private cancelAppointment(): void {
    this.closeModal();
    this.$emit("cancel-appointment");
  }

  private changeAppointmentDate(appointmentId: string): void {
    this.closeModal();
    this.$emit("changeAppointmentDate", appointmentId);
  }

  private updateAppointmentParameters(): void {
    this.closeModal();
    this.$emit("change-appointment-parameters");
  }

  private reportTreatmentType(): void {
    this.closeModal();
    this.$emit("report-treatment-type");
  }

  private async copyAppointment(): Promise<void> {
    const startCalendarDate = new Date();
    const day = startCalendarDate.getDay() || 7;
    startCalendarDate.setDate(startCalendarDate.getDate() + 1 - day);
    startCalendarDate.setHours(0, 0, 0, 0);

    await this.$router.push({
      name: "registration",
      query: {
        selectedWorkerId: this.data?.mainSpecialist.workerId,
        copyId: this.appointmentId,
        start: stringifyDate(startCalendarDate),
      }
    });
    this.closeModal();
  }
}
