<template>
  <div>
    <assigned-categories-summary
      v-if="assignedCategories.length !== 0"
      :assigned-categories="assignedCategories"
      :read-only="readOnly"
      :assigned-core-sets="assignedCoreSets"
      @redefineCategories="redefineCategories"
    />

    <icf-core-sets-form
      v-if="showCoreSets && assignedCategories.length === 0 && !readOnly"
      @showCorrespondingCategoriesForm="showCorrespondingCategoriesForm"
    />

    <icf-corresponding-categories-form
      v-if="showCorresponding && assignedCategories.length === 0"
      :chosen-core-sets="chosenCoreSets"
      :ipz-id="ipzId"
      @categoriesAssigned="getAssignedCategories"
    />
  </div>
</template>

<script>
import IcfCoreSetsForm from "./IcfCoreSetsForm.vue";
import IcfCorrespondingCategoriesForm from "./IcfCorrespondingCategoriesForm.vue";
import AssignedCategoriesSummary from "./AssignedCategoriesSummary.vue";
import read from "../../../rest/read";

export default {
  components: {
    IcfCoreSetsForm,
    IcfCorrespondingCategoriesForm,
    AssignedCategoriesSummary,
  },
  props: {
    ipzId: {type: String, required: true},
    readOnly: {type: Boolean, required: true},
    assignedCoreSets: {type: Array, required: true},
  },
  data() {
    return {
      assignedCategories: [],
      showCoreSets: true,
      showCorresponding: false,
      chosenCoreSets: [],
    };
  },
  watch: {
    ipzId() {
      this.getAssignedCategories();
    },
  },
  mounted() {
    this.getAssignedCategories();
  },
  methods: {
    showCorrespondingCategoriesForm(chosenCoreSets) {
      this.showCoreSets = false;
      this.showCorresponding = true;
      this.chosenCoreSets = chosenCoreSets;
    },
    async getAssignedCategories() {
      this.$emit("categoriesAssigned");
      const {items} = await read(`/api/ipzs/${this.ipzId}/icf/categories`);
      this.assignedCategories = items;
    },
    redefineCategories() {
      this.$emit("redefinedCategories");
      this.assignedCategories = [];
      this.showCoreSets = true;
      this.showCorresponding = false;
      this.chosenCoreSets = [];
    }
  },
};
</script>
