<template>
  <report-container
    report-id="patient_medical_care_report"
  >
    <div class="row card-box">
      <b-form-group
        class="col-lg-3 col-xl-3"
        label="Data od"
      >
        <date-picker
          id="start-date"
          v-model="startDate"
          :disabled-date="disabledStartDateDays"
          clearable
        />
      </b-form-group>

      <b-form-group
        class="col-lg-3 col-xl-3"
        label="Data do"
      >
        <date-picker
          id="end-date"
          v-model="endDate"
          :disabled-date="disabledEndDateDays"
          clearable
        />
      </b-form-group>

      <b-form-group
        class="col-lg-4 col-xl-3"
        label="Komórki organizacyjne"
      >
        <branch-select
          id="branch"
          v-model="branches"
          :multiple="true"
          :check-if-any-branch-exists="true"
        />
      </b-form-group>

      <div class="col text-right no-label">
        <back-button :to="{name: 'reportsMainView'}" />

        <a
          :href="reportUrl"
          class="btn btn-primary m-1"
        >
          <i class="fas fa-file-excel" />
          Generuj
        </a>
      </div>
    </div>
  </report-container>
</template>

<script>
import ReportContainer from "../../components/Report/ReportContainer";
import DatePicker from "../../components/Form/DatePicker/DatePicker";
import BranchSelect from "../../components/Branch/BranchSelect";
import {createUrl} from "../../utils/pageUrl/createUrl";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import BackButton from "../../components/BackButton";

export default {
  name: "RequestMedicalRecordsReport",
  components: {ReportContainer, DatePicker,BackButton,BranchSelect},
  data() {
    return {
      startDate: null,
      endDate: null,
      branches: [],
      errors: [],
      defaultReportUrl: "/api/request-medical-records-report",
      reportUrl: null
    }
  },
  watch:{
    startDate() {
      this.setReportUrl()
    },
    endDate(){
      this.setReportUrl()
    },
    branches(){
      this.setReportUrl()
    }
  },
  mounted() {
    this.setReportUrl();
  },
  methods:{
    setReportUrl() {
      this.reportUrl =  createUrl(this.defaultReportUrl, this.filters());
    },

    filters() {
      return {
        startDate: this.startDate ? stringifyDate(this.startDate, DATE_FORMAT.DATE) : null,
        endDate: this.endDate ? stringifyDate(this.endDate, DATE_FORMAT.DATE) : null,
        branches: this.branches
      }
    },
    disabledEndDateDays(endDate) {
      return null !== this.startDate ? (this.startDate.getTime() - endDate.getTime() > 1000) : false;
    },
    disabledStartDateDays(start) {
      return null !== this.endDate ? (start.getTime() - this.endDate.getTime() > 1000) : false;
    },
  }
}
</script>

<style scoped>

</style>
