







































import {Component, Mixins, Prop} from "vue-property-decorator";
import create from "../../rest/create";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import EstablishmentChildForm from "./EstablishmentChildForm.vue";
import ErrorMessage from "../Form/ErrorMessage.vue";
import {generateUuid} from "../../utils/uuid/generateUuid";
import {
  EstablishmentAddress,
  EstablishmentItem,
  EstablishmentParameters,
  EstablishmentType
} from "../../types/Establishment";
import isEmpty from "lodash/isEmpty";

interface FormData {
  name: EstablishmentItem["name"]|null;
  code: EstablishmentItem["code"]|null;
  nip: EstablishmentItem["nip"]|null;
  regon: EstablishmentItem["regon"]|null;
  phoneNumber: EstablishmentItem["phoneNumber"]|null;
  codeEight: EstablishmentItem["codeEight"]|null;
  address: EstablishmentAddress|{};
  shortName: EstablishmentItem["shortName"]|null;
  krs: EstablishmentItem["krs"]|null;
  providerNumber: EstablishmentItem["providerNumber"]|null;
  czpAreaCode: EstablishmentItem["czpAreaCode"]|null;
  parameters: EstablishmentParameters|{};
}
interface SubmitData {
  branchId: EstablishmentItem["branchId"];
  type: EstablishmentType;
  name: EstablishmentItem["name"]|null;
  code: EstablishmentItem["code"]|null;
  nip: EstablishmentItem["nip"]|null;
  regon: EstablishmentItem["regon"]|null;
  phoneNumber: EstablishmentItem["phoneNumber"]|null;
  codeEight: EstablishmentItem["codeEight"]|null;
  address: EstablishmentAddress|{}|null;
  shortName: EstablishmentItem["shortName"]|null;
  krs: EstablishmentItem["krs"]|null;
  providerNumber: EstablishmentItem["providerNumber"]|null;
  czpAreaCode: EstablishmentItem["czpAreaCode"]|null;
  parameters: EstablishmentParameters|{}|null;
}


const formDataInitialState: FormData = {
  name: null,
  code: null,
  nip: null,
  regon: null,
  phoneNumber: null,
  codeEight: null,
  address: {},
  shortName: null,
  krs: null,
  providerNumber: null,
  czpAreaCode: null,
  parameters: {
    patientAnonymous: false,
    filterResearchSurveysByAgeAndSex: false,
    ignoreBranchRestrictions: false,
    extendedAddress: false,
    nfzConfiguration: false
  },
};

@Component({
  name: "CreateEstablishmentModal",
  components: {ErrorMessage, EstablishmentChildForm},
})
export default class CreateEstablishmentModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, default: null}) readonly type!: EstablishmentType;

  loading: boolean = false;
  formData: FormData = {...formDataInitialState};

  private get title(): string {
    switch(this.type) {
      case EstablishmentType.ESTABLISHMENT:
        return "Dodawanie nowego podmiotu medycznego";
      case EstablishmentType.NON_MEDICAL:
        return "Dodawanie nowego podmiotu niemedycznego";
      default:
        return "";
    }

  }

  closeModal(): void {
    this.$bvModal.hide("create-establishment");
  }

  async submit(): Promise<void> {
    this.errors = [];
    this.loading = true;
    const data: SubmitData = {
      branchId: generateUuid(),
      name: this.formData ? this.formData.name : null,
      code: this.formData ? this.formData.code : null,
      type: this.type,
      nip: this.formData? this.formData.nip : null,
      regon: this.formData ? this.formData.regon : null,
      phoneNumber: this.formData ? this.formData.phoneNumber : null,
      codeEight: this.formData ? this.formData.codeEight : null,
      address: this.formData && !isEmpty(this.formData.address) ? this.formData.address : {},
      shortName: this.formData ? this.formData.shortName : null,
      krs: this.formData ? this.formData.krs : null,
      providerNumber: this.formData ? this.formData.providerNumber : null,
      czpAreaCode: this.formData ? this.formData.czpAreaCode : null,
      parameters: this.formData?.parameters || null,
    };
    try {
      await create("/api/branches", data);
      this.closeModal();
      this.$emit("created");
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  resetModalData(): void {
    this.formData = {...formDataInitialState};
    this.loading = false;
    this.errors = [];
  }
}
