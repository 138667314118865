































































































































import Component from "vue-class-component";
import {Mixins, Prop, Watch} from "vue-property-decorator";
import ErrorsMixin from "../../../mixins/ErrorsMixin";
import create from "../../../rest/create";
import update from "../../../rest/update";
import {InsuranceHistory} from "../../../types/Insurance";
import stringifyDate from "../../../utils/date/stringifyDate";
import processResponseException from "../../../utils/errors/processResponseException";
import DatePicker from "../../Form/DatePicker/DatePicker.vue";
import ErrorMessage from "../../Form/ErrorMessage.vue"

interface MedicalCertificate {
  validFrom: Date;
  validTo: Date | null;
  documentTitle: string;
  documentNumber: string;
  institution: string;
  author: string | null;
  onlineConsultation: boolean;
}

@Component({
  name:"PatientStatementModal",
  components: {
    DatePicker,
    ErrorMessage,
  }
})
export default class PatientStatementModal extends Mixins(ErrorsMixin) {
  @Prop({required: true, type: String}) patientId!: string;
  @Prop({required: true}) item!: InsuranceHistory | null;

  insurance: MedicalCertificate = this.defaultData;
  loading: boolean = false;

  @Watch("item")
  subscribeItem(item: InsuranceHistory | null) {
    if (!item || item.insuranceType !== "medical_certificate") {
      this.insurance = this.defaultData;
      return;
    }

    this.insurance = {
      validFrom: new Date(item.validFrom),
      validTo: item.validTo ? new Date(item.validTo) : null,
      documentTitle: item.documentTitle as string,
      documentNumber: item.documentNumber as string,
      institution: item.institution as string,
      author: item.author as string,
      onlineConsultation: item.onlineConsultation as boolean,
    }
  }

  get defaultData(): MedicalCertificate {
    return {
      validFrom: new Date(),
      validTo: new Date(),
      documentTitle: "Zaświadczenie lekarskie",
      documentNumber: "",
      institution: "",
      author: "",
      onlineConsultation: false,
    };
  }

  cancel() {
    this.hide()
    this.$emit("canceled")
  }

  hide() {
    this.$bvModal.hide("medicalCertificateModal");
    this.errors = [];
    this.loading = false;
    this.insurance = this.defaultData;
  }

  async submit() {
    this.loading = true;
    this.insurance.validFrom?.setHours(0,0,0);
    this.insurance.validTo?.setHours(23,59,59);
    const data = {
      ...this.insurance,
      author: this.insurance.author || null,
      validFrom: stringifyDate(this.insurance.validFrom),
      validTo: this.insurance.validTo ? stringifyDate(this.insurance.validTo) : null,
    };
    try {
      if (null == this.item) {
        await create(`/api/patients/${this.patientId}/insurance/medical-certificate`, data);
      } else {
        await update(`/api/patients/${this.patientId}/insurance/medical-certificate/${this.item.id}`, data);
      }
      this.hide();
      this.$emit("saved");
    } catch (error) {
      this.errors = processResponseException(error);
    }
    this.loading = false;
  }
}
