<template>
  <div>
    <div class="page-title-box">
      <h4 class="page-title">
        {{ nfzConfiguration ? "Edycja usługi anonimowej nr" : "Edycja Zgłoszenia interwencyjnego nr" }}
        {{ ordinalNumber }}
      </h4>
      <div class="clearfix" />
    </div>
    <last-edited-appointment />
    <div class="card-box m-t-10">
      <InterventionNotificationForm
        v-model="interventionNotificationData"
        :is-edit="true"
        :errors="errors"
      />
    </div>
    <error-message :errors="errors" />
    <div class="text-right">
      <router-link
        :to="{name: nfzConfiguration
          ? 'application_pzk_single'
          : 'intervention_notification_single', params: {interventionNotificationId}}"
        class="btn btn-secondary"
      >
        Anuluj
      </router-link>
      <button
        type="submit"
        class="btn btn-primary ml-2"
        @click="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </div>
</template>

<script>
import InterventionNotificationForm from "../../components/InterventionNotification/InterventionNotificationForm.vue";
import update from "../../rest/update";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../../components/Form/ErrorMessage";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import read from "../../rest/read";
import LastEditedAppointment from "../../components/Appointment/LastEditedAppointment";
import {mapState} from "vuex";

export default {
  components: {
    LastEditedAppointment,
    ErrorMessage,
    InterventionNotificationForm
  },
  props: {
    interventionNotificationId: {type: String, required: true}
  },
  data() {
    return {
      interventionNotificationData: {
        additionalWorkers: [],
      },
      errors: [],
      loading: false,
      ordinalNumber: null,
      scopeOfActivitiesAndHealthcareInformation: false,
      benefitsOfSocialAssistance: false,
      initialAssessmentHealthNeedsAndProvidingSupport: false,
      agreeingDateAndPlaceCZPServices: false,
      providingHealthServices: false,
    };
  },
  computed: {
    ...mapState({
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration
    }),
  },
  async mounted() {
    await this.loadData();
  },

  methods: {
    async loadData() {
      const data = await read(`/api/intervention-notifications/${this.interventionNotificationId}`);
      this.interventionNotificationData = {
        category: data.category,
        sex: data.sex,
        age: data.age,
        description: data.description,
        duration: data.duration,
        startDate: data.startDate,
        endDate: data.endDate,
        isDuration: !!data.duration || !!data.startDate,
        additionalWorkers: data.additionalWorkers || [],
        scopeOfActivitiesAndHealthcareInformation: data.scopeOfActivitiesAndHealthcareInformation,
        benefitsOfSocialAssistance: data.benefitsOfSocialAssistance,
        initialAssessmentHealthNeedsAndProvidingSupport: data.initialAssessmentHealthNeedsAndProvidingSupport,
        agreeingDateAndPlaceCZPServices: data.agreeingDateAndPlaceCZPServices,
        providingHealthServices: data.providingHealthServices,
      };
      this.ordinalNumber = data.ordinalNumber;
    },
    async submit() {
      this.loading = true;
      try {
        await update(`/api/intervention-notifications/${this.interventionNotificationId}/update`, {
          interventionNotificationId: this.interventionNotificationId,
          ...this.interventionNotificationData,
          description: null !== this.interventionNotificationData.description
            ? this.interventionNotificationData.description.trim()
            :null,
          startDate: this.interventionNotificationData.startDate
            ? stringifyDate(new Date(this.interventionNotificationData.startDate), DATE_FORMAT.DATE_TIME)
            : null,
          endDate: this.interventionNotificationData.endDate
            ? stringifyDate(new Date(this.interventionNotificationData.endDate), DATE_FORMAT.DATE_TIME)
            : null,
          nfzConfiguration: this.nfzConfiguration
        });
        await this.$router.push({
          name: this.nfzConfiguration ? "application_pzk_single" : "intervention_notification_single",
          params: {interventionNotificationId:this.interventionNotificationId},
        });
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    }

  }

}
</script>
