<template>
  <b-table
    :items="items"
    :fields="fields"
    :foot-clone="items.length !== 0"
    empty-text="Brak wizyt spełniających podane kryteria."
    show-empty
  >
    <template #foot(day)>
      SUMA
    </template>
    <template #foot(localAppointmentsCount)>
      {{ summary.localAppointmentsCount }}
    </template>
    <template #foot(remoteAppointmentsCount)>
      {{ summary.remoteAppointmentsCount }}
    </template>
    <template #foot(telephoneAppointmentsCount)>
      {{ summary.telephoneAppointmentsCount }}
    </template>
    <template #foot(sum)>
      {{ summary.sum }}
    </template>
  </b-table>
</template>

<script>
export default {
  name: "AppointmentMonthlyStatsList",
  props: {
    items: {type: Array, default: ()=>[]},
    summary: {type: Object, default: ()=>({
      "localAppointmentsCount": 0,
      "telephoneAppointmentsCount": 0,
      "remoteAppointmentsCount": 0,
      "sum": 0,
    })},
  },
  data() {
    return {
      fields: [
        {key: "day", label: "Dzień", thStyle: "width: 17%"},
        {key: "weekOfYear", label: "Numer tygodnia w roku",thStyle: "width: 15%"},
        {key: "localAppointmentsCount", label: "Ilość usług lokalnych", thStyle: "width: 17%"},
        {key: "remoteAppointmentsCount", label: "Ilość usług wyjazdowych", thStyle: "width: 17%"},
        {key: "telephoneAppointmentsCount", label: "Ilość usług telefonicznych", thStyle: "width: 17%"},
        {key: "sum", label: "Suma", thStyle: "width: 17%"},
      ],
    }
  },
}
</script>
