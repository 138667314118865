


































import {Component, Prop} from "vue-property-decorator";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import ErrorMessage from "../Form/ErrorMessage.vue";

@Component({
  name: TherapeuticAndRecoveryDeleteModal.name,
  components: {ErrorMessage}
})
export default class TherapeuticAndRecoveryDeleteModal extends ErrorsMixin {
  @Prop({type: String, default: null}) readonly snapshotId!: string;

  closeModal(): void {
    this.$bvModal.hide("therapeutic-and-recovery-delete-modal")
  }

  removeSnapshot() {
    this.$emit("removeSnapshot");
    this.closeModal()
  }
}
