<template>
  <collapse-list :items="items">
    <template slot-scope="data">
      <div
        :key="data.item.patientId"
        class="item"
      >
        <router-link
          v-if="hasAccessToPatientCard"
          :to="{ name: 'patientCard', params: {patientId: data.item.patientId}}"
        >
          {{ data.item.displayName }}
        </router-link>
        <span v-else>
          {{ data.item.displayName }}
        </span>
      </div>
    </template>
  </collapse-list>
</template>

<script>
import CollapseList from "../Collapse/CollapseList";
import processResponseException from "../../utils/errors/processResponseException";
import {isGranted} from "../../security/isGranted";

export default {
  components: {CollapseList},
  props: {
    items: {type: Array, required: true},
  },
  data() {
    return {
      hasAccessToPatientCard: false,
    };
  },
  async mounted() {
    try {
      await Promise.all([
        this.checkAccessToPatientCard(),
      ]);
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
  },
  methods: {
    async checkAccessToPatientCard() {
      this.hasAccessToPatientCard = await isGranted(["PATIENT_CARD"]);
    },
  }
}
</script>
<style scoped lang="scss">
  .collapse, .collapsing {
    .item {
      margin-top: 5px;
    }
  }
</style>
