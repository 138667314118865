<template>
  <div>
    <b-form-group
      label="Data zgłoszenia"
    >
      <date-picker
        v-model="formData.notificationDate"
        :state="state('notificationDate')"
        clearable
        @input="update"
      />
      <error-message
        :errors="errors"
        field="notificationDate"
      />
    </b-form-group>
    <b-form-group
      label="Pacjent"
    >
      <patient-select
        :value="formData.patientId"
        :state="state('patientId')"
        @input="updatePatient"
      />
      <error-message
        :errors="errors"
        field="patientId"
      />
    </b-form-group>
    <b-form-group
      id="typeOfBenefit"
      label="Typ świadczenia"
      label-for="typeOfBenefitSelect"
    >
      <waiting-list-type-of-benefits-select
        v-model="formData.typeOfBenefit"
        :state="state('typeOfBenefit')"
        @input="update"
      />
      <error-message
        :errors="errors"
        field="typeOfBenefit"
      />
    </b-form-group>
    <b-form-group
      id="description"
      label="Opis"
      label-for="descriptionInput"
    >
      <b-form-textarea
        id="descriptionInput"
        :value="formData.description"
        :state="state('description')"
        :rows="5"
        @input="updateTextarea"
      />
      <error-message
        :errors="errors"
        field="description"
      />
    </b-form-group>
  </div>
</template>

<script>
import WaitingListTypeOfBenefitsSelect from "./WaitingListTypeOfBenefitsSelect";
import ErrorMessage from "../Form/ErrorMessage";
import DatePicker from "../Form/DatePicker/DatePicker";
import PatientSelect from "../Patient/PatientSelect";
export default {
  name: "WaitingListRecordForm",
  components: {
    PatientSelect,
    WaitingListTypeOfBenefitsSelect,
    ErrorMessage,
    DatePicker
  },
  props: {
    value: {type: Object, required: true},
    errors: {type: Array, required: true},
  },
  data(){
    return {
      formData: {}
    }
  },
  watch: {
    value(){
      this.formData = {...this.value};
    },
  },
  methods: {
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    update(){
      this.$emit("input", this.formData);
    },
    removeWhitespace(value) {
      this.formData.description = value;
      this.update();
    },
    updateTextarea(value) {
      this.removeWhitespace(value);
    },
    updatePatient(patient){
      this.formData.patientId = patient ? patient.patientId : null;
      this.update();
    }
  }
}
</script>

<style scoped>

</style>
