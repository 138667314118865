








import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import ObjectSelect from "../Form/Select/ObjectSelect.vue";
import type {Option} from "../../types/MultiSelectTypes";

type ContinuousStayStatusName = "in_progress" | "closed" | "canceled";

@Component({
  name: "ContinuousStayStatusSelect",
  components: {ObjectSelect}
})
export default class ContinuousStayStatusSelect extends Vue {
  @Prop({type: Array, default: null}) readonly value!: Array<string> | null;
  @Prop({type: Boolean, default: false}) readonly checkedInEnabled!: boolean;

  update(selectedOptions: Array<Option>) {
    const selectedValues = selectedOptions.map(option => option.value);
    this.$emit("input", selectedValues);
  }

  get selected(): Array<Option> | null {
    if (!this.value) {
      return null;
    }

    return this.options.filter((option) => this.value!.includes(option.value));
  }

  get options(): Array<Option> {
    let statuses = this.getNames();

    return statuses.map((value: string) => ({
      name: this.getLabel(value as ContinuousStayStatusName),
      value
    }));
  }

  public getNames(): Array<ContinuousStayStatusName> {
    return [
      "in_progress",
      "closed",
      "canceled",
    ];
  }

  public getLabel(name: ContinuousStayStatusName): string {
    switch (name) {
      case "in_progress":
        return "W trakcie";
      case "closed":
        return "Zakończona";
      case "canceled":
        return "Odwołana";
      default:
        throw new Error(`Unknown continuous stay status '${name}'`);
    }
  }
}
