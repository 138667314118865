











































































































































































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import processResponseException from "../../utils/errors/processResponseException";
import read from "../../rest/read";
import AppointmentStatus from "./AppointmentStatus.vue";
import RegistrationNotes from "./RegistrationNotes.vue";
import TreatmentTypeName from "../TreatmentType/TreatmentTypeName.vue";
import IsGranted from "../IsGranted.vue";
import LoadingMask from "../Loading/LoadingMask.vue";
import FormRow from "../Form/FormRow.vue";
import AppointmentPatients from "./AppointmentPatients.vue";
import ErrorMessage from "../Form/ErrorMessage.vue";
import {SingleRequest} from "../../types/Request";
import AppointmentInfoMixin from "../../mixins/AppointmentInfoMixin";
import t from "../../i18n";

@Component({
  name: "AppointmentInfoRequestPreview",
  components: {
    ErrorMessage,
    AppointmentStatus,
    RegistrationNotes,
    TreatmentTypeName,
    IsGranted,
    LoadingMask,
    FormRow,
    AppointmentPatients,
  },
})
export default class AppointmentInfoForm extends Mixins(ErrorsMixin, AppointmentInfoMixin) {
  @Prop({type: String, required: true}) readonly changeRequestId!: string;
  @Prop({type: String, required: true}) readonly subjectId!: string;

  private loading: boolean =  false;
  private error: boolean =  false;
  private requestData: SingleRequest|null = null;

  private get cancelReason(): string|null {
    if (!this.requestData) {
      return null;
    }
    return t(`@cancel-reason.${this.requestData.params.cancelReason}`);
  }

  private get cancelComment(): string|null {
    if (!this.requestData) {
      return null;
    }
    return this.requestData.params.cancelComment;
  }

  private get deferredNote(): string|null {
    if (!this.requestData) {
      return null;
    }
    return this.requestData.params.deferredNote;
  }

  @Watch("subjectId") async onAppointmentIdChange(): Promise<void> {
    this.loading = true;
    try {
      await this.fetchAppointmentData();
    } catch(e) {
      this.error = true;
    }
    this.loading = false;
  }

  @Watch("changeRequestId") async onRequestIdChange(): Promise<void> {
    this.loading = true;
    try {
      await this.fetchRequestData();
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private async fetchAppointmentData(): Promise<void> {
    this.data = await read(`/api/appointments/${this.subjectId}`);
  }

  private async fetchRequestData(): Promise<void> {
    this.requestData = await read(`/api/change-requests/${this.changeRequestId}`);
  }

  async mounted() {
    this.loading = true;
    try {
      await Promise.all([
        this.fetchAppointmentData(),
        this.fetchRequestData(),
      ]);
    } catch (e) {
      this.errors = processResponseException(e);
      this.error = true
    }
    this.loading = false;
  }
}
