









































































































































































































































































































































































































































































































import Icd10CodeSelect from "../Form/Select/Icd10CodeSelect.vue";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import {Component, Prop} from "vue-property-decorator";
import create from "../../rest/create";
import read from "../../rest/read";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../Form/ErrorMessage.vue";
import {generateUuid} from "../../utils/uuid/generateUuid";
import PaperReferralTypeSelect from "../Form/Select/PaperReferralTypeSelect.vue";
import PaperReferralNumberTypeSelect from "../Form/Select/PaperReferralNumberTypeSelect.vue";
import {Icd10Option} from "../../types/IcdCodes";
import {Option} from "../../types/MultiSelectTypes";
import ResortCodeSelect from "../Referrals/ResortCodeSelect.vue"
import DatePicker from "../Form/DatePicker/DatePicker.vue";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import stringifyDate from "../../utils/date/stringifyDate"
import {PaperReferralNumber, PaperReferralType} from "../../types/PaperReferral";
import t from "../../i18n";
import IsGranted from "../IsGranted.vue";


type Icd10 = {
  code: string,
  displayName: string,
}

type NumberType = {
  name: string,
  value: string
}

@Component({
  name: AcceptReferralModal.name,
  components: {
    IsGranted,
    ErrorMessage,
    PaperReferralTypeSelect,
    PaperReferralNumberTypeSelect,
    Icd10CodeSelect,
    ResortCodeSelect,
    DatePicker
  }
})
export default class AcceptReferralModal extends (ErrorsMixin) {

  @Prop({type: String, default: null}) readonly patientId!: string;
  @Prop({type: String, default: null}) readonly appointmentDocumentId!: string;

  private code: string = "";
  private type: string = PaperReferralType.businessman;
  private numberType : NumberType|null = null;
  private paperReferral: boolean = false;
  private successInfo: boolean = false;
  private loading: boolean = false;
  private insured: boolean = false;
  private document: string = "";
  private referralNumber: string|null = null;
  private documentUrl: string = "";
  private icd10: Icd10[] = [];
  private dateOfIssue: Date = new Date();
  private recognitions: Array<Icd10Option> = [];
  private referralInstitutionName: string = "";
  private referralInstitutionRegon: string = "";
  private referralInstitutionBookNumber: string = "";
  private commissioningStaffName: string = "";
  private commissioningStaffSurname: string = "";
  private commissioningStaffProfessionNumber: string = "";
  private organizationalUnitName: string = "";
  private commissioningStaffOccupationalGroup: string = "";
  private organizationalUnitResortCodeVII: string = "";
  private organizationalUnitResortCodeVIII: Option|null = null;

  hideModal(): void {
    this.errors = [];
    this.documentUrl = "";
    this.code = "";
    this.icd10 = [];
    this.clearForm()
    this.$bvModal.hide("accept-referral");
  }

  paperReferralSwitch(value): void{
    this.paperReferral = value;
  }

  async accept(): Promise<void> {
    this.loading = true;
    try{
      await create("/api/accept-referral", {
        acceptReferralId: generateUuid(),
        patientId: this.patientId,
        code: this.code,
        insured: this.insured,
        appointmentDocumentId: this.appointmentDocumentId
      });
      this.$emit("created");
      this.hideModal();
    }catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  async changeInsured(insured: boolean): Promise<void> {
    this.insured = insured;
  }

  async createPaperReferral():Promise<void> {
    this.loading = true;
    this.successInfo = false;
    this.errors = [];

    try{
      await create(`/api/add-paper-referral/${this.patientId}`, {
        paperReferralId: generateUuid(),
        type: this.type,
        numberType: this.numberType ? this.numberType.value : null,
        dateOfIssue: stringifyDate(this.dateOfIssue, DATE_FORMAT.DATE),
        referralNumber: this.referralNumber,
        recognitions: this.recognitions,
        referralInstitution: {
          name : this.referralInstitutionName,
          regon : this.referralInstitutionRegon,
          bookNumber : this.referralInstitutionBookNumber,
        },
        commissioningStaff: {
          name : this.commissioningStaffName,
          surname : this.commissioningStaffSurname,
          professionNumber : this.commissioningStaffProfessionNumber,
          occupationalGroup : 11,
        },
        organizationalUnit: {
          name : this.organizationalUnitName,
          resortCodeVII : this.organizationalUnitResortCodeVII,
          resortCodeVIII : this.organizationalUnitResortCodeVIII,
        }
      });
      this.clearForm()
      this.successInfo = true;
    }catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  clearForm(){
    this.type = PaperReferralType.businessman;
    this.dateOfIssue = new Date();
    this.referralNumber = null;
    this.numberType = null;
    this.recognitions = [];
    this.referralInstitutionName = "";
    this.referralInstitutionRegon = "";
    this.referralInstitutionBookNumber = "";
    this.commissioningStaffName = "";
    this.commissioningStaffProfessionNumber = "";
    this.commissioningStaffOccupationalGroup = "";
    this.organizationalUnitName = "";
    this.commissioningStaffSurname = "";
    this.organizationalUnitResortCodeVII = "";
    this.organizationalUnitResortCodeVIII = null;
  }

  state(field) {
    return this.errors.find((error) => error.field === field) ? false : null;
  }

  isBookNumber(){
    return this.numberType?.value === PaperReferralNumber.bookNumber
  }

  isRegon(){
    return this.numberType?.value === PaperReferralNumber.regon
  }

  async search(): Promise<void> {
    this.loading = true;
    try {
      const {document, token, icd10} = await read("/api/accepted-referral", {
        code: this.code,
        patientId: this.patientId,
      });
      this.icd10 = icd10;
      this.documentUrl =
        `/api/print-accepted-referral?code=${this.code}&patientId=${this.patientId}&toke=${token}#zoom=120`;
      this.document = document;
      this.errors = [];
    }catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

}
