<template>
  <div
    class="col-10 offset-1"
  >
    <div class="text-right m-b-10">
      <button
        v-if="readonly && (ipzStatus === 'opened')"
        class="btn btn-sm btn-secondary"
        @click="allowEdit"
      >
        <i class="fa fa-pencil-alt" />
        Edytuj formularz
      </button>
      <is-granted
        v-if="!editable"
        :privileges="['GET_IPZ_ADMISSION_SHEET_PDF']"
        :subject="admissionSheetId"
        component="fragment"
      >
        <a
          :href="`/api/ipz-admission-sheet-pdf/${admissionSheetId}`"
          class="btn btn-sm btn-primary m-l-15"
          target="_blank"
        >
          <i class="fa fa-print" />
          Drukuj arkusz przyjęciowy
        </a>
      </is-granted>
    </div>
    <div class="row m-b-20">
      <h2 class="text-center col-12">
        Arkusz przyjęciowy
      </h2>
    </div>
    <div class="row m-b-30">
      <b-form-group
        label="Imie"
        label-for="patientNameInput"
        class="col-6"
      >
        <b-form-input
          id="patientNameInput"
          v-model.trim="patient.name"
          :disabled="true"
          :state="state('patient.name')"
          placeholder="Imię"
        />
        <error-message
          :errors="errors"
          field="patient.name"
        />
      </b-form-group>
      <b-form-group
        label="Nazwisko"
        label-for="patientSurnameInput"
        class="col-6"
      >
        <b-form-input
          id="patientSurnameInput"
          v-model.trim="patient.surname"
          :disabled="true"
          :state="state('patient.surname')"
          placeholder="Nazwisko"
        />
        <error-message
          :errors="errors"
          field="patient.surname"
        />
      </b-form-group>
      <b-form-group
        label="PESEL"
        label-for="peselInput"
        class="col-6"
      >
        <b-form-input
          id="peselInput"
          v-model.trim="patient.pesel"
          :disabled="true"
          :state="state('patient.pesel')"
          placeholder="PESEL"
        />
        <error-message
          :errors="errors"
          field="patient.pesel"
        />
      </b-form-group>
      <b-form-group
        label="Data"
        label-for="date"
        class="col-6"
      >
        <date-picker
          id="date"
          v-model="admissionDate"
          :disabled="readonly"
          :state="state('admissionDate')"
        />
        <error-message
          :errors="errors"
          field="admissionDate"
        />
      </b-form-group>
    </div>
    <div
      class="row m-b-30"
    >
      <div class="col-12 m-b-20">
        <h3 class="text-center">
          Informacje ogólne
        </h3>
      </div>
      <b-form-group
        label="Stan cywilny"
        label-for="maritalStatus"
        class="col-6"
      >
        <b-form-checkbox-group
          id="maritalStatus"
          v-model="generalInformation.maritalStatus"
          :options="items.maritalStatus"
          :disabled="readonly"
          :state="state('generalInformation.maritalStatus')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="generalInformation.maritalStatus"
        />
      </b-form-group>
      <b-form-group
        label="Wykształcenie"
        label-for="education"
        class="col-6"
      >
        <b-form-radio-group
          id="education"
          v-model="generalInformation.education"
          :options="items.education"
          :disabled="readonly"
          :state="state('generalInformation.education')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="generalInformation.education"
        />
      </b-form-group>
      <b-form-group
        label="Źródło utrzymania"
        label-for="sourceOfIncome"
        class="col-6"
      >
        <b-form-checkbox-group
          id="sourceOfIncome"
          v-model="generalInformation.sourceOfIncome"
          :options="items.sourceOfIncome"
          :disabled="readonly"
          :state="state('generalInformation.sourceOfIncome')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="generalInformation.sourceOfIncome"
        />
      </b-form-group>
      <b-form-group
        label="Sytuacja mieszkaniowa"
        label-for="residentialSituation"
        class="col-6"
      >
        <b-form-checkbox-group
          id="residentialSituation"
          v-model="generalInformation.residentialSituation"
          :options="items.residentialSituation"
          :disabled="readonly"
          :state="state('generalInformation.residentialSituation')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="generalInformation.residentialSituation"
        />
      </b-form-group>
      <b-form-group
        label="Opieka Ośrodka Pomocy Społecznej"
        label-for="careOfSocialWelfare"
        class="col-12"
      >
        <textarea-counter
          id="careOfSocialWelfare"
          v-model="generalInformation.careOfSocialWelfare"
          :disabled="readonly"
          :state="state('generalInformation.careOfSocialWelfare')"
          :max-characters-count="5000"
          rows="5"
        />
      </b-form-group>
      <b-form-group
        label="Sytuacja rodzinna"
        label-for="familySituation"
        class="col-12"
      >
        <textarea-counter
          id="familySituation"
          v-model="generalInformation.familySituation"
          :disabled="readonly"
          :state="state('generalInformation.familySituation')"
          :max-characters-count="5000"
          rows="5"
        />
      </b-form-group>
    </div>
    <div
      class="row m-b-30"
    >
      <div class="col-12 m-b-20">
        <h3 class="text-center">
          Dane ogólnomedyczne
        </h3>
      </div>
      <b-form-group
        label="Schorzenia współistniejące"
        label-for="concomitantConditions"
        class="col-12"
      >
        <textarea-counter
          id="concomitantConditions"
          v-model="medicalData.concomitantConditions"
          :disabled="readonly"
          :state="state('medicalData.concomitantConditions')"
          :max-characters-count="5000"
          rows="5"
        />
      </b-form-group>
      <b-form-group
        label="Leki przyjmowane na stałe"
        label-for="permanentMedications"
        class="col-12"
      >
        <textarea-counter
          id="permanentMedications"
          v-model="medicalData.permanentMedications"
          :disabled="readonly"
          :state="state('medicalData.permanentMedications')"
          :max-characters-count="5000"
          rows="5"
        />
      </b-form-group>
      <b-form-group
        label="Uczulenia"
        label-for="allergies"
        class="col-12"
      >
        <textarea-counter
          id="allergies"
          v-model="medicalData.allergies"
          :disabled="readonly"
          :state="state('medicalData.allergies')"
          :max-characters-count="5000"
          rows="5"
        />
      </b-form-group>
      <b-form-group
        label="Inne istotne dane medyczne (urazy, operacje, zabiegi, itp.)"
        label-for="otherMedical"
        class="col-12"
      >
        <textarea-counter
          id="otherMedical"
          v-model="medicalData.otherMedical"
          :disabled="readonly"
          :state="state('medicalData.otherMedical')"
          :max-characters-count="5000"
          rows="5"
        />
      </b-form-group>
    </div>
    <div
      class="row m-b-30"
    >
      <div class="col-12 m-b-20">
        <h3 class="text-center">
          Dane dotyczące przebiegu dotychczasowego leczenia
        </h3>
      </div>
      <b-form-group
        label="Czy dotychczas leczony/a psychiatrycznie?"
        label-for="treatedPsychiatrically"
        class="col-6"
      >
        <b-form-radio-group
          id="treatedPsychiatrically"
          v-model="previousTreatment.treatedPsychiatrically"
          :options="items.treatedPsychiatrically"
          :disabled="readonly"
          :state="state('previousTreatment.treatedPsychiatrically')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="previousTreatment.treatedPsychiatrically"
        />
      </b-form-group>
      <b-form-group
        label="Przebieg leczenia (pierwsza konsultacja psychiatryczna, ostatnia konsultacja psychiatryczna)"
        label-for="courseOfTreatment"
        class="col-12"
      >
        <textarea-counter
          id="courseOfTreatment"
          v-model="previousTreatment.courseOfTreatment "
          :disabled="readonly"
          :state="state('previousTreatment.courseOfTreatment')"
          :max-characters-count="5000"
          rows="5"
        />
      </b-form-group>
      <b-form-group
        label="Aktualnie pod opieką"
        label-for="currentlyUnderCare"
        class="col-12"
      >
        <textarea-counter
          id="currentlyUnderCare"
          v-model="previousTreatment.currentlyUnderCare "
          :disabled="readonly"
          :state="state('previousTreatment.currentlyUnderCare')"
          :max-characters-count="5000"
          rows="5"
        />
      </b-form-group>
      <b-form-group
        label="Pomoc psychologa (grupy wsparcia, AA, AN, psychoterapia)"
        label-for="psychologistsHelp"
        class="col-12"
      >
        <textarea-counter
          id="psychologistsHelp"
          v-model="previousTreatment.psychologistsHelp"
          :disabled="readonly"
          :state="state('previousTreatment.psychologistsHelp')"
          :max-characters-count="5000"
          rows="5"
        />
      </b-form-group>
      <b-form-group
        label="Przymusowe leczenie"
        label-for="compulsoryTreatment"
        class="col-12"
      >
        <textarea-counter
          id="compulsoryTreatment"
          v-model="previousTreatment.compulsoryTreatment"
          :disabled="readonly"
          :state="state('previousTreatment.compulsoryTreatment')"
          :max-characters-count="5000"
          rows="5"
        />
      </b-form-group>
    </div>
    <div
      class="row m-b-30"
    >
      <div class="col-12 m-b-20">
        <h3 class="text-center">
          Diagnostyka – uzależnienia
        </h3>
      </div>
      <b-form-group
        label-for="addictionsGeneral"
        class="col-12"
      >
        <b-form-radio-group
          id="addictionsGeneral"
          v-model="addictions.general"
          :options="items.addictionsGeneral"
          :disabled="readonly"
          :state="state('addictions.general')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="addictions.general"
        />
      </b-form-group>
      <b-form-group
        v-if="addictions.general === 'active-addiction'"
        label-for="addictionsActive"
        class="col-12"
      >
        <b-form-checkbox-group
          id="addictionsActive"
          v-model="addictions.active"
          :options="items.addictionsActive"
          :disabled="readonly"
          :state="state('addictions.addictionsActive')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="addictions.addictionsActive"
        />
      </b-form-group>
      <b-form-group
        v-if="addictions.general === 'active-addiction'"
        label="Inne, jakie?"
        label-for="addictionsActiveOther"
        class="col-12"
      >
        <textarea-counter
          id="addictionsActiveOther"
          v-model="addictions.activeOther"
          :disabled="readonly"
          :state="state('addictions.activeOther')"
          :max-characters-count="1000"
          rows="2"
        />
      </b-form-group>
      <b-form-group
        v-if="addictions.general === 'takes-abstinence'"
        label="Data startu abstynecji"
        label-for="addictionsAbstinence"
        class="col-6"
      >
        <date-picker
          id="addictionsAbstinence"
          v-model="addictions.abstinence"
          :state="state('addictions.abstinence')"
          :disabled="readonly"
        />
      </b-form-group>
    </div>
    <div
      class="row m-b-30"
    >
      <div class="col-12 m-b-20">
        <h3 class="text-center">
          Ocena ryzyka samobójstwa (autoagresji) / zachowań agresywnych
        </h3>
      </div>
      <b-form-group
        label="Myśli rezygnacyjne"
        label-for="resignationThoughts"
        class="col-6"
      >
        <b-form-radio-group
          id="resignationThoughts"
          v-model="suicideRisk.resignationThoughts"
          :options="items.lackPresent"
          :disabled="readonly"
          :state="state('suicideRisk.resignationThoughts')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="suicideRisk.resignationThoughts"
        />
      </b-form-group>
      <b-form-group
        label="Wypowiadanie gróźb, złowrogie zamiary"
        label-for="sinisterIntentions"
        class="col-6"
      >
        <b-form-radio-group
          id="sinisterIntentions"
          v-model="suicideRisk.sinisterIntentions"
          :options="items.lackPresentSuspicion"
          :disabled="readonly"
          :state="state('suicideRisk.sinisterIntentions')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="suicideRisk.sinisterIntentions"
        />
      </b-form-group>
      <b-form-group
        label="Myśli samobójcze"
        label-for="thoughtsOfSuicide"
        class="col-6"
      >
        <b-form-radio-group
          id="thoughtsOfSuicide"
          v-model="suicideRisk.thoughtsOfSuicide "
          :options="items.lackPresentSuspicion"
          :disabled="readonly"
          :state="state('suicideRisk.thoughtsOfSuicide')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="suicideRisk.thoughtsOfSuicide"
        />
      </b-form-group>
      <b-form-group
        label="Zachowania agresywne czynne"
        label-for="activeAggressiveBehavior "
        class="col-6"
      >
        <b-form-radio-group
          id="activeAggressiveBehavior"
          v-model="suicideRisk.activeAggressiveBehavior "
          :options="items.lackPresent"
          :disabled="readonly"
          :state="state('suicideRisk.activeAggressiveBehavior')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="suicideRisk.activeAggressiveBehavior"
        />
      </b-form-group>
      <b-form-group
        label="Plany samobójcze"
        label-for="suicidePlans"
        class="col-6"
      >
        <b-form-radio-group
          id="suicidePlans"
          v-model="suicideRisk.suicidePlans "
          :options="items.lackPresentSuspicion"
          :disabled="readonly"
          :state="state('suicideRisk.suicidePlans')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="suicideRisk.suicidePlans"
        />
      </b-form-group>
      <b-form-group
        label="Zachowania agresywne w przeszłości"
        label-for="aggressiveBehaviorInPast"
        class="col-6"
      >
        <b-form-radio-group
          id="aggressiveBehaviorInPast"
          v-model="suicideRisk.aggressiveBehaviorInPast "
          :options="items.yesNo4Weeks"
          :disabled="readonly"
          :state="state('suicideRisk.aggressiveBehaviorInPast')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="suicideRisk.aggressiveBehaviorInPast"
        />
      </b-form-group>
      <b-form-group
        label="Próba samobójcza w przeszłości"
        label-for="pastAttemptedSuicide"
        class="col-6"
      >
        <b-form-checkbox-group
          id="pastAttemptedSuicide"
          v-model="suicideRisk.pastAttemptedSuicide"
          :options="items.attemptedSuicide"
          :disabled="readonly"
          :state="state('suicideRisk.pastAttemptedSuicide')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="suicideRisk.pastAttemptedSuicide"
        />
      </b-form-group>
      <b-form-group
        label="Autoagresja"
        label-for="autoaggression"
        class="col-6"
      >
        <b-form-radio-group
          id="autoaggression"
          v-model="suicideRisk.autoaggression "
          :options="items.yesNo"
          :disabled="readonly"
          :state="state('suicideRisk.autoaggression')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="suicideRisk.autoaggression"
        />
      </b-form-group>
      <b-form-group
        label-for="suicideRiskOther"
        class="col-12"
      >
        <textarea-counter
          id="suicideRiskOther"
          v-model="suicideRisk.other"
          :disabled="readonly"
          :state="state('suicideRisk.suicideRiskOther')"
          :max-characters-count="5000"
          rows="5"
        />
      </b-form-group>
    </div>
    <div
      class="row m-b-30"
    >
      <div class="col-12 m-b-20">
        <h3 class="text-center">
          Wywiad dotyczący potrzeb / trudności / powodu zgłoszenia pacjenta
        </h3>
      </div>
      <b-form-group
        label-for="interviewOnNeedsGeneral"
        class="col-12"
      >
        <textarea-counter
          id="interviewOnNeedsGeneral"
          v-model="interviewOnNeeds.general"
          :disabled="readonly"
          :state="state('interviewOnNeeds.general')"
          :max-characters-count="5000"
          rows="5"
        />
      </b-form-group>
      <b-form-group
        label="Postrzeganie problemu / trudności pacjenta przez otoczenie"
        label-for="perceptionOfProblem"
        class="col-12"
      >
        <textarea-counter
          id="perceptionOfProblem"
          v-model="interviewOnNeeds.perceptionOfProblem"
          :disabled="readonly"
          :state="state('interviewOnNeeds.perceptionOfProblem')"
          :max-characters-count="5000"
          rows="5"
        />
      </b-form-group>
    </div>
    <div
      class="row m-b-30"
    >
      <div class="col-12 m-b-20">
        <h3 class="text-center">
          Ocena stanu psychicznego
        </h3>
      </div>
      <b-form-group
        label="Kontakt z pacjentem"
        label-for="contactWithPatient"
        class="col-6"
      >
        <b-form-radio-group
          id="contactWithPatient"
          v-model="mentalCondition.contactWithPatient"
          :options="items.contactWithPatient"
          :disabled="readonly"
          :state="state('mentalCondition.contactWithPatient')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.contactWithPatient"
        />
        <textarea-counter
          v-if="mentalCondition.contactWithPatient === 'other'"
          id="contactWithPatientOther"
          v-model="mentalCondition.contactWithPatientOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.contactWithPatientOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.contactWithPatientOther"
        />
      </b-form-group>

      <b-form-group
        label="Orientacja autopsychiczna"
        label-for="autopsychicOrientation"
        class="col-6"
      >
        <b-form-radio-group
          id="autopsychicOrientation"
          v-model="mentalCondition.autopsychicOrientation"
          :options="items.autopsychicOrientation"
          :disabled="readonly"
          :state="state('mentalCondition.autopsychicOrientation')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.autopsychicOrientation"
        />
        <textarea-counter
          v-if="mentalCondition.autopsychicOrientation === 'other'"
          id="autopsychicOrientationOther"
          v-model="mentalCondition.autopsychicOrientationOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.autopsychicOrientationOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.autopsychicOrientationOther"
        />
      </b-form-group>

      <b-form-group
        label="Orientacja allopsychiczna"
        label-for="allopsychicOrientation"
        class="col-6"
      >
        <b-form-radio-group
          id="allopsychicOrientation"
          v-model="mentalCondition.allopsychicOrientation"
          :options="items.allopsychicOrientation"
          :disabled="readonly"
          :state="state('mentalCondition.allopsychicOrientation')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.allopsychicOrientation"
        />
        <textarea-counter
          v-if="mentalCondition.allopsychicOrientation === 'other'"
          id="allopsychicOrientationOther"
          v-model="mentalCondition.allopsychicOrientationOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.allopsychicOrientationOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.allopsychicOrientationOther"
        />
      </b-form-group>
      <b-form-group
        label="Kontakt wzrokowy"
        label-for="eyeContact"
        class="col-6"
      >
        <b-form-radio-group
          id="eyeContact"
          v-model="mentalCondition.eyeContact"
          :options="items.eyeContact"
          :disabled="readonly"
          :state="state('mentalCondition.eyeContact')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.eyeContact"
        />
        <textarea-counter
          v-if="mentalCondition.eyeContact === 'other'"
          id="eyeContactOther"
          v-model="mentalCondition.eyeContactOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.eyeContactOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.eyeContactOther"
        />
      </b-form-group>
      <b-form-group
        label="Wygląd"
        label-for="appearance"
        class="col-6"
      >
        <b-form-radio-group
          id="appearance"
          v-model="mentalCondition.appearance"
          :options="items.appearance"
          :disabled="readonly"
          :state="state('mentalCondition.appearance')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.appearance"
        />
        <textarea-counter
          v-if="mentalCondition.appearance === 'other'"
          id="appearanceOther"
          v-model="mentalCondition.appearanceOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.appearanceOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.appearanceOther"
        />
      </b-form-group>
      <b-form-group
        label="Budowa ciała"
        label-for="physique"
        class="col-6"
      >
        <b-form-radio-group
          id="physique"
          v-model="mentalCondition.physique"
          :options="items.physique"
          :disabled="readonly"
          :state="state('mentalCondition.physique')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.physique"
        />
        <textarea-counter
          v-if="mentalCondition.physique === 'other'"
          id="physiqueOther"
          v-model="mentalCondition.physiqueOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.physiqueOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.physiqueOther"
        />
      </b-form-group>
      <b-form-group
        label="Uwaga"
        label-for="attention"
        class="col-6"
      >
        <b-form-radio-group
          id="attention"
          v-model="mentalCondition.attention"
          :options="items.attention"
          :disabled="readonly"
          :state="state('mentalCondition.attention')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.attention"
        />
        <textarea-counter
          v-if="mentalCondition.attention === 'other'"
          id="attentionOther"
          v-model="mentalCondition.attentionOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.attentionOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.attentionOther"
        />
      </b-form-group>
      <b-form-group
        label="Mowa"
        label-for="speech"
        class="col-6"
      >
        <b-form-radio-group
          id="speech"
          v-model="mentalCondition.speech"
          :options="items.speech"
          :disabled="readonly"
          :state="state('mentalCondition.speech')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.speech"
        />
        <textarea-counter
          v-if="mentalCondition.speech === 'other'"
          id="speechOther"
          v-model="mentalCondition.speechOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.speechOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.speechOther"
        />
      </b-form-group>
      <b-form-group
        label="Niepokój psychoruchowy"
        label-for="psychomotorRestlessness"
        class="col-6"
      >
        <b-form-radio-group
          id="psychomotorRestlessness"
          v-model="mentalCondition.psychomotorRestlessness"
          :options="items.psychomotorRestlessness"
          :disabled="readonly"
          :state="state('mentalCondition.psychomotorRestlessness')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.psychomotorRestlessness"
        />
        <textarea-counter
          v-if="mentalCondition.psychomotorRestlessness === 'other'"
          id="speechOther"
          v-model="mentalCondition.psychomotorRestlessnessOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.psychomotorRestlessnessOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.psychomotorRestlessnessOther"
        />
      </b-form-group>
      <b-form-group
        label="Napęd"
        label-for="drive"
        class="col-6"
      >
        <b-form-radio-group
          id="drive"
          v-model="mentalCondition.drive"
          :options="items.drive"
          :disabled="readonly"
          :state="state('mentalCondition.drive')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.drive"
        />
        <textarea-counter
          v-if="mentalCondition.drive === 'other'"
          id="driveOther"
          v-model="mentalCondition.driveOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.driveOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.driveOther"
        />
      </b-form-group>
      <b-form-group
        label="Nastrój"
        label-for="mood"
        class="col-6"
      >
        <b-form-radio-group
          id="mood"
          v-model="mentalCondition.mood"
          :options="items.mood"
          :disabled="readonly"
          :state="state('mentalCondition.mood')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.mood"
        />
        <textarea-counter
          v-if="mentalCondition.mood === 'other'"
          id="moodOther"
          v-model="mentalCondition.moodOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.moodOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.moodOther"
        />
      </b-form-group>
      <b-form-group
        label="Afekt"
        label-for="affect"
        class="col-6"
      >
        <b-form-radio-group
          id="affect"
          v-model="mentalCondition.affect"
          :options="items.affect"
          :disabled="readonly"
          :state="state('mentalCondition.affect')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.affect"
        />
        <textarea-counter
          v-if="mentalCondition.affect === 'other'"
          id="affectOther"
          v-model="mentalCondition.affectOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.affectOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.affectOther"
        />
      </b-form-group>
      <b-form-group
        label="Lęk"
        label-for="anxiety"
        class="col-6"
      >
        <b-form-checkbox-group
          id="anxiety"
          v-model="mentalCondition.anxiety"
          :options="items.anxiety"
          :disabled="readonly"
          :state="state('mentalCondition.anxiety')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.anxiety"
        />
        <textarea-counter
          v-if="isOtherChoosed(mentalCondition.anxiety)"
          id="anxietyOther"
          v-model="mentalCondition.anxietyOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.anxietyOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.anxietyOther"
        />
      </b-form-group>
      <b-form-group
        label="Kompulsje/Natręctwa"
        label-for="compulsions"
        class="col-6"
      >
        <b-form-radio-group
          id="compulsions"
          v-model="mentalCondition.compulsions"
          :options="items.compulsions"
          :disabled="readonly"
          :state="state('mentalCondition.compulsions')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.compulsions"
        />
        <textarea-counter
          v-if="mentalCondition.compulsions === 'other'"
          id="compulsionsOther"
          v-model="mentalCondition.compulsionsOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.compulsionsOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.compulsionsOther"
        />
      </b-form-group>

      <b-form-group
        label="Urojenia"
        label-for="delusions"
        class="col-6"
      >
        <b-form-radio-group
          id="delusions"
          v-model="mentalCondition.delusions"
          :options="items.delusions"
          :disabled="readonly"
          :state="state('mentalCondition.delusions')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.delusions"
        />
        <textarea-counter
          v-if="mentalCondition.delusions === 'other'"
          id="delusionsOther"
          v-model="mentalCondition.delusionsOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.delusionsOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.delusionsOther"
        />
      </b-form-group>

      <b-form-group
        label="Zab. formy myślenia"
        label-for="blockingFormOfThinking"
        class="col-6"
      >
        <b-form-checkbox-group
          id="blockingFormOfThinking"
          v-model="mentalCondition.blockingFormOfThinking"
          :options="items.blockingFormOfThinking"
          :disabled="readonly"
          :state="state('mentalCondition.blockingFormOfThinking')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.blockingFormOfThinking"
        />
        <textarea-counter
          v-if="isOtherChoosed(mentalCondition.blockingFormOfThinking)"
          id="blockingFormOfThinkingOther"
          v-model="mentalCondition.blockingFormOfThinkingOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.blockingFormOfThinkingOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.blockingFormOfThinkingOther"
        />
      </b-form-group>

      <b-form-group
        label="Pamięć"
        label-for="memory"
        class="col-6"
      >
        <b-form-radio-group
          id="memory"
          v-model="mentalCondition.memory"
          :options="items.memory"
          :disabled="readonly"
          :state="state('mentalCondition.memory')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.memory"
        />
        <textarea-counter
          v-if="mentalCondition.memory === 'other'"
          id="memoryOther"
          v-model="mentalCondition.memoryOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.memoryOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.memoryOther"
        />
      </b-form-group>

      <b-form-group
        label="Wgląd"
        label-for="view"
        class="col-6"
      >
        <b-form-radio-group
          id="view"
          v-model="mentalCondition.view"
          :options="items.view"
          :disabled="readonly"
          :state="state('mentalCondition.view')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.view"
        />
        <textarea-counter
          v-if="mentalCondition.view === 'other'"
          id="viewOther"
          v-model="mentalCondition.viewOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.viewOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.viewOther"
        />
      </b-form-group>

      <b-form-group
        label="Omamy"
        label-for="hallucinations"
        class="col-6"
      >
        <b-form-checkbox-group
          id="hallucinations"
          v-model="mentalCondition.hallucinations"
          :options="items.hallucinations"
          :disabled="readonly"
          :state="state('mentalCondition.hallucinations')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.hallucinations"
        />
        <textarea-counter
          v-if="isOtherChoosed(mentalCondition.hallucinations)"
          id="hallucinationsOther"
          v-model="mentalCondition.hallucinationsOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.hallucinationsOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.hallucinationsOther"
        />
      </b-form-group>

      <b-form-group
        label="Zaburzenia snu"
        label-for="sleepDisturbance"
        class="col-6"
      >
        <b-form-radio-group
          id="sleepDisturbance"
          v-model="mentalCondition.sleepDisturbance"
          :options="items.sleepDisturbance"
          :disabled="readonly"
          :state="state('mentalCondition.sleepDisturbance')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.sleepDisturbance"
        />
        <textarea-counter
          v-if="mentalCondition.sleepDisturbance === 'other'"
          id="sleepDisturbanceOther"
          v-model="mentalCondition.sleepDisturbanceOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.sleepDisturbanceOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.sleepDisturbanceOther"
        />
      </b-form-group>

      <b-form-group
        label="Apetyt"
        label-for="appetite"
        class="col-6"
      >
        <b-form-radio-group
          id="appetite"
          v-model="mentalCondition.appetite"
          :options="items.appetite"
          :disabled="readonly"
          :state="state('mentalCondition.appetite')"
          size="lg"
          text-field="label"
          value-field="value"
          stacked
        />
        <error-message
          :errors="errors"
          field="mentalCondition.appetite"
        />
        <textarea-counter
          v-if="mentalCondition.appetite === 'other'"
          id="appetiteOther"
          v-model="mentalCondition.appetiteOther"
          class="w-75"
          :disabled="readonly"
          :state="state('mentalCondition.appetiteOther')"
          :max-characters-count="5000"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="mentalCondition.appetiteOther"
        />
      </b-form-group>
    </div>

    <div class="row">
      <div class="col-12 m-b-10">
        <error-message
          :errors="errors"
        />
      </div>
      <div class="col-12 text-right m-b-30">
        <button
          v-if="!readonly"
          :disabled="loading"
          type="submit"
          class="btn btn-primary ml-2 waves-effect waves-float"
          @click="submit(false)"
        >
          <i
            :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fa"
          />
          Zapisz
        </button>
        <button
          v-if="!readonly"
          :disabled="loading"
          type="submit"
          class="btn btn-secondary ml-2 waves-effect waves-float"
          @click="submit(true)"
        >
          <i
            :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fa"
          />
          Zapisz i zatwierdź
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorMessage from "../../../components/Form/ErrorMessage";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import items from "../../../utils/ipz/AdmissionSheetItems";
import TextareaCounter from "../../../components/Form/Textarea/TextareaCounter";
import DatePicker from "../../../components/Form/DatePicker/DatePicker";
import processResponseException from "../../../utils/errors/processResponseException";
import read from "../../../rest/read";
import create from "../../../rest/create";
import update from "../../../rest/update";
import {generateUuid} from "../../../utils/uuid/generateUuid";
import stringifyDate from "../../../utils/date/stringifyDate";
import {AdmissionSheetStatus} from "../../../types/Ipz";
import IsGranted from "../../../components/IsGranted";
import {mapActions} from "vuex";
import DateTime from "@/components/DateTime";

export default {
  name: "AdmissionSheetPanel",
  components: {
    ErrorMessage,
    TextareaCounter,
    DatePicker,
    IsGranted
  },
  mixins: [errorsMixin],
  props: {
    ipzId: {type: String, required: true},
  },
  data() {
    return {
      items,
      loading:false,
      patient: {},
      generalInformation: {},
      medicalData: {},
      previousTreatment: {},
      addictions: {
        general: null
      },
      suicideRisk: {},
      interviewOnNeeds: {},
      mentalCondition: {},
      readonly: false,
      admissionSheetId: null,
      status: null,
      editable: false,
      ipzStatus: null,
      admissionDate: new Date()
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions({
      showToast: "toastNotification/showToast"
    }),
    isOtherChoosed(value){
      if(value && Array.isArray(value)){
        return value.includes("other");
      }
      return false;
    },
    allowEdit() {
      this.editable = true;
      this.readonly = false;
    },
    async submit(close){
      this.loading = true;
      this.editable = false;
      this.errors = [];

      if(null === this.status){
        await this.create(close);
      } else if(AdmissionSheetStatus.DRAFT === this.status || AdmissionSheetStatus.CREATED === this.status) {
        await this.update(close);
      }

      this.loading = false;
    },

    async create(close) {
      null !== this.admissionDate ? this.admissionDate.setHours(0,0,0) : null;
      const data = {
        admissionSheetId: generateUuid(),
        ipzId: this.ipzId,
        patient: this.patient,
        generalInformation: this.generalInformation,
        medicalData: this.medicalData,
        previousTreatment: this.previousTreatment,
        addictions: {
          ...this.addictions,
          abstinence: this.addictions.abstinence ? stringifyDate(this.addictions.abstinence) : null
        },
        suicideRisk: this.suicideRisk,
        interviewOnNeeds: this.interviewOnNeeds,
        mentalCondition: this.mentalCondition,
        status: close ? AdmissionSheetStatus.CREATED : AdmissionSheetStatus.DRAFT,
        admissionDate: null !== this.admissionDate ? stringifyDate(this.admissionDate) : null,
      }
      try {
        await create("/api/ipz/admission-sheet", {...data});
        this.showToast({
          message: "Dane zostały zapisane.",
          variant: "success",
        });
        await this.getData();
      } catch (exception) {
        this.showToast({
          message: "Formularz zawiera błędy.\nDane nie zostały zapisane.",
          variant: "danger"
        });
        this.errors = processResponseException(exception);
      }
    },

    async update(close){
      const data = {
        patient: this.patient,
        generalInformation: this.generalInformation,
        medicalData: this.medicalData,
        previousTreatment: this.previousTreatment,
        addictions: {
          ...this.addictions,
          abstinence: this.addictions.abstinence ? stringifyDate(this.addictions.abstinence) : null
        },
        suicideRisk: this.suicideRisk,
        interviewOnNeeds: this.interviewOnNeeds,
        mentalCondition: this.mentalCondition,
        status: close ? AdmissionSheetStatus.CREATED : AdmissionSheetStatus.DRAFT,
        admissionDate: null !== this.admissionDate ? stringifyDate(this.admissionDate) : null,
      }

      try {
        await update(`/api/ipz/admission-sheet/${this.admissionSheetId}`, {...data});
        this.showToast({
          message: "Dane zostały zapisane.",
          variant: "success",
        });
        await this.getData();
      } catch (exception) {
        this.showToast({
          message: "Formularz zawiera błędy.\nDane nie zostały zapisane.",
          variant: "danger"
        });
        this.errors = processResponseException(exception);
      }
    },

    async getData(){
      const data = await read(`/api/ipz/${this.ipzId}/admission-sheet`);
      this.admissionSheetId = data.admissionSheetId;
      this.readonly = AdmissionSheetStatus.CREATED === data.status;
      this.patient = data.patient;
      this.generalInformation = null !== data.generalInformation ? data.generalInformation : {
        maritalStatus: [],
        sourceOfIncome: [],
        residentialSituation: []
      };
      this.medicalData = null !== data.medicalData ? data.medicalData : {};
      this.previousTreatment = null !== data.previousTreatment ? data.previousTreatment : {};
      this.addictions = null !== data.addictions
        ? {...data.addictions,
          abstinence: null !== data.addictions.abstinence ? new Date(data.addictions.abstinence) : null
        }
        : {general: null, active: []};
      this.suicideRisk = null !== data.suicideRisk ? data.suicideRisk : {
        pastAttemptedSuicide: [],
      };
      this.interviewOnNeeds = null !== data.interviewOnNeeds ? data.interviewOnNeeds : {};
      this.mentalCondition = null !== data.mentalCondition ? data.mentalCondition : {
        anxiety: [],
        blockingFormOfThinking: [],
        hallucinations: []
      };
      this.status = data.status;
      this.ipzStatus = data.ipzStatus;
      this.admissionDate = null !== data.admissionDate ? new Date(data.admissionDate) : new Date();
    },
  }
}
</script>

<style scoped>

</style>
