<template>
  <div>
    <b-modal
      id="create-chronic-disease-modal"
      no-close-on-backdrop
      title="Dodaj rozpoznanie do pacjenta"
      size="lg"
      title-tag="h3"
      @hide="clearVariables"
    >
      <div class="card-box">
        <FormRow
          :errors="errors"
          label="Kod ICD-10"
          field="icd10Code"
        >
          <icd10-code-select
            v-model="icd10Code"
            :state="state('icd10Code')"
          />
        </FormRow>
        <FormRow
          :errors="errors"
          label="Data rozpoznania choroby"
          field="diagnosisDate"
        >
          <incomplete-date-input
            v-model="diagnosisDate"
            :state="state('diagnosisDate')"
          />
        </FormRow>
        <FormRow
          :errors="errors"
          label="Notatka"
          field="note"
        >
          <textarea-counter
            id="note"
            v-model="note"
            :rows="6"
            :state="state('note')"
            :max-characters-count="1500"
          />
        </FormRow>
        <error-message :errors="errors" />
      </div>
      <template #modal-footer>
        <button
          class="btn btn-secondary"
          @click="hideModal"
        >
          Anuluj
        </button>
        <button
          :disabled="isLoading"
          class="btn btn-primary"
          @click="createChronicDisease"
        >
          <i
            :class="isLoading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fa"
          />
          Zapisz
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import FormRow from "../../../Form/FormRow";
import Icd10CodeSelect from "../../../Form/Select/Icd10CodeSelect";
import create from "../../../../rest/create";
import {generateUuid} from "@/utils/uuid/generateUuid";
import TextareaCounter from "../../../Form/Textarea/TextareaCounter";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import processResponseException from "../../../../utils/errors/processResponseException";
import ErrorMessage from "../../../Form/ErrorMessage";
import IncompleteDateInput from "../../../Form/Input/IncompleteDateInput";
import subErrors from "../../../../utils/errors/subErrors";

export default {
  components: {
    IncompleteDateInput,
    ErrorMessage,
    TextareaCounter,
    Icd10CodeSelect,
    FormRow
  },
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      icd10Code: null,
      diagnosisDate: null,
      note: null,
      isLoading: false,
    };
  },
  methods: {
    async createChronicDisease() {
      this.isLoading = true;
      try {
        await create(`/api/patient/${this.patientId}/chronic-diseases`, {
          chronicDiseaseId: generateUuid(),
          patientId: this.patientId,
          icd10Code: this.icd10Code === null ? null : this.icd10Code.value,
          diagnosisDate: this.diagnosisDate,
          note: this.note === null ? null : this.note.trim(),
        });
        this.$emit("chronicDiseaseCreated");
        this.hideModal();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.isLoading = false;
    },
    hideModal() {
      this.$bvModal.hide("create-chronic-disease-modal");
    },
    clearVariables() {
      this.errors = [];
      this.icd10Code = null;
      this.diagnosisDate = null;
      this.note = null;
    },
    getSubErrors(field) {
      return subErrors(this.errors, field);
    }
  },
}
</script>
