<template>
  <b-modal
    id="close-ipz"
    size="lg"
    no-close-on-backdrop
    title="Zamknięcie PTiZ"
    title-tag="h3"
  >
    <template #default>
      <FormRow
        :errors="errors"
        label="Powód zamknięcia"
        field="reason"
        class="text-left"
      >
        <textarea-counter
          id="reason"
          v-model="reason"
          :state="state('reason')"
          :max-characters-count="1000"
          rows="4"
        />
      </FormRow>
    </template>
    <template #modal-footer>
      <b-btn
        variant="secondary"
        @click="closeModal"
      >
        Anuluj
      </b-btn>
      <b-btn
        :disabled="loading"
        variant="primary"
        @click="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zakończ PTiZ
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
import TextareaCounter from "../Form/Textarea/TextareaCounter";
import FormRow from "../Form/FormRow";
import modify from "../../rest/modify";
import processResponseException from "../../utils/errors/processResponseException";
import {errorsMixin} from "@/mixins/errorsMixin";

export default {
  components: {
    TextareaCounter,
    FormRow
  },
  mixins: [errorsMixin],
  props: {
    ipzId: {type: String, required: true}
  },
  data() {
    return {
      loading: false,
      reason: "",
    };
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("close-ipz");
    },
    async submit() {
      this.loading = true;
      try {
        await modify(`/api/ipzs/${this.ipzId}/close`, {reason: this.reason});
        this.closeModal();
        this.$emit("ipzClosed");
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    }
  },
};
</script>
