<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between align-items-center">
          <h4 class="page-title">
            Rejestracja pełnoletniego pacjenta
          </h4>
          <is-granted
            :privileges="['PATIENT_ARCHIVAL_DATA']"
            component="div"
          >
            <button
              v-if="!archiveId"
              type="button"
              class="btn btn-primary"
              @click="showSearchArchiveModal"
            >
              Wyszukaj w archiwum
            </button>
            <button
              v-else
              type="button"
              class="btn btn-outline-primary"
              @click="archiveId = null"
            >
              Anuluj powiązanie z archiwum
            </button>
          </is-granted>
        </div>
      </div>
      <div class="col-12">
        <last-edited-appointment />
      </div>
    </div>
    <patient-create-personal-data
      v-model="personalData"
      :errors="errors"
    />
    <div
      v-if="!nfzConfiguration"
      class="card-box"
    >
      <h4>Jednostki</h4>
      <hr>
      <establishment-select
        v-model="establishments"
        clearable
        multiple
      />
      <error-message
        :errors="errors"
        field="establishments"
      />
    </div>
    <div
      v-if="!nfzConfiguration"
      class="card-box"
    >
      <h4>Dane społeczno-demograficzne</h4>
      <hr>
      <socio-demographic-data
        v-model="socioDemographicData"
        :errors="getSubErrors('socioDemographicData')"
      />
    </div>
    <div
      v-if="!nfzConfiguration"
      class="card-box"
    >
      <h4>Status osoby na rynku pracy w chwili przystąpienia do projektu</h4>
      <hr>
      <labor-market-status
        v-model="laborMarketStatus"
        :errors="getSubErrors('laborMarketStatus')"
        :loading="loading"
      />
    </div>
    <div
      v-if="!nfzConfiguration"
      class="card-box"
    >
      <h4>Status uczestnika w chwili przystąpienia do projektu</h4>
      <hr>
      <patient-participant-status-data
        v-model="patientParticipantStatusData"
        :errors="getSubErrors('patientParticipantStatusData')"
      />
    </div>
    <div
      v-if="!nfzConfiguration"
      class="card-box"
    >
      <h4>Hospitalizacje</h4>
      <hr>
      <patient-hospitalizations
        v-model="patientHospitalizationsData"
        :errors="getSubErrors('hospitalizationsData')"
      />
    </div>
    <div
      v-if="!nfzConfiguration"
      class="card-box"
    >
      <h4>Źródło informacji o ŚCZP</h4>
      <hr>
      <source-of-information-select
        v-model="sourceOfInformation"
        :errors="getSubErrors('sourceOfInformation.sourceOfInformation')"
        :state="state('sourceOfInformation.sourceOfInformation')"
      />
      <error-message
        :errors="errors"
        field="sourceOfInformation"
      />
    </div>
    <div class="card-box">
      <h4>Adres zamieszkania</h4>
      <hr>
      <address-form
        v-model="livingAddress"
        :errors="getSubErrors('livingAddress')"
      />
      <div class="row">
        <b-form-checkbox
          v-if="!nfzConfiguration"
          v-model="differentCorrespondenceAddress"
        >
          Adres korespondencyjny jest inny niż zamieszkania
        </b-form-checkbox>
        <b-form-checkbox
          v-else
          v-model="differentRegisteredAddress"
        >
          Adres zameldowania jest inny niż zamieszkania
        </b-form-checkbox>
      </div>
    </div>
    <div
      v-if="differentCorrespondenceAddress"
      class="card-box"
    >
      <h4>Adres korespondencyjny</h4>
      <hr>
      <address-form
        v-model="correspondenceAddress"
        :errors="getSubErrors('correspondenceAddress')"
      />
    </div>
    <div
      v-if="differentRegisteredAddress"
      class="card-box"
    >
      <h4>Adres zameldowania</h4>
      <hr>
      <address-form
        v-model="registeredAddress"
        :errors="getSubErrors('registeredAddress')"
      />
    </div>
    <div class="card-box">
      <create-patient-permissions
        v-model="permissions"
        :errors="errors"
        :display-type="nfzConfiguration ? permissionType.NFZ : permissionType.ADULT"
      />
    </div>
    <div class="card-box">
      <create-patient-ewus-permissions
        v-if="nfzConfiguration"
        v-model="ewus"
        :errors="getSubErrors('ewus')"
      />
    </div>
    <error-message :errors="errors" />
    <div class="text-right">
      <router-link
        :to="{name: 'listPatients'}"
        class="btn btn-secondary"
      >
        Anuluj
      </router-link>
      <button
        :disabled="loading"
        type="submit"
        class="btn btn-primary ml-2"
        @click="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
    <patient-archive-search-modal @selected="setDataFromArchive" />
  </div>
</template>

<script>

import patientCreatePersonalData from "../../components/Patient/PatientCreatePersonalData.vue";
import create from "../../rest/create";
import {generateUuid} from "@/utils/uuid/generateUuid";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import AddressForm from "../../components/Address/AddressForm";
import SocioDemographicData from "../../components/Patient/SocioDemographicData";
import PatientParticipantStatusData from "../../components/Patient/PatientParticipantStatusData";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../../components/Form/ErrorMessage";
import subErrors from "../../utils/errors/subErrors";
import {mapActions, mapState} from "vuex";
import LaborMarketStatus from "../../components/Patient/LaborMarketStatus";
import SourceOfInformationSelect from "../../components/SourcesOfInformation/SourceOfInformationSelect";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import {BadRequestException} from "@/rest";
import PatientHospitalizations from "../../components/Patient/PatientHospitalizations";
import initHospitalizationsData from "../../utils/initHospitalizationsData";
import CreatePatientPermissions from "@/components/Patient/CreatePatientPermissions";
import EstablishmentSelect from "@/components/Branch/EstablishmentSelect";
import {PermissionType} from "../../components/Patient/patientPermissions";
import CreatePatientEwusPermissions from "../../components/Patient/CreatePatientEwusPermissions";
import LastEditedAppointment from "@/components/Appointment/LastEditedAppointment";
import PatientArchiveSearchModal from "../../components/Patient/PatientArchiveSearchModal";
import IsGranted from "../../components/IsGranted";

export default {
  name: "PatientCreate",
  components: {
    IsGranted,
    PatientArchiveSearchModal,
    CreatePatientEwusPermissions,
    EstablishmentSelect,
    CreatePatientPermissions,
    SourceOfInformationSelect,
    LaborMarketStatus,
    ErrorMessage,
    LastEditedAppointment,
    AddressForm,
    patientCreatePersonalData,
    SocioDemographicData,
    PatientParticipantStatusData,
    PatientHospitalizations,
  },
  mixins: [errorsMixin],
  data() {
    return {
      differentCorrespondenceAddress: false,
      differentRegisteredAddress: false,
      personalData: {},
      socioDemographicData: {},
      patientParticipantStatusData: {},
      patientHospitalizationsData: initHospitalizationsData(),
      livingAddress: {},
      correspondenceAddress: {},
      registeredAddress: {},
      loading: false,
      archiveId: null,
      laborMarketStatus: {},
      sourceOfInformation: {},
      establishments: [],
      permissions: {"permissionsList":[],"medicalDataAuthorizationsPersons":[]},
      ewus: [],
      permissionType: PermissionType
    };
  },
  computed: {
    ...mapState({
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration
    }),
    registeredDateString() {
      if (this.personalData && this.personalData.registeredDate) {
        return stringifyDate(this.personalData.registeredDate, DATE_FORMAT.DATE);
      }
      return null
    },
  },
  mounted() {
    this.clearStickyPatient();
  },
  methods: {
    ...mapActions({
      showToast: "toastNotification/showToast",
      clearStickyPatient: "stickyPatient/clear",
    }),
    getSubErrors(field) {
      return subErrors(this.errors, field);
    },
    getHospitalizationDataValue(dataField) {
      const fieldValue = this.patientHospitalizationsData[dataField].value;
      const value =
        this.patientHospitalizationsData[dataField].doesRemember && (fieldValue || 0 === fieldValue)
          ? fieldValue
          : null;

      return {
        ...this.patientHospitalizationsData[dataField],
        value,
      };
    },
    async submit() {
      this.loading = true;
      const patientId = generateUuid();
      const patientHospitalized = this.patientHospitalizationsData.wasHospitalized === "yes";
      const hospitalizationsData = {
        ...this.patientHospitalizationsData,
        whenFirstHospitalized: patientHospitalized
          ? this.getHospitalizationDataValue("whenFirstHospitalized") : null,
        whenLastHospitalized: patientHospitalized
          ? this.getHospitalizationDataValue("whenLastHospitalized") : null,
        howManyHospitalizationsLastPeriod: patientHospitalized
          ? this.getHospitalizationDataValue("howManyHospitalizationsLastPeriod") : null,
        howLongHospitalizedLastPeriod: patientHospitalized
          ? this.getHospitalizationDataValue("howLongHospitalizedLastPeriod") : null,
      };
      const data = {
        patientId: patientId,
        clientId: generateUuid(),
        ...this.personalData,
        birthdate: this.birthDateString(),
        registeredDate: this.registeredDateString,
        livingAddress: {...this.livingAddress},
        socioDemographicData: this.socioDemographicData,
        patientParticipantStatusData: this.patientParticipantStatusData,
        hospitalizationsData,
        laborMarketStatus: this.laborMarketStatus,
        correspondenceAddress: this.differentCorrespondenceAddress ? this.correspondenceAddress : this.livingAddress,
        registeredAddress: this.differentRegisteredAddress ? this.registeredAddress : this.livingAddress,
        sourceOfInformation: this.sourceOfInformation,
        establishments: this.establishments ? this.establishments.map((establishment) => establishment.value): [],
        permissions: this.permissions.permissionsList,
        archiveId: this.archiveId,
        ewusPermissions: this.ewus,
        medicalDataAuthorizationsPersons: this.permissions.medicalDataAuthorizationsPersons.map(medicalDataAuthorization => ({
          authorizedPersonName: medicalDataAuthorization.authorizedPersonName,
          authorizedPersonSurname: medicalDataAuthorization.authorizedPersonSurname,
          endOfAuthorization: (medicalDataAuthorization.endOfAuthorization)? stringifyDate(medicalDataAuthorization.endOfAuthorization):null,
          authorizedPersonPhoneNumber: (medicalDataAuthorization.authorizedPersonPhoneNumber !== "")? medicalDataAuthorization.authorizedPersonPhoneNumber: null,
          authorizedPersonEmail: (medicalDataAuthorization.authorizedPersonEmail !== "")? medicalDataAuthorization.authorizedPersonEmail:null
        })),
      };
      try {
        if(this.nfzConfiguration){
          await create("/api/nfz/patient/create", data);
        } else {
          await create("/api/patient/create", data);
        }
        this.showToast({
          message: "Dane zostały zapisane",
          variant: "success",
        });
        await this.$router.push({name: "patientCard", params: {patientId}});
      } catch (exception) {
        this.errors = processResponseException(exception);
        if (exception instanceof BadRequestException) {
          this.showToast({
            message: "Formularz zawiera błędy.\nUzupełnij dane aby zarejestrować pacjenta",
            variant: "danger"
          });
        } else {
          this.showToast({
            message: "Wystąpił błąd.\nDane nie zostały zapisane",
            variant: "danger",
          });
        }
      }
      this.loading = false;
    },
    birthDateString() {
      if (this.personalData && this.personalData.birthdate) {
        return stringifyDate(this.personalData.birthdate, DATE_FORMAT.DATE);
      }
      return null
    },
    showSearchArchiveModal() {
      this.$bvModal.show("patientArchiveSearchModal")
    },
    setDataFromArchive(data) {
      this.archiveId = data.id

      this.personalData = {
        ...this.personalData,
        name: data.name,
        surname: data.surname,
        birthDate: new Date(data.birthDate),
        pesel: data.pesel,
        phoneNumber: data.phoneNumber,
        fileNumber: data.fileNumber,
        externalNumber: data.externalNumber,
      }
      this.socioDemographicData = {
        ...this.socioDemographicData,
        educationLevel: data.education,
        disabilityLevel: data.disabilityLevel,
        maritalStatus: data.maritalStatus,
      }

      this.laborMarketStatus = {
        ...this.laborMarketStatus,
        ...data.laborMarketStatus,
      }

      this.patientParticipantStatusData = {
        ...this.patientParticipantStatusData,
        ...data.patientParticipantStatusData,
      }

      this.livingAddress = {
        ...this.livingAddress,
        ...data.livingAddress,
      }

      this.differentCorrespondenceAddress = true

      this.correspondenceAddress = {
        ...data.livingAddress,
        ...this.correspondenceAddress,
        ...data.correspondenceAddress,
      }

      this.differentRegisteredAddress = true

      this.registeredAddress = {
        ...data.livingAddress,
        ...data.registeredAddress,
      }
    }
  },
}
</script>
