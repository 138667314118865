<template>
  <div>
    <error-message :errors="errors" />
    <loading-mask
      v-if="prescriptionsList.length"
      :loading="loading"
    >
      <b-card no-body>
        <b-card-header
          v-b-toggle="`prescriptions-${index}`"
          class="text-left waves-effect waves-float"
          header-tag="header"
        >
          Recepty
        </b-card-header>
        <b-collapse :id="`prescriptions-${index}`">
          <b-card-body>
            <prescription-list-table
              :prescriptions-list="prescriptionsList"
              @getPrescriptions="getPrescriptions"
              @openCancelModal="openCancelPrescriptionModal"
            />
          </b-card-body>
        </b-collapse>
      </b-card>
    </loading-mask>
    <create-prescription-modal
      v-if="patient"
      :index="index"
      :appointment-document-id="appointmentDocumentId"
      :nfz-branch="nfzBranch"
      :patient="patient"
      :continuous-stay-id="continuousStayId"
      @created="getPrescriptions"
      @prepared="getPrescriptions"
    />
    <cancel-prescription-modal
      :index="index"
      :prescription-id="selectedPrescriptionId"
      :appointment-document-id="appointmentDocumentId"
      @canceled="getPrescriptions"
    />
  </div>
</template>

<script>
import CreatePrescriptionModal from "../../../Prescription/CreatePrescriptionModal.vue";
import read from "../../../../rest/read";
import processResponseException from "../../../../utils/errors/processResponseException";
import LoadingMask from "../../../Loading/LoadingMask.vue";
import ErrorMessage from "../../../Form/ErrorMessage.vue";
import CancelPrescriptionModal from "../../../Prescription/CancelPrescriptionModal.vue";
import PrescriptionListTable from "../../../Prescription/PrescriptionListTable.vue";

export default {
  name: "ContinuousStayDailyDescriptionPrescription",
  components: {
    PrescriptionListTable,
    CancelPrescriptionModal,
    CreatePrescriptionModal,
    LoadingMask,
    ErrorMessage
  },
  props: {
    appointmentDocumentId: {required: true, type: String},
    documentPrescriptions: {type: Array, default: () => []},
    continuousStayId : {required: true, type: String},
    patient: {type: Object, default: null},
    nfzBranch: {type: Object, default: null},
    index: {type: Number, default: null}
  },
  data() {
    return {
      errors: [],
      prescriptionsList: this.documentPrescriptions,
      loading: false,
      selectedPrescriptionId: null
    }
  },
  watch: {
    appointmentDocumentId(){
      this.getPrescriptions();
    }
  },
  methods: {
    async getPrescriptions() {
      this.loading = true;
      try {
        const data = await read("/api/prescription", {appointmentDocumentId: this.appointmentDocumentId});
        this.prescriptionsList = data.prescriptions;
      } catch (e) {
        this.errors = processResponseException(e)
      }
      this.loading = false;
    },

    openCancelPrescriptionModal(prescriptionId) {
      this.selectedPrescriptionId = prescriptionId;
      this.$bvModal.show(`cancel-prescription-${this.index}`);
    },
  }

}
</script>

<style scoped>

</style>
