






























































































































































































import {Component, Mixins, Prop} from "vue-property-decorator";
import ErrorsMixin from "../../../mixins/ErrorsMixin";
import {Insurance} from "../../../mixins/PatientInsuranceMixin";
import read from "../../../rest/read";
import {InsuranceHistory, InsuranceType} from "../../../types/Insurance";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import stringifyDate from "../../../utils/date/stringifyDate";
import processResponseException from "../../../utils/errors/processResponseException";
import i18n from "../../i18n.vue";
import IsGranted from "../../IsGranted.vue";
import identityConfirmMethod from "./identityConfirmMethod";
import InsuranceStatusIcon from "./InsuranceStatusIcon.vue";
import patientStatementInsuranceSource from "./patientStatementInsuranceSource";

@Component({
  name: "PatientInsuranceHistoryModal",
  components: {
    IsGranted,
    InsuranceStatusIcon,
    i18n
  }
})
export default class PatientInsurance extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly patientId !: string;

  loading: boolean = false;
  items: InsuranceHistory[] = [];
  InsuranceType = InsuranceType;

  formatDateTime(date: string): string {
    return stringifyDate(new Date(date), DATE_FORMAT.DATE_TIME);
  }

  formatDate(date: string): string {
    return stringifyDate(new Date(date), DATE_FORMAT.DATE);
  }

  hideModal(): void {
    this.$bvModal.hide("insuranceHistoryModal");
  }

  mounted() {
    this.$root.$on("bv::modal::show", (bvEvent: any, modalId: any) => {
      if (modalId === "insuranceHistoryModal") {
        this.loadData();
      }
    });
  }

  async loadData(): Promise<void> {
    this.loading = true;
    try {
      const response = await read<Insurance>(`/api/patients/${this.patientId}/insurance`);
      this.items = response.insuranceHistory;
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  identityConfirmationMethod(method: string) {
    return identityConfirmMethod.find((item) => item.value === method)?.name;
  }

  insuranceSource(source: string) {
    return patientStatementInsuranceSource.find((item) => item.value === source)?.name;
  }

  edit(data: InsuranceHistory) {
    this.$emit("edit", data);
  }

  remove(data: InsuranceHistory) {
    this.$emit("remove", data);
  }

  editPrivilege(type: InsuranceType) {
    switch (type) {
      case InsuranceType.PATIENT_STATEMENT:
        return "EDIT_PATIENT_STATEMENT";
      case InsuranceType.MEDICAL_CERTIFICATE:
        return "EDIT_MEDICAL_CERTIFICATE";
    }

    throw new Error(`Unknown type ${type}`);
  }

  get fields() {
    return [
      {key: "statementNumber", label: " Numer oświadczenia\nlub zaświadczenia"},
      {key: "addedAt", label: "Data wpisu"},
      {key: "addedBy", label: "Autor wpisu"},
      {key: "insuranceType", label: "Rodzaj"},
      {key: "status", label: "Ubezpieczony"},
      {key: "validFrom", label: "Ważne od"},
      {key: "validTo", label: "Ważne do"},
      {key: "show_details", label: "Opcje", thStyle: "width: 134px;"},
      {key: "edit", label: "Edycja", thStyle: "width: 85px;"},
      {key: "remove", label: "Usuń", thStyle: "width: 75px;"},
    ];
  }
}
