<template>
  <div
    class="pdf-background pdf-container"
    style="background-color: #0a0a0a"
  >
    <router-view />
  </div>
</template>

<script>
export default {
  name: "PdfLayout"
}

</script>

<style>
  @page {
    size: A4;
  }
</style>
<style scoped>
</style>
