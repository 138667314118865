<template>
  <table class="table">
    <thead>
      <tr>
        <th>Typ</th>
        <th>Lek</th>
        <th class="text-center">
          Kod dostępu
        </th>
        <th class="text-center">
          Anulowanie
        </th>
        <th class="text-center">
          Drukuj
        </th>
        <th class="text-center">
          Status
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-for="prescription in prescriptionsList">
        <tr
          v-for="(drug, index) in prescription.drugs"
          :key="drug.uuid"
        >
          <td>
            {{ drug.availabilityCategory }}
          </td>
          <td>
            {{ drug.name }} /
            {{ drug.wrapper?'Op. '+drug.wrapper+' '+drug.quantity:null }}
            {{ drug.wrapper&&drug.size?' / ':null }}
            {{ drug.size?'Rozmiar: '+drug.size:null }}
            {{ drug.size&&drug.dose?' / ':null }}
            {{ drug.form?'Forma: '+drug.form:null }}
            {{ drug.form&&drug.dose?' / ':null }}
            {{ drug.dose?'Dawka: '+drug.dose:null }}
          </td>
          <td
            v-if="0===index"
            :class="{'bg-muted': prescription.accessCode && prescription.drugs.length > 1}"
            class="text-center border-bottom"
            :rowspan="prescription.drugs.length"
          >
            <template v-if="prescription.accessCode">
              {{ prescription.accessCode }}
            </template>
          </td>
          <td class="text-center">
            <b-btn
              v-if="prescription.prescriptionKey"
              :disabled="prescription.status !== 'created'"
              size="sm"
              style="width: 3rem"
              variant="outline-primary"
              @click="openCancelPrescriptionModal(drug.prescriptionId)"
            >
              <i class="fa fa-trash" />
            </b-btn>
          </td>
          <td
            v-if="0===index"
            :class="{'bg-muted': prescription.drugs.length > 1}"
            class="text-center border-bottom"
            :rowspan="prescription.drugs.length"
          >
            <b-btn
              v-if="prescription.fileId"
              :href="`/api/files/${prescription.fileId}`"
              size="sm"
              style="width: 3rem"
              variant="primary"
              target="_blank"
            >
              <i class="fa fa-print" />
            </b-btn>
            <b-btn
              v-else
              size="sm"
              style="width: 3rem"
              variant="outline-primary"
              @click="getAccessCodeAndPrint(prescription.drugs[0].prescriptionId)"
            >
              <i class="fa fa-unlock" />
            </b-btn>
          </td>
          <td
            class="text-center"
          >
            <b-badge
              class="status-badge"
              :variant="getBadgeVariant(prescription.status)"
            >
              <i18n
                component="fragment"
                :msgid="`@prescription-status-${prescription.status}`"
              />
            </b-badge>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import {PrescriptionStatuses} from "../../types/Prescription";
import create from "../../rest/create";
import I18n from "../i18n.vue";

export default {
  name: "PrescriptionListTable",
  components: {
    I18n,

  },
  props: {
    prescriptionsList: {type: Array, default: () => []}
  },
  methods: {
    getBadgeVariant(status) {
      switch (status) {
        case PrescriptionStatuses.VERIFIED:
          return "secondary";
        case PrescriptionStatuses.CREATED:
          return "primary";
        case PrescriptionStatuses.COMPLETED:
          return "success";
        case PrescriptionStatuses.QUEUED:
          return "warning";
        default:
          return "light";
      }
    },
    async getAccessCodeAndPrint(prescriptionId){
      const params = {prescriptionId};
      await create("/api/document-electronic-prescription", params);
      this.$emit("getPrescriptions");
    },
    openCancelPrescriptionModal(prescriptionId){
      this.$emit("openCancelModal", prescriptionId);
    }
  }
}
</script>

<style scoped>

</style>
