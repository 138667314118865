<template>
  <div>
    <card
      :loading="loading"
      title="Dane dodatkowe"
    >
      <template #control>
        <is-granted
          :privileges="['CHANGE_PATIENT_ADDITIONAL_DATA']"
        >
          <b-btn
            v-b-modal.additionalData
            :disabled="loading || errors.length > 0"
            variant="primary"
            class="btn-sm"
          >
            <i class="fa fa-pencil-alt" />
          </b-btn>
        </is-granted>
      </template>
      <error-message :errors="errors" />
      <p v-if="patient.registeredDate">
        Data rejestracji: {{ patient.registeredDate }}
      </p>
      <p v-if="patient.dateOfEndParticipation">
        Data zakończenia uczestnictwa: {{ patient.dateOfEndParticipation }}
      </p>
      <p v-if="patient.maritalStatus">
        Stan cywilny:
        <maritalStatus :status="patient.maritalStatus" />
      </p>
      <p v-if="patient.education">
        Wykształcenie:
        <education-level :level="patient.education" />
      </p>
      <p v-if="patient.disabilityLevel">
        Poziom niepełnosprawności:
        <disabilityLevel :disability-level="patient.disabilityLevel" />
      </p>
      <p v-if="patient.nationality">
        Narodowość:
        <countryName :country-code="patient.nationality" />
      </p>
      <p v-if="patient.fileNumber">
        Numer teczki: {{ patient.fileNumber }}
      </p>
      <p v-if="patient.externalNumber">
        Numer zewnętrzny: {{ patient.externalNumber }}
      </p>
      <p v-if="patient.sourceOfInformation && patient.sourceOfInformation.sourceOfInformation">
        Źródło informacji o ŚCZP:
        <source-of-information :source-of-information="patient.sourceOfInformation.sourceOfInformation" />
        {{ patient.sourceOfInformation.comment }}
      </p>
      <p v-if="null !== patient.ops">
        Pacjent skierowany z OPS: {{ patient.ops ? "Tak" : "Nie" }}
      </p>
      <p v-if="patient.deathDate">
        Data zgonu: {{ patient.deathDate }}
      </p>
    </card>
    <b-modal
      id="additionalData"
      hide-footer
      size="lg"
      no-close-on-backdrop
      title="Edycja danych dodatkowych"
      title-tag="h3"
    >
      <additional-data-form
        :additional-data="patient"
        :patient-id="patientId"
        @updateAdditionalData="updateAdditionalData"
        @cancel="cancel"
      />
    </b-modal>
  </div>
</template>

<script>
import maritalStatus from "../MaritalStatus.vue"
import disabilityLevel from "../DisabilityLevel.vue"
import sourceOfInformation from "../../SourcesOfInformation/SourceOfInformation.vue"
import countryName from "../../Address/CountryName.vue"
import read from "../../../rest/read";
import EducationLevel from "../EducationLevel";
import ErrorMessage from "../../Form/ErrorMessage";
import processResponseException from "../../../utils/errors/processResponseException";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import AdditionalDataForm from "../AdditionalDataForm";
import Card from "../../Card";
import IsGranted from "../../IsGranted";

export default {

  components: {
    IsGranted,
    Card,
    AdditionalDataForm,
    EducationLevel,
    disabilityLevel,
    maritalStatus,
    countryName,
    ErrorMessage,
    sourceOfInformation,
  },
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      patient: {},
      loading: false,
    };
  },
  watch: {
    patientId() {
      this.loadAdditionalData();
    },
  },
  async mounted() {
    this.loadAdditionalData();
  },
  methods: {
    async loadAdditionalData() {
      this.loading = true;
      try {
        this.patient = await read(`/api/patients/${this.patientId}/additional_data`);
      } catch(exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    updateAdditionalData(additionalData) {
      this.patient = additionalData;
      this.$emit("patientUpdated");
    },
    cancel() {
      this.$bvModal.hide("additionalData");
      this.errors = [];
    },
  },
};
</script>

<style scoped>
  .overflow-hidden {
    overflow: hidden;
  }
</style>
