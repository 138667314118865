

















































import {Mixins, Prop} from "vue-property-decorator";
import ErrorsMixin from "../../../mixins/ErrorsMixin.ts";
import ErrorMessage from "../../Form/ErrorMessage.vue";
import Component from "vue-class-component";
import processResponseException from "../../../utils/errors/processResponseException";
import remove from "../../../rest/remove";
import {InsuranceHistory, InsuranceType} from "../../../types/Insurance";

@Component({
  name: "RemovePatientInsuranceModal",
  components: {ErrorMessage}
})
export default class RemovePatientInsuranceModal extends Mixins(ErrorsMixin) {
  @Prop({type: Object, default: null}) readonly item!: InsuranceHistory | null;

  loading: boolean = false;
  InsuranceType = InsuranceType;

  closeModal() {
    this.$emit("canceled");
  }

  async submit(): Promise<void> {
    this.errors = [];
    this.loading = true;

    try {
      await remove(`/api/insurances/${this.item!.id}`, {});
      this.$bvModal.hide("remove-patient-insurance-modal");
      this.$emit("removed");
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  onHide(): void {
    this.errors = [];
    this.$emit("canceled");
    this.$bvModal.hide("remove-patient-insurance-modal");
  }
}
