import {Route} from "vue-router";

export const getHomeRouteForRole = (role: string, hasPWZ: boolean): Route["name"] => {
  switch(role) {
    case "ROLE_RECEPTION":
    case "ROLE_RECEPTION_WITH_MEDICAL_RECORDS":
      return "registration";
    case "ROLE_MANAGER":
      return hasPWZ ? "home" : "worker_list";
    default: return "home"
  }
}
