














































import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import Card from "../Card.vue";

interface FormData {
  benefitsOfSocialAssistance: boolean,
  initialAssessmentHealthNeedsAndProvidingSupport: boolean,
  agreeingDateAndPlaceCZPServices: boolean,
  providingHealthServices: boolean,
  scopeOfActivitiesAndHealthcareInformation: boolean,
}

@Component({
  name: PzkForm.name,
  components: {Card,}
})
export default class PzkForm extends Vue {

  @Prop({default: null, type: Object}) readonly pzk!: FormData | null;

  private formData: FormData = {
    benefitsOfSocialAssistance: this.pzk ? this.pzk.benefitsOfSocialAssistance : false,
    scopeOfActivitiesAndHealthcareInformation: this.pzk ? this.pzk.scopeOfActivitiesAndHealthcareInformation: false,
    agreeingDateAndPlaceCZPServices: this.pzk ? this.pzk.agreeingDateAndPlaceCZPServices: false,
    // eslint-disable-next-line max-len
    initialAssessmentHealthNeedsAndProvidingSupport: this.pzk ? this.pzk.initialAssessmentHealthNeedsAndProvidingSupport : false,
    providingHealthServices: this.pzk ? this.pzk.providingHealthServices : false,
  }

  @Watch("pzk", {immediate: false}) onChange(): void {
    this.formData = {
      benefitsOfSocialAssistance: this.pzk ? this.pzk.benefitsOfSocialAssistance : false,
      scopeOfActivitiesAndHealthcareInformation: this.pzk ? this.pzk.scopeOfActivitiesAndHealthcareInformation: false,
      agreeingDateAndPlaceCZPServices: this.pzk ? this.pzk.agreeingDateAndPlaceCZPServices: false,
      // eslint-disable-next-line max-len
      initialAssessmentHealthNeedsAndProvidingSupport: this.pzk ? this.pzk.initialAssessmentHealthNeedsAndProvidingSupport : false,
      providingHealthServices: this.pzk ? this.pzk.providingHealthServices : false,
    };
  }

  private update(){
    this.$emit("input", this.formData);
  }

  private async updateFormData(field: string, value: boolean) {
    this.formData = {
      ...this.formData,
      [field]: value,
    };
    this.update();
  }


}
