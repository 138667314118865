<template>
  <b-modal
    :id="modalId"
    :title="title"
    lazy
    no-close-on-backdrop
    title-tag="h3"
    size="lg"
    @hide="resetData"
    @show="fetchData"
  >
    <loading-mask :loading="loading">
      <error-message
        :errors="errors"
        class="m-t-20"
      />
      <template v-if="meetingType !== null">
        <div class="row">
          <div class="col-md-3">
            <meeting-type-radio
              v-model="meetingType"
              :available="locationTypeAvailability"
              name="mobile"
            />
          </div>
          <div
            v-if="patientsCount >= 0"
            class="col-md-6"
          >
            <patient-presence-radio
              v-model="patientPresence"
              :patient-presence="patientPresence"
            />
          </div>
          <div class="col-md-12">
            <address-form
              v-if="meetingType === appointmentLocationTypes.REMOTE"
              v-model="address"
              :errors="getSubErrors('address')"
              horizontal
              in-modal
            />
          </div>
        </div>
      </template>
    </loading-mask>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        type="button"
        class="btn btn-primary"
        @click="save"
      >
        <span
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fas"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import processResponseException from "../../../utils/errors/processResponseException";
import LoadingMask from "../../Loading/LoadingMask";
import ErrorMessage from "../../Form/ErrorMessage";
import read from "../../../rest/read";
import update from "../../../rest/update";
import MeetingTypeRadio from "../../Form/Radio/MeetingTypeRadio";
import AddressForm from "../../Address/AddressForm";
import subErrors from "../../../utils/errors/subErrors";
import isEmpty from "lodash/isEmpty"
import PatientPresenceRadio from "../../Form/Radio/PatientPresenceRadio";
import {guessMeetingType} from "../../../utils/appointment/meetingType";
import {AppointmentLocationType} from "../../../types/Appointment";

export default {
  name: "ChangeApppointmentLocationType",
  components: {MeetingTypeRadio, AddressForm, ErrorMessage, LoadingMask, PatientPresenceRadio},
  props: {
    appointmentId: {required: true, type: String},
    patientId: {required: true, type: String},
    title: {required: true, type: String},
  },
  data() {
    return {
      loading: false,
      errors: [],
      address: {},
      meetingType: null,
      patientPresence: null,
      patientsCount: null,
      appointmentLocationTypes: AppointmentLocationType
    };
  },
  computed: {
    modalId() {
      return `changeAppointmentLocationType-${this.appointmentId}`;
    },
    locationTypeAvailability() {
      return {
        local: true,
        remote: true,
        telephone: true
      }
    },
  },
  watch: {
    meetingType: {
      immediate: true,
      handler(val) {
        if (val === AppointmentLocationType.REMOTE && isEmpty(this.address)) {
          this.loadAddress();
        }
      }
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const {location, patientPresence, patients} = await read(
          `/api/appointments/${this.appointmentId}`, {patientId: this.patientId}
        );
        this.meetingType = guessMeetingType(location);
        this.address = location.address || {};
        this.patientPresence = patientPresence;
        this.patientsCount = patients.length;
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    async loadAddress(){
      this.loading = true;
      try {
        this.address = await read(`/api/patients/${this.patientId}/living_address`);
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    resetData() {
      this.errors = [];
      this.address = {};
      this.meetingType = null;
      this.patientPresence = null;
    },
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
    async save() {
      this.loading = true;
      try {
        await update(`/api/appointment/${this.appointmentId}/location`, {
          isLocal: this.meetingType !== AppointmentLocationType.REMOTE,
          address: this.meetingType === AppointmentLocationType.REMOTE ? this.address : null,
          patientPresence: this.patientsCount > 0 ? this.patientPresence : null,
          telephone: this.meetingType === AppointmentLocationType.PHONE
        });
        this.closeModal();
        this.$emit("appointmentUpdated");
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    getSubErrors(field) {
      return subErrors(this.errors, field);
    }
  },
}
</script>

<style scoped lang="scss">
  $margin: 5px;

  .treatment-worker {
    display: flex;
    align-content: center;

    .worker {
      width: 35%;
      margin-right: $margin;
      margin-bottom: $margin;
    }

    .treatment-type {
      width: 65%;
      margin-left: $margin;
      margin-bottom: $margin;
    }
  }
</style>
