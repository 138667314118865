<template>
  <div>
    <b-table
      :items="tableItems"
      :fields="fields"
      :hover="showDetails || goToAppointment"
      :empty-text="emptyText"
      responsive
      show-empty
      @row-clicked="onRowClicked"
    >
      <template #cell(insurance)="data">
        <insurance-status-icon
          v-if="undefined !== insuranceStatus(data.item)"
          size="10px"
          style="margin: 0  auto"
          :status="insuranceStatus(data.item)"
          :with-label="true"
        />
      </template>
      <template #cell(hour)="data">
        <date-time
          :value="data.item.date"
          :format="hourFormat"
        />
      </template>
      <template #cell(date)="data">
        <date-time
          :value="data.item.date"
          :format="dateFormat"
        />
      </template>
      <template #head(date)="data">
        <div
          v-if="orderDesc !== null"
          class="order-btn"
          @click="changeOrder"
        >
          <i
            :class="{'upside-down': orderDesc}"
            class="icon fa fa-caret-up"
          />&ensp;
          {{ data.label }}
        </div>
        <template v-else>
          {{ data.label }}
        </template>
      </template>
      <template #cell(patients)="data">
        <patient-options-badge
          :patients-length="data.item.patients.length"
          :patient-presence="data.item.patientPresence"
        />
        <appointment-patients :items="data.item.patients" />
      </template>
      <template #cell(patientsLinks)="data">
        <patient-options-badge
          :patients-length="data.item.patients.length"
          :patient-presence="data.item.patientPresence"
        />
        <appointment-patients-links
          :items="data.item.patients"
          @click.native.stop
        />
      </template>

      <template #cell(options)="data">
        <appointment-item-actions
          v-bind="data.item"
          action-dropdowns-visible
          :date="data.item.date"
          :patient-id="patientId"
          :cancel-allowed-from-group="!patientId"
          :change-patients-allowed-from-group="!patientId"
          :remove-patient-allowed-from-group="!!patientId"
          @appointmentUpdated="updateAppointmentList"
        />
      </template>

      <template #row-details="data">
        <appointment-item-actions
          v-bind="data.item"
          :date="data.item.date"
          :patient-id="patientId"
          :cancel-allowed-from-group="!patientId"
          :change-patients-allowed-from-group="!patientId && !data.item.continuousStay"
          :remove-patient-allowed-from-group="!!patientId"
          @appointmentUpdated="updateAppointmentList"
        />
      </template>

      <template #cell(dateTime)="data">
        <date-time
          :value="data.item.date"
          :format="dateTimeFormat"
        />
      </template>

      <template #cell(specialistTypeStatus)="data">
        <div class="d-flex flex-column">
          <div
            v-for="worker in sortedWorkers(data.item)"
            :key="worker.workerId"
            class="d-flex flex-nowrap mb-2"
          >
            <div class="appointment-worker d-flex flex-nowrap mr-2">
              <i
                :class="{'text-muted': worker.workerId !== data.item.mainWorkerId}"
                class="fa fa-user-tie mt-1 mr-2"
              />
              <div>{{ worker.name }}</div>
            </div>
            <div class="appointment-treatment">
              <div>
                <treatment-type-name
                  :value="worker.reportedTreatmentType"
                  class="mr-2"
                />
                <i
                  v-if="(!worker.isTreatmentTypeActive || !worker.isTreatmentTypeChoosable)
                    && displayUnreportedTreatments"
                  class="fa fa-flag"
                />
                <b-badge
                  v-if="data.item.status !== 'canceled' && worker.unfinishedDocuments > 0"
                  variant="danger"
                >
                  <i class="fa fa-times-circle" />
                  Niezamknięte dokumenty: {{ worker.unfinishedDocuments }} / {{ worker.allDocuments }}
                </b-badge>
              </div>
            </div>
          </div>
          <div class="ml-auto">
            <appointment-status
              :name="data.item.status"
              badge
              class="mr-1"
            />
            <b-badge
              v-if="data.item.mobile"
              class="mr-1"
              variant="light"
            >
              <i class="fa fa-car" /> Wyjazd
            </b-badge>
            <b-badge
              v-if="!data.item.mobile && !data.item.telephone"
              class="mr-1"
              variant="light"
            >
              <i class="fa fa-hospital" /> Lokalna
            </b-badge>
            <b-badge
              v-if="!data.item.mobile && data.item.telephone"
              class="mr-1"
              variant="light"
            >
              <i class="fa fa-phone" /> Telefoniczna
            </b-badge>
            <b-badge
              v-if="data.item.group"
              class="mr-1"
              variant="light"
            >
              <i class="fa fa-users" /> Grupowa
            </b-badge>
            <patient-options-badge
              v-if="patientId"
              :patients-length="data.item.patients.length"
              :patient-presence="data.item.patientPresence"
            />
          </div>
        </div>
      </template>
      <template #cell(parameters)="data">
        <appointment-parameters-list
          :params="data.item.parameters"
        />
      </template>
      <template #cell(unificationOptions)="data">
        <appointment-unification-options
          :patient-id="patientId"
          v-bind="data.item"
        />
      </template>
    </b-table>
    <appointment-box-modals
      v-if="hasListOptions || showDetails"
      @appointmentUpdated="updateAppointmentList"
    />
    <appointment-unification-modals
      v-if="hasUnificationOptions"
      :patient-id="patientId"
      v-bind="appointmentData"
      @updateAppointmentList="updateAppointmentList"
    />
  </div>
</template>

<script>
import DateTime from "../DateTime";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import TreatmentTypeName from "../TreatmentType/TreatmentTypeName";
import AppointmentStatus from "./AppointmentStatus";
import AppointmentItemActions from "./AppointmentActions/AppointmentItemActions";
import AppointmentPatients from "./AppointmentPatients";
import AppointmentPatientsLinks from "./AppointmentPatientsLinks";
import AppointmentUnificationOptions from "./AppointmentUnificationOptions/AppointmentUnificationOptions";
import AppointmentParametersList from "./AppointmentParametersList";
import AppointmentUnificationModals from "./AppointmentUnificationOptions/AppointmentUnificationModals";
import AppointmentBoxModals from "./AppointmentBox/AppointmentBoxModals";
import {mapMutations} from "vuex";
import PatientOptionsBadge from "../PatientPresenceOptions/PatientOptionsBadge";
import InsuranceStatusIcon from "../Patient/Insurance/InsuranceStatusIcon";

export default {
  name: "AppointmentTable",
  components: {
    InsuranceStatusIcon,
    PatientOptionsBadge,
    AppointmentBoxModals,
    AppointmentUnificationModals,
    AppointmentParametersList,
    AppointmentUnificationOptions,
    AppointmentPatientsLinks,
    AppointmentPatients,
    AppointmentItemActions,
    AppointmentStatus,
    TreatmentTypeName,
    DateTime,
  },
  props: {
    items: {type: Array, required: true},
    columns: {type: Array, required: true},
    patientId: {type: String, default: null},
    orderDesc: {type: Boolean, default: null},
    showDetails: {type: Boolean, default: false},
    displayUnreportedTreatments: {type: Boolean, default: false},
    emptyText: {type: String, default: "Brak umówionych wizyt"},
    goToAppointment: {type: Boolean, default: false},
  },
  data() {
    return {
      appointmentData: null,
    };
  },
  computed: {
    hasUnificationOptions() {
      return this.columns.includes("unificationOptions");
    },
    hasListOptions() {
      return this.columns.includes("options");
    },
    tableItems() {
      return this.showDetails
        ? this.items.map(item => ({...item, _showDetails: true}))
        : this.items;
    },
    fieldsDefinitions() {
      return [
        {key: "hour", label: "Godzina", thStyle: "width: 10%"},
        {key: "date", label: "Data", thStyle: "width: 10%"},
        {key: "patients", label: "Pacjent (PESEL)", thStyle: "width: 15%"},
        {key: "insurance", label: "Ubezpieczenie"},
        {key: "specialist", label: "Specjalista", thStyle: "width: 15%"},
        {key: "type", label: "Typ wizyty", thStyle: "width: 35%"},
        {key: "options", label: ""},
        {key: "patientsLinks", label: "Pacjent (Karta pacjenta)", thStyle: "width: 15%"},
        {key: "dateTime", label: "Data"},
        {key: "specialistTypeStatus", label: "Specjalista / Typ wizyty", thStyle: "width: 50%, minWidth: 50%"},
        {key: "parameters", label: "Wybrane parametry"},
        {key: "unificationOptions", label: "Opcje"},
      ];
    },
    fields() {
      return this.columns
        .map((column) => this.fieldsDefinitions.find((definition) => definition.key === column))
        .filter((definition) => null != definition);
    },
    hourFormat() {
      return DATE_FORMAT.TIME;
    },
    dateFormat() {
      return DATE_FORMAT.DATE;
    },
    dateTimeFormat() {
      return DATE_FORMAT.DATE_TIME;
    },
  },
  methods: {
    ...mapMutations("appointmentListItem", ["saveAppointmentListItem",]),
    updateAppointmentList() {
      this.$emit("appointmentUpdated");
    },
    changeOrder() {
      this.$emit("changeOrder");
    },
    onRowClicked(item) {
      if(this.goToAppointment) {
        if (item.status === "opened") {
          this.$router.push({name: "viewDocumentAppointment", params: {appointmentId: item.appointmentId}});
        }
        return;
      }

      if(this.showDetails) {
        const appointment = {
          ...item,
          mainWorker: item.workers.find(worker => worker.workerId === item.mainWorkerId),
          patientId: this.patientId,
          cancelAllowedFromGroup: !this.patientId,
          changePatientsAllowedFromGroup: !this.patientId && !item.continuousStay,
          removePatientAllowedFromGroup: !!this.patientId,
        };
        this.saveAppointmentListItem(appointment);
        setTimeout(() => this.$bvModal.show("appointment-info-modal"));
      }
    },
    sortedWorkers(appointment) {
      return [...appointment.workers].sort((workerA, workerB) => {
        if (workerA.workerId === appointment.mainWorkerId) {
          return -1;
        }
        if (workerB.workerId === appointment.mainWorkerId) {
          return 1;
        }
        return 0;
      });
    },
    insuranceStatus(appointment) {
      const insurances = appointment.patients
        .filter((patient) => patient.patientId === this.patientId || null == this.patientId)
        .map((patient) => patient.insured)

      if (insurances.includes(false)) {
        return false;
      }

      return insurances.includes(null) ? null : true;
    }
  },
}
</script>

<style scoped lang="scss">
  .order-btn {
    cursor: pointer;
    white-space: nowrap;
  }

  .icon {
    transition: transform 1s;
    transform: rotate(0deg);

    &.upside-down {
      transform: rotate(180deg);
    }
  }

  .appointment-worker {
    flex: 0 1 30%;
  }

  .appointment-treatment {
    flex: 1 1 70%;
  }
</style>
