export const ewusPermissions = {
  U_K: "(U-K) Ubezpieczony - w przypadku karty ubezpieczenia zdrowotnego",
  U_NK: "(U-NK) Ubezpieczony - w  przypadku  innego  dokumentu, który  zgodnie  z  art.  240  ustawy  z  " +
    "dnia 27 sierpnia 2004 r. o świadczeniach opieki zdrowotnej  finansowanych  ze  środków publicznych  " +
    "(Dz.  U.  z  2018  r.  poz.  1510, z późn.  zm.),  zwanej  dalej  \"ustawą\",  do czasu  wydania  karty  " +
    "ubezpieczenia zdrowotnego  potwierdza  uprawnienia  do świadczeń opieki zdrowotnej",
  U_OS: "(U-OS) Ubezpieczony - w przypadku złożenia oświadczenia, o którym mowa w art. 50 ust. 6 ustawy",
  N_A: "(N-A) Świadczeniobiorca, o którym mowa w art. 2 ust. 1 pkt 2 ustawy - w przypadku decyzji, o której mowa w " +
    "art. 54 ustawy",
  N_OS: "(N-OS) Świadczeniobiorca, o którym mowa w art. 2 ust. 1 pkt 2 ustawy - w przypadku złożenia oświadczenia, " +
    "o którym mowa w art. 50 ust. 6 ustawy",
  IA: "(IA) Świadczeniobiorca, o którym mowa w art. 2 ust. 1 pkt 3 lit. a ustawy - pełna nazwa dokumentu",
  IA_OS: "(IA-OS) Świadczeniobiorca, o którym mowa w art. 2 ust. 1 pkt 3 lit. a ustawy - w przypadku złożenia " +
    "oświadczenia, o którym mowa w art. 50 ust. 6 ustawy",
  IAU_KB: "(IAU-KB) Świadczeniobiorca, o którym mowa w art. 2 ust. 1 pkt 3 lit. b ustawy - karta pobytu, o której" +
    " mowa w ustawie z dnia 12 grudnia 2013 r. o cudzoziemcach (Dz. U. z 2018 r. poz. 2094, z późn. zm.)",
  IAU_ZPC: "(IAU-ZPC) Świadczeniobiorca, o którym mowa w art. 2 ust. 1 pkt 3 lit. b ustawy - decyzja o udzieleniu" +
    " cudzoziemcowi zezwolenia na pobyt czasowy w związku z okolicznością, o której mowa w art. 159 ust. 1 pkt 1 lit." +
    " c lub d ustawy z dnia 12 grudnia 2013 r. o cudzoziemcach",
  IAU_OS: "(IAU-OS) Świadczeniobiorca, o którym mowa w art. 2 ust. 1 pkt 3 lit. b ustawy - w przypadku złożenia " +
    "oświadczenia,  o którym mowa w art. 50 ust. 6 ustawy",
  IB: "(IB) Świadczeniobiorca, o którym mowa w art. 2 ust. 1 pkt 4 lit. a ustawy - pełna nazwa dokumentu",
  IB_OS: "(IB-OS) Świadczeniobiorca, o którym mowa w art. 2 ust. 1 pkt 4 lit. a ustawy - w przypadku złożenia " +
    "oświadczenia, o którym mowa w art. 50 ust. 6 ustawy",
  IBU_KB: "(IBU-KB) Świadczeniobiorca, o którym mowa w art. 2 ust. 1 pkt 4 lit. b ustawy - karta pobytu, o " +
    "której mowa w ustawie z dnia 12 grudnia 2013 r. o cudzoziemcach",
  IBU_ZPC: "(IBU-ZPC) Świadczeniobiorca, o którym mowa w art. 2 ust. 1 pkt 4 lit. b ustawy - decyzja o udzieleniu " +
    "cudzoziemcowi zezwolenia na pobyt czasowy w związku z okolicznością, o której mowa w art. 159 ust. 1 pkt 1 " +
    "lit. c lub d ustawy z dnia 12 grudnia 2013 r. o cudzoziemcach",
  IBU_OS: "(IBU-OS) Świadczeniobiorca, o którym mowa w art. 2 ust. 1 pkt 4 lit. b ustawy - w przypadku złożenia " +
    "oświadczenia, o którym mowa w art. 50 ust. 6 ustawy",
  UE_E: "(UE-E) Osoba uprawniona do świadczeń opieki zdrowotnej na podstawie przepisów o koordynacji - w przypadku" +
    " Europejskiej Karty Ubezpieczenia Zdrowotnego",
  UE_O: "(UE-O) Osoba uprawniona do świadczeń opieki zdrowotnej na podstawie przepisów o koordynacji - w przypadku" +
    " poświadczenia, o którym mowa w art. 52 ustawy",
  UE_F: "(UE-F) Osoba uprawniona do świadczeń opieki zdrowotnej na podstawie przepisów o koordynacji - w przypadku" +
    " dokumentów wystawionych na formularzu serii E lub dokumentów przenośnych serii S albo DA1",
  UE_C: "(UE-C) Osoba uprawniona do świadczeń opieki zdrowotnej na podstawie przepisów o koordynacji - w przypadku" +
    " certyfikatu zastępującego Europejską Kartę Ubezpieczenia Zdrowotnego",
  UE_SED: "(UE-SED) Osoba uprawniona do świadczeń opieki zdrowotnej na podstawie przepisów o koordynacji - w " +
    "przypadku dokumentu SED S045",
  UM_T: "(UM-T) Osoby, o których mowa w art. 2 ust. 2 ustawy – w przypadku paszportu",
  PO: "(PO) Osoby, którym świadczenia są udzielane bezpłatnie, zgodnie z art. 12 ustawy pkt 1a - pełna nazwa dokumentu",
  AL: "(AL) Osoby, którym świadczenia są udzielane bezpłatnie, zgodnie z art. 12 ustawy pkt 2 - pełna nazwa dokumentu",
  NA: "(NA) Osoby, którym świadczenia są udzielane bezpłatnie, zgodnie z art. 12 ustawy pkt 3 - pełna nazwa dokumentu",
  PS: "(PS) Osoby, którym świadczenia są udzielane bezpłatnie, zgodnie z art. 12 ustawy pkt 4 - pełna nazwa dokumentu",
  CU: "(CU) Osoby, którym świadczenia są udzielane bezpłatnie, zgodnie z art. 12 ustawy pkt 5 - pełna nazwa dokumentu",
  ZA: "(ZA) Osoby, którym świadczenia są udzielane bezpłatnie, zgodnie z art. 12 ustawy pkt 6 - pełna nazwa dokumentu",
  ZP: "(ZP) Osoby, którym świadczenia są udzielane bezpłatnie, zgodnie z art. 12 ustawy pkt 10 - pełna nazwa dokumentu",
  ZF: "(ZF) Osoby, którym świadczenia są udzielane bezpłatnie, zgodnie z art. 12 ustawy pkt 11 - pełna nazwa dokumentu",
  RR: "(RR) Osoby, którym świadczenia są udzielane bezpłatnie, zgodnie z art. 12 ustawy pkt 12 - pełna nazwa dokumentu",
  RP_KP: "(RP-KP) Osoby, którym świadczenia udzielane są bezpłatnie, zgodnie z art. 12 pkt 9 ustawy - Karta Polaka, " +
    "o której mowa w ustawie z dnia 7 września 2007 r. o Karcie Polaka (Dz. U. z 2018 r. poz. 1272, z późn. zm.)",
  BU: "(BU) Osoby, którym przysługuje prawo do świadczeń opieki zdrowotnej zgodnie z art. 67 ustawy ust. 4 - pełna " +
    "nazwa dokumentu",
  UC: "(UC) Osoby, którym przysługuje prawo do świadczeń opieki zdrowotnej zgodnie z art. 67 ustawy ust. 5 - pełna " +
    "nazwa dokumentu",
  CH: "(CH) Osoby, którym przysługuje prawo do świadczeń opieki zdrowotnej zgodnie z art. 67 ustawy ust. 6 - pełna " +
    "nazwa dokumentu",
  EM: "(EM) Osoby, którym przysługuje prawo do świadczeń opieki zdrowotnej zgodnie z art. 67 ustawy ust. 7 - pełna " +
    "nazwa dokumentu",
  EL_DE: "(EL-DE) Świadczeniobiorca, którego prawo do świadczeń opieki zdrowotnej zostało potwierdzone dokumentem" +
    " elektronicznym, o którym mowa w art. 50 ust. 3 ustawy - dokument elektroniczny, o którym mowa w art. 50 ust." +
    " 3 ustawy",
  B_B: "(B-B) Świadczeniobiorca, któremu udzielono świadczenia w sytuacjach, o których mowa w art. 50 ust. 11 ustawy " +
    "- brak przedstawienia dokumentu potwierdzającego prawo do świadczeń opieki zdrowotnej",
  UP_B: "(UP-B) Osoba nieposiadająca prawa do świadczeń opieki zdrowotnej lub osoba, która utraciła to prawo w " +
    "trakcie udzielania świadczenia trwającego dłużej niż jeden dzień i poinformowała o tym świadczeniodawcę - brak" +
    " przedstawienia dokumentu potwierdzającego prawo do świadczeń opieki zdrowotnej",
  NF_B: "(NF-B) Osoba posiadająca prawo do świadczeń opieki zdrowotnej finansowanych przez inny podmiot niż Fundusz " +
    "- brak przedstawienia dokumentu potwierdzającego prawo do świadczeń opieki zdrowotnej",
  RM_B: "(RM-B) Osoba znajdująca się w stanie nagłego zagrożenia zdrowotnego mająca prawo do medycznych czynności " +
    "ratunkowych, zgodnie z ustawą z dnia 8 września 2006 r. o Państwowym Ratownictwie Medycznym " +
    "(Dz. U. z 2019 r. poz. 993) - brak przedstawienia dokumentu potwierdzającego prawo do świadczeń opieki zdrowotnej"
}
