<template>
  <div>
    <card
      :loading="loading"
      title="Adres zamieszkania"
    >
      <template #control>
        <is-granted
          :privileges="['CHANGE_PATIENT_LIVING_ADDRESS']"
        >
          <b-btn
            v-b-modal.livingAddressModal
            :disabled="loading || errors.length > 0"
            variant="primary"
            class="btn-sm"
          >
            <i class="fa fa-pencil-alt" />
          </b-btn>
        </is-granted>
      </template>
      <error-message :errors="errors" />
      <p>
        <span v-if="livingAddress.street">
          {{ livingAddress.street }}
          <!-- eslint-disable-next-line -->
          {{ livingAddress.buildingNumber }}<span v-if="livingAddress.apartmentNumber">/{{ livingAddress.apartmentNumber }}</span>,
        </span>
        {{ livingAddress.zipCode }}
        {{ livingAddress.city }}&nbsp;
      </p>
    </card>
    <b-modal
      id="livingAddressModal"
      hide-footer
      no-close-on-backdrop
      title="Edycja adresu zamieszkania"
      title-tag="h3"
    >
      <living-address-form
        :living-address="livingAddress"
        :patient-id="patientId"
        @updateLivingAddress="updateLivingAddress"
        @cancel="cancel"
      />
    </b-modal>
  </div>
</template>

<script>
import read from "../../../rest/read";
import processResponseException from "../../../utils/errors/processResponseException";
import ErrorMessage from "../../Form/ErrorMessage";
import LivingAddressForm from "../LivingAddressForm";
import Card from "../../Card";
import IsGranted from "../../IsGranted";

export default {
  components: {
    IsGranted,
    Card,
    LivingAddressForm,
    ErrorMessage,
  },
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      livingAddress: {},
      errors: [],
      loading: false,
    };
  },
  watch: {
    patientId() {
      this.fetchData();
    },
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        this.livingAddress = await read(`/api/patients/${this.patientId}/living_address`);
        this.$emit("input", this.livingAddress);
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    cancel() {
      this.$bvModal.hide("livingAddressModal");
      this.errors = [];
    },
    updateLivingAddress(livingAddress) {
      this.livingAddress = livingAddress;
      this.$emit("input", this.livingAddress);
      this.$emit("livingAddressUpdated");
    },
  },

};
</script>

<style scoped>
  .card-box {
    overflow: hidden;
  }
</style>
