



































































import {mapMutations, mapState} from "vuex";
import DateTime from "../DateTime.vue";
import AppointmentStatus from "./AppointmentStatus.vue";
import parseDate from "../../utils/date/parseDate";
import read from "../../rest/read";
import LoadingMask from "../Loading/LoadingMask.vue";
import TreatmentTypeNameTooltip from "../TreatmentType/TreatmentTypeNameTooltip.vue";
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import type {Patient, WorkerObject} from "../../types/WeeklySchedule";
import {PatientContinuousStay} from "../../store/modules/appointmentListItem/types";
import {RootState} from "../../store/types";

interface Item {
  appointmentId: string;
  end: Date;
  group: boolean;
  isLocal: boolean;
  mainWorkerId: string;
  patientPresence: boolean;
  patientsList: Array<Patient>;
  start: Date;
  status: string;
  treatmentType: string;
  workers: Array<WorkerObject>;
  branchId: string;
  branchName: string;
}

interface Appointment {
  appointmentId: string;
  date: string;
  mainSpecialist: WorkerObject;
  additionalSpecialists: WorkerObject[];
  patientPresence: boolean;
  patients: Array<Patient>;
  status: string;
  continuousStay?: boolean;
  patientContinuousStays?: PatientContinuousStay[];
}

interface CurrentItem {
  appointmentId: string;
  removePatientAllowedFromGroup: boolean;
  cancelAllowedFromGroup: boolean;
  changePatientsAllowedFromGroup: boolean;
  date: Date|null;
  group: boolean;
  patients: Array<Patient>;
  mainWorker: WorkerObject|null;
  status: string|null;
  mobile: boolean;
  workers: Array<WorkerObject>;
  continuousStay?: boolean;
  patientContinuousStays?: PatientContinuousStay[];
  branchId: string;
  branchName: string;
}

@Component({
  name: "AppointmentButton",
  components: {
    TreatmentTypeNameTooltip,
    LoadingMask,
    AppointmentStatus,
    DateTime,
  },
  computed: {...mapState({
    patientAnonymous: (state: RootState): boolean => state.clinicParameters!.parameters!.patientAnonymous,
  })},
  methods: {
    ...mapMutations("appointmentListItem", ["saveAppointmentListItem",])
  },
})

export default class AppointmentButton extends Vue {
  @Prop({type: Object, required: true}) readonly item!: Item;
  @Prop({type: Boolean, default: false}) readonly showMainWorker!: boolean;
  @Prop({type: Boolean, default: true}) readonly showLocationType!: boolean;
  @Prop({type: Boolean, default: false}) readonly showTreatmentType!: boolean;

  appointment: Appointment|null = null;
  parseDate = parseDate;
  loading: boolean = false;

  get patientName() {
    if (!this.item.patientPresence || !this.item.patientsList.length) {
      return "Wizyta bez pacjentów";
    }
    if (this.item.patientsList.length > 1) {
      return "Wizyta grupowa";
    }

    return `${this.item.patientsList[0].displayName}`;
  }

  get patientCaseNumber() {
    if (this.patientAnonymous
      || !this.item.patientPresence
      || !this.item.patientsList.length
      || this.item.patientsList.length > 1) {
      return null;
    }
    return this.item.patientsList[0].fileNumber;
  }

  get mainWorkerName() {
    const worker: WorkerObject|undefined = this.item.workers
      .find(worker => worker.workerId === this.item.mainWorkerId);
    return worker ? worker.name : null;
  }

  setAppointmentData(data: Appointment) {
    this.appointment = data;
  }

  async showAppointmentInfoModal() {
    this.loading = true;
    if (this.appointment === null) {
      this.appointment = await read<Appointment>(`/api/appointments/${this.item.appointmentId}`);
    }
    const currentItem: CurrentItem = {
      appointmentId: this.item.appointmentId,
      removePatientAllowedFromGroup: false,
      cancelAllowedFromGroup: true,
      changePatientsAllowedFromGroup: !this.appointment.continuousStay,
      date: this.appointment ? parseDate(this.appointment.date) : null,
      group: this.item.group,
      patients: this.appointment ? this.appointment.patients : [],
      mainWorker: this.appointment ? this.appointment.mainSpecialist : null,
      status: this.appointment ? this.appointment.status : null,
      mobile: !this.item.isLocal,
      workers: [this.appointment.mainSpecialist, ...this.appointment.additionalSpecialists],
      continuousStay: this.appointment.continuousStay,
      patientContinuousStays: this.appointment.patientContinuousStays,
      branchId: this.item.branchId,
      branchName: this.item.branchName,
    };
    this.saveAppointmentListItem(currentItem);
    this.loading = false;
    setTimeout(() => this.$bvModal.show("appointment-info-modal"));
  }
}
