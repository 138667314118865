var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{attrs:{"loading":_vm.isLoading,"title":"Upoważnienia do danych medycznych"},scopedSlots:_vm._u([{key:"control",fn:function(){return [_c('a',{staticClass:"btn btn-secondary btn-sm m-b-10",attrs:{"href":("/api/patient/" + _vm.patientId + "/medical-data-authorizations/print"),"target":"_blank"}},[_c('i',{staticClass:"fa fa-print"}),_vm._v(" Drukuj formularz ")]),_c('b-btn',{directives:[{name:"b-modal",rawName:"v-b-modal.createMedicalDataAuthorization",modifiers:{"createMedicalDataAuthorization":true}}],staticClass:"m-b-10 ml-2",attrs:{"size":"sm","variant":"primary"}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Dodaj upoważnienie ")])]},proxy:true}])},[_c('patient-card-create-medical-data-authorization',{attrs:{"patient-id":_vm.patientId},on:{"medicalDataAuthorizationCreated":_vm.getMedicalDataAuthorizations}}),_c('b-table',{attrs:{"items":_vm.medicalDataAuthorizations,"fields":_vm.fields,"empty-text":"Nie wystawiono upoważnień","responsive":true,"show-empty":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.authorizedPersonName)+" "+_vm._s(item.authorizedPersonSurname)+" ")]}},{key:"cell(email)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"cell(phoneNumber)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.phoneNumber)+" ")]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('b-alert',{attrs:{"show":true,"variant":item.isAuthorizationValid ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(_vm.authorizationStatusLabel(item.endOfAuthorization, item.cancellationDate))+" ")])]}},{key:"cell(options)",fn:function(ref){
var item = ref.item;
return [(item.isAuthorizationValid)?[_c('b-btn',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("confirmation" + (item.medicalDataAuthorizationId))),expression:"`confirmation${item.medicalDataAuthorizationId}`"}],attrs:{"disabled":_vm.isLoading,"size":"sm","variant":"outline-secondary"}},[_c('span',{staticClass:"fas mr-1",class:_vm.isLoading ? 'fa-spin fa-spinner' : 'fa-ban'}),_vm._v(" Cofnij upoważnienie ")]),_c('b-modal',{staticClass:"text-center",attrs:{"id":("confirmation" + (item.medicalDataAuthorizationId)),"ok-title":"Tak, cofam upoważnienie dla tej osoby","ok-variant":"danger","cancel-title":"Anuluj"},on:{"ok":function($event){return _vm.removeAuthorization(item.medicalDataAuthorizationId)}}},[_vm._v(" Potwierdź cofnięcie upoważnienia dostępu do danych medycznych dla osoby: "),_c('b',{staticClass:"d-block mt-3"},[_vm._v(" "+_vm._s(item.authorizedPersonName)+" "+_vm._s(item.authorizedPersonSurname)+" ")])])]:_vm._e()]}}])}),_c('pagination-description',{attrs:{"items-count":_vm.medicalDataAuthorizations.length,"page":_vm.page,"per-page":_vm.pagination.perPage,"total-count":_vm.pagination.totalRows}},[_c('b-pagination',{staticClass:"mb-0",attrs:{"value":_vm.page,"total-rows":_vm.pagination.totalRows,"per-page":_vm.pagination.perPage,"align":"right"},on:{"input":_vm.changePage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }