<template>
  <b-table
    :items="items"
    :fields="fields"
    empty-text="Brak wyników"
    hover
    responsive
    show-empty
  >
    <template #cell(createdAt)="data">
      {{ data.item.createdAt }}
    </template>
    <template #cell(type)="data">
      {{ data.item.type }}
    </template>
    <template #cell(status)="data">
      <i18n
        :msgid="`@nfz-communication.${data.item.status}`"
      />
    </template>
    <template #cell(number)="data">
      {{ data.item.number }}
    </template>
    <template #cell(content)="data">
      <pre>{{ data.item.content }}</pre>
    </template>
    <template #cell(config)="data">
      <pre>{{ data.item.config }}</pre>
    </template>
    <template #cell(responseAttachmentId)="data">
      <pre>{{ data.item.responseAttachmentId }}</pre>
    </template>
    <template #cell(responseContent)="data">
      <pre>{{ data.item.responseContent }}</pre>
    </template>
    <template #cell(preResponseAttachmentId)="data">
      <pre>{{ data.item.preResponseAttachmentId }}</pre>
    </template>
    <template #cell(preResponseContent)="data">
      <pre>{{ data.item.preResponseContent }}</pre>
    </template>
  </b-table>
</template>

<script>
import I18n from "@/components/i18n";

export default {
  name: "NfzCommunicationTable",
  components: {
    I18n
  },
  props: {
    items: {type: Array, default: ()=>[]},
  },
  data() {
    const fields = [
      {key: "createdAt", label: "Data"},
      {key: "type", label: "Typ"},
      {key: "status", label: "Status"},
      {key: "number", label: "Numer"},
      {key: "content", label: "Treść", class: "contentWidthColumn"},
      {key: "config", label: "Ustawienia"},
      {key: "preResponseAttachmentId", label: "Id załącznika opowiedzi (1)"},
      {key: "preResponseContent", label: "Treść załącznika odpowiedzi (1)", class: "contentWidthColumn"},
      {key: "responseAttachmentId", label: "Id załącznika opowiedzi (2)"},
      {key: "responseContent", label: "Treść załącznika odpowiedzi (1)", class: "contentWidthColumn"},
    ];
    return {
      fields,
    };
  },
}
</script>

<style>
  .contentWidthColumn{
    max-width: 800px;
  }
</style>
