<template>
  <b-modal
    id="createContinuousStayModal"
    no-close-on-backdrop
    title="Dodaj pobyt ciągły"
    title-tag="h3"
    @hide="clearModal"
  >
    <error-message :errors="errors" />
    <b-form-group
      label="Kategoria pobytu"
      label-for="continuousStayCategory"
    >
      <continuous-stay-category-select
        v-model="selectedContinuousStayCategory"
        :state="state('continuousStayCategory')"
        :multiple="false"
      />
      <error-message
        :errors="errors"
        field="continuousStayCategory"
      />
    </b-form-group>
    <b-form-group
      label="Komórka"
      label-for="branchId"
    >
      <branch-select
        v-model="branchId"
        :filter-by-code-eight="['2700']"
        check-if-any-branch-exists
      />
      <error-message
        :errors="errors"
        field="branchId"
      />
    </b-form-group>
    <b-form-group
      label="Data rozpoczęcia pobytu"
      label-for="startDate"
    >
      <div class="row">
        <date-picker
          v-model="startDate"
          class="col-12 col-sm-6"
          placeholder="Wybierz datę"
          :append-to-body="false"
          :state="state('startDate')"
        />
        <div class="col-12 col-sm-6">
          <hour-select v-model="startHour" />
        </div>
      </div>
      <error-message
        :errors="errors"
        field="startDate"
      />
    </b-form-group>

    <b-form-group
      label="Data zakończenia pobytu"
      label-for="endDate"
    >
      <div class="row">
        <date-picker
          v-model="endDate"
          class="col-12 col-sm-6"
          placeholder="Wybierz datę"
          :append-to-body="false"
          :state="state('endDate')"
          :clearable="true"
        />
        <div class="col-12 col-sm-6">
          <hour-select
            v-model="endHour"
            :clearable="true"
          />
        </div>
      </div>
      <error-message
        :errors="errors"
        field="endDate"
      />
    </b-form-group>
    <b-form-group label="Tryb przyjęcia">
      <admission-mode-select
        v-model="admissionMode"
        :state="state('admissionMode')"
      />
      <error-message
        :errors="errors"
        field="admissionMode"
      />
    </b-form-group>
    <template
      v-if="displayAdditionalEntitlements"
    >
      <hr>
      <additional-entitlements-document
        :value="additionalEntitlements"
        :errors="errors"
        :read-only="false"
        @input="updateAdditionalEntitlements"
      />
    </template>

    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="hideModal"
      >
        Anuluj
      </button>
      <button
        :disabled="loading || !selectedContinuousStayCategory"
        type="submit"
        class="btn btn-primary"
        @click="createContinuousStay"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import ContinuousStayCategorySelect from "./ContinuousStayCategorySelect";
import ErrorMessage from "../../../Form/ErrorMessage";
import HourSelect from "../../../Form/Select/HourSelect";
import create from "../../../../rest/create";
import {generateUuid} from "@/utils/uuid/generateUuid";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import DatePicker from "../../../Form/DatePicker/DatePicker";
import processResponseException
  from "../../../../utils/errors/processResponseException";
import stringifyDate from "../../../../utils/date/stringifyDate";
import BranchSelect from "@/components/Branch/BranchSelect";
import AdmissionModeSelect from "../../../Appointment/AdmissionMode/AdmissionModeSelect";
import AdditionalEntitlementsDocument from "../../../../views/Appointment/AdditionalEntitlementsDocument";

const additionalEntitlementsInitialState = {
  additionalEntitlementCode: null,
  documentName: null,
  documentId: null,
  createdDate: null,
  validDate: null
}
export default {
  components: {
    BranchSelect,
    DatePicker,
    HourSelect,
    ErrorMessage,
    ContinuousStayCategorySelect,
    AdmissionModeSelect,
    AdditionalEntitlementsDocument
  },
  mixins: [errorsMixin],
  props: {
    appointmentId: {type: String, default: null},
    patientId: {type: String, required: true},
  },
  data() {
    return {
      selectedContinuousStayCategory: null,
      startDate: null,
      startHour: null,
      endDate: null,
      endHour: null,
      branchId: null,
      loading: false,
      admissionMode: null,
      additionalEntitlements: additionalEntitlementsInitialState
    };
  },
  computed: {
    displayAdditionalEntitlements() {
      // see AdmissionModes.ts to understand values [8,9]
      return [8,9].includes(this.admissionMode);
    },
  },
  methods: {
    async createContinuousStay() {
      this.loading = true;
      let startDate = this.startDate ? new Date(this.startDate) : null;
      let endDate = this.endDate ? new Date(this.endDate) : null;

      if(startDate) {

        if(this.startHour) {
          startDate.setHours(this.startHour.HH);
          startDate.setMinutes(this.startHour.mm);
        }

        startDate = stringifyDate(startDate);
      }

      if(endDate) {

        if(this.endHour) {
          endDate.setHours(this.endHour.HH);
          endDate.setMinutes(this.endHour.mm);
        }

        endDate = stringifyDate(endDate);
      }

      try {
        const continuousStayId = generateUuid();
        await create(`/api/patients/${this.patientId}/continuous-stays`, {
          continuousStayId,
          patientId: this.patientId,
          continuousStayCategory: this.selectedContinuousStayCategory,
          startDate: startDate,
          endDate: endDate,
          appointmentId: this.appointmentId,
          branchId: this.branchId,
          admissionMode: this.admissionMode,
          additionalEntitlements: this.displayAdditionalEntitlements ? {
            ...this.additionalEntitlements,
            createdDate: null !== this.additionalEntitlements.createdDate ?stringifyDate(this.additionalEntitlements.createdDate) : null,
            validDate: null !== this.additionalEntitlements.validDate ? stringifyDate(this.additionalEntitlements.validDate) : null
          } : null
        });
        this.$emit("created", continuousStayId);
        this.clearModal();
        this.hideModal();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }

      this.loading = false;
    },
    hideModal() {
      this.$bvModal.hide("createContinuousStayModal");
    },
    clearModal() {
      this.selectedContinuousStayCategory = null;
      this.startDate = null;
      this.startHour = null;
      this.endDate = null;
      this.endHour = null;
      this.branchId = null;
      this.errors = [];
      this.admissionMode = null;
      this.additionalEntitlements = additionalEntitlementsInitialState;
    },
    updateAdditionalEntitlements(value){
      this.additionalEntitlements = value;
    }
  }
}
</script>
