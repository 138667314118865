import {Address, AppointmentLocationType} from "../../types/Appointment";

export interface Location {
  isLocal: boolean
  address: Address;
  telephone: boolean;
}

export function guessMeetingType(location: Location): string {
  if(!location.isLocal){
    return AppointmentLocationType.REMOTE
  } else if(location.isLocal && location.telephone){
    return AppointmentLocationType.PHONE
  } else {
    return AppointmentLocationType.LOCAL
  }
}
