<template>
  <div
    class="col-12 loaded-pdf-data white-bg font-options"
  >
    <div class="col-12 m-b-40 p-t-10">
      <h1 class="text-center ">
        Formularz zgłoszeniowy
      </h1>
      <p><b>Data:</b> {{ applicationDate }}</p>
      <p><b>Typ zgłoszenia:</b> {{ translatedApplicationCategory }}</p>
      <p><b>Specjalista:</b> {{ creatorName }}</p>
    </div>
    <div class="col-12 m-b-40">
      <h3 class="text-center">
        Kto dokonuje zgłoszenia
      </h3>
      <h4 class="text-center">
        (Wypełnić w sytuacji gdy zgłoszenia nie dokonuje pacjent)
      </h4>
      <p><b>Imię i nazwisko:</b> {{ applicant.name }} {{ applicant.surname }}</p>
      <p><b>Stopeń pokrewieństwa:</b> {{ translatedEligiblePerson }}</p>
      <p><b>Instytucja:</b> {{ applicant.institution }}</p>
      <p><b>Kontakt:</b> {{ applicant.contact }}</p>
    </div>
    <div class="col-12 m-b-40">
      <h3
        class="text-center"
      >
        Kto dokonuje zgłoszenia (dane pacjenta)
      </h3>
      <p><b>Imię i nazwisko:</b> {{ patient.name }} {{ patient.surname }}</p>
      <p><b>PESEL:</b> {{ patient.pesel }}</p>
      <p>
        <b>Adres:</b> {{ patient.address.street }} {{ patient.address.buildingNumber }}
        <span v-if="displayApartmentNumber">/{{ patient.address.apartmentNumber }} </span>
        {{ patient.address.zipCode }} {{ patient.address.city }}
      </p>
      <p><b>Miejsce urodzenia:</b> {{ patient.birthPlace }}</p>
      <p><b>Imię ojca:</b> {{ patient.fatherName }}</p>
      <p><b>Imię matki:</b> {{ patient.motherName }}</p>
      <p><b>Numer dowodu osobitego:</b> {{ patient.identityCard }}</p>
      <p><b>Kontakt:</b> {{ patient.phoneNumber }}</p>
    </div>
    <div class="col-12 m-b-40">
      <h3
        class="text-center"
      >
        Powód zgłoszenia / opis sytuacji
      </h3>
      <p>{{ description }}</p>
      <p class="small-font">
        <b>Pacjentowi udzielono informacji o zakresie działań Centrum Zdrowia Psychicznego i możliwości uzyskania
          świadczeń opieki zdrowotnej. Uzgodniono wstępny plan realizacji potrzeb pacjenta i wskazano termin i miejsce
          uzyskania niezbędnego świadczenia psychiatrycznego.
        </b>
      </p>
    </div>
    <div class="col-12 m-b-20">
      <p><b>Typ przypadku: </b>{{ translatedCaseType }}</p>
      <div v-if="caseType && caseType === 'sudden'">
        <p>
          <b>Skierowany do Izby przyjęć CZP / oddziału stacjonarnego:</b> {{ directedToPlace }}
        </p>
      </div>
      <div v-if="caseType && caseType !== 'sudden'">
        <p><b>Miejsce świadczenia: </b> {{ placeOfService }}</p>
        <p><b>Termin świadczenia: </b> {{ termOfService }}</p>
      </div>
    </div>
  </div>
</template>

<script>

import read from "../../rest/read";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import t from "../../i18n";

export default {
  name: "IpzApplicationFormPdfTemplate",
  props: {
    ipzId: {type: String, required: true},
  },
  data() {
    return {
      date: null,
      hour: null,
      applicationCategory: null,
      applicant: {
        eligiblePersonType: null
      },
      patient: {
        address: {}
      },
      description: null,
      caseType: null,
      termOfService: null,
      placeOfService: null,
      directedToPlace: null,
      isPrinting: false,
      creatorName: null,
    };
  },
  computed: {
    applicationDate(){
      let date = this.date;

      if(null !== this.hour){
        date.setHours(this.hour.HH, this.hour.mm);
      }

      return null !== date ? stringifyDate(date, DATE_FORMAT.DATE_TIME) : null
    },
    displayApartmentNumber(){
      return null !== this.patient.address.apartmentNumber && "" !== this.patient.address.apartmentNumber;
    },
    translatedApplicationCategory(){
      return t(`@application-form-category.${this.applicationCategory}`);
    },
    translatedCaseType(){
      return t(`@application-form-caseType.${this.caseType}`);
    },
    translatedEligiblePerson(){
      return null !== this.applicant.eligiblePersonType ?
        t(`@person-eligible.${this.applicant.eligiblePersonType}`)
        : null;
    }
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData(){
      const data = await read(`/api/ipz/${this.ipzId}/application-form`);
      this.readonly = null !== data.ipzApplicationFormId;
      this.date = null !== data.applicationDate ? new Date(data.applicationDate) : null;
      this.hour = null !== this.date ? {
        HH: stringifyDate(this.date, DATE_FORMAT.HOUR),
        mm: stringifyDate(this.date, DATE_FORMAT.MINUTE)
      } : null;
      this.applicationCategory = data.applicationCategory;
      this.applicant = null !== data.applicant ? data.applicant : {};
      this.patient = data.patient;
      this.description = data.description;
      this.caseType = data.caseType;
      this.worker = data.worker;
      this.termOfService = stringifyDate(new Date(data.termOfService), DATE_FORMAT.DATE);
      this.placeOfService = data.placeOfService;
      this.directedToPlace = data.directedToPlace;
      this.creatorName = data.creatorName;
    },
  }

}
</script>

<style scoped>
  .white-bg{
    background-color: #FFFFFF;
    height: 100%;
  }
  .font-options{
    font-size: 1.4rem;
  }
  .small-font {
    font-size: 1.1rem;
  }
  h1 {
    font-size: 2.3rem;
    font-weight: bold;
  }
  h2 {
    font-size: 1.8rem;
  }
  h3 {
    font-size: 1.6rem;
    font-weight: bold;
  }
  h4 {
    font-size: 1.4rem;
    font-weight: bold;
  }
  .m-b-40 {
    margin-bottom: 40px;
  }
</style>
