<template>
  <div class="row">
    <b-form-group
      class="col-12"
      label="Pacjent"
    >
      <input
        :value="record.patient"
        readonly
        class="form-control"
      >
    </b-form-group>
    <b-form-group
      class="col-6"
      label="Data dodania"
    >
      <input
        :value="record.notificationDate"
        readonly
        class="form-control"
      >
    </b-form-group>
    <b-form-group
      class="col-6"
      label="Data wykreślenia"
    >
      <input
        :value="record.endDate"
        readonly
        class="form-control"
      >
    </b-form-group>
    <b-form-group
      class="col-6"
      label="Dodano przez"
    >
      <input
        :value="record.createdBy"
        readonly
        class="form-control"
      >
    </b-form-group>
    <b-form-group
      class="col-6"
      label="Wykreślono przez"
    >
      <input
        :value="record.endBy"
        readonly
        class="form-control"
      >
    </b-form-group>
    <b-form-group
      class="col-6"
      label="Status"
    >
      <input
        :value="status"
        readonly
        class="form-control"
      >
    </b-form-group>
    <b-form-group
      class="col-6"
      label="Typ świadczenia"
    >
      <input
        :value="typeOfBenefit"
        readonly
        class="form-control"
      >
    </b-form-group>
    <b-form-group
      class="col-12"
      label="Opis"
    >
      <b-form-textarea
        :value="record.description"
        :rows="5"
        disabled
      />
    </b-form-group>
    <b-form-group
      class="col-12"
      label="Przyczyna wykreślenia poza kolejnością"
    >
      <b-form-textarea
        :value="record.endReason"
        :rows="5"
        disabled
      />
    </b-form-group>
  </div>
</template>

<script>
import t from "../../i18n";

export default {
  name: "WaitingListRecordPreviewForm",
  props: {
    record: {type: Object, required: true}
  },
  computed: {
    status() {
      return t(`@waiting-list-status.${this.record.status}`);
    },
    typeOfBenefit(){
      return t(`@type-of-benefit.${this.record.typeOfBenefit}`);
    }
  }
}
</script>
