var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('report-container',{attrs:{"report-id":"czp"}},[_c('div',{staticClass:"row card-box"},[_c('b-form-group',{staticClass:"col-sm-6",attrs:{"label":"Data początkowa","description":"Pole nie powinno być puste"}},[_c('date-picker',{attrs:{"state":_vm.state('startDate'),"clearable":""},on:{"input":_vm.validateReportDates},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('error-message',{attrs:{"errors":_vm.errors,"field":"startDate"}})],1),_c('b-form-group',{staticClass:"col-sm-6",attrs:{"description":"Pole nie powinno być puste","label":"Data końcowa"}},[_c('date-picker',{attrs:{"state":_vm.state('endDate'),"clearable":""},on:{"input":_vm.validateReportDates},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),_c('error-message',{attrs:{"errors":_vm.errors,"field":"endDate"}})],1),_c('div',{staticClass:"col text-right"},[_c('back-button',{attrs:{"to":{name: 'reportsMainView'}}}),_c('button',{staticClass:"btn btn-primary m-1",attrs:{"disabled":_vm.isSaving || !!_vm.errors.length || !_vm.startDate || !_vm.endDate},on:{"click":_vm.createReport}},[_c('i',{staticClass:"fas",class:_vm.isSaving ? 'fa-spin fa-spinner' : 'fa-plus'}),_vm._v(" Generuj ")])],1)],1),_c('card',{attrs:{"loading":_vm.isLoading,"title":"Wygenerowane raporty"}},[_c('b-table',{attrs:{"items":_vm.items || [],"fields":_vm.fields,"empty-text":"Brak wygenerowanych raportów","responsive":true,"show-empty":""},scopedSlots:_vm._u([{key:"cell(status)",fn:function(ref){
var status = ref.item.status;
return [_c('b-badge',{staticClass:"status-badge",attrs:{"variant":_vm.getBadgeVariant(status)}},[_c('i18n',{attrs:{"component":"fragment","msgid":("@request-reports-record-status-" + status)}})],1)]}},{key:"cell(conditions)",fn:function(ref){
var ref_item = ref.item;
var startDate = ref_item.startDate;
var endDate = ref_item.endDate;
return [(startDate)?[_c('p',[_c('b',[_vm._v("Data początkowa: ")]),_vm._v(_vm._s(startDate))])]:_vm._e(),(endDate)?[_c('p',[_c('b',[_vm._v("Data końcowa: ")]),_vm._v(_vm._s(endDate))])]:_vm._e()]}},{key:"cell(download)",fn:function(ref){
var ref_item = ref.item;
var status = ref_item.status;
var reportId = ref_item.reportId;
return [(status==='completed')?_c('a',{staticClass:"btn btn-primary btn-sm",attrs:{"href":("/api/files/" + reportId)}},[_c('i',{staticClass:"fas fa-file-excel"})]):_c('i',{staticClass:"fas",class:_vm.downloadStatusIconClass(status)})]}}])}),_c('pagination-description',{attrs:{"items-count":_vm.items ? _vm.items.length : 0,"page":_vm.currentPage,"per-page":_vm.pagination.perPage,"total-count":_vm.pagination.totalRows}},[_c('b-pagination',{staticClass:"mb-0",attrs:{"value":_vm.currentPage,"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalRows,"align":"right"},on:{"input":_vm.changePage}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }