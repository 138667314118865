export default [
  {
    value: "insurance",
    name: "objęty ubezpieczeniem zdrowotnym",
  },
  {
    value: "decision",
    name: "z tytułu uzyskania decyzji wojta/burm./prezyd.",
  },
  {
    value: "pregnant",
    name: "obywatel Polski w okresie ciąży, porodu lub połogu",
  },
  {
    value: "ukraine_war",
    name: "dostęp do ubezpieczeń zdrowotnych zgodnie z ustawą z dnia 12 marca 2022 r. " +
      "o pomocy obywatelom Ukrainy w związku z konfliktem zbrojnym na terytorium tego państwa",
  },
  {
    value: "exile",
    name: "osoba która uzyskała w RP status uchodźcy lub ochronę uzupełniającą lub zezwolenia na pobyt czasowy",
  },
  {
    value: "expired_obligation",
    name: "prawo do świadczeń po wygaśnięciu obowiązku ubezpieczenia",
  },
];
