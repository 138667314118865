<template>
  <object-select
    :value="selectedValue"
    :options="options"
    :placeholder="placeholder"
    :state="state"
    :disabled="disabled"
    :multiple="multiple"
    @input="update"
    @open="loadWorkerValues"
  />
</template>
<script>
import ObjectSelect from "./ObjectSelect";
import t from "../../../i18n";
import read from "../../../rest/read";

export default {
  name: "NfzWorkerTypeSelect",
  components: {
    ObjectSelect,
  },
  props: {
    value: {type: Array, default: () => []},
    state: ObjectSelect.props.state, // eslint-disable-line
    placeholder: {type: String, default: "Wybierz typ personelu"},
    disabled: {type: Boolean, default: false},
    workerId: {type: String, default: null},
    multiple: {type: Boolean, default: false}
  },
  data() {
    return {
      optionValues: [
        {value: "specialist_in_psychiatry"},
        {value: "incomplete_specialist_in_psychiatry"},
        {value: "psychologist"},
        {value: "nurse"},
        {value: "environmental_therapist"},
        {value: "occupational_therapist"},
        {value: "psychotherapist"},
        {value: "recovery_assistant"},
        {value: "career_counselor"},
        {value: "addiction_therapist"},
        {value: "social_worker"},
        {value: "dietician"}
      ],
    };
  },
  computed: {
    options() {
      return this.optionValues.map(option => ({
        ...option,
        name: t(`@nfz-worker-type.${option.value}`),
      }));
    },
    selectedValue() {
      return this.options.filter((option) => (this.value.includes(option.value)));
    },
  },
  watch:{
    async workerId() {
      await this.loadWorkerValues();
    }
  },
  async mounted() {
    await this.loadWorkerValues();
  },
  methods: {
    update(option) {
      if(!this.multiple){
        if(null === option){
          this.$emit("input", []);
        } else {
          this.$emit("input", [option.value]);
        }
      } else {
        this.$emit("input", option.map((opt)=> (opt.value)));
      }
    },
    async loadWorkerValues() {
      if(this.workerId){
        const {types} = await read(`/api/workers/${this.workerId}/nfz-types`);
        this.optionValues = types.map(item => ({value: item}));
      }
    }
  },
}
</script>
