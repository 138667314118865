<template>
  <b-card no-body>
    <b-card-header class="d-flex flex-nowrap">
      <date-time
        :value="parseDate(item.editDate)"
        :format="`DD.MM.YYYY HH:mm:ss`"
        class="appointment-date"
        component="div"
      />
      <div class="appointment-worker">
        <div class="d-flex flex-nowrap mr-2">
          <div>{{ item.author }}</div>
        </div>
        <div
          v-if="idx === 0"
        >
          (Aktualny dokument)
        </div>
      </div>
      <div class="appointment-visibility-status text-right">
        <button
          class="btn btn-sm btn-secondary m-1"
          @click="toggleVisibility"
        >
          {{ documentVisible ? 'Zamknij dokument' : 'Pokaż dokument' }}
        </button>
      </div>
    </b-card-header>
    <b-collapse
      :id="`details-${item.documentId}`"
      :visible="documentVisible"
    >
      <b-card-body>
        <b-form-group
          v-if="null !== item.editFinishedDocumentReason"
          class="col-12"
          label="Powód edycji dokumentu"
        >
          <b-form-textarea
            :value="item.editFinishedDocumentReason"
            :rows="3"
            disabled
            placeholder="Powód edycji"
          />
        </b-form-group>
        <appointment-nfz-configuration-box
          :treatment-type-id="item.treatmentType"
          :worker-id="null"
          :icd9="item.icd9"
          :nfz-worker-types="item.nfzWorkerTypes"
          :read-only="true"
          :patient="item.patient"
          :alternative-treatment-type="item.alternativeTreatmentType"
          :branch-id="item.branchId"
          :appointment-type="item.appointmentType"
          :admission-mode="item.admissionMode"
          :treatment-follow-up="item.treatmentFollowUp"
          :beneficiary-category="item.beneficiaryCategory"
        />
        <appointment-document-modules
          :modules="item.modules"
          :selected-document-idx="idx"
          :patient="item.patient"
          :read-only="true"
          :appointment-document-id="item.documentId"
          :continuous-stay-id="item.continuousStayId"
          :appointment-date="new Date()"
          :appointment-id="item.appointmentId"
          :canceled="false"
          :appointment-end-date="new Date()"
          :visit-at-home="item.visitAtHome"
          :treatment-type-id="item.treatmentType"
        />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import parseDate from "../../../utils/date/parseDate";
import DateTime from "../../DateTime";
import AppointmentDocumentModules from "../DocumentModules/AppointmentDocumentModules";
import AppointmentNfzConfigurationBox from "../../../views/Appointment/AppointmentNfzConfigurationBox";

export default {
  name: "AppointmentDocumentHistoricalPreview",
  components: {
    DateTime,
    AppointmentDocumentModules,
    AppointmentNfzConfigurationBox
  },
  props:{
    item: {type: Object, required: true},
    idx: {type: Number, required: true},
    currentDocumentId: {type: String, required: true}
  },
  data() {
    return {
      parseDate,
      documentVisible: false
    };
  },
  methods: {
    toggleVisibility() {
      this.documentVisible = !this.documentVisible
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../styles/variables";

.appointment {
  &-date {
    flex: 0 0 100px;
  }

  &-worker {
    flex: 0 1 100%;
  }

  &-visibility-status {
    flex: 0 0 145px;
  }
}
</style>
