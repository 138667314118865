<template>
  <card
    :loading="isLoading"
    title="Upoważnienia do danych medycznych"
  >
    <template #control>
      <a
        :href="`/api/patient/${patientId}/medical-data-authorizations/print`"
        class="btn btn-secondary btn-sm m-b-10"
        target="_blank"
      >
        <i class="fa fa-print" />
        Drukuj formularz
      </a>
      <b-btn
        v-b-modal.createMedicalDataAuthorization
        class="m-b-10 ml-2"
        size="sm"
        variant="primary"
      >
        <i class="fa fa-plus" /> Dodaj upoważnienie
      </b-btn>
    </template>
    <patient-card-create-medical-data-authorization
      :patient-id="patientId"
      @medicalDataAuthorizationCreated="getMedicalDataAuthorizations"
    />
    <b-table
      :items="medicalDataAuthorizations"
      :fields="fields"
      empty-text="Nie wystawiono upoważnień"
      :responsive="true"
      show-empty
    >
      <template #cell(name)="{item}">
        {{ item.authorizedPersonName }} {{ item.authorizedPersonSurname }}
      </template>
      <template #cell(email)="{item}">
        {{ item.email }}
      </template>
      <template #cell(phoneNumber)="{item}">
        {{ item.phoneNumber }}
      </template>
      <template #cell(status)="{item}">
        <b-alert
          :show="true"
          :variant="item.isAuthorizationValid ? 'success' : 'danger'"
        >
          {{ authorizationStatusLabel(item.endOfAuthorization, item.cancellationDate) }}
        </b-alert>
      </template>
      <template #cell(options)="{item}">
        <template v-if="item.isAuthorizationValid">
          <b-btn
            v-b-modal="`confirmation${item.medicalDataAuthorizationId}`"
            :disabled="isLoading"
            size="sm"
            variant="outline-secondary"
          >
            <span
              :class="isLoading ? 'fa-spin fa-spinner' : 'fa-ban'"
              class="fas mr-1"
            />
            Cofnij upoważnienie
          </b-btn>
          <b-modal
            :id="`confirmation${item.medicalDataAuthorizationId}`"
            ok-title="Tak, cofam upoważnienie dla tej osoby"
            ok-variant="danger"
            cancel-title="Anuluj"
            class="text-center"
            @ok="removeAuthorization(item.medicalDataAuthorizationId)"
          >
            Potwierdź cofnięcie upoważnienia dostępu do danych medycznych dla osoby:
            <b class="d-block mt-3">
              {{ item.authorizedPersonName }}&nbsp;
              {{ item.authorizedPersonSurname }}
            </b>
          </b-modal>
        </template>
      </template>
    </b-table>
    <pagination-description
      :items-count="medicalDataAuthorizations.length"
      :page="page"
      :per-page="pagination.perPage"
      :total-count="pagination.totalRows"
    >
      <b-pagination
        :value="page"
        :total-rows="pagination.totalRows"
        :per-page="pagination.perPage"
        align="right"
        class="mb-0"
        @input="changePage"
      />
    </pagination-description>
  </card>
</template>
<script>
import PatientCardCreateMedicalDataAuthorization from "./PatientCardCreateMedicalDataAuthorization";
import modify from "../../../rest/modify";
import read from "../../../rest/read";
import Card from "../../Card";
import PaginationDescription from "../../Pagination/PaginationDescription";

export default {
  name: "PatientCardMedicalDataAuthorizations",
  components: {
    PaginationDescription,
    Card,
    PatientCardCreateMedicalDataAuthorization
  },
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      medicalDataAuthorizations: [],
      isLoading: false,
      page: 1,
      pagination: {perPage: 5, totalRows: 0},
    };
  },
  computed: {
    fields() {
      return [
        {key: "name", label: "Imię i nazwisko", class: "w-40"},
        {key: "email", label: "E-mail", class: "w-10"},
        {key: "phoneNumber", label: "Numer telefonu", class: "w-10"},
        {key: "status", label: "Status", class: "w-20"},
        {key: "options", label: "Opcje", tdClass: "text-right"},
      ]
    },
  },
  watch: {
    patientId() {
      this.page = 1;
      this.getMedicalDataAuthorizations();
    },
  },
  async mounted() {
    await this.getMedicalDataAuthorizations();
  },
  methods: {
    authorizationStatusLabel(endOfAuthorization, cancellationDate) {
      if(cancellationDate !== null) {
        return `Nieważny od: ${  cancellationDate}`;
      }

      if(endOfAuthorization === null) {
        return "Ważny bezterminowo";
      }

      if(new Date(endOfAuthorization) > new Date()) {
        return `Ważny do: ${  endOfAuthorization}`;
      }

      return `Nieważny od: ${  endOfAuthorization}`;
    },
    async removeAuthorization(authorizationId) {
      this.isLoading = true;
      await modify(`/api/patient/${this.patientId}/medical-data-authorizations/${authorizationId}`, {});
      await this.getMedicalDataAuthorizations();
      this.isLoading = false;
    },
    async getMedicalDataAuthorizations() {
      this.isLoading = true;
      const response = await read(`/api/patient/${this.patientId}/medical-data-authorizations`, {
        perPage: this.pagination.perPage,
        page: this.page,
      });
      this.medicalDataAuthorizations = response.items.sort((a) => {
        return a.isAuthorizationValid === true ? -1 : 1;
      });
      this.pagination = response.pagination;
      this.isLoading = false;
    },
    changePage(page) {
      this.page = page;
      this.getMedicalDataAuthorizations();
    },
  },
}
</script>
