export const admissionModes: Array<{ value: number, name: string }> = [
  {
    value: 5,
    name: "bez wymaganego skierowania"
  },
  {
    value: 4,
    name: "ze skierowaniem"
  },
  {
    value: 2,
    name: "w trybie nagłym"
  },
  {
    value: 7,
    name: "przymusowe"
  },
  {
    value: 9,
    name: "bez skierowania poza kolejnością"
  },
  {
    value: 8,
    name: "ze skierowaniem poza kolejnością"
  },
  {
    value: 13,
    name: "w celu wykonania świadczenia zgodnie z planem leczenia w ściśle określonych terminach"
  },
]
