










import Vue from "vue";
import {Prop} from "vue-property-decorator";
import read from "../../../rest/read";
import Component from "vue-class-component";
import {Location} from "vue-router";
import IsGranted from "../../IsGranted.vue";
import {mapState} from "vuex";

interface IpzItem {
  status: string;
  closedAt: string|null;
  coordinatorFullName: string;
  createdAt: string;
  ipzId: string;
  reason: string;
}

@Component({
  name: "PattientCardIpzLink",
  components: {IsGranted},
  computed: {
    ...mapState({
      nfzConfiguration: (state: any): string => state.clinicParameters.parameters.nfzConfiguration
    }),
  },
})
export default class PattientCardIpzLink extends Vue {
  @Prop({type: String, required: true}) readonly patientId!: string

  private ipzList: Array<IpzItem> = [];

  private get activeIpz(): IpzItem|null {
    return this.ipzList.find(item => item.status === "opened") || null
  }

  async mounted(): Promise<void> {
    await this.fetchData();
  }

  private get patientIpzRoute(): Location|null {
    if (!this.activeIpz) {
      return null;
    }
    return {
      name: this.nfzConfiguration ? "ipz_details_simplified_ipz" : "ipz_details",
      params: {ipzId: this.activeIpz.ipzId},
    };
  }

  private async fetchData(): Promise<void> {
    const {items} = await read(`/api/patients/${this.patientId}/ipzs`);
    this.ipzList = items || [];
  }
}
