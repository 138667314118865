<template>
  <b-modal
    :id="`historyContinuousStayDailyDescriptionModal-${continuousStayDailyDescriptionId}`"
    title="Historia opisu dziennego"
    hide-footer
    no-close-on-backdrop
    title-tag="h3"
    size="lg"
  >
    <div
      v-for="(descriptionHistory, index) in historyList"
      :key="index"
      class="m-b-15"
    >
      <b-card
        no-body
        class="p-b-10"
      >
        <b-card-header
          header-tag="header"
          class="p-0"
        >
          <button
            v-b-toggle="`history-${index}`"
            class="btn btn-link btn-block text-left text-wrap"
          >
            <h4>{{ descriptionHistory.createDate }} - {{ descriptionHistory.creator }}</h4>
          </button>
        </b-card-header>
        <b-collapse
          :id="`history-${index}`"
        >
          <b-card-body>
            <continuous-stay-specialists-description-form
              :description="descriptionHistory"
              :display-creator="false"
              :icd9-initial-data="icd9InitialData"
              readonly
            />
            <b-form-group
              v-if="null !== descriptionHistory.updateReason"
              id="updateReason"
              label="Powód edycji"
              label-for="updateReason"
            >
              <textarea-counter
                v-model="descriptionHistory.updateReason"
                :max-characters-count="1000"
                disabled
                rows="3"
              />
            </b-form-group>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Zamknij
      </button>
    </div>
  </b-modal>
</template>

<script>
import ContinuousStaySpecialistsDescriptionForm from "./ContinuousStaySpecialistsDescriptionForm";
import TextareaCounter from "../../../Form/Textarea/TextareaCounter";

export default {
  name: "ContinuousStayDailySpecialistsDescriptionHistoryModal",
  components: {
    ContinuousStaySpecialistsDescriptionForm,
    TextareaCounter
  },
  props: {
    continuousStayDailyDescriptionId: {type: String, required: true},
    historyList: {type: Array, default: () => []},
    icd9InitialData: {type: Array, default: () => []}, // CodeItem[]
  },
  methods: {
    closeModal(){
      this.$bvModal.hide(`historyContinuousStayDailyDescriptionModal-${this.continuousStayDailyDescriptionId}`);
    },
  }
}
</script>

<style scoped>

</style>
