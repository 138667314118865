<template>
  <object-select
    :value="selectedOption"
    :options="options"
    :disabled="disabled"
    :placeholder="placeholder"
    :clearable="false"
    :state="state"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "../Form/Select/ObjectSelect";
export default {
  components: {ObjectSelect},
  props: {
    value: {type: Number, default: null},
    disabled: ObjectSelect.props.disabled, // eslint-disable-line
    placeholder: {type: String, default: "Wybierz czas trwania wizyty"},
    defaultDuration: {type: Number, default: null},
    state: {type: Boolean, default: null},
  },
  data() {
    return {
      loading: true,
      timeRanges: [
        {value: 15, name: "15 minut"},
        {value: 30, name: "30 minut"},
        {value: 45, name: "45 minut"},
        {value: 60, name: "1 godzina"},
        {value: 75, name: "1 godzina 15 minut"},
        {value: 90, name: "1 godzina 30 minut"},
        {value: 105, name: "1 godzina 45 minut"},
        {value: 120, name: "2 godziny"},
        {value: 135, name: "2 godziny 15 minut"},
        {value: 150, name: "2 godziny 30 minut"},
        {value: 165, name: "2 godziny 45 minut"},
        {value: 180, name: "3 godziny"},
        {value: 195, name: "3 godziny 15 minut"},
        {value: 210, name: "3 godziny 30 minut"},
        {value: 225, name: "3 godziny 45 minut"},
        {value: 240, name: "4 godziny"},
        {value: 255, name: "4 godziny 15 minut"},
        {value: 270, name: "4 godziny 30 minut"},
        {value: 285, name: "4 godziny 45 minut"},
        {value: 300, name: "5 godzin"},
        {value: 315, name: "5 godzin 15 minut"},
        {value: 330, name: "5 godzin 30 minut"},
        {value: 345, name: "5 godzin 45 minut"},
        {value: 360, name: "6 godzin"},
        {value: 375, name: "6 godzin 15 minut"},
        {value: 390, name: "6 godzin 30 minut"},
        {value: 405, name: "6 godzin 45 minut"},
        {value: 420, name: "7 godzin"},
        {value: 435, name: "7 godzin 15 minut"},
        {value: 450, name: "7 godzin 30 minut"},
        {value: 465, name: "7 godzin 45 minut"},
        {value: 480, name: "8 godzin"},
      ],
    }
  },
  computed: {
    selectedOption() {
      if ("number" === typeof this.value) {
        return this.options.find(option => option.value === this.value);
      }
      return this.value;
    },
    options() {
      return this.timeRanges.map(option => {
        option.name = option.name.replace(/ \(domyślnie\)/, "");
        if (option.value === this.defaultDuration) {
          option.name = `${option.name} (domyślnie)`;
        }
        return option;
      });
    },
  },
  methods: {
    update(option) {
      this.$emit("input", option ? option.value : null);
    },
  },
}
</script>
