





















































































































import {Component, Mixins, Prop} from "vue-property-decorator";
import {InsuranceHistory, InsuranceType} from "../../../types/Insurance";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import stringifyDate from "../../../utils/date/stringifyDate";
import I18n from "../../i18n.vue";
import Card from "../../Card.vue";
import ErrorMessage from "../../Form/ErrorMessage.vue";
import Tooltip from "../../Tooltip.vue";
import IsGranted from "../../IsGranted.vue";
import PatientInsuranceMixin from "../../../mixins/PatientInsuranceMixin";
import PatientStatementModal from "./PatientStatementModal.vue";
import MedicalCertificateModal from "./MedicalCertificateModal.vue";
import InsuranceStatusIcon from "./InsuranceStatusIcon.vue";
import PatientInsuranceHistoryModal from "./PatientInsuranceHistoryModal.vue";
import RemovePatientInsuranceModal from "./RemovePatientInsuranceModal.vue";
import isToday from "../../../utils/date/isToday";

@Component({
  name: "PatientInsurance",
  components: {
    InsuranceStatusIcon,
    RemovePatientInsuranceModal,
    Tooltip,
    ErrorMessage,
    I18n,
    Card,
    IsGranted,
    PatientInsuranceHistoryModal,
    PatientStatementModal,
    MedicalCertificateModal,
  }
})

export default class PatientInsurance extends Mixins(PatientInsuranceMixin) {
  @Prop({type: String, default: "Status ubezpieczenia"}) cardTitle!: string;

  currentItem: InsuranceHistory | null = null;

  formatDateTime(date: string): string {
    return stringifyDate(new Date(date), DATE_FORMAT.DATE_TIME);
  }

  edit(item: InsuranceHistory) {
    this.currentItem = item;
    this.$bvModal.hide("insuranceHistoryModal");

    switch (item.insuranceType) {
      case InsuranceType.EWUS:
        this.currentItem = null;
        throw new Error("Cannot edit ewus");
      case InsuranceType.MEDICAL_CERTIFICATE:
        this.$bvModal.show("medicalCertificateModal");
        break;
      case InsuranceType.PATIENT_STATEMENT:
        this.$bvModal.show("patientStatementModal");
        break;
    }
  }

  remove(item: InsuranceHistory) {
    if (item.insuranceType === InsuranceType.EWUS) {
      throw new Error("Cannot delete ewus");
    } else {
      this.currentItem = item;
      this.$bvModal.hide("insuranceHistoryModal");
      this.$bvModal.show("remove-patient-insurance-modal");
    }
  }

  async showList(fresh: boolean){
    if (fresh) {
      await this.loadData();
    }

    this.$emit("insuranceChanged");

    if (null !== this.currentItem) {
      this.currentItem = null;
      this.$bvModal.show("insuranceHistoryModal");
    }
  }

  get additionalInformations() {
    return this.insurance.insuranceHistory[0]?.additionalInformation;
  }

  get isAppointmentDateCurrentDate() {
    return isToday(this.date)
  }
}
