<template>
  <div>
    <b-table
      :items="items"
      :fields="fields"
      empty-text="Brak wizyt spełniających kryteria"
      :responsive="true"
      show-empty
    >
      <template #cell(dateTime)="data">
        <date-time
          :value="data.item.date"
          :format="dateTimeFormat"
        />
      </template>
      <template #cell(specialistTypeStatus)="data">
        <div class="d-flex flex-column">
          <div
            v-for="worker in appointmentWorkers(data.item)"
            :key="worker.workerId"
            :class="{'bg-muted': worker.hasSelectedDocument}"
            class="d-flex flex-nowrap align-items-center mb-2 p-2"
          >
            <div class="appointment-worker d-flex flex-nowrap">
              <i
                :class="{'text-muted': worker.workerId !== data.item.mainWorkerId}"
                class="fa fa-user-tie mt-1 mr-2"
              />
              <div class="mr-2">
                {{ worker.name }}
              </div>
              |
            </div>
            <div class="appointment-treatment mx-2">
              <treatment-type-name :value="worker.reportedTreatmentType" />
            </div>
            <div class="appointment-assign text-right ml-auto">
              <tooltip
                v-if="!worker.hasSelectedDocument"
                class="btn btn-primary"
                component="button"
                :disabled="loading"
                tooltip-content="Wybierz dokument"
                type="button"
                @click="submit(
                  data.item.appointmentId,
                  getAppointmentDocumentId(data.item.documents, worker.workerId)
                )"
              >
                <span
                  :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
                  class="fas"
                />
              </tooltip>
            </div>
          </div>
          <div class="ml-auto">
            <appointment-status
              :name="data.item.status"
              badge
              class="mr-1"
            />
            <b-badge
              v-if="data.item.mobile"
              class="mr-1"
              variant="light"
            >
              <i class="fa fa-car" /> Wyjazd
            </b-badge>
            <b-badge
              v-else
              class="mr-1"
              variant="light"
            >
              <i class="fa fa-hospital" /> Lokalna
            </b-badge>
            <b-badge
              v-if="!data.item.mobile && data.item.telephone"
              class="mr-1"
              variant="light"
            >
              <i class="fa fa-phone" /> Telefoniczna
            </b-badge>
            <b-badge
              v-if="data.item.group"
              class="mr-1"
              variant="light"
            >
              <i class="fa fa-users" /> Grupowa
            </b-badge>
            <patient-options-badge
              v-if="patientId"
              :patients-length="data.item.patients.length"
              :patient-presence="data.item.patientPresence"
            />
          </div>
        </div>
      </template>
    </b-table>
    <pagination-description
      :items-count="items.length"
      :page="pagination.currentPage"
      :per-page="pagination.perPage"
      :total-count="pagination.totalRows"
    >
      <b-pagination
        :value="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-rows="pagination.totalRows"
        align="right"
        class="mb-0"
        @input="update"
      />
    </pagination-description>
  </div>
</template>

<script>
import PaginationDescription from "../../../Pagination/PaginationDescription";
import DateTime from "../../../DateTime";
import DATE_FORMAT from "../../../../utils/date/DATE_FORMAT";
import TreatmentTypeName from "../../../TreatmentType/TreatmentTypeName";
import Tooltip from "../../../Tooltip";
import AppointmentStatus from "../../../Appointment/AppointmentStatus";
import PatientOptionsBadge from "../../../PatientPresenceOptions/PatientOptionsBadge";
import update from "../../../../rest/update";
import processResponseException from "../../../../utils/errors/processResponseException";
import {mapState} from "vuex";

export default {
  name: "AttachmentAppointmentsTable",
  components: {PatientOptionsBadge, AppointmentStatus, Tooltip, TreatmentTypeName, DateTime, PaginationDescription},
  model: {
    prop: "pagination",
  },
  props: {
    pagination: {type: Object, required: true},
    patientId: {type: String, required: true},
    attachmentId: {type: String, default: null},
    appointmentDocumentId: {type: String, default: null},
    isManager: {type: Boolean, default: false},
    items: {type: Array, default: ()=>[]},
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState("currentUser", ["user"]),
    fields() {
      return [
        {key: "dateTime", label: "Data"},
        {key: "specialistTypeStatus", label: "Specjalista / Typ wizyty"},
      ];
    },
    dateTimeFormat() {
      return DATE_FORMAT.DATE_TIME;
    },
  },
  methods: {
    update(pagination) {
      this.$emit("input", pagination);
    },
    async submit(appointmentId, appointmentDocumentId) {
      this.loading = true;
      this.errors = [];

      try {
        await update(`/api/attachments/${this.attachmentId}/appointment`, {
          appointmentId,
          appointmentDocumentId,
        });
        this.$emit("input", {
          attachmentId: this.attachmentId,
          appointment: {appointmentId, appointmentDocumentId},
        });
      } catch(e) {
        this.errors = processResponseException(e)
      }
      this.loading = false;
    },
    getAppointmentDocumentId(documents, workerId) {
      const appointmentDocument = documents
        && documents.find(document => document.patientId === this.patientId && document.workerId === workerId);
      return appointmentDocument ? appointmentDocument.documentId : null
    },
    appointmentWorkers(appointment) {
      return this.isManager
        ? [...appointment.workers].sort((workerA, workerB) => {
          if (workerA.workerId === appointment.mainWorkerId) {
            return -1;
          }
          if (workerB.workerId === appointment.mainWorkerId) {
            return 1;
          }
          return 0;
        })
        : [...appointment.workers].filter(worker => worker.workerId === this.user.workerId);
    },
  }
}
</script>
