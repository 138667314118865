









































































































































































































import {Component, Mixins, Prop} from "vue-property-decorator";
import {mapActions} from "vuex";
import ErrorMessage from "../Form/ErrorMessage.vue";
import {TheMask} from "vue-the-mask";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import update from "../../rest/update";
import processResponseException from "../../utils/errors/processResponseException";
import read from "../../rest/read";

interface Request {
  serverSmtp: string;
  portSmtp: null|number;
  serverPop3: string;
  portPop3: null|number;
  incomingEmail: string;
  outgoingEmail: null|string;
  username: null|string;
  password: null|string;
  contractNumber: null|string;
}

interface Response extends Request {
  validOutgoingTransport: null|boolean;
}

@Component({
  name: "EstablishmentNfzConfigurationModal",
  components: {
    ErrorMessage,
    TheMask,
  }
})
export default class EstablishmentSmtpModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly branchId!: string;
  @Prop({type: String, required: true}) readonly name!: string;

  loading: boolean = false;
  saved: boolean = true;
  serverSmtp: string = "";
  portSmtp: null | number = null;
  serverPop3: string = "";
  portPop3: null | number = null;
  username: string = "";
  password: string = "";
  outgoingEmail: string = "";
  incomingEmail: string = "";
  contractNumber: string = "";
  validOutgoingTransport: boolean | null = null;

  get modalId(): string {
    return `establishment-nfz-configuration-${this.branchId}`
  }

  get title(): string {
    return `Edycja ustawień NFZ podmiotu ${this.name}`
  }

  closeModal(): void {
    this.$bvModal.hide(this.modalId);
  }

  async submit(): Promise<void> {
    this.errors = [];
    this.loading = true;
    const data: Request = {
      serverSmtp: this.serverSmtp,
      portSmtp: this.portSmtp,
      serverPop3: this.serverPop3,
      portPop3: this.portPop3,
      incomingEmail: this.incomingEmail,
      outgoingEmail: this.outgoingEmail,
      username: this.username,
      password: this.password,
      contractNumber: this.contractNumber
    };
    try {
      await update(`/api/branches/${this.branchId}/nfz-configuration`, data);
      this.$emit("edited");
      this.saved = true;
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  edit() {
    this.validOutgoingTransport = null;
    this.saved = false;
  }

  async fetchData(test: boolean): Promise<void> {
    this.loading = true;
    this.errors = [];
    try {
      const {
        incomingEmail,
        outgoingEmail,
        serverSmtp,
        portSmtp,
        serverPop3,
        portPop3,
        username,
        password,
        contractNumber,
        validOutgoingTransport,
      } = await read<Response>(`/api/branches/${this.branchId}/nfz-configuration`, {test});
      this.serverSmtp = serverSmtp || "";
      this.portSmtp = portSmtp || null;
      this.serverPop3 = serverPop3 || "";
      this.portPop3 = portPop3 || null;
      this.username = username || "";
      this.password = password || "";
      this.outgoingEmail = outgoingEmail || "";
      this.incomingEmail = incomingEmail || "";
      this.contractNumber = contractNumber || "";
      this.validOutgoingTransport = validOutgoingTransport;
      this.saved = true;
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }
}

