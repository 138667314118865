




















































































































import ResizableTextarea from "../../Form/Textarea/ResizableTextarea.vue";
import processResponseException from "../../../utils/errors/processResponseException";
import update from "../../../rest/update";
import ErrorMessage from "../../Form/ErrorMessage.vue";
import TargetStatusSelect from "./TargetStatusSelect.vue";
import Card from "../../Card.vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import ErrorsMixin from "../../../mixins/ErrorsMixin";
import {mixins} from "vue-class-component";
import type {TargetFormData, Task} from "../../../types/TargetsAndTasks";
import IsGranted from "../../IsGranted.vue";
import {isGranted} from "../../../security/isGranted";
import stringifyDate from "../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import DatePicker from "../../Form/DatePicker/DatePicker.vue";

@Component({
  name: "TaskForm",
  components: {Card, TargetStatusSelect, ErrorMessage, ResizableTextarea, IsGranted, DatePicker},
})

export default class TaskForm extends mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly ipzId!: string;
  @Prop({type: String, required: true}) readonly targetId!: string;
  @Prop({type: Object, required: true}) readonly task!: Task;
  @Prop({type: Boolean, default: false}) readonly updating!: boolean;
  @Prop({type: Boolean, required: true}) readonly readonly!: boolean;
  @Prop({type: String, required: true}) readonly createdBy!: string;

  name: TargetFormData["name"] = this.task.name;
  description: TargetFormData["description"] = this.task.description;
  status: TargetFormData["status"] = this.task.status;
  createdAt: TargetFormData["createdAt"] = new Date(this.task.createdAt)
  private disabled: boolean = false;

  get disableActions(): boolean {
    return this.task.name === this.name
      && this.task.description === this.description
      && this.task.status === this.status
      && this.task.createdAt === stringifyDate(this.createdAt, DATE_FORMAT.DATE);
  }

  get title(): string {
    return `Zadanie (${this.createdBy}, ${this.task.createdAt})`;
  }

  @Watch("task", {immediate: true}) onTaskChange(): void {
    this.resetData();
  }

  async mounted(): Promise<void>
  {
    this.disabled = !(await isGranted("UPDATE_THERAPEUTIC_TARGET_TASK", this.task.taskId));
  }

  async submit(): Promise<void> {
    this.resetErrors();
    this.$emit("setUpdating", true);
    this.createdAt.setHours(0,0,0);
    try {
      const data = {
        name: this.name ? this.name.trim() : this.name,
        description: this.description ? this.description.trim() : this.description,
        status: this.status,
        createdAt: stringifyDate(this.createdAt)
      };
      await update(`/api/tasks/${this.task.taskId}`, data);
      this.$emit("updated");
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.$emit("setUpdating", false);
  }

  resetErrors(): void {
    this.errors = [];
  }

  private async remove(id: string): Promise<void>
  {
    this.$emit("remove", id);
  }

  resetData():void {
    this.resetErrors();
    this.name = this.task.name;
    this.description = this.task.description;
    this.status = this.task.status;
    this.createdAt = new Date(this.task.createdAt);
  }
}
