





































































import {Component, Mixins, Prop} from "vue-property-decorator";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import WorkerSelect from "../Worker/WorkerSelect.vue";
import ErrorMessage from "../Form/ErrorMessage.vue";
import create from "../../rest/create";
import processResponseException from "../../utils/errors/processResponseException";

@Component({
  name: EstablishmentTestCommunicationModal.name,
  components: {WorkerSelect, ErrorMessage,}
})
export default class EstablishmentTestCommunicationModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly branchId!: string;
  @Prop({type: String, required: true}) readonly branchName!: string;

  private loading: boolean = false;
  private selectedWorker: { workerId: string } | null = null;
  private checked: boolean = false;
  private p1Errors: string[] = [];

  get modalId(): string {
    return `establishment-configuration-check-${this.branchId}`;
  }

  get title(): string {
    return `Sprawdzanie poprawności konfiguracji systemu p1 dla podmiotu ${this.branchName}`
  }

  closeModal(): void {
    this.loading = false;
    this.errors = [];
    this.p1Errors = [];
    this.selectedWorker = null;
    this.checked = false;
    this.$bvModal.hide(this.modalId);
  }

  async submit(): Promise<void> {
    this.errors = [];
    this.p1Errors = [];
    this.loading = true;
    try {
      await create("/api/check-p1-configuration", {
        workerId: this.selectedWorker?.workerId,
        branchId: this.branchId
      });
      this.checked = true;
    } catch(e) {
      this.errors = processResponseException(e);
      this.p1Errors = [...this.p1Errors, ...e.errors.map((err: any) => err.params)].reduce(r => r[0]);
    }
    this.loading = false;
  }

}
