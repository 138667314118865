export enum RecurrenceType {
  NONE = "none",
  WEEKLY = "weekly",
  TWO_WEEKS = "two_weeks",
  MONTHLY = "monthly",
}

export default function generateDates(startDate: Date, endDate: Date, recurrenceType: RecurrenceType): Date[] {
  let incrementMethod;
  if (RecurrenceType.WEEKLY === recurrenceType) {
    incrementMethod = (date: Date) => {
      date.setDate(date.getDate()+7);

      return date;
    };
  }
  if (RecurrenceType.TWO_WEEKS === recurrenceType) {
    incrementMethod = (date: Date) => {
      date.setDate(date.getDate()+14);

      return date;
    };
  }
  if (RecurrenceType.MONTHLY === recurrenceType) {
    incrementMethod = (date: Date) => {
      const d = date.getDate();

      date.setMonth(date.getMonth() + 1, 1)
      const m = date.getMonth()
      date.setDate(d)
      if (date.getMonth() !== m) {
        date.setDate(0);
      }

      return date
    };
  }
  if (undefined === incrementMethod) {
    throw new Error("Invalid type");
  }

  const arr=[];
  const dt=new Date(startDate);
  for(; dt<=endDate; incrementMethod(dt)){
    arr.push(new Date(dt));
  }

  return arr;
}
