<template>
  <b-modal
    id="appointment-document-history-modal"
    :title="`Poprzednie wersje dokumentu`"
    no-close-on-backdrop
    title-tag="h3"
    size="xl"
    @show="onModalShow"
    @hide="onModalHide"
  >
    <loading-mask :loading="loading">
      <error-message :errors="errors" />
      <div
        v-if="!items.length"
        class="alert text-center"
      >
        Nie znaleziono wcześniejszych wersji dokumentu
      </div>
      <appointment-document-historical-preview
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        :idx="index"
        :current-document-id="documentId"
        class="mb-2"
      />
    </loading-mask>
    <template #modal-footer>
      <b-btn
        class="ml-auto"
        @click="closeModal"
      >
        Zamknij
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
import ErrorMessage from "../../Form/ErrorMessage";
import LoadingMask from "../../Loading/LoadingMask";
import read from "../../../rest/read";
import processResponseException from "../../../utils/errors/processResponseException";
import AppointmentDocumentHistoricalPreview from "./AppointmentDocumentHistoricalPreview";

export default {
  name: "AppointmentDocumentHistoryModal",
  components: {
    AppointmentDocumentHistoricalPreview,
    ErrorMessage,
    LoadingMask,
  },
  props: {
    documentId: {type: String, default: null},
  },
  data() {
    return {
      errors: [],
      items: [],
      loading: false,
      pagination: {
        currentPage: 1,
        perPage: 5,
      },
    };
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("appointment-document-history-modal");
    },
    async getAppointmentsList() {
      this.loading = true;
      try {
        const response = await read(`/api/appointment/document/${this.documentId}/history`);
        this.items = [...response.editedAfterFinishDocuments, response.finishedDocument];
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    async onModalShow() {
      await this.getAppointmentsList();
    },
    onModalHide() {
      this.errors = [];
      this.items = [];
    },
  },
}
</script>

<style scoped>

</style>
