<template>
  <div 
    v-if="!isEmpty" 
    class="border p-3"
  >
    <h3>
      {{ worker.name }} - <span class="font-italic">{{ worker.branchName }}</span>
    </h3>
    <div class="d-flex flex-wrap">
      <template v-if="treatmentDurationSlots.length">
        <button
          v-for="(slot, index) in treatmentDurationSlots"
          :key="index"
          :class="{
            'btn-primary': slot.editable,
            'btn-success': !slot.appointmentId && slot.fullTimeAppointment,
            'btn-warning': !slot.appointmentId && !slot.fullTimeAppointment,
          }"
          class="btn m-2"
          @click.prevent="onClick($event, slot)"
        >
          {{ showTime(slot.start) }} - {{ showTime(slot.end) }}
        </button>

        <vue-simple-context-menu
          ref="contextMenu"
          :element-id="`timetableWorkerContextMenu${worker.workerId}`"
          :options="contextMenuOptions"
          @option-clicked="onContextMenuItemClick"
        />
      </template>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import VueSimpleContextMenu from "vue-simple-context-menu";
import {ModalType} from "@/store/modules/scheduleAppointment/types";

export default {
  name: "TimetableWorker",
  components: {VueSimpleContextMenu},
  props: {
    worker: {type: Object, required:true},
    items:  {type: Array, required: true},
    treatmentDuration: {type: Number, required: true},
    filters: {type: Object, required: true},
    preferredPatient: {type: Object, default: null},
  },
  data() {
    return {
      ModalType,
    }
  },
  computed: {
    ...mapState({
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration
    }),
    isEmpty() {
      return null == this.items.find((item) => item.clickable || item.editable);
    },
    splittedItems() {
      return this.items
        .reduce((list, item) => {
          if (item.end.getTime() - item.start.getTime() > this.treatmentDuration) {
            const slots = [];
            return list.concat(this.splitItemIntoSlots(item, slots, item.start));
          } else {
            return list.concat([item]);
          }
        }, []);
    },
    treatmentDurationSlots() {
      return this.splittedItems
        .reduce((list, item) => {
          if (item.clickable) {
            const fullTimeAppointment = (item.end.getTime() - item.start.getTime()) === this.treatmentDuration;
            return list.concat([{...item, fullTimeAppointment}]);
          }

          return list;
        }, []);
    },
    contextMenuOptions() {
      const dividerOption = {type: "divider"}
      const options = [
        {name: "Umów wizytę", modalType: ModalType.DEFAULT, renderIf: true},
        {name: "Umów usługę - pobyt ciągły", modalType: ModalType.CONTINUOUS_STAY, renderIf: !this.nfzConfiguration},
      ]

      return options.reduce((menuOptions, option) => {
        return option.renderIf
          ? (menuOptions.length ? [...menuOptions, dividerOption, option] : [option])
          : menuOptions
      }, [])
    }
  },
  methods: {
    ...mapActions("scheduleAppointment", ["openModal", "setAppointmentData",]),
    showTime(date) {
      return stringifyDate(date, DATE_FORMAT.TIME)
    },
    appointmentData(slot){
      return {
        appointmentStart: slot.start,
        appointmentEnd: slot.appointmentEnd || slot.end,
        treatmentType: this.filters.treatmentType,
        worker: this.worker,
        appointmentId: slot.appointmentId,
        oldAppointmentId: this.filters.appointmentId,
        copyId: this.filters.copyId,
        preferredPatient: this.preferredPatient,
        branchId: slot.branchId,
        branchName: slot.branchName,
        plan: slot.plan,
      };
    },
    onClick($event, slot) {
      const appointmentData = this.appointmentData(slot);
      if (this.filters.copyId) {
        this.setAppointment(appointmentData, ModalType.DEFAULT);
      } if (this.filters.appointmentId || this.filters.continuousStay != null) {
        this.setAppointment(
          appointmentData,
          this.filters.continuousStay ? ModalType.CONTINUOUS_STAY : ModalType.DEFAULT
        );
      } else {
        setTimeout(() => {
          this.$refs.contextMenu.showMenu($event, {appointmentData});
        }, 0);
      }
    },
    onContextMenuItemClick(itemData) {
      this.setAppointmentData(itemData.item.appointmentData);
      this.openModal(itemData.option.modalType);
    },
    splitItemIntoSlots(item, slots, start) {
      const slot = {...item};
      slot.start = start;

      if (item.end.getTime() - start.getTime() > this.treatmentDuration) {
        slot.end = new Date(start.getTime() + this.treatmentDuration);
        slots.push(slot);

        return this.splitItemIntoSlots(item, slots, slot.end);

      } else {
        slot.end = item.end;
        slots.push(slot);

        return slots;
      }
    },
  },
}
</script>
