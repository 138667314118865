




















import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import type {Location} from "vue-router";

@Component({
  name: "BackButton"
})

export default class BackButton extends Vue {
  @Prop({type: Object, required: false}) readonly to?: Location;
}
