<template>
  <object-select
    :value="selectedValue"
    :options="options"
    :placeholder="placeholder"
    :state="state"
    :disabled="disabled"
    :multiple="true"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "./ObjectSelect";
import {ewusPermissions} from "../../../utils/ewus/ewusPermissions";

export default {
  components: {
    ObjectSelect,
  },
  props: {
    value: {type: Array, default: () => []},
    state: ObjectSelect.props.state, // eslint-disable-line
    placeholder: {type: String, default: "Wybierz typ uprawnienia"},
    disabled: {type: Boolean, default: false},
  },
  data() {
    return {
      optionValues: Object.keys(ewusPermissions).map((value) => ({value}))
    };
  },
  computed: {
    options() {
      return this.optionValues.map(option => ({
        ...option,
        name: ewusPermissions[option.value],
      }));
    },
    selectedValue() {
      return this.options.filter((option) => (this.value.includes(option.value)));
    },
  },
  methods: {
    update(option) {
      this.$emit("input", option.map((opt)=> (opt.value)));
    },
  },
}
</script>

<style scoped>

</style>
