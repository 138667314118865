<template>
  <b-modal
    :visible="visible"
    :title="modalTitle"
    no-close-on-backdrop
    size="xl"
    title-tag="h3"
    @hide="onHide"
  >
    <template v-if="visible">
      <error-message :errors="errors" />
      <loading-mask :loading="loading || fetching">
        <div class="form-group row">
          <label
            for="date"
            class="col-form-label form-control-label col-sm-2"
          >
            Termin wizyty
          </label>
          <div class="col-sm-10">
            <div class="form-row">
              <date-picker
                id="date"
                v-model="appointmentDate"
                class="col-sm-4 m-b-20"
                @input="getEarlierPossibilityStatus(modifiedTreatmentType)"
              />
              <hour-select
                v-model="hourStart"
                class="col-sm-3 m-b-20"
              />
              <hour-select
                v-model="hourEnd"
                class="col-sm-3 m-b-20"
              />
              <div>
                <tooltip
                  v-if="reschedule"
                  :to="{name: 'calendarView', query: {
                    appointmentId: oldAppointmentId,
                    continuousStay: true,
                  }}"
                  component="router-link"
                  tooltip-content="Wybierz z kalendarza"
                  class="btn btn-primary m-b-20"
                >
                  <span class="fas fa-clock text-white" />
                </tooltip>
              </div>
            </div>
            <error-message
              :errors="errors"
              field="startDate"
            />
            <error-message
              :errors="errors"
              field="endDate"
            />
          </div>
        </div>
        <form-row
          :errors="errors"
          label="Pacjenci"
          field="patientIds"
        >
          <patient-continuous-stay-select
            v-model="patientContinuousStays"
            allow-add
            allow-remove
            :errors-prop="errors"
            :end="actualEndDate"
            :start="actualStartDate"
          />
        </form-row>
        <form-row label="Specjalista">
          <div class="treatment-worker-select">
            <div class="worker">
              <worker-select
                v-model="mainWorker"
                :disabled="!reschedule"
                :clearable="false"
                placeholder="Wybierz specjalistę"
                :state="state('mainWorker')"
                @input="updateWorker"
              />
              <error-message
                :errors="errors"
                field="mainWorker"
                root
              />
            </div>
            <div class="treatment-type">
              <treatment-type-select
                ref="treatmentTypeSelect"
                :value="modifiedTreatmentType"
                :worker-id="mainWorker.workerId"
                :state="state('mainWorker.treatmentTypeId')"
                check-if-any-treatment-type-exists
                no-options-text="Specjalista nie wykonuje żadnych usług"
                preselect-undefined
                :scope="['continuous-stay']"
                @select="changeTreatmentType"
              />
              <error-message
                :errors="errors"
                field="mainWorker.treatmentTypeId"
              />
            </div>
          </div>
        </form-row>
        <form-row
          v-if="!appointmentId || appointmentId === oldAppointmentId"
          :errors="errors"
          field="additionalWorkers"
          label="Specjaliści dodatkowi"
          root
        >
          <treatment-worker-select
            v-model="additionalWorkers"
            :errors="errors"
            :exclude-worker-ids="[worker.workerId]"
            :scope="['continuous-stay']"
            :use-default-treatment-name="false"
          />
        </form-row>
        <form-row
          field="branchId"
          :errors="errors"
          label="Komórka organizacyjna"
        >
          <branch-select
            id="branch"
            check-if-any-branch-exists
            :value="selectedBranch && selectedBranch.branchId ? selectedBranch.branchId : null"
            :state="state('branchId')"
            @select="selectedBranch = $event"
          />
        </form-row>
        <b-form-group
          label="Plan wizyty"
          label-cols-sm="2"
        >
          <collapse-wrapper :show="!!plan">
            <textarea-counter
              v-model="plan"
              :max-characters-count="500"
              :state="state('plan')"
              rows="3"
            />
            <error-message
              :errors="errors"
              field="plan"
            />
          </collapse-wrapper>
        </b-form-group>
        <b-form-group
          label="Uwagi rejestracji"
          label-cols-sm="2"
        >
          <collapse-wrapper :show="!!registrationNotes">
            <textarea-counter
              v-model="registrationNotes"
              :max-characters-count="500"
              :state="state('registrationNotes')"
              rows="3"
            />
            <error-message
              :errors="errors"
              field="registrationNotes"
            />
          </collapse-wrapper>
        </b-form-group>
        <b-form-group
          v-if="overtimePossible"
          label-cols-sm="2"
        >
          <div class="alert alert-purple mb-0">
            <p>Niektórzy specjaliści mają już w tym czasie zaplanowane wizyty.</p>
            <b-checkbox v-model="overtime">
              Zaznacz jeżeli chcesz umówić wizytę dodatkową.
            </b-checkbox>
          </div>
        </b-form-group>
        <form-row
          v-if="showEarlierPossibility"
          :errors="errors"
          field="earlierPossibility"
          label=""
        >
          <div
            :class="{'border-danger': state('earlierPossibility') === false}"
            class="alert alert-purple mb-0"
          >
            <earlier-possibility-reason-radio
              v-model="earlierPossibilityReason"
            />
          </div>
        </form-row>
        <form-row
          v-if="forbidden"
          label=""
        >
          <div class="alert alert-danger mb-0">
            <p>Nie można umówić wizyty dla innego specjalisty</p>
          </div>
        </form-row>
      </loading-mask>
    </template>
    <template #modal-footer>
      <button
        :disabled="loading"
        class="btn btn-secondary"
        @click="onHide"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary"
        @click="save"
      >
        <i
          :class="loading ? 'fa-spinner fa-spin' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import {errorsMixin} from "../../mixins/errorsMixin.js";
import {mapActions, mapMutations} from "vuex";
import ErrorMessage from "@/components/Form/ErrorMessage";
import LoadingMask from "@/components/Loading/LoadingMask";
import HourSelect from "@/components/Form/Select/HourSelect";
import Tooltip from "@/components/Tooltip";
import DatePicker from "@/components/Form/DatePicker/DatePicker";
import stringifyDate from "@/utils/date/stringifyDate";
import DATE_FORMAT from "@/utils/date/DATE_FORMAT";
import FormRow from "@/components/Form/FormRow";
import WorkerSelect from "@/components/Worker/WorkerSelect";
import BranchSelect from "@/components/Branch/BranchSelect";
import CollapseWrapper from "@/components/Collapse/CollapseWrapper";
import TextareaCounter from "@/components/Form/Textarea/TextareaCounter";
import EarlierPossibilityReasonRadio from "@/components/Form/Radio/EarlierPossibilityReasonRadio";
import read from "@/rest/read";
import processResponseException from "@/utils/errors/processResponseException";
import create from "@/rest/create";
import update from "@/rest/update";
import {generateUuid} from "@/utils/uuid/generateUuid";
import {ForbiddenException} from "@/rest";
import TreatmentTypeSelect from "@/components/TreatmentType/TreatmentTypeSelect";
import TreatmentWorkerSelect from "@/components/Worker/TreatmentWorkerSelect";
import PatientContinuousStaySelect from "@/components/Patient/PatientCard/ContinuousStay/PatientContinuousStaySelect";

const earlierPossibilityReasonInitialData = {
  value: null,
  description: null,
};

export default {
  name: "ScheduleContinuousStayTreatment",
  components: {
    PatientContinuousStaySelect,
    TreatmentWorkerSelect,
    TreatmentTypeSelect,
    EarlierPossibilityReasonRadio,
    TextareaCounter,
    CollapseWrapper,
    BranchSelect,
    WorkerSelect,
    FormRow,
    Tooltip,
    HourSelect,
    DatePicker,
    LoadingMask,
    ErrorMessage,
  },
  mixins: [errorsMixin],
  props: {
    visible: {type: Boolean, default: true},
    oldAppointmentId: {type: String, default: null},
    copyId: {type: String, default: null},
    appointmentId: {type: String, default: null},
    worker: {type: Object, required: true},
    startDate: {type: Date, required: true},
    endDate: {type: Date, required: true},
    branchId: {type: String, default: null},
    branchName: {type: String, default: null},
    treatmentType: {type: Object, default: null},
    preferredPatient: {type: Object, default: null},
    reschedule: {type: Boolean, default: false},
    oldPatientContinuousStays: {type: Array, default: ()=>[]},
  },
  data() {
    const hourStart = {
      HH: stringifyDate(this.startDate, DATE_FORMAT.HOUR),
      mm: stringifyDate(this.startDate, DATE_FORMAT.MINUTE)
    }

    const hourEnd = {
      HH: stringifyDate(this.endDate, DATE_FORMAT.HOUR),
      mm: stringifyDate(this.endDate, DATE_FORMAT.MINUTE)
    }

    const modifiedTreatmentType = this.treatmentType?.value || null;

    return {
      loading: false,
      fetching: false,
      appointmentDate: this.startDate,
      hourStart,
      hourEnd,
      patientContinuousStays: this.oldPatientContinuousStays,
      mainWorker: this.worker.workerId,
      modifiedTreatmentType,
      additionalWorkers: [],
      selectedBranch: {branchId: this.branchId, name: this.branchName} || null,
      plan: "",
      registrationNotes: "",
      overtime: false,
      earlierPossibilityReason: {...earlierPossibilityReasonInitialData},
      showEarlierPossibility: false,
      forbidden: false,
    }
  },
  computed: {
    modalTitle() {
      if (null != this.oldAppointmentId) {
        return "Edycja usługi w ramach pobytu ciągłego";
      }
      if (null != this.copyId) {
        return "Kopia usługi w ramach pobytu ciągłego";
      }
      return "Umów usługę w ramach pobytu ciągłego";
    },
    actualStartDate(){
      let actualStartDate = new Date(this.appointmentDate.getTime());
      actualStartDate.setHours(this.hourStart.HH, this.hourStart.mm)
      return actualStartDate;
    },
    actualEndDate(){
      let actualEndDate = new Date(this.appointmentDate.getTime());
      actualEndDate.setHours(this.hourEnd.HH, this.hourEnd.mm)
      if(this.hourEnd.HH === "00" && this.hourEnd.mm === "00"){
        actualEndDate.setDate(actualEndDate.getDate()+1)
      }
      return actualEndDate;
    },
    overtimePossible() {
      if (this.overtime) {
        return true;
      }

      if (this.errors.length === 0) {
        return false;
      }

      return this.errors.some((error) => {
        if (error.field) {
          return error.field === "mainWorker" ||
            error.field.match(/^appointments\[\d+]\.mainWorker$/) ||
            error.field.match(/^additionalWorkers\[\d+]\.worker$/) ||
            error.field.match(/^appointments\[\d+]\.additionalWorkers\[\d+]\.worker$/);
        }
      });
    },
  },
  watch: {
    modifiedTreatmentType: {
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          await this.getEarlierPossibilityStatus(newVal);
        }
      },
    },
    preferredPatient: {
      immediate: true,
      handler(newVal) {
        if (!this.oldAppointmentId
          && newVal
          && !this.patientContinuousStays.find(item => item.patientId === newVal.patientId)
        ) {
          this.patientContinuousStays = [
            ...this.patientContinuousStays,
            {
              ...newVal,
              continuousStayId: null,
            },
          ];
        }
      }
    },
    oldAppointmentId() {
      this.loadOldAppointmentData();
    },
  },
  async mounted() {
    if (this.oldAppointmentId) {
      await this.loadOldAppointmentData();
    } else if (this.copyId) {
      await this.loadCopyAppointmentData();
    } else if (!this.modifiedTreatmentType) {
      setTimeout(() => {
        this.$refs.treatmentTypeSelect.selectUndefined();
      });
    }
  },
  destroyed() {
    this.resetAppointmentData();
  },
  methods: {
    ...mapActions("scheduleAppointment", [
      "resetAppointmentData",
    ]),
    ...mapMutations("appointmentListItem", ["saveAppointmentListItem",]),
    onHide() {
      this.$emit("cancel");
      this.errors = [];
    },
    async loadOldAppointmentData() {
      this.loading = true;

      try {
        const [{items: patients}, appointment] = await Promise.all([
          read("/api/patients", {appointmentId: this.oldAppointmentId}),
          read(`/api/appointments/${this.oldAppointmentId}`),
        ]);

        this.fillAppointmentData(patients, appointment);
        this.plan = appointment.plan;
        this.registrationNotes = appointment.registrationNotes;
        this.earlierPossibilityReason = appointment.earlierPossibility || {...earlierPossibilityReasonInitialData};
        this.showEarlierPossibility = !!appointment.earlierPossibility;
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    async loadCopyAppointmentData() {
      this.loading = true;

      try {
        const [{items}, appointment] = await Promise.all([
          read("/api/patients", {appointmentId: this.copyId}),
          read(`/api/appointments/${this.copyId}`),
        ]);

        this.fillAppointmentData(items, appointment);

      } catch(e) {
        this.errors = processResponseException(e);
      }

      this.loading = false;
    },
    fillAppointmentData(patients, appointment) {
      if (this.worker.workerId === appointment.mainSpecialist.workerId) {
        this.additionalWorkers = appointment.additionalSpecialists;
      } else {
        this.additionalWorkers = appointment.additionalSpecialists
          .filter((worker) => worker.workerId !== this.worker.workerId)
          .concat([appointment.mainSpecialist]);
      }

      if (!this.treatmentType) {
        if (this.worker.workerId === appointment.mainSpecialist.workerId) {
          this.modifiedTreatmentType = appointment.mainSpecialist.treatmentTypeId;
        } else {
          const newMainWorker = appointment.additionalSpecialists
            .find(worker => worker.workerId === this.worker.workerId);
          this.modifiedTreatmentType = newMainWorker
            ? newMainWorker.treatmentTypeId
            : this.modifiedTreatmentType;
        }
      }

      this.patientContinuousStays = appointment.patientContinuousStays.map(item => {
        const patient = patients.find(patient => patient.patientId === item.patientId) || {};
        return {
          ...item,
          ...patient,
        };
      });
    },
    async createAppointment(appointmentId) {
      const dataCreate = {
        startDate: stringifyDate(this.actualStartDate),
        endDate: stringifyDate(this.actualEndDate),
        continuousStay: true,
        patientContinuousStays: this.patientContinuousStays
          .map(({patientId, continuousStayId}) => ({patientId, continuousStayId})),
        mainWorker: {
          workerId: this.worker.workerId,
          treatmentTypeId: this.modifiedTreatmentType,
        },
        additionalWorkers: this.additionalWorkers || [],
        patientIds: this.patientContinuousStays.map(item => item.patientId),
        patientPresence: true,
        noPatients: false,
        locationType: "local",
        plan: this.plan,
        registrationNotes: this.registrationNotes ? this.registrationNotes: null,
        branchId: this.selectedBranch?.branchId,
        mobileTeamId: null,
        address: null,
        overtime: this.overtime,
        confirmationRequired: false,
        earlierPossibilityReason: this.earlierPossibilityReason.value
          ? this.earlierPossibilityReason
          : undefined,
      };

      await create(`/api/appointments/${appointmentId}`, dataCreate);
    },
    async rescheduleAppointment() {
      const dataUpdate = {
        startDate: stringifyDate(this.actualStartDate),
        endDate: stringifyDate(this.actualEndDate),
        continuousStay: true,
        patientContinuousStays: this.patientContinuousStays
          .map(({patientId, continuousStayId}) => ({patientId, continuousStayId})),
        mainWorker: {
          workerId: this.mainWorker.workerId,
          treatmentTypeId: this.modifiedTreatmentType,
        },
        additionalWorkers: (this.additionalWorkers || []),
        patientIds: this.patientContinuousStays.map(item => item.patientId),
        patientPresence: true,
        noPatients: false,
        locationType: "local",
        plan: this.plan,
        registrationNotes: this.registrationNotes ? this.registrationNotes: null,
        branchId: this.selectedBranch?.branchId,
        address: null,
        overtime: this.overtime,
        confirmationRequired: false,
        earlierPossibilityReason: this.earlierPossibilityReason.value ? this.earlierPossibilityReason: undefined,
      };

      await update(`/api/appointments/${this.oldAppointmentId}/reschedule`, dataUpdate);
    },
    async save() {
      this.loading = true;
      this.forbidden = false;

      try {
        let appointmentId;
        if (null != this.oldAppointmentId) {
          await this.rescheduleAppointment();
          appointmentId = this.oldAppointmentId;
        } else {
          appointmentId = generateUuid();
          await this.createAppointment(appointmentId);
        }
        this.saveAppointment(appointmentId, this.actualStartDate)
      } catch (exception) {
        if (exception instanceof ForbiddenException) {
          this.forbidden = true;
        }
        this.errors = processResponseException(exception);
        this.overtime = false;
      }
      this.loading = false;
    },
    changeTreatmentType(treatmentType) {
      this.modifiedTreatmentType = treatmentType?.value || null;
    },
    updateWorker(worker) {
      if (worker.workerId !== this.worker.workerId) {
        this.modifiedTreatmentType = null;
      }
    },
    async getEarlierPossibilityStatus(treatmentType) {
      if (!treatmentType) {
        return;
      }
      this.fetching = true;
      try {
        const {items} = await read("/api/appointment/earlier-possibility", {
          treatmentTypeId: treatmentType,
          startDate: stringifyDate(this.actualStartDate, DATE_FORMAT.DATE_TIME),
        });
        this.showEarlierPossibility = !!items.length;
      } catch(e) {
        console.error(e);
      }
      this.fetching = false;
    },
    saveAppointment(appointmentId, appointmentStart) {
      this.$emit("save", {
        appointmentId,
        appointmentStart: appointmentStart,
        treatmentType: this.modifiedTreatmentType,
        patients: this.patientContinuousStays.map(patient => ({...patient, continuousStayId: undefined})),
        patientPresence: true,
        worker: {name: `${this.mainWorker.name} ${this.mainWorker.surname}`},
        branchName: this.selectedBranch?.name,
        address: null,
        mobile: false,
        continuousStay: true,
        patientContinuousStays: this.patientContinuousStays
          .map(({patientId, continuousStayId}) => ({patientId, continuousStayId})),
      });
      const mainWorker = {
        name: this.worker.name,
        workerId: this.worker.workerId,
        treatmentTypeId: this.modifiedTreatmentType,
      };
      this.saveAppointmentListItem({
        patients: this.patientContinuousStays.map(patient => ({...patient, continuousStayId: undefined})),
        mobile: false,
        appointmentId: appointmentId,
        date: appointmentStart,
        group: false,
        removePatientAllowedFromGroup: false,
        cancelAllowedFromGroup: false,
        changePatientsAllowedFromGroup: false,
        status: "created",
        mainWorkerId: this.worker.workerId,
        mainWorker,
        workers: [mainWorker, ...this.additionalWorkers],
        continuousStay: true,
        patientContinuousStays: this.patientContinuousStays
          .map(({patientId, continuousStayId}) => ({patientId, continuousStayId})),
        branchId: this.selectedBranch?.branchId,
        branchName: this.selectedBranch?.name,
      });
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables";

::v-deep .modal-dialog.modal-xl {
  max-width: 95vw;
}

$select-margin: 5px;

.treatment-worker-select {
  display: flex;
  align-content: center;
  margin: -$select-margin;
}

.worker {
  width: 35%;
  margin: $select-margin;
}

.treatment-type {
  width: 65%;
  margin: $select-margin;
  border-collapse: initial;
}

.alert-purple {
  border: 2px $primary solid;
}
</style>
