<template>
  <b-form-group
    label="Upoważnienie do uzyskiwania informacji o stanie zdrowia i udzielonych świadczeń zdrowotnych"
  >
    <b-form-radio-group
      id="nfzHealthInformationAndServices"
      v-model="healthInformationAndServices"
      name="nfzHealthInformationAndServices"
      stacked
      @input="update"
    >
      <b-form-radio
        :value="true"
        :state="state('permissionForHealthInformationAndProvidedHealthServices')"
      >
        Upoważniam do uzyskiwania informacji o stanie zdrowia i udzielonych świadczeń zdrowotnych.
      </b-form-radio>
      <b-form-radio
        :value="false"
        :state="state('permissionForHealthInformationAndProvidedHealthServices')"
      >
        Nie upoważniam do uzyskiwania informacji o stanie zdrowia i udzielonych świadczeń zdrowotnych..
      </b-form-radio>
    </b-form-radio-group>
    <error-message
      :errors="errors"
      field="permissionForHealthInformationAndProvidedHealthServices"
    />
  </b-form-group>
</template>

<script>
import ErrorMessage from "../Form/ErrorMessage";

export default {
  name: "NFZHealthInformationAndServicesPermission",
  components: {ErrorMessage},
  props: {
    value: {type: Boolean, default: null},
    errors: {type: Array, default: () => []},
  },
  data() {
    return {
      healthInformationAndServices: this.value
    };
  },
  methods: {
    update(){
      this.$emit("input", this.healthInformationAndServices);
    },
    state(field){
      return this.errors.find((error) => error.field === field) ? false : null;
    },
  }
}
</script>

