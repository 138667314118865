<template>
  <div class="row">
    <div class="col-12">
      <div class="card-box">
        <h4>Dane osobowe</h4>
        <hr>
        <div class="row">
          <div class="col-md-6 col-lg-4">
            <b-form-group
              id="name"
              :label="nfzConfiguration ? 'Imiona' : 'Imię'"
              label-for="nameInput"
            >
              <b-form-input
                id="nameInput"
                v-model.trim="personalDataForm.name"
                :state="state('name')"
                :placeholder="nfzConfiguration ? 'Wprowadź imiona' : 'Wprowadź imię'"
                @input="updateModel"
              />
              <error-message
                :errors="errors"
                field="name"
              />
            </b-form-group>
          </div>
          <div class="col-md-6 col-lg-4">
            <b-form-group
              id="surname"
              label="Nazwisko"
              label-for="surnameInput"
            >
              <b-form-input
                id="surnameInput"
                v-model.trim="personalDataForm.surname"
                :state="state('surname')"
                placeholder="Wprowadź nazwisko"
                @input="updateModel"
              />
              <error-message
                :errors="errors"
                field="surname"
              />
            </b-form-group>
          </div>
          <div class="col-md-6 col-lg-4">
            <b-form-group
              id="birthdate"
              label="Data urodzenia"
              label-for="birthdateInput"
            >
              <birthdate-input
                v-model="personalDataForm.birthdate"
                :errors="errors"
                @input="updateModel"
              />
              <error-message
                :errors="errors"
                field="birthdate"
              />
            </b-form-group>
          </div>
          <div class="col-md-6 col-lg-4">
            <b-form-group
              id="pesel"
              label="Numer PESEL"
              label-for="peselInput"
            >
              <verify-pesel
                id="peselInput"
                v-model.trim="personalDataForm.pesel"
                :state="state('pesel')"
                @input="updateModel"
              />
              <error-message
                :errors="errors"
                field="pesel"
              />
            </b-form-group>
          </div>
          <div
            v-if="nfzConfiguration"
            class="col-md-6 col-lg-4"
          >
            <b-form-group
              id="identificationDocumentType"
              label="Typ dokumentu toższamości"
              label-for="identificationDocumentTypeSelect"
            >
              <identification-document-select
                id="identificationDocumentTypeSelect"
                v-model="personalDataForm.identificationDocument.type"
                :state="state('identificationDocument.type')"
                @input="updateModel"
              />
              <error-message
                :errors="errors"
                field="identificationDocument.type"
              />
            </b-form-group>
          </div>
          <div
            v-if="nfzConfiguration"
            class="col-md-6 col-lg-4"
          >
            <b-form-group
              id="identificationDocumentNumber"
              label="Numer dokumentu tożsamości"
              label-for="identificationDocumentNumberInput"
            >
              <b-form-input
                id="identificationDocumentNumberInput"
                v-model.trim="personalDataForm.identificationDocument.number"
                :state="state('identificationDocument.number')"
                placeholder="Wpisz numer dokumentu tożsamości"
                @input="updateModel"
              />
              <error-message
                :errors="errors"
                field="identificationDocument.number"
              />
            </b-form-group>
          </div>
          <div class="col-md-6 col-lg-4">
            <b-form-group
              id="phone"
              label="Telefon kontaktowy"
              label-for="phoneInput"
            >
              <b-form-input
                id="phoneInput"
                v-model.trim="personalDataForm.phoneNumber"
                :state="state('phoneNumber')"
                placeholder="Wprowadź numer telefonu"
                @input="updateModel"
              />
              <error-message
                :errors="errors"
                field="phoneNumber"
              />
            </b-form-group>
          </div>
          <div
            v-if="nfzConfiguration"
            class="col-md-6 col-lg-4"
          >
            <b-form-group
              id="email"
              label="E-mail"
              label-for="emailInput"
            >
              <b-form-input
                id="emailInput"
                v-model.trim="personalDataForm.email"
                :state="state('email')"
                placeholder="Wprowadź email"
                @input="updateModel"
              />
              <error-message
                :errors="errors"
                field="email"
              />
            </b-form-group>
          </div>
          <div class="col-md-6 col-lg-4">
            <b-form-group
              id="registrationDate"
              label="Data rejestracji"
              label-for="registrationDateInput"
            >
              <date-picker
                id="registrationDateInput"
                v-model="personalDataForm.registeredDate"
                :format="'YYYY-MM-DD'"
                :state="state('registeredDate')"
                @input="updateModel"
              />
              <error-message
                :errors="errors"
                field="registeredDate"
              />
            </b-form-group>
          </div>
          <div
            v-if="!nfzConfiguration"
            class="col-md-6 col-lg-4"
          >
            <b-form-group
              id="fileNumber"
              label="Numer teczki"
              label-for="fileNumberInput"
            >
              <b-form-input
                id="fileNumberInput"
                v-model.trim="personalDataForm.fileNumber"
                :state="state('fileNumber')"
                placeholder="Wprowadź numer teczki"
                @input="updateModel"
              />
              <error-message
                :errors="errors"
                field="fileNumber"
              />
            </b-form-group>
          </div>
          <div
            v-if="!nfzConfiguration"
            class="col-md-6 col-lg-4"
          >
            <b-form-group
              id="externalNumber"
              label="Numer zewnętrzny"
              label-for="externalNumberInput"
            >
              <b-form-input
                id="externalNumberInput"
                v-model.trim="personalDataForm.externalNumber"
                :state="state('externalNumber')"
                placeholder="Wprowadź numer zewnętrzny"
                @input="updateModel"
              />
              <error-message
                :errors="errors"
                field="externalNumber"
              />
            </b-form-group>
          </div>
          <div
            v-if="patientAnonymous|| (!patientAnonymous && nfzConfiguration)"
            class="col-md-6 col-lg-4"
          >
            <b-form-group
              id="sex"
              label="Płeć"
              label-for="sexSelectInput"
            >
              <sexSelect
                id="sexSelectInput"
                v-model="personalDataForm.sex"
                :state="state('sex')"
                @input="updateModel"
              />
              <error-message
                :errors="errors"
                field="sex"
              />
            </b-form-group>
          </div>
          <div
            v-if="!patientAnonymous && !nfzConfiguration"
            class="col-md-6 col-lg-4"
          >
            <b-form-group
              id="ops"
              label="Pacjent skierowany z OPS"
              label-for="opsInput"
            >
              <ops-select
                id="opsInput"
                v-model="personalDataForm.ops"
                :state="state('ops')"
                @input="updateModel"
              />
              <error-message
                :errors="errors"
                field="ops"
              />
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BirthdateInput from "../Form/Input/BirthdateInput.vue"
import DatePicker from "../Form/DatePicker/DatePicker.vue"
import ErrorMessage from "../Form/ErrorMessage";
import VerifyPesel from "./VerifyPesel";
import SexSelect from "../Form/Select/SexSelect";
import decodeBirthday from "../../utils/peselBirthDay";
import {mapState} from "vuex";
import OpsSelect from "@/components/Form/Select/OpsSelect";
import IdentificationDocumentSelect from "../Form/Select/IdentificationDocumentSelect";

export default {
  components: {
    IdentificationDocumentSelect,
    OpsSelect,
    VerifyPesel,
    BirthdateInput,
    DatePicker,
    ErrorMessage,
    SexSelect,
  },
  props: {
    errors: {type: Array, default: () => []},
    value: {type: Object, required: true},
  },
  data() {
    return {personalDataForm: null};
  },
  computed: {
    ...mapState({
      patientAnonymous: state => state.clinicParameters.parameters.patientAnonymous,
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration
    }),
  },
  watch:{
    value: {
      deep: true,
      immediate: true,
      handler(value) {
        const registeredDate = new Date();
        this.personalDataForm = {
          identificationDocument: {
            type: null,
            number: null
          },
          ...value,
          registeredDate,
        };
      },
    },
    "personalDataForm.pesel": function (pesel){
      const birthDate = decodeBirthday(pesel);
      if (birthDate.day && birthDate.month && birthDate.year) {
        this.personalDataForm.birthdate = new Date(birthDate.year, birthDate.month - 1, birthDate.day);
      }
    },
  },
  mounted() {
    this.updateModel();
  },
  methods: {
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    updateModel() {
      this.$emit("input", this.personalDataForm);
    }
  },
}
</script>
