













































import IsGranted from "../../../components/IsGranted.vue";
import {Component, Prop} from "vue-property-decorator";
import update from "../../../rest/update";
import processResponseException from "../../../utils/errors/processResponseException";
import {StatisticAppointmentItem} from "../../../types/AppointmentDocumentStatistics";
import ResizableTextarea from "../../Form/Textarea/ResizableTextarea.vue";
import Vue from "vue";

@Component({
  name: "AppointmentDocumentStatisticItemErrorMessage",
  components: {
    ResizableTextarea,
    IsGranted
  }
})

export default class AppointmentDocumentStatisticItemErrorMessage extends Vue {
  @Prop({type: Object, required: true}) readonly item!: StatisticAppointmentItem;

  textErrorMessageEditable = false;

  get errorMessages() {
    if (this.item?.errorMessage) {
      const messages = this.item.errorMessage.length
        ? this.item.errorMessage
        : !Array.isArray(this.item.errorMessage)
          ? Object.values(this.item.errorMessage) // parse object to array
          : [];

      return messages.join("\r\n")
    }

    return ""
  }

  formErrorMessage = this.errorMessages;

  editErrorMessage() {
    this.formErrorMessage = this.errorMessages;
    this.textErrorMessageEditable = true;
  }
  cancelEditErrorMessage() {
    this.textErrorMessageEditable = false;
  }

  async saveErrorMessage () {
    this.$emit("updateErrors", [])
    const id = this.item.appointmentDocumentId

    try {
      await update(`/api/appointment/document/${id}/update-statistics-errors`, {
        errorDescription: [this.formErrorMessage], // backend requires array
        appointmentDocumentId: id,
      });

      await this.$emit("updateList");
      this.textErrorMessageEditable = false;
    } catch (exception) {
      const errors = processResponseException(exception);
      this.$emit("updateErrors", errors);
    }
  }
}
