<template>
  <div>
    <ul class="m-0 p-0 list-unstyled">
      <is-granted
        :privileges="['APPOINTMENT_DETAILS']"
        :subject="appointmentId"
        component="li"
      >
        <tooltip
          tooltip-content="Szczegóły wizyty"
          @click="openAppointmentInfoModal"
        >
          <i
            class="fas fa-info-circle text-info"
            style="font-size: 1.5rem"
          />
        </tooltip>
      </is-granted>
      <is-granted
        :privileges="['RESCHEDULE_APPOINTMENT', 'GET_RESCHEDULE_APPOINTMENT']"
        :subject="appointmentId"
        component="li"
      >
        <tooltip
          tooltip-content="Edytuj / Przełóż wizytę"
          @click="editAppointment"
        >
          <i
            :class="scheduleDataLoading ? 'fa-spin fa-spinner' : 'fa-pencil-alt'"
            class="fas"
            style="font-size: 1.5rem"
          />
        </tooltip>
      </is-granted>
      <is-granted
        :privileges="['CHECK_IN_APPOINTMENT']"
        :subject="appointmentId"
        component="li"
      >
        <tooltip
          tooltip-content="Potwierdź wizytę"
          @click="openCheckInModal"
        >
          <i
            class="fa fa-address-card text-primary"
            style="font-size: 1.5rem"
          />
        </tooltip>
      </is-granted>
      <is-granted
        :privileges="['WITHDRAW_APPOINTMENT_CHECK_IN_COMMAND']"
        :subject="appointmentId"
        component="li"
      >
        <tooltip
          tooltip-content="Cofnij potwierdzenie wizyty"
          @click="openCheckInWithdrawalModal"
        >
          <i
            class="fa fa-history"
            style="font-size: 1.5rem"
          />
        </tooltip>
      </is-granted>
      <is-granted
        :privileges="['OPEN_APPOINTMENT']"
        :subject="appointmentId"
        component="li"
      >
        <tooltip
          tooltip-content="Otwórz wizytę"
          @click="openOpenAppointmentModal"
        >
          <i
            class="fa fa-door-open text-success"
            style="font-size: 1.5rem"
          />
        </tooltip>
      </is-granted>
      <is-granted
        :privileges="['REOPEN_CANCELED_APPOINTMENT']"
        :subject="appointmentId"
        component="li"
      >
        <tooltip
          tooltip-content="Otwórz ponownie anulowaną wizytę"
          @click="openReopenCanceledAppointmentModal"
        >
          <i
            class="fa fa-reply text-success"
            style="font-size: 1.5rem"
          />
        </tooltip>
      </is-granted>
      <is-granted
        :privileges="['SHOW_APPOINTMENT_DOCUMENT']"
        :subject="appointmentId"
        component="li"
      >
        <tooltip
          tooltip-content="Pokaż dokument"
          @click="showDocument"
        >
          <i
            class="fa fa-eye"
            style="font-size: 1.5rem"
          />
        </tooltip>
      </is-granted>
      <is-granted
        v-if="removePatientAllowedFromGroup"
        :privileges="['DELETE_APPOINTMENT_PATIENTS']"
        :subject="appointmentId"
        component="li"
      >
        <tooltip
          tooltip-content="Wypisz pacjenta"
          @click="openRemovePatientFromAppointmentModal"
        >
          <i
            class="fa fa-user-minus text-pink"
            style="font-size: 1.5rem"
          />
        </tooltip>
      </is-granted>
      <is-granted
        v-if="changePatientsAllowedFromGroup"
        :privileges="['ADD_PATIENTS_TO_APPOINTMENT']"
        :subject="appointmentId"
        component="li"
      >
        <tooltip
          tooltip-content="Dodaj/Usuń pacjentów"
          @click="openChangePatientsModal"
        >
          <i
            class="fa fa-user-cog text-success"
            style="font-size: 1.5rem"
          />
        </tooltip>
      </is-granted>
      <template v-if="!group || cancelAllowedFromGroup">
        <is-granted
          :privileges="['CANCEL_APPOINTMENT_DEFERRED']"
          :subject="appointmentId"
          component="li"
        >
          <tooltip
            tooltip-content="Odwołaj wizytę"
            @click="openCancelAppointmentDeferredModal"
          >
            <i
              class="fa fa-ban text-pink"
              style="font-size: 1.5rem"
            />
          </tooltip>
        </is-granted>
        <is-granted
          :privileges="['CANCEL_APPOINTMENT']"
          :subject="appointmentId"
          component="li"
        >
          <tooltip
            tooltip-content="Odwołaj wizytę"
            @click="openCancelAppointmentModal"
          >
            <i
              class="fa fa-ban text-pink"
              style="font-size: 1.5rem"
            />
          </tooltip>
        </is-granted>
      </template>
    </ul>
    <ul class="m-0 p-0 list-unstyled">
      <tooltip
        v-if="!patientId && patients.length === 1"
        component="li"
        tooltip-content="Karta pacjenta"
        @click="showPatientCard"
      >
        <i
          class="fa fa-user"
          style="font-size: 1.5rem"
        />
      </tooltip>
      <is-granted
        v-if="!continuousStay"
        :privileges="['GET_APPOINTMENT_WORKERS_WITH_TREATMENT']"
        :subject="appointmentId"
        component="li"
      >
        <tooltip
          tooltip-content="Raportuj typ usługi"
          @click="openReportTreatmentTypeModal"
        >
          <i
            class="fa fa-flag text-primary"
            style="font-size: 1.5rem"
          />
        </tooltip>
      </is-granted>
      <is-granted
        :privileges="['CHANGE_APPOINTMENT_DATE']"
        :subject="appointmentId"
        component="li"
      >
        <tooltip
          tooltip-content="Zmień datę wizyty"
          @click="openEditAppointmentDateModal"
        >
          <i
            class="fas fa-clock text-warning"
            style="font-size: 1.5rem"
          />
        </tooltip>
      </is-granted>
    </ul>
  </div>
</template>
<script>

import IsGranted from "../../IsGranted";
import Tooltip from "../../Tooltip";
import {appointmentItemActionsMixin} from "../../../mixins/appointmentItemActionsMixin";

export default {
  name: "AppointmentItemActionsIcons",
  components: {
    Tooltip,
    IsGranted,
  },
  mixins: [appointmentItemActionsMixin],
};
</script>
<style scoped lang="scss">
  @import "../../../styles/variables";

  ul li {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;

    &:empty {
      margin-right: 0;
    }
  }

  .btn-pink {
    background-color: $pink;
    color: $white;

    &:focus, &.focus,
    &:active, &.active,
    &:hover, &.hover {
      background-color: darken($pink, 5%);
    }
  }

  .appointment-action {
    white-space: normal;
  }
</style>
