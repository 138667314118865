<template>
  <b-modal
    id="add-attachment-appointment"
    title="Wybierz wizytę na której została pozyskana dokumentacja"
    no-close-on-backdrop
    size="xl"
    title-tag="h3"
    @hide="resetData"
    @show="onShow"
  >
    <template #default>
      <attachment-appointment-filters
        v-model="formData"
        :is-manager="currentUserIsManager"
        :loading="loading"
        :patient-id="patientId"
        @submit="getAppointments"
      />
      <error-message :errors="errors" />
      <attachment-appointments-table
        v-model="pagination"
        :appointment-document-id="appointmentDocumentId"
        :attachment-id="attachmentId"
        :is-manager="currentUserIsManager"
        :items="items"
        :patient-id="patientId"
        @input="update"
      />
    </template>
    <template #modal-footer>
      <b-btn
        variant="secondary"
        @click="closeModal"
      >
        Zamknij
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
import processResponseException from "../../../../utils/errors/processResponseException";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import stringifyDate from "../../../../utils/date/stringifyDate";
import read from "../../../../rest/read";
import parseDate from "../../../../utils/date/parseDate";
import {mapState} from "vuex";
import AttachmentAppointmentFilters from "./AttachmentAppointmentFilters";
import AttachmentAppointmentsTable from "./AttachmentAppointmentsTable";
import ErrorMessage from "../../../Form/ErrorMessage";
import {isGranted} from "../../../../security/isGranted";

export default {
  name: "AddAttachmentAppointment",
  components: {
    ErrorMessage,
    AttachmentAppointmentsTable,
    AttachmentAppointmentFilters,
  },
  mixins: [errorsMixin],
  props: {
    attachmentId: {type: String, default: null},
    patientId: {type: String, required: true},
    appointmentDocumentId: {type: String, default: null},
  },
  data() {
    return {
      loading: false,
      items: [],
      pagination: {
        currentPage: 1,
        perPage: 20
      },
      formData: this.getInitialState(),
      currentUserIsManager: false,
    };
  },
  computed: {
    ...mapState("currentUser", ["user"]),
    endDate() {
      if (!this.formData.endDate) {
        return null;
      }
      const date = new Date(this.formData.endDate);
      date.setHours(23, 59, 59);
      return stringifyDate(date);
    },
    formDataWorkerIds() {
      if ("object" === typeof this.formData.workers[0]) {
        return this.formData.workers.map(option => option.value);
      }
      return this.formData.workers;
    },
  },
  async mounted() {
    this.currentUserIsManager = await isGranted(["ROLE_MANAGER", "ROLE_ADMIN"]);
  },
  methods: {
    resetData() {
      this.items =[];
      this.formData = this.getInitialState();
      this.pagination = {
        currentPage: 1,
        perPage: 20,
      };
    },
    closeModal() {
      this.$bvModal.hide("add-attachment-appointment");
      this.$emit("close");
    },
    getInitialState() {
      const startDate = this.getStartDate();
      return {
        startDate,
        endDate: null,
        treatmentType: null,
        statuses: ["opened", "finished"],
        workers: [],
        meetingType: null,
        plan: "",
        patientOptions: [],
      };
    },
    getStartDate() {
      const date = new Date();
      date.setHours(0,0,0,0);
      return date;
    },
    async onShow() {
      this.formData.workers = this.currentUserIsManager ? [] : [this.user.workerId];
      await this.getAppointments();
    },
    async getAppointments() {
      this.loading = true;
      this.errors = [];
      const params = {
        ...this.formData,
        endDate: this.endDate,
        orderDesc: true,
        page: this.pagination.currentPage,
        patientId: this.patientId,
        perPage: this.pagination.perPage,
        startDate: this.formData.startDate ? stringifyDate(this.formData.startDate) : null,
        workerIds: this.formDataWorkerIds,
        continuousStay: false,
      };
      try {
        const {pagination, items} = await read("/api/appointments", params);
        this.pagination = pagination;
        this.items = items.reduce((list, item) => {
          if (this.currentUserIsManager
            || item.workers.findIndex(worker => worker.workerId === this.user.workerId) !== -1) {
            list.push(this.processItem(item))
          }
          return list;
        }, []);
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    getAppointmentDocumentId(documents, workerId) {
      const appointmentDocument = documents
        && documents.find(document => document.patientId === this.patientId && document.workerId === workerId);
      return appointmentDocument ? appointmentDocument.documentId : null
    },
    selectedDocument(documents, workerId) {
      return this.appointmentDocumentId
        && this.getAppointmentDocumentId(documents, workerId) === this.appointmentDocumentId;
    },
    processItem(item) {
      return {
        ...item,
        date: parseDate(item.date),
        workers: item.workers.map(worker => ({
          ...worker,
          hasSelectedDocument: this.selectedDocument(item.documents, worker.workerId),
        }))
      };
    },
    update($event) {
      this.$emit("input", $event);
      this.closeModal();
    }
  },
}
</script>

<style scoped lang="scss">
  ::v-deep .modal-dialog.modal-xl {
    max-width: 95vw;
  }

  .appointment-worker {
    flex: 0 1 20%;
  }

  .appointment-treatment {
    flex: 1 1 60%;
  }

  .appointment-assign {
    flex: 1 1 20%;
  }
</style>
